/* eslint-disable no-param-reassign */
import { ActionType, getType } from 'typesafe-actions';
import produce, { castDraft, Draft } from 'immer';
import { initialPoolsState, PoolsState } from 'store/ducks/pools/state';
import * as PoolsAction from './actionCreators';

type PoolsActionType = ActionType<typeof PoolsAction>;

export const poolsReducer = produce((state: Draft<PoolsState>, action: PoolsActionType) => {
  switch (action.type) {
    case getType(PoolsAction.fetchPoolsAction.request): {
      state.pools.loading = true;
      state.pools.loaded = false;
      state.pools.error = undefined;
      break;
    }

    case getType(PoolsAction.fetchPoolsAction.success): {
      state.pools.loading = false;
      state.pools.loaded = true;
      state.pools.items = castDraft(action.payload.pools);
      state.counters = action.payload.counters;
      break;
    }

    case getType(PoolsAction.fetchPoolsAction.failure): {
      state.pools.loading = false;
      state.pools.error = action.payload;
      break;
    }

    default: {
      //
    }
  }
}, initialPoolsState);
