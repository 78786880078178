import React, { useCallback, FocusEvent, useRef } from 'react';
import './TagField.css';
import { reject, equals } from 'ramda';
import TagFieldItem from './TagFieldItem';

export interface Option {
  value: string;
  label?: string;
  disabled?: boolean;
  groupKey?: string;
}

enum TagState {
  On,
  Off,
  OnPartial,
}

export type TagInputProps = Props;

interface Props {
  options: Option[];
  value?: string[];
  onChange?: (value: string[]) => void;
  id?: string;
  densed?: boolean;
  maxValues?: number;
  validationError?: string;
  submitError?: string;
  name?: string;
  restrictGroups?: boolean;
  color?: 'default' | 'primary';
  // Колбек, который вызывается перед изменением, если передать false, то изменения не будут применены
  beforeChange?: (newValue: string[]) => true | false;
}

const TagField: React.FC<Props> = (props: Props) => {
  const {
    options,
    value,
    onChange,
    maxValues = 100,
    validationError,
    submitError,
    color = 'default',
    name,
    restrictGroups,
    beforeChange,
  } = props;

  const currentValue = value || [];

  const firstOptionRef = useRef<HTMLLIElement>(null);
  const getCurrentValue = useCallback(() => currentValue, [currentValue]);

  const handleSelect = (newValue: string) => {
    let freshValue = getCurrentValue();
    if (freshValue.includes(newValue)) {
      freshValue = reject(equals(newValue), freshValue);
    } else if (maxValues === 1 && freshValue.length === 1) {
      freshValue = [newValue];
    } else {
      freshValue = freshValue.length < maxValues ? [...freshValue, newValue] : freshValue;
    }
    // Если мы допускаем выбор только в пределах одной группы, то делаем проверку
    if (restrictGroups) {
      const selectedItem = options.find((item) => item.value === newValue);
      if (selectedItem) {
        freshValue = freshValue.filter((item) => {
          const currentItem = options.find((val) => val.value === item);
          return currentItem && currentItem.groupKey === selectedItem.groupKey;
        });
      }
    }
    if (beforeChange) {
      if (!beforeChange(freshValue)) {
        return;
      }
    }
    if (onChange) {
      onChange(freshValue);
    }
  };

  const handleFocus = (event: FocusEvent<any>) => {
    if (firstOptionRef && firstOptionRef.current) {
      firstOptionRef.current.focus();
    }
  };

  return (
    <>
      <input
        style={{
          opacity: 0,
          height: 0,
          width: 0,
          padding: 0,
          margin: 0,
          border: 'none',
          position: 'absolute',
        }}
        type="text"
        name={name}
        onFocus={handleFocus}
      />
      <div className={['geecko-tagfield', `geecko-tagfield--color-${color}`].join(' ')}>
        <ul className="geecko-tagfield__options" tabIndex={-1} role="listbox">
          {options &&
            options.map((option, index) => (
              <TagFieldItem
                ref={index === 0 ? firstOptionRef : null}
                key={option.value}
                label={option.label}
                value={option.value}
                selected={currentValue.includes(option.value) ? TagState.On : TagState.Off}
                onSelect={handleSelect}
                disabled={
                  option.disabled ||
                  (!currentValue.includes(option.value) && currentValue.length === maxValues && maxValues > 1)
                }
              />
            ))}
        </ul>
      </div>
      {(validationError || submitError) && (
        <div className="geecko-tagfield-error">{validationError || submitError}</div>
      )}
    </>
  );
};

export default TagField;
