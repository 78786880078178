import { AppState } from 'store';
import { createSelector } from 'redux-views';
import { UserGroup } from 'models/User';
import { usualCVFormSteps, CvFormStep } from '../../../../pages/Candidate/Cv/CandidateEditCvPage/CandidateEditCvPage';

export const authSelector = (state: AppState) => state.auth || undefined;

export const authLoadingSelector = createSelector([authSelector], (auth) => !!(auth && auth.loading));

/**
 * Возвращает токен текущего пользователя или undefined
 */
export const authTokenSelector = createSelector([authSelector], (auth) =>
  auth && auth.token ? auth.token : undefined,
);

/**
 * Возвращает объект аутентифицированного пользователя или undefined
 */
export const authUserSelector = createSelector([authSelector], (auth) => auth && auth.user);

/**
 * Возвращает объект аутентифицированного компании или undefined
 */
export const authCompanySelector = createSelector([authSelector], (auth) => auth && auth.company);

/**
 * Возвращает объект персонального менеджера или undefined
 */
export const authManagerSelector = createSelector([authSelector], (auth) => auth && auth.manager);

/**
 * Возвращает объект статуса проверки резюме или undefined
 */
export const authResumeStatus = createSelector([authSelector], (auth) => auth && auth.resumeStatus);

/**
 * Возвращает объект аутентифицированного кандидата или undefined
 */
export const authCandidateSelector = createSelector([authSelector], (auth) => auth && auth.candidate);

/**
 * Возвращает тип аккаунта текущего пользователя или public
 */
export const authUserGroupSelector = createSelector(
  [authUserSelector, authCompanySelector, authCandidateSelector],
  (user, company, candidate): UserGroup => {
    if (user && (candidate || company)) {
      return candidate ? 'candidate' : 'company';
    }
    return 'public';
  },
);

export const authHomeUrlSelector = createSelector([authUserGroupSelector], (group) => {
  switch (group) {
    case 'candidate':
      return '/personal-offer';
    case 'company':
      return '/vacancies';
    default:
      return '/';
  }
});

/**
 * Возвращает аутентифицированы ли мы
 */
export const isAuthenticatedSelector = createSelector(
  [authTokenSelector, authUserSelector],
  (token, user) => !!(token && user),
);

/**
 * Возвращает true/false, есть ли текущего кандидата полностью заполненное CV
 */
export const authCandidateHasCvSelector = createSelector(
  [authCandidateSelector],
  (candidate) => !!(candidate && candidate.surveyedAt),
);

/**
 * Возвращает объект с информацией о статусе импорта CV текущего кандидата
 */
export const authCandidateCvImportSelector = createSelector([authSelector], (auth) => auth && auth.candidateCvImport);

/**
 * Возвращает статус импорта CV текущего кандидата
 */
export const authCandidateCvImportStatusSelector = createSelector(
  [authCandidateCvImportSelector],
  (candidateCvImport) => candidateCvImport && candidateCvImport.status,
);

/**
 * Возвращает true, если CV кандидата успешно импортировано
 */
export const authCandidateCvHasImportedSelector = createSelector(
  [authCandidateCvImportStatusSelector],
  (status) => !!(status && status === 'finished'),
);

/**
 * Возвращает ссылку на импортируемое CV текущего кандидата
 */
export const authCandidateCvImportLinkSelector = createSelector(
  [authCandidateCvImportSelector],
  (candidateCvImport) => candidateCvImport && candidateCvImport.task && candidateCvImport.task.link,
);

/**
 * Возвращает true/false нужно ли показывать шаг импорта CV
 */
export const authCandidateCvShowImportSelector = createSelector(
  [authCandidateHasCvSelector, authCandidateCvHasImportedSelector],
  (hasCv, hasImportedCv) => !hasCv && !hasImportedCv,
);

/**
 * Возвращает последний сохраненный шаг формы CV текущего кандидата
 */
export const authCandidateCvFormStepSelector = createSelector([authCandidateSelector], (candidate) => {
  const stateFormStep = candidate && candidate.formProgress;
  if (stateFormStep && usualCVFormSteps.indexOf(stateFormStep as CvFormStep) >= 0) {
    return stateFormStep as CvFormStep;
  }
  return undefined;
});

/**
 * Возвращает доступен ли суперпоиск для текущего кандидата
 */
export const authCandidateSuperSearchAvailableSelector = createSelector(
  [authCandidateSelector],
  (candidate) => candidate && candidate.isSearchAvailable,
);

/**
 * Возвращает статус поиска текущего кандидата или undefined
 */
export const authCandidateSearchStatusSelector = createSelector(
  [authCandidateSelector],
  (candidate) => candidate && candidate.searchStatus,
);

/**
 * Возвращает тип получения контактов кандидата или undefined
 */
export const authCandidateContactAccessTypeSelector = createSelector(
  [authCandidateSelector],
  (candidate) => candidate && candidate.contactAccessType,
);

export const authMenuRoleTitleSelector = createSelector(
  [authUserGroupSelector, authCompanySelector, authCandidateSelector],
  (userGroup, company, candidate) => {
    switch (userGroup) {
      case 'company':
        return company && company.name;
      case 'candidate':
        return candidate && candidate.profession;
      default:
        return undefined;
    }
  },
);
