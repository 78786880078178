import React, { FC, MouseEvent, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDropzone } from 'react-dropzone';
import './PhotoUploader.css';
import classNames from 'classnames';
import { AnyObject } from 'models/BaseTypes';
import { decodeUser } from 'models/User';
import Button from 'components/v2/Button/Button';
import UserRoundedIcon from '../../Icons/UserRoundedIcon';
import PhotoIcon from '../../Icons/PhotoIcon';
import { useApiInstance } from '../../../api';
import TrashRounded from '../../Icons/TrashRounded/TrashRounded';
import Spinner from '../../Spinner/Spinner';

interface Props {
  label?: string;
  value: string;
  onChange: (imageURL: string) => void;
}

const PHOTO_API_URL = '/company/user/avatar';

const PhotoUploader: FC<Props> = ({ value, label, onChange }) => {
  const intl = useIntl();
  const fetchApi = useApiInstance();
  const [isLoading, setIsLoading] = useState(false);

  const handleRemove = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onChange('');
    fetchApi.delete(PHOTO_API_URL);
  };

  const handleSelectFiles = (acceptedFiles: File[]) => {
    if (acceptedFiles.length) {
      setIsLoading(true);
      const formData = new FormData();
      formData.append('avatar', acceptedFiles[0]);
      fetchApi
        .post(PHOTO_API_URL, formData, {
          headers: {
            'content-type': 'multipart/form-data',
          },
        })
        .then((res: AnyObject) => {
          setIsLoading(false);
          const profileData = decodeUser(res.data);
          onChange(profileData.avatar || '');
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else {
      onChange('');
      fetchApi.delete(PHOTO_API_URL);
    }
  };

  const handleDrop = (acceptedFiles: File[]) => {
    handleSelectFiles(acceptedFiles);
  };

  const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
    accept: ['image/jpeg', 'image/png'],
    onDrop: handleDrop,
  });

  const wrapperClassName = useMemo(
    () =>
      classNames('geecko-photo-uploader-v2__wrapper', {
        'geecko-photo-uploader-v2__wrapper--active': isDragAccept,
        'geecko-photo-uploader-v2__wrapper--reject': isDragReject,
      }),
    [isDragAccept, isDragReject],
  );

  return (
    <div className={wrapperClassName} {...getRootProps()}>
      <input
        multiple={false}
        aria-label={label}
        {...getInputProps()}
        disabled={isLoading}
        accept="image/jpeg,image/png"
      />
      <div className="geecko-photo-uploader-v2__inner">
        <div className="geecko-photo-uploader-v2__avatar-wrapper">
          {value && <img src={value} alt="" />}
          {!value && <UserRoundedIcon size={24} />}
          {isLoading && (
            <div className="geecko-photo-uploader-v2__avatar-uploading">
              <Spinner color="#fff" size={24} />
            </div>
          )}
        </div>
        <div className="geecko-photo-uploader-v2__content-wrapper">
          <div className="geecko-photo-uploader-v2__main-text">
            <PhotoIcon size={16} />
            <div>{intl.formatMessage({ id: 'app.upload.fromcomputer' })}</div>
          </div>
          <div className="geecko-photo-uploader-v2__second-text">
            {intl.formatMessage({ id: 'app.upload.photo.requirements' })}
          </div>
          <div className="geecko-photo-uploader-v2__second-text">
            {intl.formatMessage({ id: 'app.upload.photo.movehere' })}
          </div>
        </div>
        {value && (
          <div className="geecko-photo-uploader-v2__remove-wrapper">
            <Button
              variant="ghost"
              color="secondary"
              size="sm"
              iconLeft={<TrashRounded size={16} />}
              onClick={handleRemove}
            >
              {intl.formatMessage({ id: 'app.users.list.action.delete' })}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PhotoUploader;
