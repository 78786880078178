import React from 'react';

interface Props {
  size? : number;
}

const RemoveIcon: React.FC<Props> = (props: Props) => {
  const { size = 8 } = props;
  return (
    <>
      <svg
        viewBox="0 0 8 8"
        width={size}
        height={size}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 7L7 1 M7 7L1 1"
          stroke="currentColor"
        />
      </svg>
    </>
  );
};

export default RemoveIcon;
