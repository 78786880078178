import React, { FC } from 'react';
import { Field } from 'react-final-form';

interface Props {
  field: string;
  is?: any;
  condition?: (value: any) => boolean;
}

const FormCondition: FC<Props> = ({ field, is, condition, children }) => (
  <Field name={field} subscription={{ value: true }}>
    {({ input: { value } }) => {
      if (condition) {
        return condition(value) ? children : null;
      }

      return (value === is ? children : null);
    }}
  </Field>
);

export default FormCondition;
