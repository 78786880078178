/* eslint-disable @typescript-eslint/ban-ts-ignore */
import React, {
  forwardRef, HTMLProps, FocusEvent, useState,
} from 'react';
import classNames from 'classnames';
import TextareaAutosize from 'react-textarea-autosize';
import './Input.css';

export type TextareaProps = Props;

interface Props extends HTMLProps<HTMLTextAreaElement> {
  isInvalid?: boolean;
  onNativeChange?: (value: string, props: Partial<TextareaProps>) => void;
}

const Textarea = forwardRef<HTMLTextAreaElement, Props>(({
  isInvalid,
  className,
  onNativeChange,
  disabled,
  onFocus,
  onBlur,
  ...rest
}, ref) => {
  const [valueOnFocus, setValueOnFocus] = useState('');
  const completeClassName = classNames([
    'geecko-input',
    'geecko-input--textarea',
    isInvalid ? 'geecko-input--error' : '',
  ]);
  const handleFocus = (event: FocusEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;
    setValueOnFocus(value);
    if (onFocus) {
      onFocus(event);
    }
  };
  const handleBlur = (event: FocusEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;
    if (value !== valueOnFocus && onNativeChange) {
      onNativeChange(value, { ...rest });
    }
    if (onBlur) {
      onBlur(event);
    }
  };

  return (
    <div
      className={classNames(
        'geecko-input__wrapper',
        `geecko-input__wrapper--textarea`,
        `geecko-input__wrapper--size-medium`,
      )}
    >
      <TextareaAutosize
        // @ts-ignore
        ref={ref}
        {...rest}
        className={`${completeClassName} ${className || ''}`}
        disabled={disabled}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
    </div>
  );
});

export default Textarea;
