export interface RejectReason {
  id: string;
  name: string;
  children?: RejectReason[];
}

export const decodeRejectReason = (json: any): RejectReason => ({
  id: json.id || '',
  name: json.name || '',
  children: json.children && Array.isArray(json.children) ? json.children.map(decodeRejectReason) : undefined,
});
