/* eslint-disable no-param-reassign */
import { ActionType, getType } from 'typesafe-actions';
import produce, { Draft, castDraft } from 'immer';
import { createInitialFillRateMap } from 'models/CV/CvFormValues';
import { CandidateState, initialCandidateState } from './state';
import * as CandidateAction from './actionCreators';

type CandidateActionType = ActionType<typeof CandidateAction>;

export const candidateReducer = produce((state: Draft<CandidateState>, action: CandidateActionType) => {
  switch (action.type) {
    case getType(CandidateAction.fetchCandidateVacanciesAction.request): {
      state.vacancies.vacancies.items = [];
      state.vacancies.vacancies.loading = true;
      state.vacancies.vacancies.loaded = false;
      state.vacancies.vacancies.error = undefined;
      state.vacancies.status = action.payload.status;
      break;
    }
    case getType(CandidateAction.fetchCandidateVacanciesAction.success): {
      state.vacancies.vacancies.items = castDraft(action.payload.vacancies);
      state.vacancies.vacancies.loading = false;
      state.vacancies.vacancies.loaded = true;
      state.vacancies.counters = action.payload.counters;
      break;
    }
    case getType(CandidateAction.fetchCandidateVacanciesAction.failure): {
      state.vacancies.vacancies.loading = false;
      state.vacancies.vacancies.error = action.payload;
      break;
    }
    case getType(CandidateAction.openVacancyPreview): {
      state.vacancies.previewId = action.payload;
      break;
    }
    case getType(CandidateAction.closeVacancyPreview): {
      state.vacancies.previewId = undefined;
      break;
    }
    case getType(CandidateAction.previewVacancyGoNext): {
      if (state.vacancies.vacancies.items && state.vacancies.vacancies.items.length > 0 && state.vacancies.previewId) {
        const vacancyIndex = state.vacancies.vacancies.items.findIndex(
          (item) => item.vacancy.id === state.vacancies.previewId,
        );
        if (vacancyIndex >= 0 && vacancyIndex < state.vacancies.vacancies.items.length - 1) {
          state.vacancies.previewId = state.vacancies.vacancies.items[vacancyIndex + 1].vacancy.id;
          break;
        }
        state.vacancies.previewId = state.vacancies.vacancies.items[0].vacancy.id;
        break;
      }

      break;
    }
    case getType(CandidateAction.previewVacancyGoPrev): {
      if (state.vacancies.vacancies.items && state.vacancies.vacancies.items.length > 0 && state.vacancies.previewId) {
        const vacancyIndex = state.vacancies.vacancies.items.findIndex(
          (item) => item.vacancy.id === state.vacancies.previewId,
        );
        if (vacancyIndex > 0) {
          state.vacancies.previewId = state.vacancies.vacancies.items[vacancyIndex - 1].vacancy.id;
          break;
        }

        state.vacancies.previewId =
          state.vacancies.vacancies.items[state.vacancies.vacancies.items.length - 1].vacancy.id;
        break;
      }

      break;
    }

    case getType(CandidateAction.openRejectVacancyDialog): {
      state.vacancies.rejectId = action.payload;
      break;
    }

    case getType(CandidateAction.closeRejectVacancyDialog): {
      state.vacancies.rejectId = undefined;
      break;
    }

    case getType(CandidateAction.fetchCandidateSkillsSessionsAction.success): {
      state.skillsSessions = action.payload;
      break;
    }

    case getType(CandidateAction.fetchCandidateSkillsStatusAction.request): {
      state.skillsStatus.loading = true;
      state.skillsStatus.error = undefined;
      break;
    }

    case getType(CandidateAction.fetchCandidateSkillsStatusAction.success): {
      state.skillsStatus.loading = false;
      state.skillsStatus.loaded = true;
      state.skillsStatus.item = action.payload;
      break;
    }

    case getType(CandidateAction.fetchCandidateSkillsStatusAction.failure): {
      state.skillsStatus.loading = false;
      state.skillsStatus.error = action.payload;
      break;
    }

    /**
     * Модальное окно оценки интервью кандидатом
     */
    case getType(CandidateAction.openVacancyInterviewFeedbackDialog): {
      state.vacancies.interviewFeedbackId = action.payload;
      break;
    }

    case getType(CandidateAction.closeVacancyInterviewFeedbackDialog): {
      state.vacancies.interviewFeedbackId = undefined;
      break;
    }

    /**
     * Модальное окно ответа на запрос интервью
     */
    case getType(CandidateAction.openVacancyInterviewAcceptDialog): {
      state.vacancies.interview = castDraft(action.payload.interview);
      state.vacancies.interviewAcceptVacancyId = action.payload.vacancyId;
      break;
    }

    case getType(CandidateAction.closeVacancyInterviewAcceptDialog): {
      state.vacancies.interview = undefined;
      state.vacancies.interviewAcceptVacancyId = undefined;
      break;
    }

    /**
     * Модальное окно оценки отклика на вакансию
     */
    case getType(CandidateAction.openVacancyResponseDialog): {
      state.vacancies.responseVacancyId = action.payload;
      break;
    }

    case getType(CandidateAction.closeVacancyResponseDialog): {
      state.vacancies.responseVacancyId = undefined;
      break;
    }

    /**
     * Обновление вакансии в сторе
     */
    case getType(CandidateAction.fetchCandidateVacancyAction.request): {
      state.vacancies.loading = true;
      break;
    }
    case getType(CandidateAction.fetchCandidateVacancyAction.success): {
      state.vacancies.vacancies.items = castDraft(state.vacancies.vacancies.items
        ? state.vacancies.vacancies.items.map((item) =>
            item.vacancy.id === action.payload.vacancy.id ? action.payload : item,
          )
        : [action.payload]);
      state.vacancies.loading = false;
      break;
    }
    case getType(CandidateAction.fetchCandidateVacancyAction.failure): {
      state.vacancies.loading = false;
      break;
    }

    case getType(CandidateAction.fetchCandidateSuperSearchSessionAction.request): {
      state.superSearch.session.loading = true;
      state.superSearch.session.loaded = true;
      state.superSearch.session.error = undefined;
      break;
    }

    case getType(CandidateAction.fetchCandidateSuperSearchSessionAction.success): {
      state.superSearch.session.loading = false;
      state.superSearch.session.loaded = true;
      state.superSearch.session.item = action.payload;
      break;
    }

    case getType(CandidateAction.fetchCandidateSuperSearchSessionAction.failure): {
      state.superSearch.session.loading = false;
      state.superSearch.session.error = action.payload;
      break;
    }

    case getType(CandidateAction.fetchCandidateCvForEditAction.request): {
      state.editCv.cv.loading = true;
      state.editCv.cv.loaded = false;
      state.editCv.cv.error = undefined;
      break;
    }

    case getType(CandidateAction.fetchCandidateCvForEditAction.success): {
      state.editCv.cv.loading = false;
      state.editCv.cv.loaded = true;
      state.editCv.cv.item = castDraft(action.payload);
      state.editCv.fillRate = createInitialFillRateMap(action.payload);
      break;
    }

    case getType(CandidateAction.fetchCandidateCvForEditAction.failure): {
      state.editCv.cv.loading = false;
      state.editCv.cv.error = action.payload;
      break;
    }

    case getType(CandidateAction.updateCandidateCvFillRateForStep): {
      if (!state.editCv.fillRate) {
        state.editCv.fillRate = {};
      }
      if (state.editCv.fillRate[action.payload.step] !== action.payload.fillRate) {
        state.editCv.fillRate[action.payload.step] = action.payload.fillRate;
      }
      break;
    }

    case getType(CandidateAction.candidateStartSuperSearchAction.request):
    case getType(CandidateAction.candidateStopSuperSearchAction.request): {
      state.superSearch.updating = true;
      break;
    }

    case getType(CandidateAction.candidateStartSuperSearchAction.success):
    case getType(CandidateAction.candidateStopSuperSearchAction.success): {
      state.superSearch.updating = false;
      break;
    }

    case getType(CandidateAction.candidateStartSuperSearchAction.failure):
    case getType(CandidateAction.candidateStopSuperSearchAction.failure): {
      state.superSearch.updating = false;
      break;
    }

    /**
     * Получение отчета разработчика
     */
    case getType(CandidateAction.fetchCandidateReportAction.request): {
      state.report.loading = true;
      state.report.loaded = false;
      state.report.error = undefined;
      break;
    }

    case getType(CandidateAction.fetchCandidateReportAction.success): {
      state.report.loading = false;
      state.report.loaded = true;
      state.report.item = action.payload;
      break;
    }

    case getType(CandidateAction.fetchCandidateReportAction.failure): {
      state.report.loading = false;
      state.report.error = action.payload;
      break;
    }

    /**
     * Получение рекомендаций по улучшению резюме
     */
    case getType(CandidateAction.fetchCandidateCvImprovementsAction.request): {
      state.cvImprovements.loading = true;
      state.cvImprovements.loaded = false;
      state.cvImprovements.error = undefined;
      break;
    }

    case getType(CandidateAction.fetchCandidateCvImprovementsAction.success): {
      state.cvImprovements.loading = false;
      state.cvImprovements.loaded = true;
      state.cvImprovements.items = castDraft(action.payload);
      break;
    }

    case getType(CandidateAction.fetchCandidateCvImprovementsAction.failure): {
      state.cvImprovements.loading = false;
      state.cvImprovements.error = action.payload;
      break;
    }

    default: {
      //
    }
  }
}, initialCandidateState);
