import { ServerApi } from 'modules/api/server-api';
import { Pool } from 'models/Company/Pool';
import { PoolCounters } from 'models/Company/Counters';
import { fetchPools } from 'api/pools/fetch-pools';
import { call, getContext, put, takeLatest } from 'redux-saga/effects';
import { fetchPoolsAction } from 'store/ducks/pools/actionCreators';

/**
 * Получение пулов
 */
function* getPoolsSaga() {
  try {
    const api: ServerApi = yield getContext('api');
    const result: { pools: Pool[]; counters: PoolCounters } = yield call(fetchPools(api));
    yield put(fetchPoolsAction.success(result));
  } catch (e) {
    yield put(fetchPoolsAction.failure(e));
  }
}

export function* poolsSaga() {
  yield takeLatest(fetchPoolsAction.request, getPoolsSaga);
}
