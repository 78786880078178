/* eslint-disable @typescript-eslint/ban-ts-ignore */
import React, { FC } from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { ValidationErrors } from 'final-form';
import { useSelector, useDispatch } from 'react-redux';
import { createScrollToErrorDecorator } from 'modules/final-form-scroll-to-error';
import { useApiInstance } from 'api';
import debounce from 'lodash/debounce';
import { InputFormField } from 'components/v2/FinalForm/Fields/InputFormField';
import { TextareaFormField } from 'components/v2/FinalForm/Fields/TextareaFormField';
import { candidateCvEditDataSelector } from 'store/ducks/candidate/selectors';
import { updateCandidateCvFillRateForStep } from 'store/ducks/candidate/actionCreators';
import FormBigSkeleton from 'components/v2/skeletons/FormSkeletons/FormBigSkeleton';
import { Immutable, castDraft } from 'immer';
import { languagesSelector } from 'store/ducks/dictionary/selectors';
import { MultiSelectFormField } from 'components/v2/FinalForm/Fields/MultiSelectFormField';
import CvSalaryFormItem from 'modules/candidate/cv/components/CvSalaryFormItem/CvSalaryFormItem';
import CvAboutFormItem from 'modules/candidate/cv/components/CvAboutFormItem/CvAboutFormItem';
import { calculateCvFormFillPercent, CvFormValues, submitCvFormValues } from '../../models/CV/CvFormValues';
import Label from '../../components/Form/Label/Label';
import {
  CheckRequired,
  PlainCheckboxAdapter,
  TagFieldAdapter,
  validateBirthDate,
} from '../../components/FormAdapter/FormAdapter';
import Grid from '../../components/Grid/Grid';
import Alert from '../../components/Alert/Alert';
import InfoCircleIcon from '../../components/Icons/InfoCircleIcon';
import { CommonEntity } from '../../models/BaseTypes';
import { CvFormStep } from '../../../pages/Candidate/Cv/CandidateEditCvPage/CandidateEditCvPage';
import { loadLocationsOptions, loadCountriesOptions, Location, Country } from '../../models/Location';
import { authCandidateHasCvSelector, authCandidateCvShowImportSelector } from '../../store/ducks/auth/selectors';
import CVFormMatchingGuide from './CVFormMatchingGuide';
import FormCondition from '../../components/Form/FormCondition/FormCondition';
import LanguageFormField from '../../components/Form/LanguageFormField/LanguageFormField';
import { AsyncSelectFinalForm } from '../../components/final-form-adapters/AsyncSelectFinalForm';
import CVFormFooter from './CVFormFooter';
import { InputMaskedField } from '../../components/final-form-adapters/InputMaskedField';
import Text from '../../components/v2/Text/Text';

interface Props {
  nextStep?: CvFormStep;
  previousStep?: CvFormStep;
}

const scrollToErrorDecorator = createScrollToErrorDecorator<Immutable<CvFormValues>>();

const CVCommonForm: FC<Props> = ({ previousStep, nextStep }) => {
  const api = useApiInstance();
  const cvData = useSelector(candidateCvEditDataSelector);
  const languages = useSelector(languagesSelector);
  const candidateHasCv = useSelector(authCandidateHasCvSelector);
  const dispatch = useDispatch();
  const showCvImport = useSelector(authCandidateCvShowImportSelector);
  const handleFormSubmit = async (cvFormData: Immutable<CvFormValues>) =>
    submitCvFormValues(showCvImport, dispatch, api, castDraft(cvFormData), 'common', !candidateHasCv);
  const handleLoadLocationsOptions = (inputValue: string) => loadLocationsOptions(api, inputValue);
  const handleLoadCountriesOptions = (inputValue: string) => loadCountriesOptions(api, inputValue);

  const handleUpdateFillRate = debounce((formValues: CvFormValues) => {
    const fillRate = calculateCvFormFillPercent(formValues, 'common');
    dispatch(updateCandidateCvFillRateForStep({ step: 'common' as CvFormStep, fillRate }));
  }, 500);

  const validateForm = (values: any): ValidationErrors => {
    const errors: any = {};

    if (values.salary) {
      const salaryFrom = values.salary.min;
      const salaryTo = values.salary.public;

      if (!Number.isNaN(salaryFrom) && salaryFrom > 0 && !Number.isNaN(salaryTo) && salaryTo > 0) {
        if (salaryFrom > salaryTo) {
          if (!errors.salary) {
            errors.salary = {};
          }
          errors.salary.public = 'Должна быть больше минимальной';
        }
      }
    }

    return errors;
  };

  return (
    <div className="geecko-paper__wrapper">
      <div className="geecko-candidate-cv-form__form-header-wrapper">
        <div className="geecko-candidate-cv-form__dot-bg geecko-candidate-cv-form__form-header-dot--1" />
        <div className="geecko-candidate-cv-form__dot-bg geecko-candidate-cv-form__form-header-dot--2" />
        <Grid container spacing={4}>
          <Grid item xs={12} md={7}>
            <Text variant="h1" tag="h1">
              Главная
              <br />
              информация
            </Text>
          </Grid>
          <Grid item xs={12} md={5}>
            <Text variant="h3">Вакансии подбираются с учетом указанной зарплаты</Text>
            <div className="geecko-util__mt-2">
              <Text variant="md" color="#646D7B" bold>
                Минимальная
              </Text>
              <Text variant="md" color="#646D7B">
                Мы не будем показывать вакансии ниже нее и отображать ее в вашем резюме
              </Text>
            </div>
            <div className="geecko-util__mt-2">
              <Text variant="md" color="#646D7B" bold>
                Публичная
              </Text>
              <Text variant="md" color="#646D7B">
                Отображается компаниям, но вы можете ее скрыть. Постарайтесь не отпугнуть ей потенциального работодателя
              </Text>
            </div>
          </Grid>
        </Grid>
      </div>
      {cvData.loading && (
        <>
          <FormBigSkeleton />
          <FormBigSkeleton />
        </>
      )}

      {cvData.loaded && cvData.item && (
        <Form<Immutable<CvFormValues>>
          validate={validateForm}
          onSubmit={handleFormSubmit}
          initialValues={cvData.item}
          mutators={{
            ...arrayMutators,
          }}
          subscription={{ submitting: true }}
          decorators={[scrollToErrorDecorator]}
          render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit} encType="multipart/form-data" className="geecko-company-edit-profile__form">
              <FormSpy subscription={{ values: true }}>
                {(props) => {
                  handleUpdateFillRate(props.values as CvFormValues);
                  return null;
                }}
              </FormSpy>

              <div className="geecko-form__item">
                <Label required>Название резюме</Label>
                <InputFormField
                  name="profession"
                  validate={CheckRequired}
                  placeholder="Например: Программист C#/.NET"
                />
              </div>

              <div className="geecko-form__item qa-about">
                <Label>О себе</Label>
                <Alert icon={<InfoCircleIcon />} spacing={3}>
                  Это первое, что увидят компании, когда столкнутся с вашим профилем. Выделите свои основные качества.
                </Alert>
                <CvAboutFormItem />
              </div>

              <CVFormMatchingGuide>
                <div className="geecko-form__item">
                  <Label required>Зарплата</Label>
                  <Alert icon={<InfoCircleIcon />} spacing={3}>
                    Вакансии подбираются с учетом указанной зарплаты. Укажите{' '}
                    <span className="geecko-text--bold">минимальную</span> цифру - мы не будем показывать вакансии ниже
                    нее и отображать ее в вашем резюме. <span className="geecko-text--bold">Публичная</span> зарплата
                    отображается компаниям, но вы можете ее скрыть. Постарайтесь не отпугнуть публичной зарплатой
                    потенциального работодателя ;-)
                  </Alert>
                </div>
                <CvSalaryFormItem />
              </CVFormMatchingGuide>

              <CVFormMatchingGuide>
                <div className="geecko-form__item">
                  <Label required>Ваш город</Label>
                  <Field<CommonEntity>
                    name="location"
                    className="qa-location-container"
                    // @ts-ignore
                    component={AsyncSelectFinalForm}
                    async
                    loadOnInit
                    valueKey="id"
                    labelKey="name"
                    loadOptions={handleLoadLocationsOptions}
                    placeholder="Например: Москва"
                    validate={CheckRequired}
                  />
                </div>
              </CVFormMatchingGuide>

              <CVFormMatchingGuide marginTop={8}>
                <div className="geecko-form__item">
                  <Grid container spacing={3} alignItems="flex-start">
                    <Grid item xs={12} md={4}>
                      <Label required>Работа</Label>
                      <Field<string[]>
                        name="workType"
                        multiple
                        component={TagFieldAdapter}
                        options={[
                          { label: 'В офисе', value: 'office' },
                          { label: 'Удалённо', value: 'remote' },
                        ]}
                        validate={CheckRequired}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Label>&nbsp;</Label>
                      <div className="geecko-form__densed-checkbox">
                        <Field<boolean>
                          name="readyForRelocation"
                          type="checkbox"
                          component={PlainCheckboxAdapter}
                          content="Готов к переезду в другой город"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Label>&nbsp;</Label>
                      <div className="geecko-form__densed-checkbox">
                        <Field<boolean>
                          name="readyForRelocationCountries"
                          type="checkbox"
                          component={PlainCheckboxAdapter}
                          content="Готов к переезду в другую страну"
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <Grid container spacing={3} alignItems="flex-start">
                  <FormCondition field="readyForRelocation" is>
                    <Grid item xs={12} md={6}>
                      <Label>Только указанные города (не более 5)</Label>
                      <MultiSelectFormField<Location>
                        name="relocationCities"
                        loadOptions={handleLoadLocationsOptions}
                        valueKey="id"
                        labelKey="name"
                        maxValues={5}
                        placeholder="Например: Санкт-Петербург, Лондон"
                        noOptionsPlaceholder="Начните вводить название города"
                        async
                      />
                    </Grid>
                  </FormCondition>
                  <FormCondition field="readyForRelocationCountries" is>
                    <Grid item xs={12} md={6}>
                      <Label>Только указанные страны (не более 5)</Label>
                      <MultiSelectFormField<Country>
                        name="relocationCountries"
                        loadOptions={handleLoadCountriesOptions}
                        valueKey="id"
                        labelKey="name"
                        maxValues={5}
                        placeholder="Например: Нидерланды, Германия"
                        noOptionsPlaceholder="Начните вводить название страны"
                        async
                      />
                    </Grid>
                  </FormCondition>
                </Grid>
              </CVFormMatchingGuide>

              <div id="cvFormManagementExperience" className="geecko-form__item">
                <Field<boolean>
                  name="hasManagementExperience"
                  type="checkbox"
                  component={PlainCheckboxAdapter}
                  content="Есть опыт управления разработкой"
                />
              </div>

              <FormCondition field="hasManagementExperience" is>
                <>
                  <div style={{ marginTop: 15 }}>
                    <Alert icon={<InfoCircleIcon />} spacing={3}>
                      Если вы имели опыт управления разработкой, то это может быть как плюсом, так и минусом для вас.
                      Опишите свой опыт подробнее и компании смогут лучше понять вас, чтобы сделать вам релевантное
                      предложение.
                    </Alert>
                  </div>
                  <div className="geecko-form__item">
                    <Grid container spacing={3} alignItems="flex-start">
                      <Grid item xs={12} md={10} className="qa-management-experience">
                        <Label required>Коротко об опыте управления</Label>
                        <TextareaFormField
                          name="managementExperienceInfo"
                          validate={CheckRequired}
                          placeholder="Например: «Руководил командой из 3 человек: два middle, 1 senior. В дальнейшем планирую развиваться в этом направлении». Или «Имел опыт управления командой из 5 человек. В дальнейшем решил развиваться как программист»"
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Label>Лет опыта</Label>
                        <InputMaskedField
                          mask={Number}
                          name="managementExperienceMonth"
                          radix="."
                          maskMin={0}
                          lazy
                          validate={validateBirthDate}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </>
              </FormCondition>

              {languages.loading && (
                <>
                  <FormBigSkeleton />
                </>
              )}
              {languages.loaded && (
                <div className="geecko-form__item">
                  <Label>Иностранные языки</Label>
                  <LanguageFormField name="languages" languages={languages.items} />
                </div>
              )}

              <CVFormFooter
                candidateHasCv={candidateHasCv}
                nextStep={nextStep}
                previousStep={previousStep}
                submitting={submitting}
              />
            </form>
          )}
        />
      )}
    </div>
  );
};

export default CVCommonForm;
