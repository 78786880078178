import { Manager, decodeManager } from 'models/Manager';

export interface Video {
  url: string;
  coverUrl?: string;
}

export interface VideoInterview {
  video?: Video;
  user?: Manager;
  summary?: string;
}

export const decodeVideo = (json: any): Video => {
  return {
    url: json.url,
    coverUrl: json.cover_url ? json.cover_url : undefined,
  };
};

export const decodeVideoInterview = (json: any): VideoInterview => {
  return {
    video: json.video ? decodeVideo(json.video) : undefined,
    user: json.user ? decodeManager(json.user) : undefined,
    summary: json.summary ? json.summary : undefined,
  };
};
