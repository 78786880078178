/* eslint-disable @typescript-eslint/ban-ts-ignore */
import React, { Fragment, FC } from 'react';
import { useIntl } from 'react-intl';
import { FieldArray } from 'react-final-form-arrays';
import { Field } from 'react-final-form';
import Button from 'components/v2/Button/Button';
import Grid from '../../Grid/Grid';
import { CheckRequired } from '../../FormAdapter/FormAdapter';
import { Language, languageLevelOptions } from '../../../models/BaseTypes';
import IconButton from '../../IconButton/IconButton';
import RemoveIcon from '../../Icons/RemoveIcon/RemoveIcon';
import PlusCircle from '../../Icons/PlusCircle/PlusCircle';
import { AsyncSelectFinalForm } from '../../final-form-adapters/AsyncSelectFinalForm';

interface Props {
  name: string;
  languages: ReadonlyArray<Language>;
}

const LanguageFormField: FC<Props> = ({ name, languages }) => {
  const intl = useIntl();
  return (
    <>
      <FieldArray name={name}>
        {({ fields }) => (
          <>
            {fields.map((fieldName, index) => (
              <Fragment key={fieldName}>
                <Grid container spacing={3} key={fieldName} alignItems="flex-start" className="qa-language-container">
                  <Grid xs={12} sm item>
                    <Field
                      name={`${fieldName}.id`}
                      className="qa-location-container"
                      // @ts-ignore
                      component={AsyncSelectFinalForm}
                      options={languages || []}
                      valueKey="id"
                      labelKey="name"
                      plainValue
                      placeholder={intl.formatMessage({ id: 'app.form.language.select' })}
                      validate={CheckRequired}
                    />
                  </Grid>
                  <Grid xs={12} sm item>
                    <Field
                      name={`${fieldName}.level`}
                      className="qa-location-container"
                      // @ts-ignore
                      component={AsyncSelectFinalForm}
                      options={languageLevelOptions}
                      valueKey="value"
                      labelKey="label"
                      plainValue
                      placeholder={intl.formatMessage({ id: 'app.vacancy.langs.level' })}
                      validate={CheckRequired}
                      isDisabled={!fields.value[index] || !fields.value[index].id}
                    />
                  </Grid>
                  <Grid xs="auto" item className="qa-delete-language-button">
                    <IconButton type="button" onClick={() => fields.remove(index)}>
                      <RemoveIcon size={10} />
                    </IconButton>
                  </Grid>
                </Grid>
              </Fragment>
            ))}
            <div className="geecko-form__bottom-buttons qa-add-language-button">
              <Button
                variant="ghost"
                color="secondary"
                size="sm"
                iconLeft={<PlusCircle size={15} />}
                onClick={() => fields.push(undefined)}
              >
                {intl.formatMessage({ id: 'app.vacancy.langs.add' })}
              </Button>
            </div>
          </>
        )}
      </FieldArray>
    </>
  );
};

export default LanguageFormField;
