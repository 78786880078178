import React from 'react';

interface Props {
  size? : number;
}

const CoronaIcon: React.FC<Props> = (props: Props) => {
  const { size = 36 } = props;
  return (
    <svg height={size} viewBox="0 0 512 512" width={size} xmlns="http://www.w3.org/2000/svg">
      <path d="m91 300v90h330v-90zm0 0" fill="#ff9f00" />
      <path d="m256 300h165v90h-165zm0 0" fill="#ff7816" />
      <path d="m422.5 169.199219-24.300781 47.398437h-284.398438l-24.300781-47.398437c35.699219-49.5 97.800781-79.199219 166.5-79.199219s130.800781 29.699219 166.5 79.199219zm0 0" fill="#ff4b00" />
      <path d="m422.5 169.199219-24.300781 47.398437h-142.199219v-126.597656c68.699219 0 130.800781 29.699219 166.5 79.199219zm0 0" fill="#cc1e0d" />
      <path d="m477.101562 139.199219-56.101562 190.800781h-330l-56.101562-190.800781 37.800781-14.398438c17.101562 32.699219 49.800781 51.898438 76.5 55.199219h15.601562c34.5-5.398438 60.296875-58.199219 70.800781-108.601562h40.800782c10.5 50.402343 36.300781 103.203124 70.796875 108.601562h15.601562c26.699219-3.300781 59.398438-22.5 76.5-55.199219zm0 0" fill="#fdbf00" />
      <path d="m477.101562 139.199219-56.101562 190.800781h-165v-258.601562h20.398438c10.503906 50.402343 36.300781 103.203124 70.800781 108.601562h15.601562c26.699219-3.300781 59.398438-22.5 76.5-55.199219zm0 0" fill="#ff9f00" />
      <path d="m46 150c-24.8125 0-46-20.1875-46-45s21.1875-45 46-45 45 20.1875 45 45-20.1875 45-45 45zm0 0" fill="#ff9f00" />
      <path d="m466 150c-24.8125 0-45-20.1875-45-45s20.1875-45 45-45 46 20.1875 46 45-21.1875 45-46 45zm0 0" fill="#ff7816" />
      <path d="m256 0c-24.902344 0-45 20.097656-45 45 0 24.898438 20.097656 45 45 45s45-20.101562 45-45c0-24.902344-20.097656-45-45-45zm0 0" fill="#ff9f00" />
      <path d="m256 173.699219-51.300781 51.300781 51.300781 51.300781 51.300781-51.300781zm0 0" fill="#ff4b00" />
      <path d="m346 233.785156 21.210938 21.210938-21.210938 21.210937-21.210938-21.210937zm0 0" fill="#cc1e0d" />
      <path d="m166 233.785156 21.210938 21.210938-21.210938 21.210937-21.210938-21.210937zm0 0" fill="#ff4b00" />
      <path d="m256 90v-90c24.902344 0 45 20.097656 45 45 0 24.898438-20.097656 45-45 45zm0 0" fill="#ff7816" />
      <path d="m307.300781 225-51.300781 51.300781v-102.601562zm0 0" fill="#cc1e0d" />
    </svg>
  );
};

export default CoronaIcon;
