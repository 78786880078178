// TODO: Переменовать просто в User поскольку будет общая сущность
export interface CompanyUser {
  id: number;
  fullName: string;
  email: string;
  photoUrl?: string;
  avatar?: string;
  createdAt?: string;
  updatedAt?: string;
  emailVerifiedAt?: string;
  name?: string;
}

export const decodeCompanyUser = (json: any): CompanyUser => {
  const result = {
    ...json,
    fullName: json.full_name,
    createdAt: new Date(json.created_at),
    updatedAt: new Date(json.updated_at),
  };
  if (json.photo_url) {
    result.photoUrl = json.photo_url;
    delete result.photo_url;
  }
  if (json.email_verified_at) {
    result.emailVerifiedAt = json.email_verified_at;
    delete result.email_verified_at;
  }
  delete result.full_name;
  delete result.created_at;
  delete result.updated_at;
  return result;
};
