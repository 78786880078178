import React, { FC } from 'react';

interface Props {
  size?: number;
}

const StackOverflowIcon: FC<Props> = ({ size = 18 }) => (
  <svg width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="18" height="18" fill="none" />
    <path d="M6.38486 6.30596L11.648 9.41022L12.311 8.28619L7.04782 5.18193L6.38486 6.30596Z" fill="currentColor" />
    <path d="M5.31982 9.44407L11.2209 11.0304L11.5597 9.77005L5.65862 8.18372L5.31982 9.44407Z" fill="currentColor" />
    <path d="M4.99665 12.2159L11.0816 12.7769L11.2014 11.4775L5.11644 10.9165L4.99665 12.2159Z" fill="currentColor" />
    <path d="M11.0209 13.1441H4.90994V14.4494H11.0209V13.1441Z" fill="currentColor" />
    <path d="M13.1029 16.2094C13.1029 16.2094 13.1028 16.5142 13.0926 16.5142V16.5192H3.3151C3.3151 16.5192 3.01039 16.5192 3.01039 16.5142H3V10.1547H4.04385V15.4903H12.059V10.1547H13.1029V16.2094Z" fill="currentColor" />
    <path d="M13.6221 7.31785L10.1792 2.269L9.10085 3.00436L12.5438 8.05322L13.6221 7.31785Z" fill="currentColor" />
    <path d="M15.1462 7.0208L14.1013 1L12.8153 1.22319L13.8602 7.24399L15.1462 7.0208Z" fill="currentColor" />
  </svg>
);

export default StackOverflowIcon;
