import { PublicVacancy, decodePublicVacancy } from 'models/Vacancy';
import { Immutable } from 'immer';
import { RejectReason, decodeRejectReason } from './Dictionary/RejectReason';

export enum VacancyActiveStatus {
  Active = 'active',
  Archived = 'archived',
  Draft = 'draft',
  Review = 'review',
}

export enum VacancyCandidateActiveStatus {
  Active = 'active',
  Archived = 'archived',
}

export enum VacancySort {
  Created = 'created',
  New = 'new',
  Unreviewed = 'unreviewed',
}

export type VacancyStatusValue =
  | 'matching.profile_sent'
  | 'matching.interview.requested'
  | 'matching.interview.confirmed'
  | 'matching.interview.feedback'
  | 'matching.interview.response'
  | 'matching.interview.finished'
  | 'matching.interview.rejected'
  | 'matching.reject.resume'
  | 'matching.reject.interview'
  | 'matching.reject.self'
  | 'matching.offer'
  | 'matching.offer.accepted'
  | 'matching.offer.received'
  | 'matching.jobfound'
  | 'matching.response';

export type VacancyStateStatus = {
  id: VacancyStatusValue;
  prevId?: VacancyStatusValue;
  name: string;
  createdAt: string;
  sla?: number;
  matchScore?: number; // процент соответствия 0-100
};

export type InterviewSchedule = Immutable<{
  id: number;
  date: string;
  isConfirmed: boolean;
}>;

export type Interview = Immutable<{
  id: number;
  date: string;
  type: 'meet' | 'call';
  address?: string;
  comment?: string;
  isPassed: boolean;
  candidateComment?: string;
  schedule?: InterviewSchedule[];
  callMethod?: string;
  callContact?: string;
}>;

export type Offer = {
  salary?: number;
  firstDay?: string;
  confirmed: boolean;
  address?: string;
  comment?: string;
  scheduleType?: string;
};

export type Reject = {
  comment?: string;
  reasons: RejectReason[];
};

export type Response = {
    id: number;
    candidateId?: number;
    vacancyId?: number;
    source?: 'candidate' | 'company' | string;
    isAccepted?: boolean;
    createdAt?: string;
    updatedAt?: string;
    isRejected?: boolean;
    rejectedBy?: any;
    comment?: string;
}

export type VacancyState = {
  readonly vacancy: PublicVacancy;
  readonly status?: VacancyStateStatus;
  readonly interview?: Interview;
  readonly offer?: Offer;
  readonly feedback?: string;
  readonly reject?: Reject;
  readonly viewedAt?: string;
  readonly response?: Response;
};

export const decodeInterviewSchedule = (json: any): InterviewSchedule => {
  return {
    id: json.id,
    date: json.date,
    isConfirmed: json.is_confirmed,
  };
};

export const decodeReject = (json: any): Reject => {
  return {
    comment: json.comment ? json.comment : undefined,
    reasons: json.reasons.map(decodeRejectReason),
  };
};

export const decodeVacancyStateStatus = (json: any): VacancyStateStatus => {
  return {
    id: json.id,
    prevId: json.prev_id ? json.prev_id : undefined,
    name: json.name,
    createdAt: json.created_at,
    sla: json.sla ? json.sla : undefined,
    matchScore: json.match_score ? json.match_score : undefined,
  };
};

export const decodeInterview = (json: any): Interview => {
  return {
    id: json.id,
    date: json.date,
    type: json.type,
    address: json.address ? json.address : undefined,
    comment: json.comment ? json.comment : undefined,
    isPassed: json.is_passed,
    candidateComment: json.candidate_comment ? json.candidate_comment : undefined,
    schedule: json.schedule ? json.schedule.map(decodeInterviewSchedule) : undefined,
    callMethod: json.call_method ? json.call_method : undefined,
    callContact: json.call_contact ? json.call_contact : undefined,
  };
};

export const decodeOffer = (json: any): Offer => {
  return {
    salary: json.salary ? json.salary : undefined,
    firstDay: json.first_day ? json.first_day : undefined,
    confirmed: json.confirmed,
    address: json.address ? json.address : undefined,
    comment: json.comment ? json.comment : undefined,
    scheduleType: json.schedule_type ? json.schedule_type : undefined,
  };
};

export const decodeResponse = (json: any): Response => {
  return {
    id: json.id,
    candidateId: json.candidate_id ? json.candidate_id : undefined,
    vacancyId: json.vacancy_id ? json.vacancy_id : undefined,
    source: json.vacancy_id ? json.vacancy_id : undefined,
    isAccepted: json.is_accepted ? json.is_accepted : undefined,
    createdAt:json.created_at ? json.created_at : undefined,
    updatedAt: json.updated_at ? json.updated_at : undefined,
    isRejected: json.is_rejected ? json.is_rejected : undefined,
    rejectedBy: json.rejected_by ? json.rejected_by : undefined,
    comment: json.comment ? json.comment : undefined,
  }
}

export const decodeVacancyState = (json: any): VacancyState => {
  return {
    vacancy: decodePublicVacancy(json.vacancy),
    status: json.status ? decodeVacancyStateStatus(json.status) : undefined,
    interview: json.interview ? decodeInterview(json.interview) : undefined,
    offer: json.offer ? decodeOffer(json.offer) : undefined,
    feedback: json.feedback ? json.feedback : undefined,
    reject: json.reject ? decodeReject(json.reject) : undefined,
    viewedAt: json.viewed_at ? json.viewed_at : undefined,
    response: json.response ? decodeResponse(json.response) : undefined,
  };
};
