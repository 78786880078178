import { ServerApi } from 'modules/api/server-api';
import { decodePool } from 'models/Company/Pool';
import { decodePoolCounters } from 'models/Company/Counters';
import { decodePoolCandidate } from 'models/Company/Candidate';

export const fetchPool = (api: ServerApi) => async (poolId: string, page: number) => {
  const response = await api.get(`/v3/pools/${poolId}/candidates?page=${page}&per_page=20`);
  const pool = decodePool(response.data.pool);
  const counters = decodePoolCounters(response.data.counters);
  const candidates = response.data.candidates.map(decodePoolCandidate);
  const { online, total, vacancies } = response.data;
  return { pool, counters, online, candidates, total, vacancies };
};
