import React, { FC } from 'react';

interface Props {
  size?: number;
}

const TosterIcon: FC<Props> = ({ size = 18 }) => (
  <svg width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16 1H2V5.70588H6.34483V17H11.6552V5.70588H16V1Z" fill="currentColor" />
  </svg>
);

export default TosterIcon;
