import React, { FC } from 'react';
import { Field } from 'react-final-form';
import { FieldValidator } from 'final-form';
import { LOCALE } from 'utils/base';
import ReactPhoneInput from '../../ReactPhoneInput';
import ru from '../../ReactPhoneInput/lang/ru.json';
import './PhoneField.css';

interface Props {
  name: string;
  validate?: FieldValidator<string>;
}

const PhoneField: FC<Props> = ({ name, validate }) => (
  <Field name={name} validate={validate}>
    {props => (
      <div className={props.meta.touched && (props.meta.error || (!props.meta.dirtySinceLastSubmit && !props.meta.submitting && props.meta.submitError)) ? 'react-tel-input-invalid' : undefined}>
        <ReactPhoneInput
          {...props.input}
          defaultCountry={LOCALE === 'ru' ? 'ru' : 'us'}
          localization={LOCALE === 'ru' ? ru : undefined}
          preferredCountries={LOCALE === 'ru' ? ['ru', 'by', 'ua'] : ['us']}
          inputExtraProps={{ name }}
        />
        {props.meta.touched && (props.meta.error || (!props.meta.dirtySinceLastSubmit && !props.meta.submitting && props.meta.submitError)) && (
          <div className="geecko-field-error">{props.meta.error || props.meta.submitError}</div>
        )}
      </div>
    )}
  </Field>

);

export default PhoneField;
