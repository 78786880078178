export interface Specialization {
  id: string;
  name: string;
  type: string;
  text?: string;
}

export const decodeSpecialization = (json: any): Specialization => ({
  id: json.id,
  name: json.name,
  type: json.type,
  text: json.text,
});
