import { all } from 'redux-saga/effects';
import { poolsSaga } from 'store/ducks/pools/saga';
import { dictionarySaga } from './ducks/dictionary/saga';
import { candidateSaga } from './ducks/candidate/saga';
import { companySaga } from './ducks/company/saga';
import { authSaga } from './ducks/auth/saga';
import { resumeSaga } from './ducks/resume/saga';

export function* rootSaga() {
  yield all([
    authSaga(),
    candidateSaga(),
    dictionarySaga(),
    companySaga(),
    poolsSaga(),
    resumeSaga(),
  ]);
}
