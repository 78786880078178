import axios, { AxiosRequestConfig } from 'axios';
import startsWith from 'lodash/startsWith';
import { LOCALE } from 'utils/base';

export class GeeckoApi {
  protected token?: string;

  constructor(token?: string) {
    this.token = token;
  }

  protected processUrl = (url: string): string => {
    const preparedUrl = startsWith(url, '/') ? url : `/${url}`;
    return startsWith(preparedUrl, '/v3') ? preparedUrl : `v2${preparedUrl}`;
  };

  protected createInstance() {
    const headers: any = {
      'Content-Type': 'application/json',
      'Locale': LOCALE,
    };
    if (this.token) {
      headers.Authorization = this.token;
    }

    const baseUrl = process.env.REACT_APP_API_URL;

    return axios.create({
      baseURL: typeof baseUrl === 'string' ? baseUrl.replace('/v2', '') : '',
      headers,
    });
  }

  get(url: string, config?: AxiosRequestConfig) {
    return this.createInstance().get(this.processUrl(url), config);
  }

  post(url: string, data?: any, config?: AxiosRequestConfig) {
    return this.createInstance().post(this.processUrl(url), data, config);
  }

  put(url: string, data?: any, config?: AxiosRequestConfig) {
    return this.createInstance().put(this.processUrl(url), data, config);
  }

  delete(url: string, config?: AxiosRequestConfig) {
    return this.createInstance().delete(this.processUrl(url), config);
  }
}
