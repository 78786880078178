import { createSelector } from 'redux-views';
import { ResumeState } from 'models/Resume';
import { VacancyStateStatus } from 'models/VacancyState';
import { AppState, getNetworkEntitySelectorState, NetworkEntitySelector } from '../../index';
import { RESUME_MODULE_NAME } from './index';

export const resumeStateSelector = (state: AppState) => state[RESUME_MODULE_NAME] || undefined;

/**
 * Селектор резюме
 */

export const selectResumeState: NetworkEntitySelector<ResumeState> = createSelector(
  [resumeStateSelector],
  (resumeState) => getNetworkEntitySelectorState(resumeState.resume)
);

export const selectResumeIsExpired = createSelector(
  [selectResumeState],
  (resumeState) => resumeState.item && resumeState.item.isExpired
);

export const selectResumeShowAsCompany = createSelector(
  [resumeStateSelector],
  (resumeState) => !!resumeState?.viewAsCompany
);

/**
 * Селектор статуса матчинга
 */

export const selectResumeStatus: NetworkEntitySelector<VacancyStateStatus> = createSelector(
  [resumeStateSelector],
  (resumeState) => getNetworkEntitySelectorState(resumeState.status)
);
