import React from 'react';
import { Field } from 'react-final-form';
import FormError from 'components/Form/FormError/FormError';
import { BaseFinalFormField } from 'components/v2/FinalForm';
import MultiSelect, { MultiSelectProps } from 'components/AsyncSelect/MultiSelect';

type Props<T> = BaseFinalFormField<T[]> & Omit<MultiSelectProps<T>, 'onChange' | 'value'>;

export function MultiSelectFormField<T>(componentProps: Props<T>) {
  const { name, validate, format, formatOnBlur, parse, subscription, ...rest } = componentProps;

  return (
    <Field<T[]>
      name={name}
      validate={validate}
      format={format}
      formatOnBlur={formatOnBlur}
      parse={parse}
      subscription={subscription}
    >
      {(props) => {
        const { input, meta } = props;

        const error = meta.touched ? meta.error : '';
        const submitError = meta.touched && !meta.dirtySinceLastSubmit && !meta.submitting ? meta.submitError : '';

        return (
          <>
            <MultiSelect<T> {...input} {...rest} isInvalid={error || submitError} name={name} />
            <FormError name={name} />
          </>
        );
      }}
    </Field>
  );
}