import React, { FC } from 'react';

interface Props {
  size?: number;
}

const EditPaperRounded: FC<Props> = ({ size = 18 }) => (
  <svg width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.25 3H3C2.60218 3 2.22064 3.15804 1.93934 3.43934C1.65804 3.72064 1.5 4.10218 1.5 4.5V15C1.5 15.3978 1.65804 15.7794 1.93934 16.0607C2.22064 16.342 2.60218 16.5 3 16.5H13.5C13.8978 16.5 14.2794 16.342 14.5607 16.0607C14.842 15.7794 15 15.3978 15 15V9.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.875 1.87502C14.1734 1.57665 14.578 1.40903 15 1.40903C15.422 1.40903 15.8266 1.57665 16.125 1.87502C16.4234 2.17339 16.591 2.57806 16.591 3.00002C16.591 3.42197 16.4234 3.82665 16.125 4.12502L9 11.25L6 12L6.75 9.00002L13.875 1.87502Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default EditPaperRounded;
