import {
  VacancyStateStatus,
  decodeVacancyStateStatus,
  Interview,
  Offer,
  Reject,
  Response,
  decodeInterview,
  decodeOffer,
  decodeReject,
  decodeResponse,
} from 'models/VacancyState';
import { Resume, decodeResume } from 'models/Resume';
import { Immutable } from 'immer';

export type Candidate = Immutable<{
  burnAt: string;
  burnPeriod: number;
  status?: VacancyStateStatus;
  resume: Resume;
  interview?: Interview;
  offer?: Offer;
  feedback?: { id: number };
  reject?: Reject;
  response?: Response;
  // Флаг отмечается true, когда для резюме из списка, который не является полным, подгружаются полные данные
  isFull?: boolean;
}>;

export const decodePoolCandidate = (json: any): Candidate => ({
  burnAt: json.burn_at,
  burnPeriod: json.burn_period,
  status: json.status ? decodeVacancyStateStatus(json.status) : undefined,
  resume: decodeResume(json.resume),
  interview: json.interview ? decodeInterview(json.interview) : undefined,
  offer: json.offer ? decodeOffer(json.offer) : undefined,
  feedback: json.feedback ? json.feedback : undefined,
  reject: json.reject ? decodeReject(json.reject) : undefined,
  response: json.response ? decodeResponse(json.response) : undefined,
});
