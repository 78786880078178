import React, { FC } from 'react';
import { Form, FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { useSelector, useDispatch } from 'react-redux';
import { useApiInstance } from 'api';
import debounce from 'lodash/debounce';
import { InputFormField } from 'components/v2/FinalForm/Fields/InputFormField';
import { candidateCvEditDataSelector } from 'store/ducks/candidate/selectors';
import { updateCandidateCvFillRateForStep } from 'store/ducks/candidate/actionCreators';
import FormBigSkeleton from 'components/v2/skeletons/FormSkeletons/FormBigSkeleton';
import { Immutable, castDraft } from 'immer';
import CvCodeSamplesFormItem from 'modules/candidate/cv/components/CvCodeSamplesFormItem/CvCodeSamplesFormItem';
import {
  calculateCvFormFillPercent,
  CvFormValues,
  submitCvFormValues,
} from 'models/CV/CvFormValues';
import Alert from 'components/Alert/Alert';
import Grid from 'components/Grid/Grid';
import Label from 'components/Form/Label/Label';
import InfoCircleIcon from 'components/Icons/InfoCircleIcon';
import GitHubIcon from 'components/Icons/GitHubIcon';
import StackOverflowIcon from 'components/Icons/StackOverflowIcon';
import HabrIcon from 'components/Icons/HabrIcon';
import BitBucketIcon from 'components/Icons/BitBucketIcon';
import GitLabIcon from 'components/Icons/GitLabIcon';
import TosterIcon from 'components/Icons/TosterIcon';
import Text from 'components/v2/Text/Text';
import { CvFormStep } from '../../../pages/Candidate/Cv/CandidateEditCvPage/CandidateEditCvPage';
import { authCandidateHasCvSelector, authCandidateCvShowImportSelector } from '../../store/ducks/auth/selectors';
import CVFormFooter from './CVFormFooter';

interface Props {
  nextStep?: CvFormStep;
  previousStep?: CvFormStep;
}

const CVCommunityForm: FC<Props> = ({ previousStep, nextStep }) => {
  const api = useApiInstance();
  const cvData = useSelector(candidateCvEditDataSelector);
  const dispatch = useDispatch();
  const candidateHasCv = useSelector(authCandidateHasCvSelector);
  const showCvImport = useSelector(authCandidateCvShowImportSelector);
  const handleFormSubmit = async (cvFormData: Immutable<CvFormValues>) =>
    submitCvFormValues(showCvImport, dispatch, api, castDraft(cvFormData), 'community', !candidateHasCv);

  const handleUpdateFillRate = debounce((formValues: CvFormValues) => {
    const fillRate = calculateCvFormFillPercent(formValues, 'community');
    dispatch(updateCandidateCvFillRateForStep({ step: 'community' as CvFormStep, fillRate }));
  }, 500);

  return (
    <>
      <div className="geecko-paper__wrapper">
        <div className="geecko-candidate-cv-form__form-header-wrapper">
          <div className="geecko-candidate-cv-form__dot-bg geecko-candidate-cv-form__form-header-dot--1" />
          <div className="geecko-candidate-cv-form__dot-bg geecko-candidate-cv-form__form-header-dot--2" />
          <Text variant="h1" tag="h1">
            Сообщества
          </Text>
        </div>

        {cvData.loading && (
          <>
            <FormBigSkeleton />
            <FormBigSkeleton />
          </>
        )}
      </div>
      {cvData.loaded && cvData.item && (
        <Form<Immutable<CvFormValues>>
          onSubmit={handleFormSubmit}
          initialValues={cvData.item}
          mutators={{
            ...arrayMutators,
          }}
          render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit} encType="multipart/form-data" className="geecko-company-edit-profile__form">
              <>
                <FormSpy subscription={{ values: true }}>
                  {(props) => {
                    handleUpdateFillRate(props.values as CvFormValues);
                    return null;
                  }}
                </FormSpy>

                <div className="geecko-paper__wrapper">
                  <Text variant="h2" color="#99A2B0">
                    Участие в сообществах
                  </Text>

                  <div className="geecko-form__item">
                    <Alert color="warning" icon={<InfoCircleIcon />}>
                      Указывайте полную ссылку на ваш профиль
                    </Alert>
                  </div>

                  <div className="geecko-form__item">
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <Label>GitHub</Label>
                        <InputFormField
                          name="linkGitHub"
                          placeholder="https://github.com/..."
                          addonLeft={<GitHubIcon size={16} />}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Label>GitLab</Label>
                        <InputFormField
                          name="linkGitLab"
                          placeholder="https://gitlab.com/..."
                          addonLeft={<GitLabIcon size={16} />}
                        />
                      </Grid>
                    </Grid>
                  </div>

                  <div className="geecko-form__item">
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <Label>Stack Overflow</Label>
                        <InputFormField
                          name="linkStackOverflow"
                          placeholder="https://stackoverflow.com/..."
                          addonLeft={<StackOverflowIcon size={18} />}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Label>Habr</Label>
                        <InputFormField
                          name="linkHabr"
                          placeholder="https://habr.com/..."
                          addonLeft={<HabrIcon size={16} />}
                        />
                      </Grid>
                    </Grid>
                  </div>

                  <div className="geecko-form__item">
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <Label>Bitbucket</Label>
                        <InputFormField
                          name="linkBitBucket"
                          placeholder="https://bitbucket.org/..."
                          addonLeft={<BitBucketIcon size={16} />}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Label>Toster</Label>
                        <InputFormField
                          name="linkToster"
                          placeholder="https://toster.ru/..."
                          addonLeft={<TosterIcon size={16} />}
                        />
                      </Grid>
                    </Grid>
                  </div>

                  <div className="geecko-form__item">
                    <Text variant="h2" color="#99A2B0">
                      Примеры кода
                    </Text>
                  </div>
                </div>

                <CvCodeSamplesFormItem />

                <div className="geecko-paper__wrapper">
                  <CVFormFooter
                    candidateHasCv={candidateHasCv}
                    nextStep={nextStep}
                    previousStep={previousStep}
                    submitting={submitting}
                  />
                </div>
              </>
            </form>
          )}
        />
      )}
    </>
  );
};

export default CVCommunityForm;
