import { Immutable } from 'immer';
import { Specialization } from 'models/Specialization';
import { RejectReason } from 'models/Dictionary/RejectReason';
import { SkillsTemplate } from 'models/Dictionary/SkillsTemplate';
import { NetworkEntitiesCollecion } from 'store';
import { Language, CommonEntity } from 'models/BaseTypes';
import { CompanyUser } from '../../../models/CompanyUser';
import { CompanyOffice } from '../../../models/CompanyOffice';

export type DictionaryState = Immutable<{
  specializations: NetworkEntitiesCollecion<Specialization>;
  languages: NetworkEntitiesCollecion<Language>;
  workFeatures: NetworkEntitiesCollecion<CommonEntity>;
  companyScopes: NetworkEntitiesCollecion<CommonEntity>;
  vacancyRejectReasons: NetworkEntitiesCollecion<RejectReason>;
  candidateRejectReasons: NetworkEntitiesCollecion<RejectReason>;
  skillsTemplates: NetworkEntitiesCollecion<SkillsTemplate>;
  companyUsers: NetworkEntitiesCollecion<CompanyUser>;
  companyOffices: NetworkEntitiesCollecion<CompanyOffice>;
}>;

export const initialDictionaryState: DictionaryState = {
  specializations: {},
  languages: {},
  workFeatures: {},
  companyScopes: {},
  vacancyRejectReasons: {},
  candidateRejectReasons: {},
  skillsTemplates: {},
  companyUsers: {},
  companyOffices: {},
}
