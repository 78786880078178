import { NetworkEntitiesCollecion, NetworkEntity } from 'store';
import { VacancyActiveStatus } from 'models/VacancyState';
import { Immutable } from 'immer';
import { Vacancy } from 'models/Vacancy';
import { CompanyRequisites } from 'models/Company/CompanyRequisites';
import { Candidate } from 'models/Company/Candidate';
import { Pool, PoolOnline } from 'models/Company/Pool';
import { PoolCounters } from 'models/Company/Counters';
import { ShortVacancy } from 'models/Company/CompanyPool';

export interface VacanciesCounters {
  [VacancyActiveStatus.Active]: number;
  [VacancyActiveStatus.Archived]: number;
  [VacancyActiveStatus.Draft]: number;
  [VacancyActiveStatus.Review]: number;
}

export interface ResumeAndVacancy {
  resumeId: string;
  vacancyId: number;
}

type CompanyStateInterface = {
  resumeOfferId?: string;
  vacancyOfferId?: number;

  resumeRejectId?: string;
  vacancyRejectId?: number;

  resumeCandidateResponseId?: string;
  vacancyCandidateResponseId?: number;
  vacancyResponseComment?: string;

  interviewFeedback?: ResumeAndVacancy;
  interviewSchedule?: ResumeAndVacancy;

  vacancies: {
    status?: VacancyActiveStatus;
    vacancies: NetworkEntitiesCollecion<Vacancy>;
    counters?: VacanciesCounters;
    previewId?: number;
    loading?: boolean;
  };

  vacancy: {
    vacancy: NetworkEntity<Vacancy>;
  };

  requisites: NetworkEntity<CompanyRequisites>;

  pool: {
    pool: NetworkEntity<Pool>;
    online?: PoolOnline;
    counters?: PoolCounters;
    vacancies?: ShortVacancy[];
  };

  candidates: {
    candidates: NetworkEntitiesCollecion<Candidate>;
    counters: { [status: string]: number };
    total?: number;
    previewId?: string;
    status?: string;
  };

  // До того времени, пока вакансии у нас не будут в сторе, воспользуемся хаком,
  // когда происходит какое-то изменение статусов кандидатов в эту перенную помещается рандомная строка
  // Контекст вакансий будет прослушивать её изменение и дергать ручку обновления данных
  statusReloadId?: string;
};

export type CompanyState = Immutable<CompanyStateInterface>;

export const initialCompanyState: CompanyState = {
  vacancies: { vacancies: {} },
  vacancy: { vacancy: {} },
  pool: { pool: {} },
  candidates: { candidates: {}, counters: {} },
  requisites: {},
};
