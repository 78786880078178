import React, { MouseEvent, KeyboardEvent } from 'react';
import './SegmentedControl.css';

export type Option = {
  label: string;
  value: string;
  default?: boolean;
}

interface Props {
  value?: string;
  options: Option[];
  onChange?: (value: string) => void;
}

const SegmentedControl: React.FC<Props> = (props: Props) => {
  const {
    options, onChange, value,
  } = props;
  const handleChange = (newValue: string) => {
    if (onChange) onChange(newValue);
  };

  return (
    <>
      <div
        className={[
          'geecko-segmented-control',
        ].join(' ')}
      >
        {options.map(option => (
          <SegmentedControlItem
            key={option.value}
            data={option}
            onChange={handleChange}
            current={value}
            total={options.length || 1}
          />
        ))}
      </div>
    </>
  );
};

interface ItemProps {
  data: Option;
  onChange: ((value: string) => void);
  current: string | undefined;
  total: number;
}

const SegmentedControlItem: React.FC<ItemProps> = (props: ItemProps) => {
  const {
    data, onChange, current, total,
  } = props;
  const handleClick = () => {
    onChange(data.value);
  };

  const handleMouseDown = (event: MouseEvent<HTMLButtonElement>) => {
    const target = event.target as HTMLLIElement;
    if (target) {
      target.blur();
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    switch (event.key) {
      case ' ':
      case 'Enter':
        event.preventDefault();
        onChange(data.value);
        break;
      case 'Escape':
      case 'Tab':
        break;
      default:
        break;
    }
  };

  return (
    <button
      className={[
        'geecko-segmented-control__item',
        data.value === current ? 'geecko-segmented-control__item--active' : '',
      ].join(' ')}
      style={{ flexBasis: `${100 / total}%` }}
      onClick={handleClick}
      onMouseDown={handleMouseDown}
      onKeyDown={handleKeyDown}
      type="button"
    >
      {data.label}
    </button>
  );
};

export default SegmentedControl;
