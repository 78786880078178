import { SearchStageStatus, SearchStatus } from './SearchSession';

export interface ReportStageCounter {
  readonly title: string;
  readonly count: number;
  readonly change: number;
  readonly icon: string;
}

interface ReportRejectReasonComment {
  readonly text: string;
  readonly author: string;
}

interface ReportRejectReason {
  readonly id: string;
  readonly title: string;
  readonly count: number;
  readonly comments?: ReportRejectReasonComment[];
}

export enum ReportRecomendationType {
  Positive = 'positive',
  Negative = 'negative',
}

interface ReportRecomendation {
  readonly type: ReportRecomendationType;
  readonly description: string;
}

export interface ReportStage {
  readonly id: SearchStageStatus;
  readonly startedAt?: string;
  readonly finishAt?: string;
  readonly status: SearchStatus;
  readonly name: string;
  readonly text?: string;
  readonly counters?: ReportStageCounter[];
  readonly rejectReasons?: ReportRejectReason[];
  readonly recommendations?: ReportRecomendation[];
}

export interface Report {
  startedAt: string;
  finishAt: string;
  stages: ReportStage[];
}

const decodeReportRejectReasonComment = (json: any): ReportRejectReasonComment => ({
  text: json.text,
  author: json.author,
});

const decodeReportRejectReason = (json: any): ReportRejectReason => ({
  id: json.id,
  title: json.title,
  count: json.count,
  comments: json.comments && Array.isArray(json.comments) ? json.comments.map(decodeReportRejectReasonComment) : [],
});

const decodeReportStageCounter = (json: any): ReportStageCounter => ({
  title: json.title,
  count: json.count,
  change: json.change,
  icon: json.icon,
});

const decodeReportRecomendation = (json: any): ReportRecomendation => ({
  type: json.type,
  description: json.description,
});

export const decodeReportStage = (json: any): ReportStage => ({
  id: json.id,
  startedAt: json.started_at || undefined,
  finishAt: json.finish_at || undefined,
  status: json.status,
  name: json.name,
  text: json.text || undefined,
  counters: json.counters && Array.isArray(json.counters) ? json.counters.map(decodeReportStageCounter) : [],
  rejectReasons:
    json.reject_reasons && Array.isArray(json.reject_reasons)
      ? json.reject_reasons.map(decodeReportRejectReason)
      : [],
  recommendations:
    json.recommendations && Array.isArray(json.recommendations)
      ? json.recommendations.map(decodeReportRecomendation)
      : [],
});

export const decodeReport = (json: any): Report => ({
  startedAt: json.started_at,
  finishAt: json.finish_at,
  stages: json.stages && Array.isArray(json.stages) ? json.stages.map(decodeReportStage) : [],
})
