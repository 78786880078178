import React, { FC } from 'react';

interface Props {
  size?: number;
}

const PhotoIcon: FC<Props> = ({ size = 24 }) => (
  <svg width={size} height={size} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <g clipPath="url(#clip0)" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
      <path d="M15.334 12.667A1.333 1.333 0 0114 14H2a1.333 1.333 0 01-1.333-1.333V5.333A1.333 1.333 0 012 4h2.667L6 2h4l1.334 2H14a1.333 1.333 0 011.334 1.333v7.334z" />
      <path d="M8 11.333A2.667 2.667 0 108 6a2.667 2.667 0 000 5.333z" />
    </g>
    <defs><clipPath id="clip0"><path d="M0 0h16v16H0V0z" fill="#fff" /></clipPath></defs>
  </svg>
);

export default PhotoIcon;
