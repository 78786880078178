import React, { FC } from 'react';
import { redirect } from 'utils/redirect';
import { CvFormStep } from '../../../pages/Candidate/Cv/CandidateEditCvPage/CandidateEditCvPage';
import Button from '../../components/v2/Button/Button';

interface Props {
  candidateHasCv?: boolean;
  nextStep?: CvFormStep;
  previousStep?: CvFormStep;
  submitting?: boolean;
}

const CVFormFooter: FC<Props> = ({
  candidateHasCv, nextStep, previousStep, submitting,
}) => (
  <div className="geecko-form__buttons-line geecko-form__buttons-line--margin-lg">
    <div className="geecko-form__buttons-line__button">
      <Button
        size="lg"
        color="primary"
        type="submit"
        isLoading={submitting}
      >
        {(candidateHasCv || !nextStep) ? 'Сохранить' : 'Далее'}
      </Button>
    </div>
    {previousStep && !candidateHasCv && (
    <div className="geecko-form__buttons-line__button">
      <Button
        size="lg"
        color="secondary"
        onClick={() => redirect(`/my/cv/edit/${previousStep}`)}
      >
        Назад
      </Button>
    </div>
    )}
  </div>
);

export default CVFormFooter;
