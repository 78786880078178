import { createAction, createAsyncAction } from 'typesafe-actions';
import { VacancyActiveStatus } from 'models/VacancyState';
import { Vacancy, VacanciesSort } from 'models/Vacancy';
import { CompanyRequisites } from 'models/Company/CompanyRequisites';
import { Pool, PoolOnline } from 'models/Company/Pool';
import { PoolCounters } from 'models/Company/Counters';
import { Candidate } from 'models/Company/Candidate';
import { ShortVacancy } from 'models/Company/CompanyPool';
import { ResumeAndVacancy, VacanciesCounters } from './state';
import { Resume } from '../../../models/Resume';

/**
 * Открывает модальное окно оффера с указанным Id
 */
export const openOfferDialog = createAction('company/OPEN_OFFER')<{ resumeId: string; vacancyId: number }>();

/**
 * Закрывает модальное оффера
 */
export const closeOfferDialog = createAction('company/CLOSE_OFFER')();

/**
 * Открывает модальное окно оценки интервью со стороны компании
 */
export const openCompanyInterviewFeedbackDialog = createAction('company/OPEN_INTERVIEW_FEEDBACK')<ResumeAndVacancy>();

/**
 * Закрывает модальное окно оценки интервью со стороны компании
 */
export const closeCompanyInterviewFeedbackDialog = createAction('company/CLOSE_INTERVIEW_FEEDBACK')();

/**
 * Открывает модальное окно отказа кандидату с указанным Id
 */
export const openRejectCandidateDialog = createAction('company/OPEN_CANDIDATE_REJECT')<{
  resumeId: string;
  vacancyId: number;
}>();

/**
 * Закрывает модальное окно отказа кандидату
 */
export const closeRejectCandidateDialog = createAction('company/CLOSE_CANDIDATE_REJECT')();

/**
 * Открывает модальное окно отклика кандидата с указанным Id
 */
export const openResponseCandidateDialog = createAction('company/OPEN_CANDIDATE_RESPONSE')<{
  resumeId: string;
  vacancyId: number;
  comment?: string;
}>();

/**
 * Закрывает модальное окно отклика кандидата
 */
export const closeResponseCandidateDialog = createAction('company/CLOSE_CANDIDATE_RESPONSE')();

/**
 * Уведомляет о каком либо изменении в матчинге вакансий
 */
export const reloadVacanciesState = createAction('company/RELOAD_VACANCY_STATUSES')<string>();

/**
 * Получение вакансий компании
 */
export const fetchCompanyVacanciesAction = createAsyncAction(
  'company/VACANCIES_REQUEST',
  'company/VACANCIES_SUCCESS',
  'company/VACANCIES_FAILURE',
)<
  { status: VacancyActiveStatus; vacanciesSort: VacanciesSort },
  { vacancies: Vacancy[]; counters: VacanciesCounters },
  Error
>();

/**
 * Получение реквизитов компании
 */
export const fetchCompanyRequisitesAction = createAsyncAction(
  'company/REQUISITES_REQUEST',
  'company/REQUISITES_SUCCESS',
  'company/REQUISITES_FAILURE',
)<undefined, CompanyRequisites, Error>();

/**
 * Получение конкретного пула
 */
export const fetchPoolAction = createAsyncAction('pools/POOL_REQUEST', 'pools/POOL_SUCCESS', 'pools/POOL_FAILURE')<
  { id: string; loadMore: boolean },
  {
    pool: Pool;
    counters: PoolCounters;
    online: PoolOnline;
    candidates: Candidate[];
    total: number;
    append: boolean;
    vacancies: ShortVacancy[];
  },
  Error
>();

/**
 * Открывает кандидата с переданным Id на предпросмотр
 */
export const openCandidatePreview = createAction('candidate/OPEN_CANDIDATE_PREVIEW')<string>();

/**
 * Закрывает предпросмотр кандидата
 */
export const closeCandidatePreview = createAction('candidate/CLOSE_CANDIDATE_PREVIEW')();

/**
 * Открывает предыдущего кандидата в предпросмотре
 */
export const previewCandidateGoPrev = createAction('candidate/PREVIEW_CANDIDATE_PREV')();

/**
 * Открывает следующую вакансию в предпросмотре
 */
export const previewCandidateGoNext = createAction('candidate/PREVIEW_CANDIDATE_NEXT')();

/*
 * Страница вакансии
 */
export const fetchVacancyAction = createAsyncAction(
  'company/FETCH_VACANCY_REQUEST',
  'company/FETCH_VACANCY_SUCCESS',
  'company/FETCH_VACANCY_FAILURE',
)<string | number, Vacancy, Error>();

/**
 * Получение кандидатов вакансии
 */
export const fetchVacancyCandidatesAction = createAsyncAction(
  'company/FETCH_VACANCY_CANDIDATE_REQUEST',
  'company/FETCH_VACANCY_CANDIDATE_SUCCESS',
  'company/FETCH_VACANCY_CANDIDATE_FAILURE',
)<
  { vacancyId: string | number; status: string; loadMore: boolean },
  { candidates: Candidate[]; total: number; append: boolean; counters: { [status: string]: number } },
  Error
>();

/*
 * Обновление резюме кандидата в списке
 */
export const reloadCandidatesListResumeAction = createAsyncAction(
  'company/CANDIDATES_LIST_RESUME_RELOAD_REQUEST',
  'company/CANDIDATES_LIST_RESUME_RELOAD_SUCCESS',
  'company/CANDIDATES_LIST_RESUME_RELOAD_FAILURE',
)<string, Resume, Error>();

/**
 * Открывает модальное окно назначения собеседования
 */
export const openScheduleInterviewDialogAction = createAction('company/OPEN_SCHEDULE_INTERVIEW')<{
  resumeId: string;
  vacancyId: number;
}>();

/**
 * Закрывает модальное окно назначения собеседования
 */
export const closeScheduleInterviewDialogAction = createAction('company/CLOSE_SCHEDULE_INTERVIEW')();
