/* eslint-disable prefer-destructuring */
import { FORM_ERROR } from 'final-form';
import { nanoid } from 'nanoid';
import getMonth from 'date-fns/getMonth';
import getYear from 'date-fns/getYear';
import endOfMonth from 'date-fns/endOfMonth';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import qs from 'qs';
import { GeeckoApi } from 'modules/api/geecko-api';
import { Dispatch } from 'redux';
import { redirect } from 'utils/redirect';
import { PositionValue } from 'models/Vacancy';
import { Option } from 'components/Form/SelectField/SelectField';
import { validateBirthDate } from 'components/FormAdapter/FormAdapter';
import { StackTool, decodeStackTool } from 'models/StackTool';
import { Immutable } from 'immer';
import { showNotifyNew } from '../Notify';
import { CommonEntity, Language } from '../BaseTypes';
import {
  CvFormStep,
  getCvFormSteps,
  getNextCvFormStepByIndex,
} from '../../../pages/Candidate/Cv/CandidateEditCvPage/CandidateEditCvPage';
import { monthsToYearsString, yearsStringToMonths } from '../helpers';
import { decodeLocation, decodeCountry, getLocationTitle, Location, Country } from '../Location';
import { decodeSkillNew, SkillNew } from '../Skill';
import { decodeUniversity, University } from '../University';
import { decodeSkillSet, SkillSetInterface } from '../Tech/skillset';

export interface CvCandidateSpecialization {
  id: string;
  name: string;
  isPrimary: boolean;
}

export interface SkillTool {
  id: number;
  name: string;
  type: 'lang' | 'tech' | 'tech.db' | 'Service';
  image: string;
  link: string;
}

export interface CvSkills {
  lang: SkillNew[];
  db: SkillNew[];
  tech: SkillNew[];
}

export interface CvSalary {
  currency: string;
  period: string;
  min: any;
  public: any;
  isVisible: boolean;
}

// Интерфейс, описывающий резюме кандидата в форме редактирования
export interface CvFormValues {
  fullName: string;
  profession: string;
  photoUrl: string;
  phone: string;
  email: string;
  birthDay: string;
  contacts: CvFormValuesContact[];
  about: string;
  salary: CvSalary;
  hasManagementExperience: boolean;
  managementExperienceInfo: string;
  managementExperienceMonth: string;
  workType: PositionValue[];
  specializations: CvCandidateSpecialization[];
  location?: CommonEntity;
  relocationCities: Location[];
  readyForRelocation: boolean;
  relocationCountries: Country[];
  readyForRelocationCountries: boolean;
  tools: StackTool[];
  languages: Language[];
  linkGitHub: string;
  linkGitLab: string;
  linkStackOverflow: string;
  linkBitBucket: string;
  linkToster: string;
  linkHabr: string;
  codeSamples: CvFormValuesCodeSample[];
  workFeatures: number[];
  workAreas: number[];
  expectations: string;
  experience: CvFormValuesExperience[];
  education: CvFormValuesUniversityItem[];
  areas: string[];
  skills: CvSkills;
  skillSet: SkillSetInterface;
}

export type CvFormValuesCodeSample = Immutable<{
  uid: string;
  link: string;
  description: string;
  tools: StackTool[];
}>

export interface CvFormValuesContact {
  channelId: string;
  value: string;
}

export interface CvFormValuesExperience {
  uid: string;
  id?: number;
  companyName: string;
  companyLink: string;
  description: string;
  position: string;
  showProjects?: boolean;
  type: 'common' | 'project';
  tools: StackTool[];
  startMonth?: string;
  startYear?: string;
  finishMonth?: string;
  finishYear?: string;
  isContinuous?: boolean;
  projects: CvFormValuesExperienceProject[];
  areas: string[];
  specializations: CvCandidateSpecialization[];
  skills: CvSkills;
}

export interface CvFormValuesExperienceProject {
  uid: string;
  id?: number;
  title: string;
  link: string;
  position: string;
  description: string;
  specializations: CvCandidateSpecialization[];
  tools: StackTool[];
  areas: string[];
  skills: CvSkills;
}

export interface CvFormValuesUniversityItem {
  uid: string;
  university?: University;
  faculty?: Option;
  startMonth?: string;
  startYear?: string;
  finishMonth?: string;
  finishYear?: string;
  isContinuous?: boolean;
}

export const getCandidateEducationItem = (): CvFormValuesUniversityItem => ({
  uid: nanoid(),
  isContinuous: false,
});

export const getCandidateExperienceItem = (): CvFormValuesExperience => ({
  uid: nanoid(),
  companyName: '',
  companyLink: '',
  description: '',
  position: '',
  type: 'common',
  tools: [],
  projects: [],
  areas: [],
  specializations: [],
  skills: {
    lang: [],
    db: [],
    tech: [],
  },
});

export const getCandidateExperienceProjectItem = (): CvFormValuesExperienceProject => ({
  uid: nanoid(),
  title: '',
  link: '',
  position: '',
  description: '',
  tools: [],
  areas: [],
  skills: {
    lang: [],
    db: [],
    tech: [],
  },
  specializations: [],
});

export const decodeSkills = (json: any): CvSkills => ({
  lang: json?.skills?.lang && Array.isArray(json.skills.lang) ? json.skills.lang.map(decodeSkillNew) : [],
  db: json?.skills?.db && Array.isArray(json.skills.db) ? json.skills.db.map(decodeSkillNew) : [],
  tech: json?.skills?.tech && Array.isArray(json.skills.tech) ? json.skills.tech.map(decodeSkillNew) : [],
});

export const decodeCandidateExperienceItem = (experienceJson: any): CvFormValuesExperience => {
  let fromMonth: string | undefined;
  let fromYear: string | undefined;
  let toMonth: string | undefined;
  let toYear: string | undefined;
  let isContinued = false;

  if (experienceJson.start) {
    try {
      const startDate = new Date(experienceJson.start);
      fromMonth = (getMonth(startDate) + 1).toString();
      fromYear = getYear(startDate).toString();
    } catch (e) {
      //
    }
  }

  if (experienceJson.finish) {
    try {
      const finishDate = new Date(experienceJson.finish);
      toMonth = (getMonth(finishDate) + 1).toString();
      toYear = getYear(finishDate).toString();
    } catch (e) {
      //
    }
  }

  if (!toMonth && !toYear) {
    isContinued = true;
  }

  let projects: CvFormValuesExperienceProject[] = [];
  if (experienceJson.projects) {
    projects = experienceJson.projects.map(decodeCandidateExperienceProject);
  }

  return {
    uid: nanoid(),
    id: experienceJson.id,
    companyName: experienceJson.company ? experienceJson.company.title : '',
    companyLink: experienceJson.company ? experienceJson.company.link : '',
    description: experienceJson.company ? experienceJson.company.description : '',
    position: experienceJson.position,
    type: 'common',
    tools: experienceJson.tools && Array.isArray(experienceJson.tools) ? experienceJson.tools.map(decodeStackTool) : [],
    projects,
    startMonth: fromMonth,
    startYear: fromYear,
    finishMonth: toMonth,
    finishYear: toYear,
    isContinuous: isContinued,
    specializations:
      experienceJson.specializations && Array.isArray(experienceJson.specializations)
        ? experienceJson.specializations.map((specializationJson: any) => ({
            id: specializationJson.id,
            name: specializationJson.name,
            isPrimary: !!specializationJson.is_primary,
          }))
        : [],
    areas:
      experienceJson.areas && Array.isArray(experienceJson.areas)
        ? experienceJson.areas.filter((item: any) => !!item)
        : [],
    skills: decodeSkills(experienceJson),
    showProjects: projects.length > 0,
  };
};

export const decodeCandidateExperience = (experienceItems: any): CvFormValuesExperience[] => {
  if (!experienceItems || !Array.isArray(experienceItems)) {
    return [];
  }

  return experienceItems.map(decodeCandidateExperienceItem);
};

export const decodeCandidateExperienceProject = (projectJson: any): CvFormValuesExperienceProject => ({
  uid: nanoid(),
  id: projectJson.id,
  title: projectJson.title,
  link: projectJson.link,
  position: projectJson.position,
  description: projectJson.description,
  tools: projectJson.tools && Array.isArray(projectJson.tools) ? projectJson.tools.map(decodeStackTool) : [],
  areas: projectJson.areas && Array.isArray(projectJson.areas) ? projectJson.areas.filter((item: any) => !!item) : [],
  skills: decodeSkills(projectJson),
  specializations:
    projectJson.specializations && Array.isArray(projectJson.specializations)
      ? projectJson.specializations.map((specializationJson: any) => ({
          id: specializationJson.id,
          name: specializationJson.name,
          isPrimary: !!specializationJson.is_primary,
        }))
      : [],
});

export const encodeCandidateExperience = (experience: Immutable<CvFormValuesExperience[]>): any =>
  experience
    .filter((experienceItem) => experienceItem.companyName || experienceItem.position)
    .map((experienceItem) => {
      const company = {
        title: experienceItem.companyName,
        link: experienceItem.companyLink,
        description: experienceItem.description,
      };

      let startDate: string | undefined;
      if (experienceItem.startMonth && experienceItem.startYear) {
        try {
          const startDateObject = new Date(
            parseInt(experienceItem.startYear, 10),
            parseInt(experienceItem.startMonth, 10) - 1,
            1,
          );
          startDate = format(startDateObject, 'yyyy-MM-dd');
        } catch (e) {
          //
        }
      }

      let finishDate: string | undefined;
      if (!experienceItem.isContinuous && experienceItem.finishMonth && experienceItem.finishYear) {
        try {
          const finishDateObject = endOfMonth(
            new Date(parseInt(experienceItem.finishYear, 10), parseInt(experienceItem.finishMonth, 10) - 1, 1),
          );
          finishDate = format(finishDateObject, 'yyyy-MM-dd');
        } catch (e) {
          //
        }
      }

      return {
        id: experienceItem.id,
        position: experienceItem.position,
        company,
        start: startDate || null,
        finish: finishDate || null,
        specializations: experienceItem.specializations.map((item) => item.id),
        areas: experienceItem.areas,
        skills: {
          lang: experienceItem.skills.lang.map((item) => ({ id: item.id, is_primary: item.isPrimary })),
          tech: experienceItem.skills.tech.map((item) => ({ id: item.id, is_primary: item.isPrimary })),
          db: experienceItem.skills.db.map((item) => ({ id: item.id, is_primary: item.isPrimary })),
        },
        projects: experienceItem.projects
          .filter((projectItem) => projectItem.title || projectItem.link)
          .map((projectItem) => ({
            id: projectItem.id,
            title: projectItem.title,
            position: projectItem.position,
            description: projectItem.description,
            link: projectItem.link,
            specializations: projectItem.specializations.map((item) => item.id),
            areas: projectItem.areas,
            skills: {
              lang: projectItem.skills.lang.map((item) => ({ id: item.id, is_primary: item.isPrimary })),
              tech: projectItem.skills.tech.map((item) => ({ id: item.id, is_primary: item.isPrimary })),
              db: projectItem.skills.db.map((item) => ({ id: item.id, is_primary: item.isPrimary })),
            },
            tools:
              projectItem.tools && Array.isArray(projectItem.tools)
                ? projectItem.tools.map((tool) => tool.id || tool.value)
                : [],
          })),
        tools:
          experienceItem.tools && Array.isArray(experienceItem.tools)
            ? experienceItem.tools.map((tool) => tool.id || tool.value)
            : [],
      };
    });

const encodeCandidateEducationItem = (educationItems: CvFormValuesUniversityItem[]): any =>
  educationItems
    .filter((educationItem) => educationItem.university)
    .map((educationItem) => {
      let startDate: string | undefined;
      if (educationItem.startMonth && educationItem.startYear) {
        try {
          const startDateObject = new Date(
            parseInt(educationItem.startYear, 10),
            parseInt(educationItem.startMonth, 10) - 1,
            1,
          );
          startDate = format(startDateObject, 'yyyy-MM-dd');
        } catch (e) {
          //
        }
      }

      let finishDate: string | undefined;
      if (!educationItem.isContinuous && educationItem.finishMonth && educationItem.finishYear) {
        try {
          const finishDateObject = endOfMonth(
            new Date(parseInt(educationItem.finishYear, 10), parseInt(educationItem.finishMonth, 10) - 1, 1),
          );
          finishDate = format(finishDateObject, 'yyyy-MM-dd');
        } catch (e) {
          //
        }
      }

      return {
        university_id: educationItem.university!.id ? educationItem.university!.id : undefined,
        name: educationItem.university!.name,
        faculty: educationItem.faculty ? educationItem.faculty.label : '',
        faculty_id: educationItem.faculty && educationItem.faculty!.value ? educationItem.faculty!.value : undefined,
        start: startDate,
        finish: finishDate,
      };
    });

export const decodeCandidateEducation = (educationItems: any): CvFormValuesUniversityItem[] => {
  if (!educationItems || !Array.isArray(educationItems)) {
    return [];
  }

  const result: CvFormValuesUniversityItem[] = [];

  educationItems.forEach((educationJson: any) => {
    let fromMonth: string | undefined;
    let fromYear: string | undefined;
    let toMonth: string | undefined;
    let toYear: string | undefined;
    let isContinued = false;

    if (educationJson.start) {
      try {
        const startDate = new Date(educationJson.start);
        fromMonth = (getMonth(startDate) + 1).toString();
        fromYear = getYear(startDate).toString();
      } catch (e) {
        //
      }
    }

    if (educationJson.finish) {
      try {
        const finishDate = new Date(educationJson.finish);
        toMonth = (getMonth(finishDate) + 1).toString();
        toYear = getYear(finishDate).toString();
      } catch (e) {
        //
      }
    }

    if (!toMonth && !toYear) {
      isContinued = true;
    }

    result.push({
      uid: nanoid(),
      university: educationJson.name
        ? {
            id: educationJson.university_id ? educationJson.university_id : undefined,
            name: educationJson.name,
            faculties: educationJson.faculties,
          }
        : undefined,
      faculty: educationJson.faculty
        ? {
            value: educationJson.faculty_id ? educationJson.faculty_id.toString() : undefined,
            label: educationJson.faculty,
          }
        : undefined,
      startMonth: fromMonth,
      startYear: fromYear,
      finishMonth: toMonth,
      finishYear: toYear,
      isContinuous: isContinued,
    });
  });

  return result;
};

export const CONTACTS_CHANNELS: Option[] = [
  {
    label: 'Telegram',
    value: 'telegram',
  },
  {
    label: 'Skype',
    value: 'skype',
  },
  {
    label: 'Facebook',
    value: 'fb',
  },
  {
    label: 'ВКонтакте',
    value: 'vk',
  },
];

export const decodeCvCodeSample = (json: any): CvFormValuesCodeSample => ({
  uid: nanoid(),
  link: json.link,
  description: json.description,
  tools: json.tools && Array.isArray(json.tools) ? json.tools.map(decodeStackTool) : [],
});

export const decodeCvSalary = (json: any): CvSalary => ({
  currency: json.currency,
  period: json.period,
  min: json.from,
  public: json.to,
  isVisible: json.is_visible,
});

export const decodeCvFormValues = (json: any): CvFormValues => {
  const contacts: CvFormValuesContact[] = [];

  const emailContact =
    json.contacts && Array.isArray(json.contacts)
      ? json.contacts.find((contact: any) => contact.channel_id === 'email')
      : undefined;
  const phoneContact =
    json.contacts && Array.isArray(json.contacts)
      ? json.contacts.find((contact: any) => contact.channel_id === 'phone')
      : undefined;
  let linkGitHub = '';
  let linkGitLab = '';
  let linkBitBucket = '';
  let linkStackOverflow = '';
  let linkHabr = '';
  let linkToster = '';

  if (json.contacts && Array.isArray(json.contacts)) {
    json.contacts.forEach((jsonContact: any) => {
      if (['email', 'phone'].indexOf(jsonContact.channel_id) >= 0) {
        return;
      }
      if (!jsonContact.value) {
        return;
      }

      contacts.push({
        channelId: jsonContact.channel_id,
        value: jsonContact.value,
      });
    });
  }

  if (json.communities && Array.isArray(json.communities)) {
    json.communities.forEach((community: any) => {
      switch (community.id) {
        case 'github':
          linkGitHub = community.link;
          break;
        case 'gitlab':
          linkGitLab = community.link;
          break;
        case 'toster':
          linkToster = community.link;
          break;
        case 'stackoverflow':
          linkStackOverflow = community.link;
          break;
        case 'habr':
          linkHabr = community.link;
          break;
        case 'bitbucket':
          linkBitBucket = community.link;
          break;
      }
    });
  }

  let vacancyWorkType: PositionValue[];
  if (Array.isArray(json.work_type)) {
    vacancyWorkType = json.work_type;
  } else if (json.work_type === 'office_or_remote') {
    vacancyWorkType = ['office', 'remote'];
  } else {
    vacancyWorkType = [json.work_type];
  }

  let birthDay = '';
  try {
    const birthDayDate = parse(json.birthday, 'yyyy-LL-dd', new Date());
    birthDay = format(birthDayDate, 'dd.LL.yyyy');
  } catch (e) {
    //
  }

  const managementExperienceMonth =
    json.experience_summary && json.experience_summary.management_months
      ? monthsToYearsString(json.experience_summary.management_months)
      : '';
  return {
    fullName: json.full_name,
    photoUrl: json.photo_url || '',
    profession: json.profession,
    phone: phoneContact ? phoneContact.value : '',
    email: emailContact ? emailContact.value : '',
    contacts,
    birthDay,
    about: json.about,
    salary: decodeCvSalary(json.salary),
    managementExperienceMonth,
    managementExperienceInfo: json.management_experience_info,
    hasManagementExperience: !!managementExperienceMonth || !!json.management_experience_info,
    workType: vacancyWorkType,
    specializations:
      json.specializations && Array.isArray(json.specializations)
        ? json.specializations.map((specializationJson: any) => ({
            id: specializationJson.id,
            name: specializationJson.name,
            isPrimary: !!specializationJson.is_primary,
          }))
        : [],
    tools: json.tools && Array.isArray(json.tools) ? json.tools.map(decodeStackTool) : [],
    languages: json.languages,
    linkGitHub,
    linkStackOverflow,
    linkBitBucket,
    linkToster,
    linkHabr,
    linkGitLab,
    codeSamples: json.code_samples && Array.isArray(json.code_samples) ? json.code_samples.map(decodeCvCodeSample) : [],
    location: json.location
      ? { id: decodeLocation(json.location).id, name: getLocationTitle(decodeLocation(json.location)) }
      : undefined,
    workFeatures:
      json.work_features && Array.isArray(json.work_features)
        ? json.work_features.map((workFeature: any) => workFeature.id)
        : [],
    workAreas:
      json.work_areas && Array.isArray(json.work_areas) ? json.work_areas.map((workArea: any) => workArea.id) : [],
    expectations: json.expectations || '',
    readyForRelocation: json.relocation_type === 'ready',
    relocationCities:
      json.relocation_city && Array.isArray(json.relocation_city) ? json.relocation_city.map(decodeLocation) : [],
    readyForRelocationCountries: json.country_relocation_type === 'ready',
    relocationCountries: json.countries && Array.isArray(json.countries) ? json.countries.map(decodeCountry) : [],
    experience: decodeCandidateExperience(json.experience),
    education: decodeCandidateEducation(json.education),
    areas: json.areas && Array.isArray(json.areas) ? json.areas.filter((item: any) => !!item) : [],
    skills: {
      lang: json?.skills?.lang && Array.isArray(json.skills.lang) ? json.skills.lang.map(decodeSkillNew) : [],
      db: json?.skills?.db && Array.isArray(json.skills.db) ? json.skills.db.map(decodeSkillNew) : [],
      tech: json?.skills?.tech && Array.isArray(json.skills.tech) ? json.skills.tech.map(decodeSkillNew) : [],
    },
    skillSet: decodeSkillSet(json?.skills),
  };
};

export const exnodeSalary = (salary: CvSalary) => ({
  currency: salary.currency,
  period: salary.period,
  from: salary.min,
  to: salary.public,
  is_visible: salary.isVisible,
});

export const encodeCodeSamples = (codeSamples: Immutable<CvFormValuesCodeSample[]>) => {
  return codeSamples
    .filter((codeSample) => !!codeSample.link)
    .map((codeSample) => ({
      link: codeSample.link,
      description: codeSample.description,
      tools: codeSample.tools ? codeSample.tools.map((tool) => tool.id || tool.value) : [],
    }));
};

export const encodeCvToFormValues = (cvData: CvFormValues, formStep: string) => {
  const contacts: any[] = [];
  contacts.push({
    channel_id: 'phone',
    value: cvData.phone,
  });
  contacts.push({
    channel_id: 'email',
    value: cvData.email,
  });

  CONTACTS_CHANNELS.forEach((contact) => {
    const formContact =
      cvData.contacts && Array.isArray(cvData.contacts)
        ? cvData.contacts.find((item) => item.channelId === contact.value)
        : null;
    contacts.push({
      channel_id: contact.value,
      value: formContact ? formContact.value : null,
    });
  });

  let birthDay;
  try {
    const birthDayDate = parse(cvData.birthDay, 'dd.LL.yyyy', new Date());
    birthDay = format(birthDayDate, 'yyyy-LL-dd');
  } catch (e) {
    birthDay = '';
  }

  const newSpecs: { [key: string]: any } = {};
  cvData.specializations.forEach((spec) => {
    newSpecs[spec.id] = { is_primary: spec.isPrimary };
  });

  const result: any = {
    full_name: cvData.fullName,
    photo_url: cvData.photoUrl,
    profession: cvData.profession,
    form_progress: formStep,
    birthday: birthDay,
    contacts,
    about: cvData.about || '',
    salary: exnodeSalary(cvData.salary),
    work_type: cvData.workType,
    specializations: newSpecs, // cvData.specializations.map(spec => ({ id: spec.id, is_primary: spec.isPrimary })),
    skills: {
      lang: cvData.skills.lang.map((item) => ({ id: item.id, is_primary: item.isPrimary })),
      tech: cvData.skills.tech.map((item) => ({ id: item.id, is_primary: item.isPrimary })),
      db: cvData.skills.db.map((item) => ({ id: item.id, is_primary: item.isPrimary })),
    },
    tools: cvData.tools ? cvData.tools.map((tool) => tool.id || tool.value) : [],
    languages: cvData.languages,
    github: cvData.linkGitHub || '',
    gitlab: cvData.linkGitLab || '',
    stackoverflow: cvData.linkStackOverflow || '',
    bitbucket: cvData.linkBitBucket || '',
    toster: cvData.linkToster || '',
    habr: cvData.linkHabr || '',
    code_samples: encodeCodeSamples(cvData.codeSamples),
    work_features: cvData.workFeatures,
    work_areas: cvData.workAreas,
    expectations: cvData.expectations || '',
    experience: encodeCandidateExperience(cvData.experience),
    location: cvData.location || null,
    relocation_city: cvData.relocationCities ? cvData.relocationCities.map((item) => ({ name: item.name })) : [],
    relocation_type: cvData.readyForRelocation ? 'ready' : 'not_ready',
    countries: cvData.relocationCountries ? cvData.relocationCountries.map((item) => item.id) : [],
    country_relocation_type: cvData.readyForRelocationCountries ? 'ready' : 'not_ready',
    management_experience_info: cvData.managementExperienceInfo,
    management_experience_months: yearsStringToMonths(cvData.managementExperienceMonth),
    education: encodeCandidateEducationItem(cvData.education),
  };

  return result;
};

export const defaultCvFormSteps = ['step1', 'step2', 'step3', 'step4', 'step5', 'step6'];

export const submitCvFormValues = async (
  showCvImport: boolean,
  dispatch: Dispatch,
  api: GeeckoApi,
  cvFormData: CvFormValues,
  formStep: CvFormStep,
  goNext: boolean,
  nextStep?: string,
) => {
  try {
    await api.put('/candidate/resume', encodeCvToFormValues(cvFormData, formStep));

    const steps = getCvFormSteps(showCvImport);
    const stepIndex = steps.indexOf(formStep);
    const newFormStep = getNextCvFormStepByIndex(stepIndex, showCvImport);

    let successMessage = 'Данные успешно сохранены';
    if (goNext && newFormStep) {
      successMessage += '. Переходим к следующему шагу...';
    } else if (nextStep) {
      successMessage += '. Переходим к финальному шагу...';
    }

    showNotifyNew(dispatch, {
      type: 'success',
      content: successMessage,
    });

    if (goNext || nextStep) {
      setTimeout(() => {
        if (nextStep) {
          redirect(`/my/cv/edit/${nextStep}`);
        } else if (newFormStep) {
          redirect(`/my/cv/edit/${newFormStep}`);
        } else {
          redirect('/personal-offer');
        }
      }, 100);
    }

    return undefined;
  } catch (error) {
    let errorMessage = 'Произошла ошибка, попробуйте еще раз';
    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 422:
          errorMessage = 'Проверьте правильность заполнения формы';
          const validationErrors: any = {};
          Object.keys(error.response.data.errors).forEach((fieldName: string) => {
            const fieldErrors = error.response.data.errors[fieldName];
            if (Array.isArray(fieldErrors) && fieldErrors.length > 0) {
              validationErrors[fieldName] = fieldErrors[0];
            }
          });
          return validationErrors;
        case 500:
          errorMessage = 'Произошла ошибка на сервере. Попробуйте еще раз';
      }
    }

    showNotifyNew(dispatch, {
      type: 'error',
      content: errorMessage,
    });

    return {
      [FORM_ERROR]: 'Произошла ошибка',
    };
  }
};

export const loadUniversitiesOptions = async (api: GeeckoApi, inputValue: string) => {
  const response = await api.get(`/dictionary/universities?${qs.stringify({ query: inputValue })}`);
  return response.data && response.data.universities && response.data.universities.length > 0
    ? response.data.universities.map(decodeUniversity)
    : [];
};

export const MONTHS: Option[] = [
  { value: '1', label: 'Январь' },
  { value: '2', label: 'Февраль' },
  { value: '3', label: 'Март' },
  { value: '4', label: 'Апрель' },
  { value: '5', label: 'Май' },
  { value: '6', label: 'Июнь' },
  { value: '7', label: 'Июль' },
  { value: '8', label: 'Август' },
  { value: '9', label: 'Сентябрь' },
  { value: '10', label: 'Октябрь' },
  { value: '11', label: 'Ноябрь' },
  { value: '12', label: 'Декабрь' },
];

export type CvFillRate = { [step: string]: number };

export const createInitialFillRateMap = (cvData: CvFormValues): CvFillRate => {
  const result: CvFillRate = {};
  result.import = 0;
  result.personal = calculateCvFormFillPercent(cvData, 'personal');
  result.common = calculateCvFormFillPercent(cvData, 'common');
  result.tech = calculateCvFormFillPercent(cvData, 'tech');
  result.community = calculateCvFormFillPercent(cvData, 'community');
  result.preferences = calculateCvFormFillPercent(cvData, 'preferences');
  result.experience = calculateCvFormFillPercent(cvData, 'experience');
  result.education = calculateCvFormFillPercent(cvData, 'education');
  return result;
};

export const calculateCvFormFillPercent = (cvFormValues: CvFormValues, step: CvFormStep): number => {
  let filledFields = 0;
  let allFields = 0;
  switch (step) {
    case 'personal': {
      allFields = 6;

      if (cvFormValues.photoUrl) {
        filledFields += 1;
      }

      if (cvFormValues.fullName) {
        filledFields += 1;
      }

      if (cvFormValues.email) {
        filledFields += 1;
      }

      if (cvFormValues.phone && cvFormValues.phone.length > 5) {
        filledFields += 1;
      }

      if (typeof validateBirthDate(cvFormValues.birthDay) === 'undefined') {
        filledFields += 1;
      }

      const filledContact = cvFormValues.contacts ? cvFormValues.contacts.filter((contact) => !!contact.value) : [];
      if (filledContact.length > 0) {
        filledFields += 1;
      }

      break;
    }
    case 'common': {
      allFields = 7;

      if (cvFormValues.profession) {
        filledFields += 1;
      }

      if (cvFormValues.salary.public) {
        filledFields += 1;
      }

      if (cvFormValues.salary.min) {
        filledFields += 1;
      }

      if (cvFormValues.location) {
        filledFields += 1;
      }

      if (cvFormValues.workType && cvFormValues.workType.filter((item) => !!item).length > 0) {
        filledFields += 1;
      }

      break;
    }
    case 'tech': {
      allFields = 8;
      filledFields += cvFormValues.specializations.filter((item) => item.isPrimary).length;
      filledFields += cvFormValues.skills.lang.filter((item) => item.isPrimary).length;
      filledFields += cvFormValues.skills.tech.filter((item) => item.isPrimary).length;
      filledFields += cvFormValues.skills.db.filter((item) => item.isPrimary).length;
      break;
    }
    case 'community': {
      allFields = 4;
      let profilesFields = 0;
      if (cvFormValues.linkGitHub) {
        profilesFields += 1;
      }
      if (cvFormValues.linkGitLab) {
        profilesFields += 1;
      }
      if (cvFormValues.linkBitBucket) {
        profilesFields += 1;
      }
      if (cvFormValues.linkHabr) {
        profilesFields += 1;
      }
      if (cvFormValues.linkStackOverflow) {
        profilesFields += 1;
      }
      if (cvFormValues.linkToster) {
        profilesFields += 1;
      }
      if (profilesFields > 3) {
        profilesFields = 3;
      }
      filledFields += profilesFields;

      const filledCodeSamples = cvFormValues.codeSamples ? cvFormValues.codeSamples.filter((item) => !!item.link) : [];
      if (filledCodeSamples.length > 0) {
        filledFields += 1;
      }

      break;
    }
    case 'preferences': {
      allFields = 7;

      if (cvFormValues.workAreas && cvFormValues.workAreas.length > 0) {
        filledFields += 1;
      }
      if (cvFormValues.workFeatures) {
        const selectedWorkFeatures = cvFormValues.workFeatures.filter((item) => !!item).length;
        filledFields += selectedWorkFeatures > 5 ? 5 : selectedWorkFeatures;
      }
      if (cvFormValues.expectations) {
        filledFields += 1;
      }

      break;
    }
    case 'experience': {
      allFields = 7;

      const experienceFillRates: number[] = cvFormValues.experience
        ? cvFormValues.experience.map((experienceItem) => {
            let experienceFillRate = 0;

            if (experienceItem.position) {
              experienceFillRate += 1;
            }
            if (experienceItem.companyName) {
              experienceFillRate += 1;
            }
            if (experienceItem.startMonth) {
              experienceFillRate += 1;
            }
            if (experienceItem.startYear) {
              experienceFillRate += 1;
            }
            if (experienceItem.isContinuous) {
              experienceFillRate += 2;
            } else {
              if (experienceItem.finishMonth) {
                experienceFillRate += 1;
              }
              if (experienceItem.finishYear) {
                experienceFillRate += 1;
              }
            }
            if (experienceItem.description) {
              experienceFillRate += 1;
            }

            return experienceFillRate;
          })
        : [];

      if (experienceFillRates.length > 0) {
        filledFields = Math.max(...experienceFillRates);
      }

      break;
    }
    case 'education': {
      allFields = 6;

      const educationFillRates: number[] = cvFormValues.education
        ? cvFormValues.education.map((educationItem) => {
            let educationFillRate = 0;

            if (educationItem.university) {
              educationFillRate += 1;
            }
            if (educationItem.faculty) {
              educationFillRate += 1;
            }
            if (educationItem.startMonth) {
              educationFillRate += 1;
            }
            if (educationItem.startYear) {
              educationFillRate += 1;
            }
            if (educationItem.isContinuous) {
              educationFillRate += 2;
            } else {
              if (educationItem.finishMonth) {
                educationFillRate += 1;
              }
              if (educationItem.finishYear) {
                educationFillRate += 1;
              }
            }

            return educationFillRate;
          })
        : [];

      if (educationFillRates.length > 0) {
        filledFields = Math.max(...educationFillRates);
      }
      break;
    }
  }

  if (allFields > 0) {
    if (filledFields > allFields) {
      return 100;
    }
    return (filledFields / allFields) * 100;
  }
  return 0;
};
