import React, { Ref } from 'react';
import { Field } from 'react-final-form';
import FormError from 'components/Form/FormError/FormError';
import Input, { InputProps } from 'components/v2/Input/Input';
import { BaseFinalFormField } from '..';

// Переопределяем тип ref из-за forwardRef
interface RefProps {
  ref?: Ref<HTMLInputElement>;
}

type Props<T> = BaseFinalFormField<T> & InputProps & RefProps;

export function InputFormField<T = string>(componentProps: Props<T>) {
  const { name, validate, format, formatOnBlur, parse, subscription, ...rest } = componentProps;
  return (
    <Field<T>
      name={name}
      validate={validate}
      format={format}
      formatOnBlur={formatOnBlur}
      parse={parse}
      subscription={subscription}
    >
      {(props) => {
        const { input, meta } = props;

        const error = meta.touched ? meta.error : '';
        const submitError = meta.touched && !meta.dirtySinceLastSubmit && !meta.submitting ? meta.submitError : '';

        const { value } = input;
        const preparedValue = typeof value === "number" || typeof value === "string" ? value : '';
        
        return (
          <>
            <Input {...input} {...rest} isInvalid={error || submitError} value={preparedValue} />
            <FormError name={name} />
          </>
        );
      }}
    </Field>
  );
};