import React from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import './StackTag.css';
import Tooltip from 'components/Tooltip/Tooltip';
import CancelCircle from 'components/Icons/CancelCircle/CancelCircle';
import CheckCircleIcon from 'components/Icons/CheckCircleIcon';

interface Props {
  label: string;
  experienceFromMonth?: number | null;
  experienceToMonth?: number | null;
  isInactive?: boolean;
  isPrimary?: boolean;
  isMatched?: boolean;
  autoWidth?: boolean;
  isVacancy?: boolean;
  hasNext?: boolean;
  group?: boolean;
}

/**
 * Тег для скиллсета кандидата - Язык\технология\фреймворк
 *
 * @param label - строка, название язык\технологии, сро
 * @param experience - строка, опыт в виде '5-8'
 * @param isInactive - boolean, неактивный тег (не ищет работу)
 * @param isPrimary - boolean, выделение языка (полоса с левой стороны тега) - удалено, не использовалось 06.03.2020
 * @param isMatched - boolean, совпал ли при матчинге кандидата на вакансию
 * @param isVacancy - boolean, если true скрывает сообщение ищу работу с использованием...
 */
const SkillTag: React.FC<Props> = ({
  label,
  experienceFromMonth,
  experienceToMonth,
  isInactive = false,
  isMatched = false,
  autoWidth = false,
  isVacancy = false,
  group,
}) => {
  const intl = useIntl();
  const skillTagClassName = classNames({
    'geecko-candidate-stacktag': true,
    'geecko-candidate-stacktag--inactive': isInactive,
    'geecko-candidate-stacktag--match': isMatched,
    'geecko-candidate-stacktag--autowidth': autoWidth,
    'geecko-candidate-stacktag--group': group,
  });

  const noExperience =
    (experienceFromMonth === null && experienceToMonth === null) ||
    (experienceFromMonth === 0 && experienceToMonth === 0);

  const showExperience = (named?: boolean) => (
    <>
      {named ? (
        <>
          {experienceFromMonth && !experienceToMonth
            ? intl.formatMessage(
                { id: 'app.plural.years' },
                { count: Math.max(Math.floor(experienceFromMonth / 12), 1) },
              )
            : null}
          {experienceFromMonth && experienceToMonth ? `${Math.max(Math.floor(experienceFromMonth / 12), 1)}-` : ''}
          {experienceToMonth
            ? intl.formatMessage({ id: 'app.plural.years' }, { count: Math.max(Math.floor(experienceToMonth / 12), 1) })
            : null}
          {noExperience ? intl.formatMessage({ id: 'app.no' }) : null}
        </>
      ) : (
        <>
          {experienceFromMonth ? Math.max(Math.floor(experienceFromMonth / 12), 1) : ''}
          {experienceFromMonth && experienceToMonth ? <>&ndash;</> : null}
          {!experienceFromMonth && !experienceToMonth ? <>&ndash;</> : null}
          {!experienceFromMonth && experienceToMonth
            ? '< 1'
            : ''}
          {experienceFromMonth && !experienceToMonth ? '+' : ''}
          {experienceToMonth && Math.max(Math.ceil(experienceToMonth / 12), 1) > 1
            ? Math.max(Math.ceil(experienceToMonth / 12), 1)
            : ''}
        </>
      )}
    </>
  );

  const showVacancyExperience = (named?: boolean) => (
    <>
      {named ? (
        <>
          {experienceFromMonth
            ? intl.formatMessage(
                { id: 'app.candidate.skilltag.experience.from.years' },
                { count: Math.max(Math.floor(experienceFromMonth / 12), 1) },
              )
            : intl.formatMessage({ id: 'app.anylevel' })}
        </>
      ) : (
        <>
          {experienceFromMonth ? (
            `${intl.formatMessage({ id: 'app.candidate.skilltag.experience.from' })} ${Math.max(
              Math.floor(experienceFromMonth / 12),
              1,
            )}`
          ) : (
            <>&#10033;</>
          )}
        </>
      )}
    </>
  );

  const tagBody = (
    <div className={skillTagClassName}>
      <div className="geecko-candidate-stacktag__label">{label}</div>
      {(!isVacancy && (experienceFromMonth || experienceToMonth || noExperience)) || isVacancy ? (
        <div
          className={
            !experienceFromMonth && experienceToMonth
              ? 'geecko-candidate-stacktag__experience--verbose'
              : 'geecko-candidate-stacktag__experience'
          }
        >
          {isVacancy ? showVacancyExperience() : showExperience()}
        </div>
      ) : null}
    </div>
  );

  return (
    <>
      {(!isVacancy && (experienceFromMonth || experienceToMonth || noExperience)) || isVacancy ? (
        <>
          <Tooltip
            overlay={
              <div className="geecko-candidate-stacktag__popup">
                <div className="geecko-candidate-stacktag__popup-title">
                  <div className="geecko-candidate-stacktag__popup-label">{label}</div>
                  {experienceFromMonth || experienceToMonth || noExperience || isVacancy ? (
                    <div className="geecko-candidate-stacktag__popup-experience">
                      {isVacancy ? showVacancyExperience(true) : showExperience(true)}{' '}
                      {intl.formatMessage({ id: 'app.candidate.skilltag.experience' })}
                    </div>
                  ) : null}
                </div>
                {!isVacancy ? (
                  <div
                    className={`geecko-candidate-stacktag__popup-text ${
                      isInactive ? 'geecko-color-danger' : 'geecko-color-primary'
                    }`}
                  >
                    <span>{isInactive ? <CancelCircle /> : <CheckCircleIcon />}</span>
                    <div>
                      {intl.formatMessage({ id: `app.candidate.skilltag.${isInactive ? 'not' : ''}lookingfor` })}
                    </div>
                  </div>
                ) : null}
              </div>
            }
            placement="bottom"
          >
            {tagBody}
          </Tooltip>
        </>
      ) : (
        tagBody
      )}
    </>
  );
};

export default SkillTag;
