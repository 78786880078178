import React, { FC, useMemo } from 'react';
import './Progress.css';

interface Props {
  value: number;
  max?: number;
  height?: number;
  color?: 'primary' | 'success' | 'error' | 'secondary';
  animated?: boolean;
}

const Progress: FC<Props> = ({
  value, max = 100, height = 9, color = 'primary',
}) => {
  const percentValue = useMemo(() => {
    const percent = value / max * 100;

    return percent || 0;
  }, [value, max]);
  return (
    <div className="geecko-progress" style={{ height }}>
      <div
        className={`geecko-progress__value geecko-progress__value--color-${color}`}
        style={{ width: `${percentValue}%`, height }}
      />
    </div>
  );
};

export default Progress;
