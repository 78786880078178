/* eslint-disable no-param-reassign */
import { ActionType, getType } from 'typesafe-actions';
import produce, { Draft, castDraft } from 'immer';
import { ResumeState, initialResumeState } from './state';
import * as ResumeAction from './actionCreators';

type ResumeActionType = ActionType<typeof ResumeAction>;

export const resumeReducer = produce((state: Draft<ResumeState>, action: ResumeActionType) => {
  switch (action.type) {
    /**
     * Получение данных резюме
     */
    case getType(ResumeAction.fetchResumeAction.request): {
      state.resume.loading = true;
      state.resume.loaded = false;
      state.resume.error = undefined;
      state.resumeId = action.payload.resumeId;
      state.vacancyId = action.payload.vacancyId;
      state.token = action.payload.token;
      state.viewAsCompany = action.payload.viewAsCompany;
      break;
    }
    case getType(ResumeAction.fetchResumeAction.success): {
      state.resume.item = castDraft(action.payload);
      state.resume.loading = false;
      state.resume.loaded = true;
      break;
    }
    case getType(ResumeAction.fetchResumeAction.failure): {
      state.resume.loading = false;
      state.resume.error = action.payload;
      break;
    }

    /**
     * Получение статуса матчинга
     */
    case getType(ResumeAction.fetchResumeStatusAction.request): {
      state.status.loading = true;
      state.status.loaded = false;
      state.status.error = undefined;
      break;
    }
    case getType(ResumeAction.fetchResumeStatusAction.success): {
      state.status.item = action.payload;
      state.status.loading = false;
      state.status.loaded = true;
      break;
    }
    case getType(ResumeAction.fetchResumeStatusAction.failure): {
      state.status.loading = false;
      state.status.error = action.payload;
      break;
    }

    default: {
      //
    }
  }
}, initialResumeState);
