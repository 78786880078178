import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import axios, { AxiosRequestConfig, Canceler } from 'axios';
import { isEmpty } from 'ramda';
import { ApiContext } from './modules/api/ApiProvider';

export interface ApiError {
  text?: string;
  statusCode?: string | number;
}

interface UseApiState {
  data?: any;
  error?: ApiError;
  loading: boolean;
  willReload: boolean;
}

export const useApiInstance = () => useContext(ApiContext);

export const useApi = (
  path: string,
  method: 'post' | 'get' = 'get',
  // for post requests
  params?: object,
  // если false, запрос будет отправлен только после того как значение станет true
  start = true,
) => {
  const [state, setState] = useState<UseApiState>({
    data: undefined,
    error: undefined,
    loading: true,
    willReload: false,
  });

  const api = useApiInstance();

  const reload = () => setState((prevState) => ({ ...prevState, willReload: !prevState.willReload }));

  useEffect(() => {
    const apiGet = async (requestOptions?: AxiosRequestConfig) => {
      let result;
      try {
        result = method === 'post' ? await api.post(path, params, requestOptions) : await api.get(path, requestOptions);
      } catch (error) {
        result = error;
      }
      return result;
    };
    let cancelRequest: Canceler;
    const fetchData = async () => {
      setState((prev) => ({ ...prev, loading: true }));
      const gotData = await apiGet({
        cancelToken: new CancelToken((c) => {
          cancelRequest = c;
        }),
      });
      if (!isCancel(gotData)) {
        setState((prev) => ({ ...prev, loading: false }));

        if (gotData.response && gotData.response.status) {
          if (gotData.response.status > 299) {
            const errorMessage = gotData.message || 'An error occurred during the request processing.';
            setState((prev) => ({
              ...prev,
              error: { text: errorMessage, statusCode: gotData.response.status },
            }));

            return;
          }
        }

        if (!isEmpty(gotData.data)) {
          setState((prev) => ({ ...prev, data: gotData.data }));
        } else if (!gotData.data && gotData.response && gotData.response.data && gotData.response.data.message) {
          setState((prev) => ({ ...prev, error: { text: gotData.response.data.message } }));
        } else {
          setState((prev) => ({
            ...prev,
            error: { text: gotData.message, statusCode: gotData.statusCode },
          }));
        }
      }
    };
    if (start) fetchData();
    return () => {
      if (cancelRequest) cancelRequest();
    };
  }, [api, method, params, path, start, state.willReload]);

  return [state.data, state.error, state.loading, reload];
};

interface UseApiOptions<T> {
  enabled?: boolean;
  params?: object;
  initialData?: T;
  autoStart?: boolean;
  dataProcess?: (data: any) => T | undefined;
}

export const useApiNew = <T>(
  path: string,
  method = 'get',
  options?: UseApiOptions<T>,
): [T | undefined, ApiError | undefined, boolean, () => void] => {
  const isEnabled = !options || typeof options.enabled === 'undefined' ? true : options.enabled;
  const [data, setData] = useState<T | undefined>(options && options.initialData ? options.initialData : undefined);
  const [error, setError] = useState<ApiError | undefined>();
  const [loading, setLoading] = useState(false);
  const initRender = useRef(true);
  // const [cancelRequest, setCancelRequest] = useState<Canceler | undefined>();

  const api = useApiInstance();

  const makeRequest = useCallback(async () => {
    if (!isEnabled) {
      return;
    }
    if (initRender.current) {
      initRender.current = false;
      return;
    }
    // if (cancelRequest) cancelRequest();

    const params = options && options.params ? options.params : undefined;

    const apiGet = async (requestOptions?: AxiosRequestConfig) => {
      let result;
      try {
        result = method === 'post' ? await api.post(path, params, requestOptions) : await api.get(path, requestOptions);
      } catch (apiError) {
        result = apiError;
      }
      return result;
    };

    setError(undefined);
    setLoading(true);
    const gotData = await apiGet({
      // cancelToken: new CancelToken(((c) => {
      //   setCancelRequest(c);
      // })),
    });
    if (!isCancel(gotData)) {
      setLoading(false);

      if (gotData.response && gotData.response.status) {
        if (gotData.response.status > 299) {
          const errorMessage = gotData.message || 'An error occurred during the request processing.';
          setError({ text: errorMessage, statusCode: gotData.response.status });

          return;
        }
      }

      if (!isEmpty(gotData.data)) {
        if (options && options.dataProcess) {
          setData(options.dataProcess(gotData.data));
        } else {
          setData(gotData.data);
        }
      } else if (!gotData.data && gotData.response && gotData.response.data && gotData.response.data.message) {
        setError({ text: gotData.response.data.message });
      } else {
        setError({ text: gotData.message, statusCode: gotData.statusCode });
      }
    }
  }, [initRender, options, method, api, path, options]);

  useEffect(() => {
    if (options && options.autoStart) {
      makeRequest();
    }
  }, []);

  useEffect(() => {
    makeRequest();
  }, [path]);

  return [data, error, loading, makeRequest];
};

export const convertApiResponseToValidationErrors = (error: any): { [field: string]: string } | undefined => {
  if (!error || !error.response || !error.response.status || error.response.status !== 422) {
    return undefined;
  }
  const result: { [field: string]: string } = {};

  if (error.response.data && error.response.data.errors && typeof error.response.data.errors === 'object') {
    Object.keys(error.response.data.errors).forEach((fieldName: string) => {
      const fieldErrors = error.response.data.errors[fieldName];

      if (fieldErrors && Array.isArray(fieldErrors) && fieldErrors.length > 0) {
        const [fieldError] = fieldErrors;
        result[fieldName] = fieldError;
      }
    });
  }

  return result;
};

export const { CancelToken, isCancel } = axios;
