export enum SkillsSessionStatus {
  CREATED = 0, //создана
  RUNNING = 1, //выполняется
  FINISHED = 2, //завершено
  PAUSED = 3, //на паузе
  CANCELED = 4, //отменено
}
export interface SkillsSession {
  readonly uid: string;
  readonly url: string;
  readonly badge?: {
    readonly id: number;
    readonly isVisible: boolean;
  };
  readonly template: string;
  readonly templateId: number;
  readonly statusId: SkillsSessionStatus;
  readonly scorePercent?: number;
  readonly startedAt?: string;
  readonly createdAt?: string;
  readonly finishedAt?: string;
}

export interface SkillsStatus {
  canCreate: boolean;
  lastSession?: SkillsSession;
  canCreateSince?: string;
  daysLeft?: number;
}

export const decodeSkillsSession = (json: any): SkillsSession => ({
  uid: json.uid,
  url: json.url,
  template: json.template,
  templateId: json.template_id,
  statusId: json.status_id,
  badge: json.badge ? {
    id: json.badge.id,
    isVisible: json.badge.is_visible,
  } : undefined,
  scorePercent: json.score_percent,
  startedAt: json.started_at,
  createdAt: json.created_at,
  finishedAt: json.finished_at,
});

export const decodeSkillsStatus = (json: any): SkillsStatus => ({
  canCreate: json.can_create,
  lastSession: json.last_session ? decodeSkillsSession(json.last_session) : undefined,
  canCreateSince: json.can_create_since ? json.can_create_since : undefined,
  daysLeft: typeof json.days_left === 'number' ? json.days_left : undefined,
})
