import { Immutable } from 'immer';

export interface SkillSetInterface {
  lang: Skill[];
  tech: Skill[];
  db: Skill[];
}

export interface Skill {
  id: number;
  name: string;
  slug: string;
  type: 'lang' | 'tech' | 'tech.db';
  image: string;
  level: number;
  experienceMonths?: number;
  experienceFrom?: number;
  experienceTo?: number;
  isPrimary: boolean;
  popularity: number;
}

const decodeSkill = (json: any): Skill => ({
  id: json.id,
  name: json.name,
  slug: json.slug,
  type: json.type,
  image: json.image,
  level: json.level,
  experienceMonths: json.experience_amount,
  experienceFrom: json.experience_from,
  experienceTo: json.experience_to,
  isPrimary: !!json.is_primary,
  popularity: !Number.isNaN(parseFloat(json.popularity)) ? parseFloat(json.popularity) : 0,
});

export const decodeSkillSet = (json: any): SkillSetInterface => ({
  lang: json.lang && Array.isArray(json.lang) ? json.lang.map(decodeSkill) : [],
  tech: json.tech && Array.isArray(json.tech) ? json.tech.map(decodeSkill) : [],
  db: json.db && Array.isArray(json.db) ? json.db.map(decodeSkill) : [],
});

export const isSkillSetHasData = (skillSet: Immutable<SkillSetInterface>): boolean => {
  const { lang, db, tech } = skillSet;
  return lang.length > 0 || db.length > 0 || tech.length > 0;
};
