import React, { FC, ReactNode } from 'react';
import TooltipTrigger from 'react-popper-tooltip';
import './Tooltip.css';

type Placement = 'top' | 'bottom' | 'right' | 'left';
type Trigger = 'focus' | 'click' | 'hover';

interface Props {
  placement?: Placement;
  trigger?: Trigger;
  overlay: ReactNode;
  active?: boolean;
  // Если true, то обычные переносы строк (\n), будут учтены
  preWrap?: boolean;
}

const Tooltip: FC<Props> = ({ overlay, placement = 'top', trigger = 'hover', active = true, preWrap, children }) => {
  // Если содержимое тултипа пустое, то просто возвращаем ребенка
  if (!overlay || !active) {
    return <>{children}</>;
  }

  return (
    <TooltipTrigger
      placement={placement}
      trigger={trigger}
      tooltip={({
        arrowRef,
        tooltipRef,
        getArrowProps,
        getTooltipProps,
      }) => (
        <div
          {...getTooltipProps({
            ref: tooltipRef,
            className: 'tooltip-container'
          })}
        >
          <div
            {...getArrowProps({
              ref: arrowRef,
              className: 'tooltip-arrow',
              'data-placement': placement
            })}
          />
          <div className={preWrap ? 'tooltip-content--pre-wrap' : undefined}>
            {overlay}
          </div>
        </div>
      )}
    >
      {({getTriggerProps, triggerRef}) => (
        <span
          {...getTriggerProps({
            ref: triggerRef,
            className: 'trigger'
          })}
        >
        {children}
      </span>
      )}
    </TooltipTrigger>
  );
};

export default Tooltip;
