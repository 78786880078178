import { Immutable } from 'immer';
import { NetworkEntity } from 'store';
import { ResumeState as ResumeDataState } from 'models/Resume';
import { VacancyStateStatus } from 'models/VacancyState';

interface ResumeStateInterface {
  resume: NetworkEntity<ResumeDataState>;
  status: NetworkEntity<VacancyStateStatus>;
  resumeId?: string;
  vacancyId?: number;
  token?: string;
  viewAsCompany: boolean;
}

export type ResumeState = Immutable<ResumeStateInterface>;

export const initialResumeState: ResumeState = {
  resume: {},
  status: {},
  viewAsCompany: false,
};
