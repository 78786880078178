import React, { FC, Ref } from 'react';
import { Field } from 'react-final-form';
import FormError from 'components/Form/FormError/FormError';
import Textarea, { TextareaProps } from 'components/v2/Input/Textarea';
import { BaseFinalFormField } from '..';

// Переопределяем тип ref из-за forwardRef
interface RefProps {
  ref?: Ref<HTMLTextAreaElement>;
}

type Props = BaseFinalFormField<string> & TextareaProps & RefProps;

export const TextareaFormField: FC<Props> = ({ name, validate, format, formatOnBlur, parse, subscription, ...rest }) => {
  return (
    <Field<string>
      name={name}
      validate={validate}
      format={format}
      formatOnBlur={formatOnBlur}
      parse={parse}
      subscription={subscription}
    >
      {(props) => {
        const { input, meta } = props;

        const error = meta.touched ? meta.error : '';
        const submitError = meta.touched && !meta.dirtySinceLastSubmit && !meta.submitting ? meta.submitError : '';

        return (
          <>
            <Textarea {...input} {...rest} isInvalid={error || submitError} />
            <FormError name={name} />
          </>
        );
      }}
    </Field>
  );
};