import { Manager, decodeManager } from 'models/Manager';
import { AuthCandidate, decodeAuthCandidate } from 'models/Candidate';
import { User, decodeUser } from 'models/User';

export interface ResumeStatus {
  id: 'review' | string;
  name: string;
  createdAt: string;
  sla?: number;
}

export interface UserProfile {
  user: User;
  manager?: Manager;
  resumeStatus?: ResumeStatus;
  candidate: AuthCandidate;
}

export const decodeResumeStatus = (json: any): ResumeStatus => {
  return {
    id: json.id,
    name: json.name,
    createdAt: json.created_at,
    sla: json.sla ? json.sla : undefined,
  };
};

export const decodeUserProfile = (json: any): UserProfile => {
  return {
    user: decodeUser(json.user),
    manager: json.manager ? decodeManager(json.manager) : undefined,
    resumeStatus: json.resume_status ? decodeResumeStatus(json.resume_status) : undefined,
    candidate: decodeAuthCandidate(json.candidate),
  };
};
