import { FieldRenderProps } from 'react-final-form';
import React from 'react';
import Select, { AsyncSelectProps } from '../AsyncSelect/AsyncSelect';

export const AsyncSelectFinalForm = (
  props: FieldRenderProps<any | undefined> & AsyncSelectProps<{}>,
) => {
  const {
    input, meta, ...rest
  } = props;
  const error = meta.touched ? meta.error : '';
  const submitError = meta.touched && !meta.dirtySinceLastSubmit && !meta.submitting ? meta.submitError : '';
  return (
    <>
      <Select
        {...input}
        {...rest}
        isInvalid={!!(error || submitError)}
      />
      {(error || submitError) && (
        <div className="geecko-field-error">{error || submitError}</div>
      )}
    </>
  );
};
