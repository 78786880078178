import { createSelector } from 'redux-views';
import { VacancyCandidateActiveStatus } from 'models/VacancyState';
import { castDraft } from 'immer';
import { SearchSession } from 'models/Candidate/SearchSession';
import { Report } from 'models/Candidate/Report';
import { ResumeImprovement } from 'models/Candidate/ResumeImprovement';
import {
  AppState,
  getNetworkEntitySelectorState,
  NetworkEntitySelector,
  NetworkEntitiesCollecionSelector,
  getNetworkEntitiesCollectionSelectorState,
} from '../../index';
import { CANDIDATE_MODULE_NAME } from './index';

export const candidateStateSelector = (state: AppState) => state[CANDIDATE_MODULE_NAME] || undefined;

const selectCandidateVacanciesState = createSelector(
  [candidateStateSelector],
  (candidateState) => candidateState.vacancies,
);

export const selectCandidateVacanciesLoading = createSelector(
  [selectCandidateVacanciesState],
  (vacanciesState) => !!vacanciesState.vacancies.loading,
);

export const candidateVacanciesSelector = createSelector([selectCandidateVacanciesState], (vacanciesState) =>
  castDraft((vacanciesState.vacancies && vacanciesState.vacancies.items) || []),
);

export const selectCandidateVacanciesCounters = createSelector(
  [selectCandidateVacanciesState],
  (vacanciesState) => vacanciesState.counters,
);

export const selectVacanciesActiveStatus = createSelector(
  [selectCandidateVacanciesState],
  (vacanciesState) => vacanciesState.status || VacancyCandidateActiveStatus.Active,
);

export const selectCandidateSkillsSessions = createSelector(
  [candidateStateSelector],
  (candidateState) => (candidateState && candidateState.skillsSessions) || [],
);

/**
 * Селекторы статуса тестовой сессии Skills
 */
export const selectCandidateSkillsStatusState = createSelector(
  [candidateStateSelector],
  (candidateState) => candidateState && candidateState.skillsStatus,
);

export const selectCandidateSkillsStatus = createSelector(
  [selectCandidateSkillsStatusState],
  (skillsStatusState) => skillsStatusState && skillsStatusState.item,
);

export const selectCandidateSkillsStatusLoading = createSelector(
  [selectCandidateSkillsStatusState],
  (skillsStatusState) => skillsStatusState && skillsStatusState.loading,
);

export const selectCandidatePreviewId = createSelector(
  [selectCandidateVacanciesState],
  (vacanciesState) => vacanciesState && vacanciesState.previewId,
);

export const selectCandidateVacancyUpdateLoading = createSelector(
  [selectCandidateVacanciesState],
  (vacanciesState) => !!(vacanciesState && vacanciesState.loading),
);

export const selectCandidatePreviewVacancyIndex = createSelector(
  [selectCandidatePreviewId, candidateVacanciesSelector],
  (previewId, vacancies) => {
    return vacancies && previewId ? vacancies.findIndex((item) => item.vacancy.id === previewId) : -1;
  },
);

export const selectCandidatePreviewCurrentVacancy = createSelector(
  [selectCandidatePreviewVacancyIndex, candidateVacanciesSelector],
  (index, vacancies) => {
    return index >= 0 && vacancies && vacancies[index] ? vacancies[index] : undefined;
  },
);

export const selectCandidateRejectingVacancyId = createSelector(
  [selectCandidateVacanciesState],
  (candidateState) => candidateState && candidateState.rejectId,
);

export const selectCandidateRejectingVacancy = createSelector(
  [selectCandidateRejectingVacancyId, candidateVacanciesSelector],
  (rejectId, vacancies) => {
    return rejectId && vacancies && vacancies.find((item) => item.vacancy.id === rejectId);
  },
);

export const selectCandidateInterviewFeedbackVacancyId = createSelector(
  [selectCandidateVacanciesState],
  (candidateState) => candidateState && candidateState.interviewFeedbackId,
);

export const selectCandidateInterviewAcceptVacancyId = createSelector(
  [selectCandidateVacanciesState],
  (candidateState) => candidateState && candidateState.interviewAcceptVacancyId,
);

export const selectCandidateResponseVacancyId = createSelector(
  [selectCandidateVacanciesState],
  (candidateState) => candidateState && candidateState.responseVacancyId,
);

export const selectCandidateInterviewAcceptInterview = createSelector(
  [selectCandidateVacanciesState],
  (candidateState) => candidateState && candidateState.interview,
);

export const selectCandidateInterviewFeedbackVacancy = createSelector(
  [selectCandidateInterviewFeedbackVacancyId, candidateVacanciesSelector],
  (vacancyFeedbackId, vacancies) => {
    return vacancyFeedbackId && vacancies && vacancies.find((item) => item.vacancy.id === vacancyFeedbackId);
  },
);

/**
 * Возвращает true/false открыт ли в данный момент предпросмотр вакансий
 */
export const isCandidateVacanciesPreviewOpened = createSelector(
  [selectCandidateVacanciesState, candidateVacanciesSelector],
  (candidateState, vacancies) => {
    const previewId = candidateState && candidateState.previewId;
    if (previewId) {
      return !!vacancies.find((vacancy) => vacancy.vacancy.id === previewId);
    }
    return false;
  },
);

const selectCandidateSuperSearchState = createSelector(
  [candidateStateSelector],
  (candidateState) => candidateState.superSearch,
);

export const candidateSuperSearchUpdatingSelector = createSelector(
  [selectCandidateSuperSearchState],
  (superSearchState) => {
    return !!superSearchState.updating;
  },
);

export const candidateSuperSearchSessionSelector: NetworkEntitySelector<SearchSession> = createSelector(
  [selectCandidateSuperSearchState],
  (superSearchState) => {
    return getNetworkEntitySelectorState(superSearchState.session);
  },
);

export const candidateCvEditStateSelector = createSelector(
  [candidateStateSelector],
  (candidateState) => candidateState.editCv,
);

export const candidateCvEditFillRateSelector = createSelector(
  [candidateCvEditStateSelector],
  (editCvState) => editCvState.fillRate,
);

export const candidateCvEditDataSelector = createSelector([candidateCvEditStateSelector], (editCvState) =>
  getNetworkEntitySelectorState(editCvState.cv),
);

/**
 * Селекторы отчета разработчика
 */
const candidateReportStateSelector = createSelector(
  [candidateStateSelector],
  (candidateState) => candidateState.report,
);

export const candidateReportSelector: NetworkEntitySelector<Report> = createSelector(
  [candidateReportStateSelector],
  (reportState) => getNetworkEntitySelectorState(reportState),
);

/**
 * Селектор рекомендаций по улучшению резюме
 */
export const candidateCvImprovementsStateSelector: NetworkEntitiesCollecionSelector<ResumeImprovement> = createSelector(
  [candidateStateSelector],
  (candidateState) => getNetworkEntitiesCollectionSelectorState(candidateState.cvImprovements),
);
