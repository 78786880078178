/* eslint-disable no-param-reassign */
import produce, { Draft } from 'immer';
import { ActionType, getType } from 'typesafe-actions';
import { initialDictionaryState, DictionaryState } from './state';
import * as DictionaryAction from './actionCreators';

type DictionaryActionType = ActionType<typeof DictionaryAction>;

export const dictionaryReducer = produce((state: Draft<DictionaryState>, action: DictionaryActionType) => {
  switch (action.type) {
    /**
     * Получение причин отказа компаниям
     */
    case getType(DictionaryAction.fetchVacancyRejectReasonsAction.request): {
      state.vacancyRejectReasons.loading = true;
      state.vacancyRejectReasons.error = undefined;
      break;
    }

    case getType(DictionaryAction.fetchVacancyRejectReasonsAction.success): {
      state.vacancyRejectReasons.loading = false;
      state.vacancyRejectReasons.loaded = true;
      state.vacancyRejectReasons.items = action.payload;
      break;
    }

    case getType(DictionaryAction.fetchVacancyRejectReasonsAction.failure): {
      state.vacancyRejectReasons.loading = false;
      state.vacancyRejectReasons.error = action.payload;
      break;
    }

    /**
     * Получение причин отказа кандидатам
     */
    case getType(DictionaryAction.fetchCandidateRejectReasonsAction.request): {
      state.candidateRejectReasons.loading = true;
      state.candidateRejectReasons.error = undefined;
      break;
    }

    case getType(DictionaryAction.fetchCandidateRejectReasonsAction.success): {
      state.candidateRejectReasons.loading = false;
      state.candidateRejectReasons.loaded = true;
      state.candidateRejectReasons.items = action.payload;
      break;
    }

    case getType(DictionaryAction.fetchCandidateRejectReasonsAction.failure): {
      state.candidateRejectReasons.loading = false;
      state.candidateRejectReasons.error = action.payload;
      break;
    }

    /**
     * Тестовые шаблоны Skills
     */

    case getType(DictionaryAction.fetchSkillsTemplatesAction.request): {
      state.skillsTemplates.loading = true;
      state.skillsTemplates.error = undefined;
      break;
    }

    case getType(DictionaryAction.fetchSkillsTemplatesAction.success): {
      state.skillsTemplates.loading = false;
      state.skillsTemplates.loaded = true;
      state.skillsTemplates.items = action.payload;
      break;
    }

    case getType(DictionaryAction.fetchSkillsTemplatesAction.failure): {
      state.vacancyRejectReasons.loading = false;
      state.vacancyRejectReasons.error = action.payload;
      break;
    }

    /**
     * Иностранные языки
     */

    case getType(DictionaryAction.fetchLanguagesAction.request): {
      state.languages.loading = true;
      state.languages.error = undefined;
      break;
    }

    case getType(DictionaryAction.fetchLanguagesAction.success): {
      state.languages.loading = false;
      state.languages.loaded = true;
      state.languages.items = action.payload;
      break;
    }

    case getType(DictionaryAction.fetchLanguagesAction.failure): {
      state.languages.loading = false;
      state.languages.error = action.payload;
      break;
    }

    /**
     * Специализации
     */

    case getType(DictionaryAction.fetchSpecializationsAction.request): {
      state.specializations.loading = true;
      state.specializations.error = undefined;
      break;
    }

    case getType(DictionaryAction.fetchSpecializationsAction.success): {
      state.specializations.loading = false;
      state.specializations.loaded = true;
      state.specializations.items = action.payload;
      break;
    }

    case getType(DictionaryAction.fetchSpecializationsAction.failure): {
      state.specializations.loading = false;
      state.specializations.error = action.payload;
      break;
    }

    /**
     * Важно в работе
     */

    case getType(DictionaryAction.fetchWorkFeaturesAction.request): {
      state.workFeatures.loading = true;
      state.workFeatures.loaded = false;
      state.workFeatures.error = undefined;
      break;
    }

    case getType(DictionaryAction.fetchWorkFeaturesAction.success): {
      state.workFeatures.loading = false;
      state.workFeatures.loaded = true;
      state.workFeatures.items = action.payload;
      break;
    }

    case getType(DictionaryAction.fetchWorkFeaturesAction.failure): {
      state.workFeatures.loading = false;
      state.workFeatures.error = action.payload;
      break;
    }

    /**
     * Предметные области
     */

    case getType(DictionaryAction.fetchCompanyScopesAction.request): {
      state.companyScopes.loading = true;
      state.companyScopes.loaded = false;
      state.companyScopes.error = undefined;
      break;
    }

    case getType(DictionaryAction.fetchCompanyScopesAction.success): {
      state.companyScopes.loading = false;
      state.companyScopes.loaded = true;
      state.companyScopes.items = action.payload;
      break;
    }

    case getType(DictionaryAction.fetchCompanyScopesAction.failure): {
      state.companyScopes.loading = false;
      state.companyScopes.error = action.payload;
      break;
    }

    /**
     * Пользователи компании
     */

    case getType(DictionaryAction.fetchCompanyUsersAction.request): {
      state.companyUsers.loading = true;
      state.companyUsers.loaded = false;
      state.companyUsers.error = undefined;
      break;
    }

    case getType(DictionaryAction.fetchCompanyUsersAction.success): {
      state.companyUsers.loading = false;
      state.companyUsers.loaded = true;
      state.companyUsers.items = action.payload;
      break;
    }

    case getType(DictionaryAction.fetchCompanyUsersAction.failure): {
      state.companyUsers.loading = false;
      state.companyUsers.error = action.payload;
      break;
    }

    /**
     * Офисы компании
     */

    case getType(DictionaryAction.fetchCompanyOfficesAction.request): {
      state.companyOffices.loading = true;
      state.companyOffices.loaded = false;
      state.companyOffices.error = undefined;
      break;
    }

    case getType(DictionaryAction.fetchCompanyOfficesAction.success): {
      state.companyOffices.loading = false;
      state.companyOffices.loaded = true;
      state.companyOffices.items = action.payload;
      break;
    }

    case getType(DictionaryAction.fetchCompanyOfficesAction.failure): {
      state.companyOffices.loading = false;
      state.companyOffices.error = action.payload;
      break;
    }

    default:
      break;
  }
}, initialDictionaryState);
