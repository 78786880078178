import React from 'react';
import { TextareaFormField } from 'components/v2/FinalForm/Fields/TextareaFormField';

const CvAboutFormItem: React.FC = () => {
  return (
    <TextareaFormField
      name="about"
      className="qa-about"
      placeholder="Например: увлеченный Full-stack разработчик, имею опыт в e-commerce и финтех проектах. Последние два года работал в продуктовой команде. Предпочитаю сперва разобраться в вопросе, а уже затем реализую решение."
    />
  );
};

export default CvAboutFormItem;
