import React, { FC, ReactNode } from 'react';
import { useIntl } from 'react-intl';

import TargetIcon from '../../components/Icons/TargetIcon';
import Spinner from '../../components/Spinner/Spinner';
import Tooltip from '../../components/Tooltip/Tooltip';

import './CVFormMatchingGuide.css';

interface Props {
  marginTop?: number;
  variant?: 'vacancy' | 'candidate';
  enabled?: boolean;
  isLoading?: boolean;
}

const CVFormMatchingGuide: FC<Props> = ({
  enabled = true, isLoading, marginTop = 0, children, variant = 'candidate',
}) => {
  if (!enabled) {
    return <>{children}</>;
  }

  const intl = useIntl();

  let tooltipOverlay: ReactNode;
  if (!isLoading) {
    tooltipOverlay =
      variant === 'candidate'
        ? intl.formatMessage({ id: 'app.matchingparam.candidate' })
        : intl.formatMessage({ id: 'app.matchingparam.company' });
  }

  return (
    <div className="geecko-matching-fieldset">
      <Tooltip
        placement="right"
        overlay={tooltipOverlay}
      >
        <div className="geecko-matching-icon" style={{ top: marginTop }}>
          {isLoading && <Spinner size={16} color="#000" />}
          {!isLoading && <TargetIcon size={16} />}
        </div>
      </Tooltip>
      <div className="geecko-matching-guide" style={{ top: marginTop + 26 }} />
      {children}
    </div>
  );
};

export default CVFormMatchingGuide;
