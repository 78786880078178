import { put, takeLatest, getContext, call, select } from 'redux-saga/effects';
import { ServerApi } from 'modules/api/server-api';
import { fetchResume } from 'api/resume/fetch-resume';
import { ResumeState } from 'models/Resume';
import { VacancyStateStatus } from 'models/VacancyState';
import { fetchResumeStatus } from 'api/resume/fetch-resume-status';
import { fetchResumeAction, fetchResumeStatusAction, reloadResumeAction } from './actionCreators';
import { resumeStateSelector } from './selectors';

/**
 * Получение резюме
 */
function* getResumeSaga(action: ReturnType<typeof fetchResumeAction.request>) {
  try {
    const api: ServerApi = yield getContext('api');
    const { resumeId, vacancyId, token, viewAsCompany } = action.payload;
    const resumeState: ResumeState = yield call(fetchResume(api), resumeId, vacancyId, token, viewAsCompany);
    yield put(fetchResumeAction.success(resumeState));
  } catch (e) {
    yield put(fetchResumeAction.failure(e));
  }
}

/**
 * Получение статуса матчинга
 */
function* getStatusSaga(action: ReturnType<typeof fetchResumeStatusAction.request>) {
  try {
    const api: ServerApi = yield getContext('api');
    const { resumeId, vacancyId } = action.payload;
    const status: VacancyStateStatus = yield call(fetchResumeStatus(api), resumeId, vacancyId);
    yield put(fetchResumeStatusAction.success(status));
  } catch (e) {
    yield put(fetchResumeStatusAction.failure(e));
  }
}

/**
 * Обновление резюме
 */
function* reloadResumeSaga(action: ReturnType<typeof reloadResumeAction>) {
  try {
    const { resumeId, vacancyId, token, viewAsCompany } = yield select(resumeStateSelector);
    const params = { resumeId, vacancyId, token, viewAsCompany };
    yield put(fetchResumeAction.request({ ...params, ...action.payload }));
  } catch (e) {
    yield put(fetchResumeAction.failure(e));
  }
}

export function* resumeSaga() {
  yield takeLatest(fetchResumeAction.request, getResumeSaga);
  yield takeLatest(fetchResumeStatusAction.request, getStatusSaga);
  yield takeLatest(reloadResumeAction, reloadResumeSaga);
}
