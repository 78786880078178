import React from 'react';
import classNames from 'classnames';

import './Check.css';

interface Props {
  isChecked?: boolean;
  isInvalid?: boolean;
}

const Check: React.FC<Props> = (props: Props) => {
  const {
    isChecked, isInvalid,
  } = props;

  const checkClassName = classNames({
    'geecko-check': true,
    'geecko-check--checked': isChecked,
    'geecko-check--invalid': isInvalid,
  });
  return (
    <>
      <div
        className={checkClassName}
      >
        {(isChecked) ? (
          <svg viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
            <path d="M11,5c0.391,0.39,0.391,1.024,0,1.414l-3.888,3.888c-0.587,0.586,-1.537,0.586,-2.124,0l-1.767,-1.767c-0.39,-0.39,-0.39,-1.023,0,-1.414c0.391,-0.39,1.024,-0.39,1.414,0l0.706,0.706c0.391,0.391,1.026,0.391,1.418,0l2.827,-2.827c0.39,-0.391,1.024,-0.391,1.414,0z" />
          </svg>
        ) : ''}
      </div>
    </>
  );
};

export default Check;
