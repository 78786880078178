import { put, takeLatest, getContext, call, takeLeading } from 'redux-saga/effects';
import { fetchMe } from 'api/auth/fetch-me';
import { ServerApi } from 'modules/api/server-api';
import { decodeUser } from 'models/User';
import { decodeManager } from 'models/Manager';
import { decodeAuthCandidate } from 'models/Candidate';
import { decodeResumeStatus } from 'models/Candidate/UserProfile';
import camelcaseKeys from 'camelcase-keys';
import { logDebug, isServer, nextRedirect } from 'models/helpers';
import { decodeAuthCompany } from 'models/Company/AuthCompany';
import {
  fetchCurrentUserAction,
  setAuthUserAction,
  setAuthManagerAction,
  setAuthCompanyAction,
  setAuthCandidateAction,
  setCandidateResumeStatusAction,
  setCandidateCvImportStatusAction,
  logoutAction,
} from './actionCreators';
import { CandidateCvImport } from './state';

/**
 * Получение информации о текущем пользователе
 */
function* fetchMeSaga() {
  try {
    const api: ServerApi = yield getContext('api');
    const meData = yield call(fetchMe(api));
    yield put(fetchCurrentUserAction.success());

    if (meData) {
      if (meData.user) {
        const user = decodeUser(meData.user);
        yield put(setAuthUserAction(user));
      }

      if (meData.manager) {
        const manager = decodeManager(meData.manager);
        yield put(setAuthManagerAction(manager));
      }

      if (meData.company) {
        // TODO: Функция прогона данных из апи
        yield put(setAuthCompanyAction(decodeAuthCompany(meData.company)));
      }

      if (meData.candidate) {
        const candidate = decodeAuthCandidate(meData.candidate);
        yield put(setAuthCandidateAction(candidate));

        if (meData.candidate.parser_status) {
          try {
            const cvImportStatus = camelcaseKeys(meData.candidate.parser_status) as CandidateCvImport;
            yield put(setCandidateCvImportStatusAction(cvImportStatus));
          } catch (e) {
            logDebug('Произошла ошибка при запросе CV кандидата', e);
          }
        }
      }

      if (meData.resume_status) {
        const resumeStatus = decodeResumeStatus(meData.resume_status);
        yield put(setCandidateResumeStatusAction(resumeStatus));
      }
    }
  } catch (e) {
    yield put(fetchCurrentUserAction.failure(e));
  }
}

/**
 * Получение информации о текущем пользователе
 */
function* logoutSaga() {
  if (!isServer) {
    yield call(nextRedirect, '/auth/logout');
  }
}

export function* authSaga() {
  yield takeLatest(fetchCurrentUserAction.request, fetchMeSaga);
  yield takeLeading(logoutAction, logoutSaga);
}
