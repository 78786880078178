import { ServerApi } from "modules/api/server-api";
import { decodeVacancyState, VacancyCandidateActiveStatus } from "models/VacancyState";

export const fetchCandidateVacancies = (api: ServerApi) => async (status: VacancyCandidateActiveStatus, tariff: string) => {
  const response = await api.get(`/v3/candidate/vacancies?status=${status}&tariff=${tariff}`);
  return {
    vacancies: response.data.vacancies.map((item: any) => decodeVacancyState(item)),
    counters: response.data.counters,
  };
};
