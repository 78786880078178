import axios, { AxiosRequestConfig } from 'axios';
import { Store } from 'redux';
import startsWith from 'lodash/startsWith';
import { logoutAction } from 'store/ducks/auth/actionCreators';
import { LOCALE } from 'utils/base';
import { AppState } from '../../store';
import { authTokenSelector } from '../../store/ducks/auth/selectors';

const logoutInterceptor = (store: Store<AppState>) => async (error: any) => {
  console.log('axios error interceptor', error);
  if (error.response && error.response.status && error.response.status === 401) {
    store.dispatch(logoutAction(undefined));
  }
  return Promise.reject(error);
};


export class ServerApi {
  protected store: Store<AppState>;

  constructor(store: Store<AppState>) {
    this.store = store;
  }

  protected processUrl = (url: string): string => {
    return startsWith(url, '/v3') ? url : `v2${url}`;
  };

  protected createInstance() {
    const token = this.store ? authTokenSelector(this.store.getState()) : '';

    const headers: any = {
      'Content-Type': 'application/json',
      'Locale': LOCALE,
    };
    if (token) {
      headers.Authorization = token;
    }

    const baseUrl = process.env.REACT_APP_API_URL;

    axios.interceptors.response.use(undefined, logoutInterceptor);

    return axios.create({
      baseURL: typeof baseUrl === 'string' ? baseUrl.replace('/v2', '') : '',
      headers,
    });
  }

  get(url: string, config?: AxiosRequestConfig) {
    return this.createInstance().get(this.processUrl(url), config);
  }

  post(url: string, data?: any, config?: AxiosRequestConfig) {
    return this.createInstance().post(this.processUrl(url), data, config);
  }

  put(url: string, data?: any, config?: AxiosRequestConfig) {
    return this.createInstance().put(this.processUrl(url), data, config);
  }

  delete(url: string, config?: AxiosRequestConfig) {
    return this.createInstance().delete(this.processUrl(url), config);
  }
}
