import React, { FC } from 'react';

interface Props {
  size?: number;
}

const TargetIcon: FC<Props> = ({ size = 16 }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.99998 14.6663C11.6819 14.6663 14.6666 11.6816 14.6666 7.99968C14.6666 4.31778 11.6819 1.33301 7.99998 1.33301C4.31808 1.33301 1.33331 4.31778 1.33331 7.99968C1.33331 11.6816 4.31808 14.6663 7.99998 14.6663Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8 12C10.2091 12 12 10.2091 12 8C12 5.79086 10.2091 4 8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.99998 9.33366C7.2636 9.33366 6.66665 8.7367 6.66665 8.00032C6.66665 7.26395 7.2636 6.66699 7.99998 6.66699C8.73636 6.66699 9.33331 7.26395 9.33331 8.00032C9.33331 8.7367 8.73636 9.33366 7.99998 9.33366Z" fill="currentColor" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default TargetIcon;
