export type Manager = {
  name: string;
  image?: string;
  phone?: string;
  telegram?: string;
  title?: string;
}

export const decodeManager = (json: any): Manager => {
  return {
    name: json.name,
    image: json.image ? json.image : undefined,
    phone: json.phone ? json.phone : undefined,
    telegram: json.telegram ? json.telegram : undefined,
    title: json.title ? json.title : undefined,
  };
};
