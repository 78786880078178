import React, { HTMLProps } from 'react';
import './Grid.css';

const BREAKPOINTS = ['xs', 'sm', 'md', 'lg', 'xl'];

export type GridItemSize = 'auto' | true | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
type HideOnBreakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

/**
 * Grid props interface
 */
interface Props extends HTMLProps<HTMLDivElement> {
  /**
   * Grid item size on xs browser winow size and bigger (xs - lower than 600 by default)
   */
  xs?: GridItemSize;
  /**
   * Grid item size on sm browser winow size (sm - bigger than 600 by default)
   */
  sm?: GridItemSize;
  /**
   * Grid item size on md browser winow size (md - bigger than 960 by default)
   */
  md?: GridItemSize;
  /**
   * grid item size on lg browser winow size (lg - bigger than 1280 by default)
   */
  lg?: GridItemSize;
  /**
   * Grid item size on xl browser winow size (xl - bigger than 1440 by default)
   */
  xl?: GridItemSize;
  /**
   * grid item will be hidden on this browser winow sizes
   * (can be single string size or array of string sizes: 'xs' | 'sm' | 'md' | 'lg' | 'xl')
   */
  hide?: HideOnBreakpoint | Array<HideOnBreakpoint>;

  reverse?: HideOnBreakpoint;
  /**
   * this component renders as grid element (like cell)
   */
  item?: boolean;
  /**
   * this component renders as grid root (like table)
   */
  container?: boolean;
  /**
   * this component will have 100% height
   */
  fullHeight?: boolean;
  /**
   * flex align content property
   */
  alignContent?: 'stretch' | 'center' | 'flex-start' | 'flex-end' | 'space-between' | 'space-around';
  /**
   * flex align items property
   */
  alignItems?: 'flex-start' | 'center' | 'flex-end' | 'stretch' | 'baseline';
  /**
   * flex direction property
   */
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  /**
   * flex justify content property
   */
  justify?: 'flex-start' | 'center' | 'flex-end' | 'space-between' | 'space-around' | 'space-evenly';
  /**
   * horisontal spacing between grid items (equals 5px*spacing by default)
   */
  spacing?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;

  toRight?: boolean;

  noWrap?: boolean;
}

/**
 * Renders part of 12-rows grid.
 *
 * @remarks
 * This component should be used in pair with its onwn instances.
 *
 * @returns Grid React functional component
 *
 */
const Grid: React.FC<Props> = (props: Props) => {
  const {
    xs, sm, md, lg, xl, hide, item, container, fullHeight, alignContent, alignItems, direction,
    justify,
    spacing,
    children,
    reverse,
    className,
    toRight,
    noWrap,
    ...other
  } = props;
  let hiddenClasses: Array<string> = [];
  if (hide) {
    hiddenClasses = hide instanceof Array ? hide.map(size => (`geecko-grid--${size}-hide`)) : [`geecko-grid--${hide}-hide`];
  }
  return (
    <>
      <div
        className={[
          'geecko-grid',
          container ? 'geecko-grid--container' : '',
          item ? 'geecko-grid--item' : '',
          ...[xs, sm, md, lg, xl].map((size, i) => (size ? `geecko-grid--${BREAKPOINTS[i]}-${size.toString()}` : '')),
          ...hiddenClasses,
          fullHeight ? 'geecko-grid--full-height' : '',
          alignContent ? `geecko-grid--align-content-${alignContent}` : '',
          alignItems ? `geecko-grid--align-items-${alignItems}` : '',
          direction ? `geecko-grid--flex-direction-${direction}` : '',
          justify ? `geecko-grid--justify-content-${justify}` : '',
          spacing ? `geecko-grid--spacing-${spacing}` : '',
          reverse ? `geecko-grid--container--reverse-${reverse}` : '',
          toRight ? `geecko-grid--item--to-right` : '',
          noWrap ? `geecko-grid--container--no-wrap` : '',
          className,
        ].filter(Boolean).join(' ')}

        {...other}
      >
        {children}
      </div>
    </>
  );
};

export default Grid;
