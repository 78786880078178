import qs from 'qs';
import { GeeckoApi } from 'modules/api/geecko-api';

export type Location = {
  id: number;
  name: string;
  country?: Country;
  address?: string;
  dadataAddress?: any | null;
}

export type Country = {
  id: number;
  name: string;
}

export const decodeLocation = (json: any): Location => ({
  id: json.id,
  name: json.name,
  country: json.country ? decodeCountry(json.country) : undefined,
});

export const decodeCountry = (json: any): Country => json;

export const getLocationOption = (locationItem: Location) => ({
  label: getLocationTitle(locationItem),
  value: locationItem.id.toString(),
});

export const getLocationTitle = (locationItem: Location): string => [
  locationItem.name,
  locationItem.country ? locationItem.country.name : undefined,
].filter(item => !!item).join(', ');

export const loadLocationsOptions = async (api: GeeckoApi, inputValue: string) => {
  if (inputValue) {
    const response = await api.get(`/dictionary/new-locations?${qs.stringify({ query: inputValue })}`);
    return response.data && response.data.locations && response.data.locations.length > 0
      ? response.data.locations.map((item: any) => {
        const location = decodeLocation(item);
        return {
          id: location.id,
          name: getLocationTitle(location),
        };
      })
      : [];
  }

  return [];
};

export const loadCountriesOptions = async (api: GeeckoApi, inputValue: string) => {
  if (inputValue) {
    const response = await api.get(`/dictionary/locations/countries?${qs.stringify({ query: inputValue })}`);
    return response.data && response.data.locations && response.data.locations.length > 0
      ? response.data.locations.map(decodeCountry)
      : [];
  }

  return [];
};
