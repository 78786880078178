export interface CompanyRequisites {
  isIndividual: boolean;
  name: string;
  address: string;
  ogrn: number;
  inn: number;
  kpp: number;
  managementName: string;
  managementPost: string;
  authority?: 'bylaw' | 'other';
  authorityDocument?: string;
  dadataParty?: Array<any>;
}

export const decodeCompanyRequisites = (json: any): CompanyRequisites => ({
  isIndividual: json.is_individual,
  name: json.name,
  address: json.address,
  ogrn: json.ogrn,
  inn: json.inn,
  kpp: json.kpp,
  managementName: json.is_individual ? undefined : json.management_name,
  managementPost: json.is_individual ? undefined : json.management_post,
  authority: json.is_individual || !json.authority ? undefined : json.authority,
  authorityDocument:
    json.is_individual || json.authority === 'bylaw' || !json.authority_document ? undefined : json.authority_document,
  dadataParty: json.dadata_party ? json.dadata_party : undefined,
});
