import React, { FC, Fragment, useRef, useState } from 'react';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useIntl } from 'react-intl';
import EditPaperRounded from 'components/Icons/EditPaperRounded';
import { useApiInstance } from 'api';
import { castDraft } from 'immer';
import { CvFormValuesExperience, encodeCandidateExperience, getCandidateExperienceItem } from 'models/CV/CvFormValues';
import Grid from 'components/Grid/Grid';
import Text from 'components/v2/Text/Text';
import FormDivider from 'components/Form/FormDivider/FormDivider';
import { formatFinalFormDate, formatFinalFormDateRange } from 'models/helpers';
import Typography from 'components/Typography/Typography';
import TrashRounded from 'components/Icons/TrashRounded/TrashRounded';
import Label from 'components/Form/Label/Label';
import { getFilteredSkillsAreas } from 'components/Form/SkillSetStackField/SkillSetStackField';
import { decodeSkillSet } from 'models/Tech/skillset';
import TagField from 'components/Form/TagField/TagField';
import { CheckRequired } from 'components/FormAdapter/FormAdapter';
import FormError from 'components/Form/FormError/FormError';
import Button from 'components/v2/Button/Button';
import CVFormExperienceCompanyModal, { StackFields } from 'forms/CV/CVFormExperienceCompanyModal';
import CVFormExperienceStackModal from 'forms/CV/CVFormExperienceStackModal';
import CVExperienceStack from 'forms/CV/CVExperienceStack';

interface Props {
  values: any;
  form: any;
  formApi: any;
  isSingle?: boolean;
}

const CVExperienceFormItem: FC<Props> = ({ values, form, formApi, isSingle }) => {
  const api = useApiInstance();
  const intl = useIntl();
  const companiesApi = useRef<any>();

  const [editMode, setEditMode] = useState<'add' | 'edit'>('add');
  const [editingCompany, setEditingCompany] = useState<CvFormValuesExperience | undefined>();

  const handleAddCompany = () => {
    setEditingCompany(getCandidateExperienceItem());
    setEditMode('add');
  };

  const handleUpdateCompany = (company: CvFormValuesExperience) => {
    setEditingCompany(undefined);
    if (!companiesApi.current) {
      return;
    }
    if (editMode === 'add') {
      companiesApi.current.push(company);
    } else {
      const index = companiesApi.current.value.findIndex((item: CvFormValuesExperience) => item.uid === company.uid);
      if (index >= 0) {
        companiesApi.current.update(index, company);
      }
    }

    handleUpdateSkillsExperience();
  };

  const handleUpdateSkillsExperience = () => {
    setTimeout(() => {
      if (formApi.current) {
        const formValues = formApi.current.getState().values;
        api
          .post('/candidate/skills/experience-calculate', {
            experience: encodeCandidateExperience(castDraft(formValues.experience)),
            skills: {
              // any затипизировать
              lang: formValues.skills.lang.map((item: any) => ({ id: item.id, is_primary: item.isPrimary })),
              tech: formValues.skills.tech.map((item: any) => ({ id: item.id, is_primary: item.isPrimary })),
              db: formValues.skills.db.map((item: any) => ({ id: item.id, is_primary: item.isPrimary })),
            },
          })
          .then((response) => {
            if (response.data.skills && formApi.current) {
              formApi.current.change('skillSet', decodeSkillSet(response.data.skills));
            }
          });
      }
    }, 500);
  };

  const handleCloseEditingCompany = () => {
    setEditingCompany(undefined);
  };

  const [editingStack, setEditingStack] = useState<StackFields | undefined>();
  const [editingStackCompany, setEditingStackCompany] = useState<string | undefined>();
  const handleCloseStack = () => {
    setEditingStack(undefined);
  };
  const handleUpdateStack = (currentValues: StackFields) => {
    if (formApi.current) {
      formApi.current.change(`${editingStackCompany}.areas`, currentValues.areas);
      formApi.current.change(`${editingStackCompany}.specializations`, currentValues.specializations);
      formApi.current.change(`${editingStackCompany}.skills`, currentValues.skills);
    }
    setEditingStack(undefined);

    handleUpdateSkillsExperience();
  };

  return (
    <>
      {editingCompany && (
        <CVFormExperienceCompanyModal
          isOpen={!!editingCompany}
          editMode={editMode}
          value={editingCompany}
          commonSkills={castDraft({
            areas: values.areas,
            specializations: values.specializations,
            skills: values.skills,
          })}
          onChange={handleUpdateCompany}
          onClose={handleCloseEditingCompany}
        />
      )}

      {editingStack && (
        <CVFormExperienceStackModal
          isOpen={!!editingStack}
          value={editingStack!}
          commonSkills={castDraft({
            areas: values.areas,
            specializations: values.specializations,
            skills: values.skills,
          })}
          onChange={handleUpdateStack}
          onClose={handleCloseStack}
          type="company"
        />
      )}

      <FieldArray<CvFormValuesExperience> name="experience">
        {({ fields }) => {
          companiesApi.current = fields;
          const companiesCount = fields.value.length;

          return (
            <>
              {fields.map((fieldName, fieldIndex) => {
                const fieldValue = fields.value[fieldIndex];
                const dateRangeFormatted = formatFinalFormDateRange(
                  intl,
                  fieldValue.startMonth,
                  fieldValue.startYear,
                  fieldValue.finishMonth,
                  fieldValue.finishYear,
                  fieldValue.isContinuous,
                );
                const dateStart = formatFinalFormDate(intl, fieldValue.startMonth, fieldValue.startYear);
                const dateFinish = fieldValue.isContinuous
                  ? intl.formatMessage({ id: 'app.resume.activity.utilnow' })
                  : formatFinalFormDate(intl, fieldValue.finishMonth, fieldValue.finishYear);

                const handleSelectAreas = (newAreas: string[]) => {
                  if (newAreas.length > 0 && newAreas.indexOf('other') < 0) {
                    setEditingStackCompany(fieldName);
                    setEditingStack({
                      areas: newAreas,
                      skills: fieldValue.skills,
                      specializations: fieldValue.specializations,
                    });
                  } else {
                    form.change(`${fieldName}.areas`, newAreas);
                  }
                };

                const handleEditCpmpanyStack = () => {
                  setEditingStackCompany(fieldName);
                  setEditingStack({
                    areas: fieldValue.areas,
                    skills: fieldValue.skills,
                    specializations: fieldValue.specializations,
                  });
                };

                return (
                  <Fragment key={fieldValue.uid}>
                    <div className="geecko-paper__wrapper">
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                          <Text variant="h4">{dateRangeFormatted}</Text>
                          <div className="geecko-util__mt-1">
                            <Typography variant="body1" component="span" muted>
                              {dateStart}
                              &ndash;
                              {dateFinish}
                            </Typography>
                          </div>
                          <div className="geecko-util__mt-2 geecko-cv-form__experience-company-actions">
                            <Button
                              variant="ghost"
                              color="secondary"
                              size="sm"
                              onClick={() => {
                                setEditingCompany(fieldValue);
                                setEditMode('edit');
                              }}
                            >
                              <EditPaperRounded size={16} />
                            </Button>
                            {!isSingle && (
                              <Button
                                variant="ghost"
                                color="secondary"
                                size="sm"
                                onClick={() => {
                                  if (confirm('Вы действительно хотите удалить эту компанию')) {
                                    fields.remove(fieldIndex);
                                  }
                                }}
                              >
                                <TrashRounded size={18} />
                              </Button>
                            )}
                          </div>
                        </Grid>
                        <Grid item xs={12} md={8}>
                          <Text variant="h3">
                            {fieldValue.position}
                            {' в '}
                            {fieldValue.companyName}
                          </Text>
                          {fieldValue.description && (
                            <div className="geecko-util__mt-2">
                              <Text variant="md" color="#646D7B">
                                {fieldValue.description}
                              </Text>
                            </div>
                          )}
                          {fieldValue.areas.length > 0 &&
                            fieldValue.areas.indexOf('other') < 0 &&
                            fieldValue.specializations.length > 0 && (
                              <div className="geecko-util__mt-3">
                                <CVExperienceStack
                                  specializations={fieldValue.specializations}
                                  skills={fieldValue.skills}
                                />
                              </div>
                            )}
                          {(fieldValue.areas.length === 0 ||
                            (fieldValue.areas.length === 1 && fieldValue.areas.indexOf('other') >= 0)) && (
                            <Field name={`${fieldName}.areas`} validate={CheckRequired}>
                              {() => (
                                <div className="geecko-form__item">
                                  <Label required>Область деятельности</Label>
                                  <TagField
                                    value={fieldValue.areas}
                                    restrictGroups
                                    options={[
                                      ...getFilteredSkillsAreas(castDraft(values.areas)).map((item) => ({
                                        value: item.id,
                                        label: item.name,
                                        groupKey: 'dev',
                                      })),
                                      { value: 'other', label: 'Другое', groupKey: 'other' },
                                    ]}
                                    onChange={handleSelectAreas}
                                  />
                                  <FormError name={`${fieldName}.areas`} />
                                </div>
                              )}
                            </Field>
                          )}
                          {fieldValue.areas.length > 0 && fieldValue.areas.indexOf('other') < 0 && (
                            <div className="geecko-util__mt-1">
                              <Button
                                variant="ghost"
                                color="secondary"
                                size="sm"
                                iconLeft={<EditPaperRounded size={16} />}
                                onClick={handleEditCpmpanyStack}
                              >
                                Редактировать стек
                              </Button>
                            </div>
                          )}

                          {fieldValue.projects && fieldValue.projects.length > 0 && (
                            <>
                              {fieldValue.projects.map((project) => (
                                <div key={project.uid} className="geecko-cv-form__experience-project-wrapper">
                                  <Text variant="h4">{project.title}</Text>
                                  {project.description && (
                                    <div className="geecko-util__mt-2">
                                      <Text variant="md" color="#646D7B">
                                        {project.description}
                                      </Text>
                                    </div>
                                  )}
                                  {project.areas.length > 0 &&
                                    project.areas.indexOf('other') < 0 &&
                                    project.specializations.length > 0 && (
                                      <div className="geecko-util__mt-3">
                                        <CVExperienceStack
                                          specializations={project.specializations}
                                          skills={project.skills}
                                        />
                                      </div>
                                    )}
                                </div>
                              ))}
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </div>
                  </Fragment>
                );
              })}
              {!isSingle && (
                <FormDivider
                  text={companiesCount === 0 ? 'Добавьте первую компанию' : undefined}
                  onClick={handleAddCompany}
                />
              )}
            </>
          );
        }}
      </FieldArray>
    </>
  );
};

export default CVExperienceFormItem;
