import React, { AnchorHTMLAttributes, FC } from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import routes, { Link as NextLink } from '../../../server/routes';

interface Props extends AnchorHTMLAttributes<HTMLAnchorElement>{
  to: string;
}

const Link: FC<Props> = ({
  to, children, ...rest
}) => {
  const { route } = routes.match(to);
  if (route) {
    return (
      <NextLink route={to}>
        <a {...rest}>
          {children}
        </a>
      </NextLink>
    );
  }
  return (
    <a href={to} {...rest}>
      {children}
    </a>
  );
};

export default Link;
