/* eslint-disable no-param-reassign,no-restricted-globals */
import React, {
  FC, useEffect, useMemo, useState,
} from 'react';
import qs from 'qs';
import { Field, useField, useForm } from 'react-final-form';
import Label from '../Label/Label';
import { CheckRequired, TagFieldAdapter } from '../../FormAdapter/FormAdapter';
import CVFormMatchingGuide from '../../../forms/CV/CVFormMatchingGuide';
import { Specialization } from '../../../models/Specialization';
import TagFieldItem, { TagState } from '../TagField/TagFieldItem';
import { CvCandidateSpecialization, SkillTool } from '../../../models/CV/CvFormValues';
import { useApiInstance } from '../../../api';
import { SkillNew } from '../../../models/Skill';
import Select, { AsyncSelectLoadOptionsHandler } from '../../AsyncSelect/AsyncSelect';
import Tooltip from '../../Tooltip/Tooltip';
import { StackFields } from '../../../forms/CV/CVFormExperienceCompanyModal';
import FormError from '../FormError/FormError';

interface SkillArea {
  id: string;
  name: string;
}

export const SKILL_AREAS: SkillArea[] = [
  { id: 'dev', name: 'Разработка' },
  { id: 'test', name: 'Тестирование' },
  { id: 'admin', name: 'Администрирование' },
  { id: 'data', name: 'Data Science' },
];

export const getFilteredSkillsAreas = (selectedAreas: string[]): SkillArea[] => {
  return SKILL_AREAS.filter(item => selectedAreas.indexOf(item.id) >= 0);
};

interface Props {
  variant?: 'common' | 'specific';
  specializations: ReadonlyArray<Specialization>;
  commonSkills?: StackFields;
}

const SkillSetStackField: FC<Props> = ({ variant = 'common', commonSkills, specializations }) => {
  const commonSelectedSkills = commonSkills || { specializations: [], areas: [], skills: { lang: [], db: [], tech: [] } };
  const form = useForm();
  const api = useApiInstance();
  const [skillsLangs, setSkillsLangs] = useState<SkillTool[]>([]);
  const [skillsTechnologies, setSkillsTechnologies] = useState<SkillTool[]>([]);
  const [skillsDatabases, setSkillsDatabases] = useState<SkillTool[]>([]);
  const [languagesLoading, setLanguagesLoading] = useState(false);
  const [technologiesLoading, setTechnologiesLoading] = useState(false);
  const [databasesLoading, setDatabasesLoading] = useState(false);
  const areasField = useField('areas');
  const specializationsField = useField('specializations');
  const skillsField = useField('skills');
  const skillsFieldValue = skillsField.input.value as {
    lang: SkillNew[];
    db: SkillNew[];
    tech: SkillNew[];
  };
  const selectedSpecializations = useMemo(() => specializationsField.input.value as CvCandidateSpecialization[], [specializationsField.input.value]);
  const filteredSpecializations = useMemo(() => {
    if (variant === 'common') {
      const value = areasField.input.value as string[];
      return specializations.filter(item => value.indexOf(item.type) >= 0);
    }
    return commonSelectedSkills.specializations.map(specialization => ({
      id: specialization.id,
      name: specialization.name,
      type: '',
      text: undefined,
    }));
  }, [specializations, areasField.input.value, selectedSpecializations, variant, commonSelectedSkills.specializations]);
  useEffect(() => {
    // Проверяем что у нас все специализации матчаися по направлению
    let hasMismatches = false;
    const newSelectedSpecs = [...selectedSpecializations].filter(selectedSpec => {
      const specCheck = filteredSpecializations.find(spec => selectedSpec.id === spec.id);
      if (!specCheck) {
        hasMismatches = true;
        return false;
      }
      return true;
    });
    if (hasMismatches) {
      form.change('specializations', newSelectedSpecs);
    }
  }, [filteredSpecializations]);
  const handleSelectSpecialization = (specializationId: string) => {
    const selectedItem: CvCandidateSpecialization | undefined = selectedSpecializations.find(item => item.id === specializationId);
    if (selectedItem) {
      if (selectedItem.isPrimary && variant === 'common') {
        form.change('specializations', [...selectedSpecializations.map(item => (item.id === specializationId ? { ...item, isPrimary: false } : { ...item }))]);
      } else {
        form.change('specializations', [...selectedSpecializations].filter(item => item.id !== specializationId));
      }
    } else {
      const spec = specializations.find(item => item.id === specializationId);
      const specName = spec ? spec.name : '';
      form.change('specializations', [...selectedSpecializations, { id: specializationId, name: specName, isPrimary: true }]);
    }
  };

  const searchLanguages = async (searchQuery?: string) => {
    if (selectedSpecializations.length > 0) {
      setLanguagesLoading(true);
      try {
        const query = {
          query: searchQuery,
          type: 'lang',
          specializations: selectedSpecializations.map(spec => spec.id),
        };

        const toolsResponse = await api.get(`/dictionary/tools?${qs.stringify(query, { arrayFormat: 'brackets' })}`);
        if (toolsResponse?.data?.tools) {
          setSkillsLangs(toolsResponse.data.tools);
        }
      } catch (e) {
        //
      }
      setLanguagesLoading(false);
    } else {
      setSkillsLangs([]);
    }
  };
  const searchAdditionalLanguages: AsyncSelectLoadOptionsHandler<SkillTool> = async (searchQuery) => {
    const query = {
      query: searchQuery,
      type: 'lang',
      exclude: availableLanguages.map(spec => spec.id),
    };

    const toolsResponse = await api.get(`/dictionary/tools?${qs.stringify(query, { arrayFormat: 'brackets' })}`);
    return toolsResponse?.data?.tools || [];
  };

  const searchTechnologies = async (searchQuery?: string) => {
    if (skillsFieldValue.lang.length > 0) {
      setTechnologiesLoading(true);
      try {
        const query = {
          query: searchQuery,
          type: 'tech',
          specializations: selectedSpecializations.map(spec => spec.id),
          languages: skillsFieldValue.lang.map(lang => lang.id),
          exclude: skillsFieldValue.tech.map(tech => tech.id),
        };

        const toolsResponse = await api.get(`/dictionary/tools?${qs.stringify(query, { arrayFormat: 'brackets' })}`);
        if (toolsResponse?.data?.tools) {
          setSkillsTechnologies(toolsResponse.data.tools);
        }
      } catch (e) {
        //
      }
      setTechnologiesLoading(false);
    } else {
      setSkillsTechnologies([]);
    }
  };

  const searchAdditionalTechnologies: AsyncSelectLoadOptionsHandler<SkillTool> = async (searchQuery) => {
    const query = {
      query: searchQuery,
      type: 'tech',
      exclude: availableTechnologies.map(spec => spec.id),
    };

    const toolsResponse = await api.get(`/dictionary/tools?${qs.stringify(query, { arrayFormat: 'brackets' })}`);
    return toolsResponse?.data?.tools || [];
  };

  const searchDatabases = async (searchQuery?: string) => {
    if (selectedSpecializations.length > 0) {
      setDatabasesLoading(true);
      try {
        const query = {
          query: searchQuery,
          type: 'tech.db',
          specializations: selectedSpecializations.map(spec => spec.id),
        };

        const toolsResponse = await api.get(`/dictionary/tools?${qs.stringify(query, { arrayFormat: 'brackets' })}`);
        if (toolsResponse?.data?.tools) {
          setSkillsDatabases(toolsResponse.data.tools);
        }
      } catch (e) {
        //
      }
      setDatabasesLoading(false);
    } else {
      setSkillsDatabases([]);
    }
  };
  const searchAdditionalDatabases: AsyncSelectLoadOptionsHandler<SkillTool> = async (searchQuery) => {
    const query = {
      query: searchQuery,
      type: 'tech.db',
      exclude: availableDatabases.map(spec => spec.id),
    };

    const toolsResponse = await api.get(`/dictionary/tools?${qs.stringify(query, { arrayFormat: 'brackets' })}`);
    return toolsResponse?.data?.tools || [];
  };

  const handleSelectLanguage = (languageId: string) => {
    const selectedItem: SkillNew | undefined = skillsFieldValue.lang.find(item => item.id === +languageId);
    if (selectedItem) {
      if (selectedItem.isPrimary && variant === 'common') {
        form.change('skills.lang', [...skillsFieldValue.lang.map(item => (item.id === +languageId ? { ...item, isPrimary: false } : { ...item }))]);
      } else {
        form.change('skills.lang', [...skillsFieldValue.lang].filter(item => item.id !== +languageId));
      }
    } else {
      const baseLang = availableLanguages.find(item => item.id === +languageId);
      if (baseLang) {
        form.change('skills.lang', [
          ...skillsFieldValue.lang, {
            id: +languageId,
            name: baseLang.name,
            image: baseLang.image,
            link: baseLang.link,
            isPrimary: true,
          },
        ]);
      }
    }
  };

  const handleSelectTechnology = (technologyId: string) => {
    const selectedItem: SkillNew | undefined = skillsFieldValue.tech.find(item => item.id === +technologyId);
    if (selectedItem) {
      if (selectedItem.isPrimary && variant === 'common') {
        form.change('skills.tech', [...skillsFieldValue.tech.map(item => (item.id === +technologyId ? { ...item, isPrimary: false } : { ...item }))]);
      } else {
        form.change('skills.tech', [...skillsFieldValue.tech].filter(item => item.id !== +technologyId));
      }
    } else {
      const baseTech = availableTechnologies.find(item => item.id === +technologyId);
      if (baseTech) {
        form.change('skills.tech', [
          ...skillsFieldValue.tech, {
            id: +technologyId,
            name: baseTech.name,
            image: baseTech.image,
            link: baseTech.link,
            isPrimary: true,
          },
        ]);
      }
    }
  };

  const handleSelectDatabase = (databaseId: string) => {
    const selectedItem: SkillNew | undefined = skillsFieldValue.db.find(item => item.id === +databaseId);
    if (selectedItem) {
      if (selectedItem.isPrimary && variant === 'common') {
        form.change('skills.db', [...skillsFieldValue.db.map(item => (item.id === +databaseId ? { ...item, isPrimary: false } : { ...item }))]);
      } else {
        form.change('skills.db', [...skillsFieldValue.db].filter(item => item.id !== +databaseId));
      }
    } else {
      const baseDB = availableDatabases.find(item => item.id === +databaseId);
      if (baseDB) {
        form.change('skills.db', [
          ...skillsFieldValue.db, {
            id: +databaseId,
            name: baseDB.name,
            image: baseDB.image,
            link: baseDB.link,
            isPrimary: true,
          },
        ]);
      }
    }
  };

  const handleAddLanguage = (newItem?: SkillTool) => {
    if (newItem) {
      form.change('skills.lang', [
        ...skillsFieldValue.lang, {
          id: newItem.id,
          name: newItem.name,
          image: newItem.image,
          link: newItem.link,
          isPrimary: true,
        },
      ]);
    }
  };

  const handleAddTechnology = (newItem?: SkillTool) => {
    if (newItem) {
      form.change('skills.tech', [
        ...skillsFieldValue.tech, {
          id: newItem.id,
          name: newItem.name,
          image: newItem.image,
          link: newItem.link,
          isPrimary: true,
        },
      ]);
    }
  };

  const handleAddDatabase = (newItem?: SkillTool) => {
    if (newItem) {
      form.change('skills.db', [
        ...skillsFieldValue.db, {
          id: newItem.id,
          name: newItem.name,
          image: newItem.image,
          link: newItem.link,
          isPrimary: true,
        },
      ]);
    }
  };

  useEffect(() => {
    if (variant === 'common') {
      searchLanguages();
      searchTechnologies();
      searchDatabases();
    }
  }, [selectedSpecializations]);

  useEffect(() => {
    if (variant === 'common') {
      searchTechnologies();
    }
  }, [skillsFieldValue.lang]);

  const availableLanguages = useMemo<SkillTool[]>(() => {
    const result: SkillTool[] = [];
    const usedIds: number[] = skillsLangs.map(lang => lang.id);
    if (variant === 'specific') {
      commonSelectedSkills.skills.lang.forEach(lang => {
        if (usedIds.indexOf(lang.id) < 0) {
          usedIds.push(lang.id);
          result.push(lang);
        }
      });
    }
    skillsFieldValue.lang.forEach(lang => {
      if (usedIds.indexOf(lang.id) < 0) {
        result.push(lang);
      }
    });
    return [...result, ...skillsLangs];
  }, [skillsLangs, skillsFieldValue.lang, variant, commonSelectedSkills.skills.lang]);

  const availableTechnologies = useMemo<SkillTool[]>(() => {
    const result: SkillTool[] = [];
    const usedIds: number[] = skillsTechnologies.map(tech => tech.id);
    if (variant === 'specific') {
      commonSelectedSkills.skills.tech.forEach(tech => {
        if (usedIds.indexOf(tech.id) < 0) {
          usedIds.push(tech.id);
          result.push(tech);
        }
      });
    }
    skillsFieldValue.tech.forEach(tech => {
      if (usedIds.indexOf(tech.id) < 0) {
        result.push(tech);
      }
    });
    return [...result, ...skillsTechnologies];
  }, [skillsTechnologies, skillsFieldValue.tech, variant, commonSelectedSkills.skills.tech]);

  const availableDatabases = useMemo<SkillTool[]>(() => {
    const result: SkillTool[] = [];
    const usedIds: number[] = skillsDatabases.map(tech => tech.id);
    if (variant === 'specific') {
      commonSelectedSkills.skills.db.forEach(db => {
        if (usedIds.indexOf(db.id) < 0) {
          usedIds.push(db.id);
          result.push(db);
        }
      });
    }
    skillsFieldValue.db.forEach(tech => {
      if (usedIds.indexOf(tech.id) < 0) {
        result.push(tech);
      }
    });
    return [...result, ...skillsDatabases];
  }, [skillsDatabases, skillsFieldValue.db, commonSelectedSkills.skills.db]);

  return (
    <>
      <CVFormMatchingGuide enabled={variant === 'common'}>
        <div className="geecko-form__item">
          <Label required>Область деятельности</Label>
          <Field<string[]>
            name="areas"
            multiple
            component={TagFieldAdapter}
            options={(variant === 'common' || !commonSkills ? SKILL_AREAS : getFilteredSkillsAreas(commonSkills!.areas)).map(item => ({ value: item.id, label: item.name }))}
            validate={CheckRequired}
            beforeChange={(newAreas: string[]) => {
              // Проверяем, есть ли специализации, которые не попадают в новый фильтр
              const notMatchedSpecs = [...selectedSpecializations].filter(spec => {
                const specInfo = specializations.find(baseSpec => baseSpec.id === spec.id);
                if (specInfo) {
                  return newAreas.indexOf(specInfo.type) < 0;
                }
                return false;
              });

              if (notMatchedSpecs.length > 0) {
                return confirm(`У вас ${notMatchedSpecs.length > 1 ? 'сбросятся следующие специализации:' : 'сбросится специализация'} ${notMatchedSpecs.map(item => item.name).join(', ')}. Вы хотите продолжить?`);
              }

              return true;
            }}
          />
        </div>
      </CVFormMatchingGuide>

      {/* Специализации */}
      <CVFormMatchingGuide enabled={variant === 'common'}>
        <div className="geecko-form__item">
          <Label required>Специализация</Label>
          <Field name="specializations" validate={CheckRequired}>
            {() => (
              <div
                className={[
                  'geecko-tagfield',
                  'geecko-tagfield--color-default',
                ].join(' ')}
              >
                <ul
                  className="geecko-tagfield__options"
                  role="listbox"
                >
                  {filteredSpecializations && filteredSpecializations.length > 0 && (
                    <>
                      {filteredSpecializations.map((option, index) => {
                        let tagState: TagState = TagState.Off;
                        const selectedItem = selectedSpecializations.find(item => item.id === option.id);
                        if (selectedItem) {
                          tagState = selectedItem.isPrimary ? TagState.On : TagState.OnPartial;
                        }
                        return (
                          <Tooltip key={option.id} overlay={option.text} placement="top" preWrap>
                            <TagFieldItem
                              label={option.name}
                              value={option.id}
                              selected={tagState}
                              onSelect={handleSelectSpecialization}
                            />
                          </Tooltip>
                        );
                      })}
                    </>
                  )}
                  {(!filteredSpecializations || filteredSpecializations.length === 0) && (
                    <div className="geecko-tagfield__options-item geecko-tagfield__options-item--not-active">
                      Выберите направление
                    </div>
                  )}
                </ul>
                <FormError name="specializations" />
              </div>
            )}
          </Field>
        </div>
      </CVFormMatchingGuide>

      {/* Языки */}
      <CVFormMatchingGuide enabled={variant === 'common'} isLoading={languagesLoading}>
        <div className="geecko-form__item">
          <Label>Языки</Label>
          <div
            className={[
              'geecko-tagfield',
              'geecko-tagfield--color-default',
            ].join(' ')}
          >
            <ul
              className="geecko-tagfield__options"
              role="listbox"
            >
              {availableLanguages && availableLanguages.length > 0 && (
                <>
                  {availableLanguages.map(language => {
                    let tagState: TagState = TagState.Off;
                    const selectedItem = skillsFieldValue.lang.find(item => item.id === language.id);
                    if (selectedItem) {
                      tagState = selectedItem.isPrimary ? TagState.On : TagState.OnPartial;
                    }
                    return (
                      <TagFieldItem
                        key={language.id}
                        label={language.name}
                        value={language.id.toString()}
                        selected={tagState}
                        onSelect={handleSelectLanguage}
                        disabled={languagesLoading}
                      />
                    );
                  })}
                </>
              )}
              {(selectedSpecializations.length > 0 || variant === 'specific') && (
                <div className="geecko-tagfield__empty-item" style={{ width: 280 }}>
                  <Select<SkillTool>
                    placeholder="Другие языки..."
                    loadOptions={searchAdditionalLanguages}
                    onChange={handleAddLanguage}
                    valueKey="id"
                    labelKey="name"
                    imageKey="image"
                    isDisabled={languagesLoading}
                    async
                  />
                </div>
              )}
              {selectedSpecializations.length === 0 && variant === 'common' && (
                <div className="geecko-tagfield__options-item geecko-tagfield__options-item--not-active">
                  Выберите специализацию
                </div>
              )}
            </ul>
          </div>
        </div>
      </CVFormMatchingGuide>

      <CVFormMatchingGuide enabled={variant === 'common'} isLoading={technologiesLoading}>
        <div className="geecko-form__item">
          <Label>Технологии</Label>
          <div
            className={[
              'geecko-tagfield',
              'geecko-tagfield--color-default',
            ].join(' ')}
          >
            <ul
              className="geecko-tagfield__options"
              role="listbox"
            >
              {availableTechnologies && availableTechnologies.length > 0 && (
                <>
                  {availableTechnologies.map(technology => {
                    let tagState: TagState = TagState.Off;
                    const selectedItem = skillsFieldValue.tech.find(item => item.id === technology.id);
                    if (selectedItem) {
                      tagState = selectedItem.isPrimary ? TagState.On : TagState.OnPartial;
                    }
                    return (
                      <TagFieldItem
                        key={technology.id}
                        label={technology.name}
                        value={technology.id.toString()}
                        selected={tagState}
                        onSelect={handleSelectTechnology}
                        disabled={technologiesLoading}
                      />
                    );
                  })}
                </>
              )}
              {(selectedSpecializations.length > 0 || variant === 'specific') && (
                <div className="geecko-tagfield__empty-item" style={{ width: 280 }}>
                  <Select<SkillTool>
                    placeholder="Другие технологии..."
                    loadOptions={searchAdditionalTechnologies}
                    onChange={handleAddTechnology}
                    valueKey="id"
                    labelKey="name"
                    imageKey="image"
                    isDisabled={technologiesLoading}
                    async
                  />
                </div>
              )}
              {(!availableTechnologies || availableTechnologies.length === 0) && variant === 'common' && (
                <div className="geecko-tagfield__options-item geecko-tagfield__options-item--not-active">
                  Выберите язык
                </div>
              )}
            </ul>
          </div>
        </div>
      </CVFormMatchingGuide>

      {/* Базы данных */}
      <CVFormMatchingGuide enabled={variant === 'common'} isLoading={databasesLoading}>
        <div className="geecko-form__item">
          <Label>Базы данных</Label>
          <div
            className={[
              'geecko-tagfield',
              'geecko-tagfield--color-default',
            ].join(' ')}
          >
            <ul
              className="geecko-tagfield__options"
              role="listbox"
            >
              {availableDatabases && availableDatabases.length > 0 && (
                <>
                  {availableDatabases.map(databaseItem => {
                    let tagState: TagState = TagState.Off;
                    const selectedItem = skillsFieldValue.db.find(item => item.id === databaseItem.id);
                    if (selectedItem) {
                      tagState = selectedItem.isPrimary ? TagState.On : TagState.OnPartial;
                    }
                    return (
                      <TagFieldItem
                        key={databaseItem.id}
                        label={databaseItem.name}
                        value={databaseItem.id.toString()}
                        selected={tagState}
                        onSelect={handleSelectDatabase}
                        disabled={databasesLoading}
                      />
                    );
                  })}
                </>
              )}
              {(selectedSpecializations.length > 0 || variant === 'specific') && (
                <div className="geecko-tagfield__empty-item" style={{ width: 280 }}>
                  <Select<SkillTool>
                    placeholder="Другие базы..."
                    loadOptions={searchAdditionalDatabases}
                    onChange={handleAddDatabase}
                    valueKey="id"
                    labelKey="name"
                    imageKey="image"
                    isDisabled={databasesLoading}
                    async
                  />
                </div>
              )}
              {selectedSpecializations.length === 0 && variant === 'common' && (
                <div className="geecko-tagfield__options-item geecko-tagfield__options-item--not-active">
                  Выберите специализацию
                </div>
              )}
            </ul>
          </div>
        </div>
      </CVFormMatchingGuide>
    </>
  );
};

export default SkillSetStackField;
