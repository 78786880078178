import React, { useEffect, useMemo } from 'react';
import trimStart from 'lodash/trimStart';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'components/v2/Button/Button';
import { fetchCandidateCvForEditAction } from 'store/ducks/candidate/actionCreators';
import {
  fetchSpecializationsAction,
  fetchLanguagesAction,
  fetchWorkFeaturesAction,
  fetchCompanyScopesAction,
} from 'store/ducks/dictionary/actionCreators';
import { candidateCvEditDataSelector } from 'store/ducks/candidate/selectors';
import { NextPage } from '../../../_app';
import { serverRedirect, showNotFoundError } from '../../../../src/models/helpers';
import PageNext from '../../../../src/components/Page/PageNext';
import Container from '../../../../src/components/Container/Container';
import Grid from '../../../../src/components/Grid/Grid';
import CvEditFormMenu from '../../../../src/components/CvEditFormMenu/CvEditFormMenu';
import { SidebarNavLink } from '../../../../src/components/SidebarNav/SidebarNav';
import Paper from '../../../../src/components/Paper/Paper';
import Typography from '../../../../src/components/Typography/Typography';
import CVPersonalForm from '../../../../src/forms/CV/CVPersonalForm';
import CVCommonForm from '../../../../src/forms/CV/CVCommonForm';
import CVCommunityForm from '../../../../src/forms/CV/CVCommunityForm';
import CVPreferencesForm from '../../../../src/forms/CV/CVPreferencesForm';
import CVEducationForm from '../../../../src/forms/CV/CVEducationForm';
import CVExperienceForm from '../../../../src/forms/CV/CVExperienceForm';
import { UserGroup } from '../../../../src/models/User';
import HideableElement, { hideElement } from '../../../../src/components/HideableElement/HideableElement';
import HeroView from '../../../../src/components/HeroView/HeroView';
import { authCandidateCvShowImportSelector } from '../../../../src/store/ducks/auth/selectors';
import CVImportForm from '../../../../src/forms/CV/CVImportForm';
import './CandidateEditCvPage.css';
import CVFinishForm from '../../../../src/forms/CV/CVFinishForm';
import CVTechForm from '../../../../src/forms/CV/CVTechForm';

export type CvFormStep =
  | 'import'
  | 'personal'
  | 'common'
  | 'tech'
  | 'experience'
  | 'community'
  | 'education'
  | 'preferences'
  | 'search';
export const usualCVFormSteps: CvFormStep[] = [
  'personal',
  'common',
  'tech',
  'community',
  'preferences',
  'experience',
  'education',
  'search',
];

export const getCvFormSteps = (showImport: boolean): CvFormStep[] =>
  showImport ? ['import', ...usualCVFormSteps] : usualCVFormSteps;

const getPreviousStep = (currentStep: CvFormStep, showImport: boolean): CvFormStep | undefined => {
  const cvFormSteps = getCvFormSteps(showImport);
  const currentStepIndex = cvFormSteps.indexOf(currentStep);
  if (currentStepIndex <= 0) {
    return undefined;
  }

  return cvFormSteps[currentStepIndex - 1];
};

const getNextStep = (currentStep: CvFormStep, showImport: boolean): CvFormStep | undefined => {
  const cvFormSteps = getCvFormSteps(showImport);
  const currentStepIndex = cvFormSteps.indexOf(currentStep);
  if (currentStepIndex < 0 || currentStepIndex >= cvFormSteps.length - 1) {
    return undefined;
  }

  return cvFormSteps[currentStepIndex + 1];
};

export const getNextCvFormStepByIndex = (index: number, showImport: boolean): CvFormStep | undefined => {
  const cvFormSteps = getCvFormSteps(showImport);
  if (index >= 0 && index < cvFormSteps.length - 1) {
    return cvFormSteps[index + 1];
  }

  return undefined;
};

interface Props {
  step: CvFormStep;
}

const cvImportSideBarLink: SidebarNavLink = {
  id: 'import',
  link: '/my/cv/edit/import',
  title: 'Импорт резюме',
  subtitle: 'Перенос данных со сторонних ресурсов',
};

const menuItems: SidebarNavLink[] = [
  {
    id: 'personal',
    link: '/my/cv/edit/personal',
    title: 'Персональная информация',
    subtitle: 'Имя, контакты',
  },
  {
    id: 'common',
    link: '/my/cv/edit/common',
    title: 'Главная информация',
    subtitle: 'О себе, зарплата',
  },
  {
    id: 'tech',
    link: '/my/cv/edit/tech',
    title: 'Стэк',
    subtitle: 'Специализация, языки и технологии',
  },
  {
    id: 'community',
    link: '/my/cv/edit/community',
    title: 'Сообщества',
    subtitle: 'Социальные профили, примеры кода',
  },
  {
    id: 'preferences',
    link: '/my/cv/edit/preferences',
    title: 'Предпочтения',
    subtitle: 'Предметные области, важно в работе',
  },
  {
    id: 'experience',
    link: '/my/cv/edit/experience',
    title: 'Профильный опыт',
    subtitle: 'Компании и проекты',
  },
  {
    id: 'education',
    link: '/my/cv/edit/education',
    title: 'Образование',
    subtitle: 'Учебные заведения',
  },
  {
    id: 'search',
    link: '/my/cv/edit/search',
    title: 'Настройки поиска',
    subtitle: 'Статус поиска работы',
  },
];

const CandidateEditCvPage: NextPage<Props> = ({ step }) => {
  const showCvImport = useSelector(authCandidateCvShowImportSelector);
  const dispatch = useDispatch();
  const cvEditData = useSelector(candidateCvEditDataSelector);

  useEffect(() => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      let { hash } = window.location;
      hash = hash ? trimStart(hash, '#') : '';
      if (hash) {
        const inputElement = document.querySelector(`[name="${hash}"]`);
        if (inputElement && typeof (inputElement as HTMLInputElement).focus === 'function') {
          (inputElement as HTMLInputElement).focus();
        }
      }
    }
  }, []);

  const stepTitle = useMemo(() => {
    const menuItem = menuItems.find((currentItem) => currentItem.id === step);
    return menuItem ? menuItem.title : '';
  }, [step]);

  const handleCloseWelcomeHero = () => {
    hideElement(dispatch, 'candidate_cv_form_welcome');
  };

  const handleUpdateFillRate = (formStep: CvFormStep, fillRate: number) => {
    // setFormFillRate((previousFillRate) => {
    //   const previousValue = previousFillRate.get(formStep);
    //   if (previousValue !== fillRate) {
    //     previousFillRate.set(formStep, fillRate);
    //     return new Map(previousFillRate);
    //   }
    //   return previousFillRate;
    // });
  };

  const actualMenuItems: SidebarNavLink[] = useMemo(
    () => (showCvImport ? [cvImportSideBarLink, ...menuItems] : menuItems),
    [showCvImport],
  );

  return (
    <PageNext title={`${stepTitle ? `${stepTitle} - ` : ''}Редактирование резюме`}>
      <Container maxWidth="xl" verticalPadding>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <CvEditFormMenu items={actualMenuItems} currentStep={step} />
          </Grid>
          <Grid item xs={12} md={9}>
            <HideableElement elementId="candidate_cv_form_welcome">
              <HeroView
                title="Надоело отвечать на одни и те же вопросы от рекрутеров?"
                text={
                  <Typography component="p">
                    Резюме Geecko — это подробный профиль ваших навыков и достижений, учитывающий всё: от предпочтений в
                    работе до примеров кода. Чем подробнее вы заполните резюме, тем меньше вопросов останется к вам у
                    компании при первом контакте.
                  </Typography>
                }
                button={
                  <Button size="md" color="secondary" onClick={handleCloseWelcomeHero}>
                    Больше не показывать
                  </Button>
                }
                image={<img src="/images/responsespic.svg" alt="" style={{ width: 400 }} />}
              />
            </HideableElement>
            <Paper withPadding isNew>
              {step === 'import' && <CVImportForm onChangeFillRate={handleUpdateFillRate} />}

              {step === 'personal' && (
                <CVPersonalForm
                  previousStep={getPreviousStep('personal', showCvImport)}
                  nextStep={getNextStep('personal', showCvImport)}
                />
              )}

              {step === 'common' && (
                <CVCommonForm
                  previousStep={getPreviousStep('common', showCvImport)}
                  nextStep={getNextStep('common', showCvImport)}
                />
              )}

              {step === 'tech' && (
                <CVTechForm
                  previousStep={getPreviousStep('tech', showCvImport)}
                  nextStep={getNextStep('tech', showCvImport)}
                />
              )}

              {step === 'community' && (
                <CVCommunityForm
                  previousStep={getPreviousStep('community', showCvImport)}
                  nextStep={getNextStep('community', showCvImport)}
                />
              )}

              {step === 'preferences' && (
                <CVPreferencesForm
                  previousStep={getPreviousStep('preferences', showCvImport)}
                  nextStep={getNextStep('preferences', showCvImport)}
                />
              )}

              {step === 'experience' && (
                <CVExperienceForm
                  previousStep={getPreviousStep('experience', showCvImport)}
                  nextStep={getNextStep('experience', showCvImport)}
                />
              )}

              {step === 'search' && <CVFinishForm />}

              {step === 'education' && (
                <CVEducationForm
                  previousStep={getPreviousStep('education', showCvImport)}
                  nextStep={getNextStep('education', showCvImport)}
                />
              )}
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </PageNext>
  );
};

CandidateEditCvPage.accessGroups = 'candidate' as UserGroup;

CandidateEditCvPage.getInitialProps = async (context) => {
  const { query, store } = context;

  const showCvImportStep = authCandidateCvShowImportSelector(store.getState());
  const defaultFirstStep = showCvImportStep ? 'import' : 'personal';

  if (
    query.step &&
    (typeof query.step !== 'string' ||
      (getCvFormSteps(showCvImportStep).indexOf(query.step as CvFormStep) < 0 && query.step !== 'search'))
  ) {
    showNotFoundError();
  }

  const step = (query.step as CvFormStep) || defaultFirstStep;

  if (!showCvImportStep && step === 'import') {
    serverRedirect('/my/cv/edit');
  }

  store.dispatch(fetchCandidateCvForEditAction.request());

  // Запрашиваем только те словари, которые нужны для конкретных шагов
  if (step === 'tech' || step === 'experience') {
    store.dispatch(fetchSpecializationsAction.request());
  }
  if (step === 'common') {
    store.dispatch(fetchLanguagesAction.request());
  }
  if (step === 'preferences') {
    store.dispatch(fetchWorkFeaturesAction.request());
    store.dispatch(fetchCompanyScopesAction.request());
  }

  return { step };
};

export default CandidateEditCvPage;
