import React, { CSSProperties, FC } from 'react';
import './Text.css';
import classNames from 'classnames';

export type TextVariant = 'spec' | 'h0' | 'h0-thin' | 'h1' | 'h2' | 'h3' | 'h4' | 'lg' | 'md' | 'sm';

interface Props {
  variant?: TextVariant;
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span' | 'div';
  color?: string;
  bold?: boolean;
  isLoading?: boolean;
}

const Text: FC<Props> = ({
  variant = 'md', tag = 'div', color, bold, isLoading, children,
}) => {
  const Component = tag;
  const cssProps: CSSProperties = color ? { color } : {};
  return (
    <Component
      className={classNames(
        'geecko-text',
        `geecko-text--variant-${variant}`,
        bold ? ` geecko-text--variant-${variant}-bold` : '',
        isLoading ? ` geecko-text--is-loading` : '',
      )}
      style={cssProps}
    >
      {children}
    </Component>
  );
};

export default Text;
