import { createAsyncAction } from 'typesafe-actions';
import { RejectReason } from 'models/Dictionary/RejectReason';
import { SkillsTemplate } from 'models/Dictionary/SkillsTemplate';
import { Specialization } from 'models/Specialization';
import { Language, CommonEntity } from 'models/BaseTypes';
import { CompanyUser } from '../../../models/CompanyUser';
import { CompanyOffice } from '../../../models/CompanyOffice';

/**
 * Экшн получения причин отказов компаниям
 */
export const fetchVacancyRejectReasonsAction = createAsyncAction(
  'dictionary/VACANCY_REJECT_REASONS_REQUEST',
  'dictionary/VACANCY_REJECT_REASONS_SUCCESS',
  'dictionary/VACANCY_REJECT_REASONS_FAILURE'
)<undefined, RejectReason[], Error>();

/**
 * Экшн получения причин отказов кандидатам
 */
export const fetchCandidateRejectReasonsAction = createAsyncAction(
  'dictionary/CANDIDATE_REJECT_REASONS_REQUEST',
  'dictionary/CANDIDATE_REJECT_REASONS_SUCCESS',
  'dictionary/CANDIDATE_REJECT_REASONS_FAILURE'
)<undefined, RejectReason[], Error>();

/**
 * Экшн получения специализаций
 */
export const fetchSpecializationsAction = createAsyncAction(
  'dictionary/SPECIALIZATIONS_REQUEST',
  'dictionary/SPECIALIZATIONS_SUCCESS',
  'dictionary/SPECIALIZATIONS_FAILURE'
)<undefined, Specialization[], Error>();

/**
 * Экшн получения преимуществ компаний
 */
export const fetchWorkFeaturesAction = createAsyncAction(
  'dictionary/WORK_FEATURES_REQUEST',
  'dictionary/WORK_FEATURES_SUCCESS',
  'dictionary/WORK_FEATURES_FAILURE'
)<undefined, CommonEntity[], Error>();

/**
 * Экшн получения предметных областей
 */
export const fetchCompanyScopesAction = createAsyncAction(
  'dictionary/COMPANY_SCOPES_REQUEST',
  'dictionary/COMPANY_SCOPES_SUCCESS',
  'dictionary/COMPANY_SCOPES_FAILURE'
)<undefined, CommonEntity[], Error>();

/**
 * Экшн получения иностранных языков
 */
export const fetchLanguagesAction = createAsyncAction(
  'dictionary/LANGUAGES_REQUEST',
  'dictionary/LANGUAGES_SUCCESS',
  'dictionary/LANGUAGES_FAILURE'
)<undefined, Language[], Error>();


/**
 * Экшн получения тестовых шаблонов SKills
 */
export const fetchSkillsTemplatesAction = createAsyncAction(
  'dictionary/SKILLS_TEMPLATES_REQUEST',
  'dictionary/SKILLS_TEMPLATES_SUCCESS',
  'dictionary/SKILLS_TEMPLATES_FAILURE'
)<undefined, SkillsTemplate[], Error>();

/**
 * Экшн получения пользователей компании
 */
export const fetchCompanyUsersAction = createAsyncAction(
  'dictionary/COMPANY_USERS_REQUEST',
  'dictionary/COMPANY_USERS_SUCCESS',
  'dictionary/COMPANY_USERS_FAILURE'
)<undefined, CompanyUser[], Error>();

/**
 * Экшн получения офисов компании
 */
export const fetchCompanyOfficesAction = createAsyncAction(
  'dictionary/COMPANY_OFFICES_REQUEST',
  'dictionary/COMPANY_OFFICES_SUCCESS',
  'dictionary/COMPANY_OFFICES_FAILURE'
)<undefined, CompanyOffice[], Error>();
