import React, { FC, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { redirect } from 'utils/redirect';
import BigTagSelect, { BigTagSelectOption } from 'components/BigTagSelect/BigTagSelect';
import TargetIcon from 'components/Icons/TargetIcon';
import MagnifyIcon from 'components/Icons/MagnifyIcon';
import CancelCircle from 'components/Icons/CancelCircle/CancelCircle';
import Label from 'components/Form/Label/Label';
import Text from 'components/v2/Text/Text';
import Button from 'components/v2/Button/Button';
import { authCandidateSearchStatusSelector } from '../../store/ducks/auth/selectors';
import { showNotifyNew } from '../../models/Notify';
import { useApiInstance } from '../../api';
import { CandidateSearchType } from '../../models/Candidate';

const searchTypeOptions: BigTagSelectOption[] = [
  {
    value: 'active',
    icon: (
      <div style={{ color: '#5CB589' }}>
        <TargetIcon size={18} />
      </div>
    ),
    title: 'Активный поиск',
    description: 'Компании видят, что вы ищите работу и вы видите вакансии компаний.',
  },
  {
    value: 'passive',
    icon: (
      <div style={{ color: '#646D7B' }}>
        <MagnifyIcon size={18} />
      </div>
    ),
    title: 'Пассивный поиск',
    description: 'Компании не видят ваше резюме, но вы видите вакансии компаний.',
  },
  {
    value: 'off',
    icon: (
      <div style={{ color: '#646D7B' }}>
        <CancelCircle size={18} />
      </div>
    ),
    title: 'Не ищу работу',
    description: 'Вы не видите вакансии и компании не видят ваше резюме.',
  },
];

const CVFinishForm: FC = () => {
  const initialSearchStatus = useSelector(authCandidateSearchStatusSelector);
  const [searchType, setSearchType] = useState<CandidateSearchType | undefined>(initialSearchStatus || 'active');
  const [submitting, setSubmitting] = useState(false);
  const api = useApiInstance();
  const dispatch = useDispatch();
  const handleChangeSearchType = (newValue: string) => {
    setSearchType(newValue as CandidateSearchType);
  };
  const handleSubmit = async () => {
    setSubmitting(true);
    try {
      await api.post('/candidate/set-statuses', {
        search_status: searchType,
        is_anonymous: false,
      });
      setSubmitting(false);
      showNotifyNew(dispatch, {
        type: 'success',
        content: 'Настройки сохранены. Перенаправляем на поиск...',
      });
      redirect('/personal-offer');
    } catch (error) {
      setSubmitting(false);
      showNotifyNew(dispatch, {
        type: 'error',
        content: 'Произошла ошибка',
      });
    }
  };
  return (
    <div className="geecko-paper__wrapper">
      <div className="">
        <Text variant="h1" tag="h1">
          Настройки поиска
        </Text>
      </div>

      <div className="geecko-form__item">
        <Label>Статус поиска работы</Label>
        <BigTagSelect options={searchTypeOptions} value={searchType} onChange={handleChangeSearchType} />
      </div>

      <div className="geecko-form__buttons-line geecko-form__buttons-line--margin-lg">
        <div className="geecko-form__buttons-line__button">
          <Button
            size="lg"
            color="primary"
            type="submit"
            onClick={handleSubmit}
            disabled={!searchType}
            isLoading={submitting}
          >
            Сохранить
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CVFinishForm;
