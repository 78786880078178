import { ServerApi } from 'modules/api/server-api';
import { decodeResumeState, ResumeState } from 'models/Resume';

export const fetchResume = (api: ServerApi) => async (
  resumeId: string,
  vacancyId?: number,
  token?: string,
  companyPreview?: boolean,
) => {
  const response = await api.get(
    `/resume/${resumeId}?${token ? `&token=${token}` : ''}${companyPreview ? '&view-as-company' : ''}${
      vacancyId ? `&vacancy_id=${vacancyId}` : ''
    }`,
  );
  return decodeResumeState(response.data) as ResumeState;
};
