import React, { FC } from 'react';
import StickyBox from 'react-sticky-box';
import { useSelector } from 'react-redux';
import { candidateCvEditFillRateSelector } from 'store/ducks/candidate/selectors';
import { CvFormStep, usualCVFormSteps } from '../../../pages/Candidate/Cv/CandidateEditCvPage/CandidateEditCvPage';
import SidebarNav, { SidebarNavLink } from '../SidebarNav/SidebarNav';
import SidebarNavItem from '../SidebarNav/SidebarNavItem';
import {
  authCandidateCvFormStepSelector,
  authCandidateCvShowImportSelector,
  authCandidateHasCvSelector,
} from '../../store/ducks/auth/selectors';

interface Props {
  items: SidebarNavLink[];
  currentStep: CvFormStep;
}

const CvEditFormMenu: FC<Props> = ({ items, currentStep }) => {
  const formStep = useSelector(authCandidateCvFormStepSelector);
  const showCvImport = useSelector(authCandidateCvShowImportSelector);
  const candidateHasCv = useSelector(authCandidateHasCvSelector);
  const fillRate = useSelector(candidateCvEditFillRateSelector);
  let formStepIndex = formStep ? usualCVFormSteps.indexOf(formStep) : -1;
  formStepIndex += 1;
  if (showCvImport) {
    formStepIndex += 1;
  }
  return (
    <StickyBox offsetTop={80} offsetBottom={15}>
      <SidebarNav>
        {items.map((item, index) => (
          <SidebarNavItem
            key={item.id}
            link={item.link}
            title={item.title}
            subtitle={item.subtitle}
            active={item.id === currentStep}
            progress={fillRate && typeof fillRate[item.id] !== 'undefined' ? fillRate[item.id] : 0}
            disabled={formStepIndex < index && !candidateHasCv}
            showProgress
          />
        ))}
      </SidebarNav>
    </StickyBox>
  );
};

export default CvEditFormMenu;
