import React, { ReactNode } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import SelectField, { Option } from 'components/Form/SelectField/SelectField';
import Switch from 'components/Switch/Switch';
import SegmentedControl from 'components/SegmentedControl/SegmentedControl';
import TagField from 'components/Form/TagField/TagField';
import Checkbox from 'components/Checkbox/Checkbox';
import isValid from 'date-fns/isValid';
import parse from 'date-fns/parse';
import PlainCheckbox from '../PlainCheckbox/PlainCheckbox';

interface SelectFieldAdapterProps extends HTMLElement {
  options: Option[];
  placeholder?: string;
}

export const SelectFieldAdapter = (
  props: FieldRenderProps<string, SelectFieldAdapterProps>
    & { options?: any; placeholder?: string },
) => {
  const {
    input,
    meta,
    options,
    placeholder,
    ...rest
  } = props;
  return (
    <SelectField
      options={options}
      placeholder={placeholder}
      validationError={meta.touched ? meta.error : ''}
      submitError={meta.touched && !meta.dirtySinceLastSubmit && !meta.submitting ? meta.submitError : ''}
      {...input}
      {...rest}
    />
  );
};

interface SegmentedControlAdapterProps extends HTMLElement {
  options: Option[];
  color?: 'default' | 'primary';
}

export const SegmentedControlAdapter = (
  props: FieldRenderProps<string, SegmentedControlAdapterProps>
    & { options?: any; color?: any },
) => {
  const {
    input,
    meta,
    options,
    ...rest
  } = props;
  return (
    <SegmentedControl
      options={options}
      {...input}
      {...rest}
    />
  );
};

interface TagFieldAdapterProps extends HTMLElement {
  options: Option[];
  maxValues?: number;
}

export const TagFieldAdapter = (
  props: FieldRenderProps<string[], TagFieldAdapterProps>
    & { options?: any; maxValues?: number },
) => {
  const {
    input,
    meta,
    options,
    maxValues,
    ...rest
  } = props;
  return (
    <TagField
      options={options}
      maxValues={maxValues}
      validationError={meta.touched ? meta.error : ''}
      submitError={meta.touched && !meta.dirtySinceLastSubmit && !meta.submitting ? meta.submitError : ''}
      {...input}
      {...rest}
      value={input.value || []}
    />
  );
};

interface CheckboxAdapterProps extends HTMLInputElement {
  label?: string;
  labelChecked?: string;
  icon?: ReactNode;
  iconChecked?: ReactNode;
  overflow?: boolean;
}

export const CheckboxAdapter = (
  props: FieldRenderProps<boolean, CheckboxAdapterProps> & {
    label?: string;
    labelChecked?: string;
    icon?: ReactNode;
    iconChecked?: ReactNode;
    overflow?: boolean;
  },
) => {
  const {
    label, labelChecked, icon, iconChecked, input, meta, overflow, ...rest
  } = props;
  return (
    <Checkbox
      {...input}
      {...rest}
      validationError={meta.touched ? meta.error : ''}
      submitError={meta.touched && !meta.dirtySinceLastSubmit && !meta.submitting ? meta.submitError : ''}
      label={label}
      labelChecked={labelChecked}
      icon={icon}
      iconChecked={iconChecked}
      overflow={overflow}
    />
  );
};

export const PlainCheckboxAdapter = (
  props: FieldRenderProps<boolean> & {
    content?: ReactNode;
    overflow?: boolean;
  },
) => {
  const {
    content, input: { value, ...inputRest }, meta, overflow, ...rest
  } = props;
  return (
    <PlainCheckbox {...inputRest} {...rest}>
      {content}
    </PlainCheckbox>
  );
};

interface SwitchAdapterProps extends HTMLInputElement {
  label?: string;
}

export const SwitchAdapter = (
  props: FieldRenderProps<boolean, SwitchAdapterProps> & { label?: string },
) => {
  const {
    label, input, meta, ...rest
  } = props;
  return (
    <Switch
      {...input}
      {...rest}
      validationError={meta.touched ? meta.error : ''}
      submitError={meta.touched && !meta.dirtySinceLastSubmit && !meta.submitting ? meta.submitError : ''}
      label={label}
    />
  );
};

// validate functions, start with 'Check'

export const CheckRequired = (value: any) => {
  let check = value;
  if (Array.isArray(value)) check = value.filter(el => el != null).length;
  return check ? undefined : <FormattedMessage id="app.form.required" />;
};

export const CheckEmail = (value: any) => {
  const emailRegexp = /^[^@]+@[^@]+\.[^@]+$/;
  return emailRegexp.test(String(value).toLowerCase()) ? undefined : <FormattedMessage id="app.form.email" />;
};

export const validateBirthDate = (value: any) => {
  try {
    const birthDayDate = parse(value, 'dd.LL.yyyy', new Date());
    if (isValid(birthDayDate)) {
      return undefined;
    }
  } catch (e) {
    //
  }
  return <FormattedMessage id="app.form.error.birth" />;
};

export const validateYear = (value: any) => {
  if (value) {
    if (/^\d{4}$/.test(value.toString())) {
      return undefined;
    }
  }

  return <FormattedMessage id="app.form.error.correctyear" />;
};

export const CheckMinLength = (min: number) => (value: string) => (
  !value.length || value.length >= min
    ? undefined
    : <FormattedMessage id="app.form.shouldbe.gte" values={{ min }} />
);

export const CheckPasswordEquals = (argument?: string) => (value: string) => (value === argument ? undefined : <FormattedMessage id="app.form.shouldbe.passwordequals" />);

export const composeValidators = (...validators: any) => (value: any) => validators
  .reduce((error: string, validator: any) => error || validator(value), undefined);

// function maps errors to final form fields
export const MapServerErrors = (errors: any) => {
  const result = { ...errors };
  for (const property in result) {
    if (Object.prototype.hasOwnProperty.call(result, property)) {
      result[property] = result[property].join(' ');
    }
  }
  for (const property in result) {
    if (property.includes('.')) {
      let currentObj = result;
      const keys = property.split('.');
      let i; const l = Math.max(1, keys.length - 1);
      let key;

      for (i = 0; i < l; i += 1) {
        key = keys[i];
        currentObj[key] = currentObj[key] || {};
        currentObj = currentObj[key];
      }

      currentObj[keys[i]] = result[property];
      delete result[property];
    }
  }
  return result;
};

export const formFormatUpperCase = (value: any) => {
  if (typeof value === 'string') {
    return value.toUpperCase();
  }

  return value;
};
