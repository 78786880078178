import React, { FC, ReactNode } from 'react';
import './HeroView.css';
import Paper from '../Paper/Paper';
import Grid from '../Grid/Grid';
import Typography from '../Typography/Typography';

interface Props {
  title: string;
  text?: ReactNode;
  button?: ReactNode;
  image?: ReactNode;
}

const HeroView: FC<Props> = ({
  title, text, button, image,
}) => (
  <div className="geecko-hero__wrapper">
    <Paper transparent>
      <div className="geecko-paper__wrapper" style={{ paddingBottom: 30 }}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={6}>
            <Typography variant="h2" component="div">
              {title}
            </Typography>
            {text && (
              <div className="geecko-hero__item">
                {text}
              </div>
            )}
            {button && (
              <div className="geecko-hero__item">
                {button}
              </div>
            )}
          </Grid>
          <Grid item xs={12} md={6} hide={['xs', 'sm']}>
            {image && (
              <div className="geecko-hero__image-wrapper">
                {image}
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    </Paper>
  </div>
);

export default HeroView;
