import { User } from 'models/User';
import { Manager } from 'models/Manager';
import { AuthCandidate } from 'models/Candidate';
import { ResumeStatus } from 'models/Candidate/UserProfile';
import { AuthCompany } from 'models/Company/AuthCompany';

export interface AuthState {
  loading?: boolean;
  token?: string;
  user?: User;
  manager?: Manager;
  company?: AuthCompany;
  candidate?: AuthCandidate;
  candidateCvImport?: CandidateCvImport;
  resumeStatus?: ResumeStatus;
}

export const initialAuthState: AuthState = {
  token: '',
};

export interface CandidateCvImport {
  status?: CandidateCvImportProgressStatus;
  task?: CandidateCvImportTask;
}

type CandidateCvImportProgressStatus = null | 'queued' | 'in-progress' | 'finished' | 'failed';

export interface CandidateCvImportTask {
  id: number;
  link: string;
}