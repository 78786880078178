/* eslint-disable @typescript-eslint/ban-ts-ignore */
import React, { ReactNode, ChangeEvent, MouseEvent, KeyboardEvent, useRef, useState, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { nanoid } from 'nanoid';
import { useDebounce } from 'hooks/use-debounce';
import './MultiSelect.css';
import RemoveIcon from 'components/Icons/RemoveIcon/RemoveIcon';

export type AsyncSelectLoadOptionsHandler<ValueType> = (searchQuery: string) => Promise<ValueType[]>;

export type AsyncSelectProps<T> = MultiSelectProps<T>;

export interface MultiSelectProps<ValueType> {
  size?: 'medium' | 'big';
  loadOptions?: AsyncSelectLoadOptionsHandler<ValueType>;
  value?: ValueType[];
  options?: readonly ValueType[];
  name?: string;
  onChange: (value?: ValueType[]) => void;
  renderOption?: (option: ValueType) => ReactNode | null;
  loadOnInit?: boolean;
  placeholder?: string;
  hideIfEmpty?: boolean;
  autoFocus?: boolean;
  isInvalid?: boolean;
  valueKey: keyof ValueType;
  labelKey: keyof ValueType;
  imageKey?: keyof ValueType;
  noOptionsPlaceholder?: string | ReactNode;
  creatable?: boolean;
  isDisabled?: boolean;
  async?: boolean;
  maxValues?: number;
  isValidNewOption?: (value: string) => boolean;
}

function MultiSelect<ValueType extends {}>(props: MultiSelectProps<ValueType>) {
  const {
    value,
    name,
    loadOptions,
    loadOnInit,
    renderOption,
    onChange,
    placeholder,
    noOptionsPlaceholder,
    hideIfEmpty,
    valueKey,
    labelKey,
    imageKey,
    creatable,
    isDisabled,
    async,
    options,
    isInvalid,
    maxValues,
    isValidNewOption,
  } = props;
  const intl = useIntl();
  if (async && !loadOptions) {
    throw new Error(intl.formatMessage({ id: 'app.select.async.required' }));
  }

  const lastRequestId = useRef(nanoid());
  const inputRef = useRef<HTMLInputElement>(null);
  const [asyncOptions, setOptions] = useState<ValueType[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [inputQuery, setInputQuery] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const valueFormatted = value || [];

  const selectOptions = useMemo<ValueType[]>(() => {
    if (async) {
      return asyncOptions;
    }
    return (options || []).filter((item) => {
      if (!inputQuery) {
        return true;
      }
      if (typeof item[labelKey] === 'string') {
        // @ts-ignore
        return item[labelKey].includes(inputQuery);
      }
      return true;
    });
  }, [async, asyncOptions, options, inputQuery]);

  const uniqueSelectOptions = useMemo(() => {
    const selectedValues = valueFormatted.map((item) => item[valueKey]);
    return (selectOptions || []).filter((item) => selectedValues.indexOf(item[valueKey]) < 0);
  }, [value, selectOptions]);

  const optionsLabelNames = useMemo<string[]>(() => {
    // @ts-ignore
    return uniqueSelectOptions.map((option) => option[labelKey] as string);
  }, [labelKey, uniqueSelectOptions]);

  useEffect(() => {
    if (async && loadOnInit && loadOptions) {
      loadOptions('')
        .then((newOptions) => {
          setOptions(newOptions);
        })
        .catch(() => {
          //
        });
    }
  }, []);

  const handleFocus = () => {
    setIsOpen(true);
    handleLoadOptions();
  };
  const handleBlur = () => {
    setIsOpen(false);
  };

  const handleSelect = (option: ValueType) => {
    // @ts-ignore
    if (option.disabled) {
      return;
    }
    // @ts-ignore
    const newValues = [...valueFormatted, option];
    onChange(newValues);
    if (async) {
      setOptions([]);
    }
    setSelectedIndex(-1);
    setInputQuery('');
    handleLoadOptions('');

    if (!maxValues || newValues.length < maxValues) {
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 150);
    } else if (inputRef.current) {
      inputRef.current.blur();
    }
  };

  const handleChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setInputQuery(inputValue);
  };

  const handleValueButtonClick = () => {
    setInputQuery('');
    setIsOpen(true);
    setTimeout(() => {
      if (inputRef && inputRef.current) {
        inputRef.current.focus();
      }
    });
  };

  useEffect(() => {
    if (isOpen) {
      handleLoadOptions();
    }
  }, [isOpen]);

  const handleLoadOptions = (query?: string) => {
    if (!loadOnInit && !isOpen) {
      return;
    }
    if (!loadOptions) {
      return;
    }
    const currentRequestId = nanoid();
    lastRequestId.current = currentRequestId;
    setIsLoading(true);
    loadOptions(typeof query === 'string' ? query : inputQuery)
      .then((newOptions) => {
        if (lastRequestId.current === currentRequestId) {
          setIsLoading(false);
          setOptions(newOptions);
          if (!creatable) {
            setSelectedIndex(newOptions.length > 0 ? 0 : -1);
          } else {
            setSelectedIndex(0);
          }
        }
      })
      .catch(() => {
        if (lastRequestId.current === currentRequestId) {
          setIsLoading(true);
        }
      });
  };

  const debouncedQuery = useDebounce(inputQuery, 200);
  useEffect(() => {
    handleLoadOptions();
  }, [debouncedQuery]);

  const handleSelectOption = (option: ValueType) => {
    handleSelect(option);
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Backspace') {
      if (inputQuery.length === 0 && valueFormatted.length > 0) {
        onChange([...valueFormatted].slice(0, valueFormatted.length - 1));
      }
    }

    if (event.key === 'Enter') {
      event.preventDefault();
      if (creatable && selectedIndex === uniqueSelectOptions.length) {
        handleCreateOption();
      } else if (selectedIndex >= 0 && selectedIndex < uniqueSelectOptions.length) {
        const option = uniqueSelectOptions[selectedIndex];
        // @ts-ignore
        if (!option.disabled) {
          handleSelect(option);
        }
      }
      return;
    }
    if (event.key === 'ArrowDown') {
      event.preventDefault();
      const indexFix = creatable ? 0 : 1;
      if (selectedIndex < uniqueSelectOptions.length - indexFix) {
        setSelectedIndex(selectedIndex + 1);
      }

      return;
    }
    if (event.key === 'ArrowUp') {
      event.preventDefault();
      if (selectedIndex > 0) {
        setSelectedIndex(selectedIndex - 1);
      }
    }
  };

  const handleCreateOption = () => {
    handleSelectOption({
      [valueKey]: inputQuery,
      [labelKey]: inputQuery,
    } as ValueType);
  };

  const handleRemoveItem = (selectedItem: ValueType) => {
    // @ts-ignore
    onChange([...valueFormatted].filter((item) => item[valueKey] !== selectedItem[valueKey]));
  };

  const handleClickContainer = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const isSelectAvailable = !maxValues || valueFormatted.length < maxValues;

  return (
    <div
      className={classNames(
        'geecko-multi-select',
        isOpen ? 'geecko-multi-select--open' : '',
        isDisabled ? 'geecko-multi-select--disabled' : '',
        isInvalid ? 'geecko-multi-select--invalid' : '',
      )}
      onClick={handleClickContainer}
    >
      {value &&
        value.map((valueItem) => (
          <MultiSelectValueItem
            // @ts-ignore
            key={valueItem[valueKey] || valueItem[labelKey]}
            item={valueItem}
            imageKey={imageKey}
            labelKey={labelKey}
            valueKey={valueKey}
            onRemove={handleRemoveItem}
            onClick={handleClickContainer}
          />
        ))}
      {isSelectAvailable && (
        <div className="geecko-multi-select__query-input-wrapper">
          <input
            ref={inputRef}
            type="text"
            name={name}
            size={valueFormatted.length > 0 ? inputQuery.length || 1 : undefined}
            className="geecko-multi-select__query-input"
            value={inputQuery}
            disabled={isDisabled}
            placeholder={valueFormatted.length === 0 ? placeholder : '...'}
            onKeyDown={handleKeyPress}
            onKeyPress={handleKeyPress}
            onChange={handleChangeInput}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        </div>
      )}
      {isSelectAvailable && isOpen && (!hideIfEmpty || inputQuery) && (
        <div className="geecko-multi-select__options">
          {uniqueSelectOptions.length > 0 && (
            <>
              {uniqueSelectOptions.map((option, optionIndex) => (
                <AsyncSelectOptionBlock<ValueType>
                  // @ts-ignore
                  key={option[valueKey]}
                  valueKey={valueKey}
                  labelKey={labelKey}
                  imageKey={imageKey}
                  option={option}
                  isDisabled={(option as any).disabled}
                  isSelected={optionIndex === selectedIndex}
                  renderOption={renderOption}
                  onSelect={handleSelectOption}
                />
              ))}
            </>
          )}
          {!creatable && uniqueSelectOptions.length === 0 && !isLoading && inputQuery && (
            <div className="geecko-multi-select__options-placeholder">
              {intl.formatMessage({ id: 'app.select.async.noresult' })}
            </div>
          )}
          {!creatable && uniqueSelectOptions.length === 0 && !isLoading && !inputQuery && noOptionsPlaceholder && (
            <div className="geecko-multi-select__options-placeholder">{noOptionsPlaceholder}</div>
          )}
          {creatable &&
            inputQuery &&
            optionsLabelNames.indexOf(inputQuery) < 0 &&
            (!isValidNewOption || isValidNewOption(inputQuery)) && (
              <button
                type="button"
                className={`geecko-multi-select__option${
                  selectedIndex === uniqueSelectOptions.length ? ' geecko-multi-select__option--selected' : ''
                }`}
                onMouseDown={handleCreateOption}
              >
                {intl.formatMessage({ id: 'app.add' })}{' '}
                <span className="geecko-multi-select__create-option">{inputQuery}</span>
              </button>
            )}
          {uniqueSelectOptions.length === 0 && isLoading && (
            <div className="geecko-multi-select__options-placeholder">{intl.formatMessage({ id: 'app.loading' })}</div>
          )}
        </div>
      )}
    </div>
  );
}

interface ValueItemProps<ValueType> {
  item: ValueType;
  valueKey: keyof ValueType;
  labelKey: keyof ValueType;
  imageKey?: keyof ValueType;
  onClick: () => void;
  onRemove: (value: ValueType) => void;
}

function MultiSelectValueItem<ValueType>(props: ValueItemProps<ValueType>) {
  const { item, labelKey, imageKey, onClick, onRemove } = props;

  const handleClick = () => {
    onClick();
  };

  const handleRemove = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onRemove(item);
  };

  return (
    <div className="geecko-multi-select__value-item" onClick={handleClick}>
      {imageKey && item[imageKey] && (
        <div className="geecko-multi-select__value-item-image">
          <img
            // @ts-ignore
            src={item[imageKey]}
            alt=""
          />
        </div>
      )}
      <div>{item[labelKey]}</div>
      <button type="button" className="geecko-multi-select__value-item-remove" onClick={handleRemove}>
        <RemoveIcon />
      </button>
    </div>
  );
}

interface OptionProps<ValueType> {
  option: ValueType;
  valueKey: keyof ValueType;
  labelKey: keyof ValueType;
  imageKey?: keyof ValueType;
  isSelected?: boolean;
  isDisabled?: boolean;
  isCurrent?: boolean;
  renderOption?: (option: ValueType) => ReactNode | null;
  onSelect: (option: ValueType) => void;
}

function AsyncSelectOptionBlock<ValueType>(props: OptionProps<ValueType>) {
  const { option, renderOption, isSelected, isDisabled, isCurrent, onSelect, labelKey, imageKey } = props;

  const handleMouseDown = (event: MouseEvent<HTMLButtonElement>) => {
    // @ts-ignore
    if (option.disabled) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      onSelect(option);
    }
  };

  return (
    <button
      type="button"
      className={classNames(
        'geecko-multi-select__option',
        { 'geecko-multi-select__option--selected': isSelected },
        { 'geecko-multi-select__option--current': isCurrent },
      )}
      disabled={isDisabled}
      onMouseDown={handleMouseDown}
    >
      {renderOption && renderOption(option)}
      {!renderOption && (
        <>
          {imageKey && option[imageKey] && (
            <div className="geecko-multi-select__option-image">
              <img
                // @ts-ignore
                src={option[imageKey]}
                alt=""
              />
            </div>
          )}
          <div>{option[labelKey]}</div>
        </>
      )}
    </button>
  );
}

export default MultiSelect;
