import React, { createContext, FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { authTokenSelector } from 'store/ducks/auth/selectors';
import { GeeckoApi } from './geecko-api';

export const ApiContext = createContext<GeeckoApi>(new GeeckoApi());

const ApiProvider: FC = ({ children }) => {
  const token = useSelector(authTokenSelector);
  const api = useMemo(() => (new GeeckoApi(token)), [token]);
  return (
    <ApiContext.Provider value={api}>
      {children}
    </ApiContext.Provider>
  );
};

export default ApiProvider;
