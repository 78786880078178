import { Option } from 'components/Form/SelectField/SelectField';

export type Level = 'junior' | 'middle' | 'senior' | 'lead';

export type WorkType = 'remote' | 'onplace' | 'prefer-remote' | 'prefer-onplace';

export type IdOrAdd = number | 'add' | '';

export type Currency = 'RUB' | 'USD' | 'EUR' | 'GBR';

export interface IFocusableElement {
  focus(): void;
}

export type SalaryPeriod = 'monthly' | 'annually';

export type AnyObject = {
  [key: string]: any;
}

export const monthsToYears = (months: string): string => (
  months
    .split('-')
    .map((monthsCount: string) => Math.round(parseInt(monthsCount, 10) / 12))
    .join('-')
);

export interface CommonEntity<IdType = number> {
  id: IdType;
  name: string;
}

export type LanguageLevel = 'beginner' | 'intermediate' | 'upper-intermediate' | 'advanced' | 'native';

export interface Language {
  id: number;
  name: string;
  level?: string;
}

export const languageLevelOptions: Option[] = [
  { label: 'Beginner', value: 'beginner' },
  { label: 'Intermediate', value: 'intermediate' },
  { label: 'Upper-Intermediate', value: 'upper-intermediate' },
  { label: 'Advanced', value: 'advanced' },
  { label: 'Native', value: 'native' },
];
