import React, { FC } from 'react';

interface Props {
  size?: number;
}

const HabrIcon: FC<Props> = ({ size = 18 }) => (
  <svg width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.32943 7.25348C7.95017 6.37081 8.94051 5.80458 10.0576 5.80458L10.3117 5.81424L10.3005 5.81374C12.3836 5.81374 14 7.33386 14 9.85367V17H10.6711V11.0803C10.6711 9.79364 10.0323 9.08038 9.02243 9.08038C8.09596 9.08038 7.32895 9.74684 7.32895 11.1469V17H4V1H7.32895L7.32943 7.25348Z" fill="currentColor" />
  </svg>
);

export default HabrIcon;
