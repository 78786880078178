import React, { HTMLProps } from 'react';
import './Container.css';

interface Props extends HTMLProps<HTMLDivElement> {
  maxWidth?: 'xs' | 'sm' | 'md' | 'xl' | 'lg' | undefined;
  verticalPadding?: boolean;
}

const Container: React.FC<Props> = (props: Props) => {
  const {
    maxWidth = 'lg', children, verticalPadding, style,
  } = props;
  return (
    <>
      <div
        className={[
          'geecko-container',
          `geecko-container--max-width-${maxWidth}`,
          verticalPadding ? 'geecko-container--vertical-padding' : '',
        ].join(' ')}
        style={style}
      >
        {children}
      </div>
    </>
  );
};

export default Container;
