import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { addHiddenElement, isElementHiddenSelector } from '../../store/ducks/settings';
import { AppState } from '../../store';
import { setHttpCookie } from '../../models/helpers';

export const HIDEABLE_COOKIE_PREFIX = 'geecko:vis:';

interface Props {
  elementId: string;
}

export const hideElement = (dispatch: Dispatch, key: string) => {
  setHttpCookie(`${HIDEABLE_COOKIE_PREFIX}${key}`, '1');
  dispatch(addHiddenElement(key));
};

const HideableElement: FC<Props> = ({ elementId, children }) => {
  const isHidden = useSelector((state: AppState) => isElementHiddenSelector(state, elementId));
  if (isHidden) {
    return null;
  }

  return (
    <>
      {children}
    </>
  );
};

export default HideableElement;
