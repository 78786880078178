import React, { FC, ReactNode } from 'react';
import { useIntl } from 'react-intl';
import './CVExperienceStack.css';
import { Immutable } from 'immer';
import { CvCandidateSpecialization, CvSkills } from '../../models/CV/CvFormValues';
import { SkillNew } from '../../models/Skill';

type Props = Immutable<{
  specializations: CvCandidateSpecialization[];
  skills: CvSkills;
}>;

const renderBlock = (title: string, skills: Immutable<SkillNew[]>): ReactNode => {
  if (skills.length === 0) {
    return null;
  }
  return (
    <div className="geecko-experience-stack__type">
      <div className="geecko-experience-stack__type-title">{title}</div>
      <div className="geecko-experience-stack__items">
        {skills.map((item) => (
          <div key={item.id} className="geecko-experience-stack__item">
            {item.image && <img src={item.image} className="geecko-experience-stack__item-image" alt="" />}
            {item.name}
          </div>
        ))}
      </div>
    </div>
  );
};

const CVExperienceStack: FC<Props> = ({ specializations, skills }) => {
  const intl = useIntl();
  return (
    <div>
      {specializations.length > 0 && (
        <div className="geecko-experience-stack__type">
          <div className="geecko-experience-stack__type-title">
            {intl.formatMessage({ id: 'app.vacancy.specialization' })}
          </div>
          <div className="geecko-experience-stack__items">
            {specializations.map((spec) => (
              <div key={spec.id} className="geecko-experience-stack__item">
                {spec.name}
              </div>
            ))}
          </div>
        </div>
      )}
      {renderBlock(intl.formatMessage({ id: 'app.languages' }), skills.lang)}
      {renderBlock(intl.formatMessage({ id: 'app.technology' }), skills.tech)}
      {renderBlock(intl.formatMessage({ id: 'app.databases' }), skills.db)}
    </div>
  );
};

export default CVExperienceStack;
