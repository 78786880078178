import React, { FC } from 'react';

interface Props {
  size?: number;
}

const GitHubIcon: FC<Props> = ({ size = 18 }) => (
  <svg width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 0C4.02979 0 0 4.02979 0 9C0 12.9771 2.5785 16.3499 6.15509 17.5397C6.60498 17.6221 6.76923 17.3441 6.76923 17.1068C6.76923 16.8926 6.76099 16.1829 6.75659 15.4314C4.25391 15.9752 3.72491 14.3701 3.72491 14.3701C3.31513 13.3308 2.7257 13.054 2.7257 13.054C1.90777 12.4958 2.78775 12.5068 2.78775 12.5068C3.69137 12.5694 4.16711 13.4341 4.16711 13.4341C4.97021 14.8096 6.27428 14.4119 6.78625 14.1812C6.86805 13.6011 7.10097 13.2034 7.35754 12.9792C5.35968 12.7518 3.258 11.9795 3.258 8.53088C3.258 7.54759 3.60902 6.74561 4.18359 6.11554C4.09185 5.88702 3.78204 4.9713 4.27257 3.73261C4.27257 3.73261 5.02734 3.49091 6.74779 4.65546C7.46518 4.45496 8.23479 4.35609 8.99998 4.35278C9.76463 4.35609 10.5348 4.45605 11.2533 4.65655C12.9705 3.49091 13.7263 3.7337 13.7263 3.7337C14.2185 4.9735 13.9087 5.88811 13.8164 6.11554C14.3932 6.74561 14.7415 7.54761 14.7415 8.53088C14.7415 11.9883 12.6365 12.7496 10.6315 12.9727C10.9556 13.2517 11.2423 13.7988 11.2423 14.6382C11.2423 15.8412 11.2302 16.8113 11.2302 17.1079C11.2302 17.3474 11.3939 17.6276 11.8499 17.5397C15.4237 16.3477 18 12.9749 18 9C18 4.02979 13.9702 0 9 0Z" fill="currentColor" />
  </svg>
);

export default GitHubIcon;
