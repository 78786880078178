import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import debounce from 'lodash/debounce';
import arrayMutators from 'final-form-arrays';
import { Form, FormSpy } from 'react-final-form';
import { candidateCvEditDataSelector } from 'store/ducks/candidate/selectors';
import { updateCandidateCvFillRateForStep } from 'store/ducks/candidate/actionCreators';
import FormBigSkeleton from 'components/v2/skeletons/FormSkeletons/FormBigSkeleton';
import { Immutable, castDraft } from 'immer';
import { specializationsSelector } from 'store/ducks/dictionary/selectors';
import { calculateCvFormFillPercent, CvFormValues, submitCvFormValues } from '../../models/CV/CvFormValues';
import { CvFormStep } from '../../../pages/Candidate/Cv/CandidateEditCvPage/CandidateEditCvPage';
import { useApiInstance } from '../../api';
import { authCandidateHasCvSelector, authCandidateCvShowImportSelector } from '../../store/ducks/auth/selectors';
import SkillSetStackField from '../../components/Form/SkillSetStackField/SkillSetStackField';
import CVFormFooter from './CVFormFooter';
import Grid from '../../components/Grid/Grid';
import Text from '../../components/v2/Text/Text';
import TargetIconOff from '../../components/Icons/TargetIconOff';

interface Props {
  nextStep?: CvFormStep;
  previousStep?: CvFormStep;
}

const CVTechForm: FC<Props> = ({ previousStep, nextStep }) => {
  const api = useApiInstance();
  const specializations = useSelector(specializationsSelector);
  const cvData = useSelector(candidateCvEditDataSelector);
  const candidateHasCv = useSelector(authCandidateHasCvSelector);
  const dispatch = useDispatch();
  const showCvImport = useSelector(authCandidateCvShowImportSelector);
  const handleFormSubmit = async (cvFormData: Immutable<CvFormValues>) =>
    submitCvFormValues(showCvImport, dispatch, api, castDraft(cvFormData), 'tech', !candidateHasCv);
  const handleUpdateFillRate = debounce((formValues: CvFormValues) => {
    const fillRate = calculateCvFormFillPercent(formValues, 'tech');
    dispatch(updateCandidateCvFillRateForStep({ step: 'tech' as CvFormStep, fillRate }));
  }, 500);

  return (
    <div className="geecko-paper__wrapper">
      <div className="geecko-candidate-cv-form__form-header-wrapper">
        <div className="geecko-candidate-cv-form__dot-bg geecko-candidate-cv-form__form-header-dot--1" />
        <div className="geecko-candidate-cv-form__dot-bg geecko-candidate-cv-form__form-header-dot--2" />
        <Grid container spacing={4}>
          <Grid item xs={12} md={7}>
            <Text variant="h1" tag="h1">
              Стек
              <br />
              технологий
            </Text>
          </Grid>
          <Grid item xs={12} md={5}>
            <Text variant="h3">Стек это очень важно</Text>
            <div className="geecko-util__mt-2">
              <Text variant="md" color="#646D7B">
                Укажите ваш технологический стек и мы сможем подбирать вакансии, максимально ему соответствующие
              </Text>
            </div>
            <div className="geecko-util__mt-2">
              <TargetIconOff />
              <Text variant="md" color="#646D7B">
                Такой иконкой отмечаются навыки, которыми вы владеете, но не ищите по ним работу в данный момент.
                Нажмите на навык повторно для такого выбора
              </Text>
            </div>
          </Grid>
        </Grid>
      </div>

      {cvData.loading && (
        <>
          <FormBigSkeleton />
          <FormBigSkeleton />
        </>
      )}

      {cvData.loaded && cvData.item && (
        <Form<Immutable<CvFormValues>>
          onSubmit={handleFormSubmit}
          initialValues={cvData.item}
          mutators={{
            ...arrayMutators,
          }}
          render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit} encType="multipart/form-data" className="geecko-company-edit-profile__form">
              <FormSpy subscription={{ values: true }}>
                {(props) => {
                  handleUpdateFillRate(props.values as CvFormValues);
                  return null;
                }}
              </FormSpy>
  
              {specializations.loading && (
                <FormBigSkeleton />
              )}
              {specializations.loaded && specializations.items && (
                <SkillSetStackField specializations={specializations.items} />
              )}
  
              <CVFormFooter
                candidateHasCv={candidateHasCv}
                nextStep={nextStep}
                previousStep={previousStep}
                submitting={submitting}
              />
            </form>
          )}
        />
      )}
    </div>
  );
};

export default CVTechForm;
