import { ServerApi } from "modules/api/server-api";
import { VacancyActiveStatus } from "models/VacancyState";
import { decodeVacancy, VacanciesSort } from "models/Vacancy";

export const fetchCompanyVacancies = (api: ServerApi) => async (status: VacancyActiveStatus, vacanciesSort: VacanciesSort) => {
  const response = await api.get(`/company/vacancies?status=${status}&order_by=${vacanciesSort}`);
  return {
    vacancies: response.data.vacancies.vacancies.map((item: any) => decodeVacancy(item)),
    counters: {
      [VacancyActiveStatus.Active]: response.data.vacancies.active_count,
      [VacancyActiveStatus.Archived]: response.data.vacancies.archived_count,
      [VacancyActiveStatus.Draft]: response.data.vacancies.draft_count,
    }
  };
};
