import { SkillsSession, SkillsStatus } from 'models/Candidate/SkillsSession';
import { Immutable } from 'immer';
import { NetworkEntitiesCollecion, NetworkEntity } from 'store';
import { SearchSession } from 'models/Candidate/SearchSession';
import { CvFormValues, CvFillRate } from 'models/CV/CvFormValues';
import { VacancyState, VacancyCandidateActiveStatus, Interview } from 'models/VacancyState';
import { Report } from 'models/Candidate/Report';
import { ResumeImprovement } from 'models/Candidate/ResumeImprovement';

export interface VacanciesCounters {
  [VacancyCandidateActiveStatus.Active]: number;
  [VacancyCandidateActiveStatus.Archived]: number;
}

interface CandidateStateInterface {
  vacancies: {
    status?: VacancyCandidateActiveStatus;
    vacancies: NetworkEntitiesCollecion<VacancyState>;
    counters?: VacanciesCounters;
    previewId?: number;

    // Id вакансии, которую собирается отклонить кандидат
    rejectId?: number;

    // Id вакансии, для которой кандидат собирается оценить интервью
    interviewFeedbackId?: number;

    // Объект интервью для ответа на запрос
    interview?: Interview;

    // Id вакансии для ответа на запрос интервью
    interviewAcceptVacancyId?: number;

    // Id вакансии, на которую кандидат собирается откликнуться
    responseVacancyId?: number;

    loading?: boolean;
  };
  skillsSessions?: SkillsSession[];
  skillsStatus: NetworkEntity<SkillsStatus>;

  superSearch: {
    session: NetworkEntity<SearchSession>;
    updating?: boolean;
  };

  editCv: {
    cv: NetworkEntity<CvFormValues>;
    fillRate?: CvFillRate;
  };

  report: NetworkEntity<Report>;

  cvImprovements: NetworkEntitiesCollecion<ResumeImprovement>;
}

export type CandidateState = Immutable<CandidateStateInterface>;

export const initialCandidateState: CandidateState = {
  vacancies: { vacancies: {} },
  skillsStatus: {},
  superSearch: { session: {} },
  editCv: { cv: {} },
  report: {},
  cvImprovements: {}
};
