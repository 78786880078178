import React from 'react';
import { TextareaFormField } from 'components/v2/FinalForm/Fields/TextareaFormField';
import { CvFormValuesCodeSample } from 'models/CV/CvFormValues';
import Grid from 'components/Grid/Grid';
import { InputFormField } from 'components/v2/FinalForm/Fields/InputFormField';
import Label from 'components/Form/Label/Label';
import { MultiSelectFormField } from 'components/v2/FinalForm/Fields/MultiSelectFormField';
import { StackTool, loadStackOptionsNew } from 'models/StackTool';
import TrashRounded from 'components/Icons/TrashRounded/TrashRounded';
import { useApiInstance } from 'api';
import FormDivider from 'components/Form/FormDivider/FormDivider';
import { nanoid } from 'nanoid';
import Divider from 'components/Divider/Divider';
import { FieldArray } from 'react-final-form-arrays';
import Button from 'components/v2/Button/Button';

const CvCodeSamplesFormItem: React.FC = () => {
  const api = useApiInstance();
  const validateCodeSampleLink = (value: string) => (value ? undefined : 'Укажите ссылку на пример кода');
  const handleLoadStackOptions = (inputValue: string) => loadStackOptionsNew(api, inputValue);
  return (
    <FieldArray<CvFormValuesCodeSample> name="codeSamples">
      {({ fields }) => {
        const codeSamplesCount = fields.value.length;

        return (
          <>
            {fields.map((fieldName, fieldIndex) => {
              const fieldValue = fields.value[fieldIndex];
              return (
                <>
                  <div key={fieldValue.uid} className="geecko-form__item qa-code-example">
                    <div className="geecko-paper__wrapper">
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                          <Label>Ссылка</Label>
                          <InputFormField
                            name={`${fieldName}.link`}
                            placeholder="Github, Gist, Bitbucket..."
                            validate={validateCodeSampleLink}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Label>Используемые языки и технологии</Label>
                          <MultiSelectFormField<StackTool>
                            name={`${fieldName}.tools`}
                            loadOptions={handleLoadStackOptions}
                            valueKey="id"
                            labelKey="name"
                            imageKey="image"
                            placeholder="Например: Github, Jira, Slack, Javascript, React"
                            noOptionsPlaceholder="Начните вводить названия технологий и сервисов"
                            async
                          />
                        </Grid>
                      </Grid>
                      <div className="geecko-form__item">
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={12}>
                            <Label>Короткое описание</Label>
                            <TextareaFormField
                              name={`${fieldName}.description`}
                              placeholder="Расскажите, что  делает этот код и на что обратить внимание"
                            />
                          </Grid>
                        </Grid>
                      </div>
                      <div className="geecko-form__bottom-buttons">
                        <Button
                          variant="ghost"
                          color="danger"
                          size="sm"
                          iconLeft={<TrashRounded size={15} />}
                          onClick={() => {
                            fields.remove(fieldIndex);
                          }}
                        >
                          Удалить пример кода
                        </Button>
                      </div>
                      {fieldIndex < codeSamplesCount - 1 && <Divider margin={20} />}
                    </div>
                  </div>
                </>
              );
            })}

            <FormDivider
              text="Добавить пример кода"
              onClick={() => {
                fields.push({
                  uid: nanoid(),
                  link: '',
                  description: '',
                  tools: [],
                });
              }}
            />
          </>
        );
      }}
    </FieldArray>
  );
};

export default CvCodeSamplesFormItem;
