import React, {
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import cloneDeep from 'lodash/cloneDeep';
import './SelectField.css';
import { ucFirst } from 'models/helpers';
import Corner from 'components/Icons/Corner/Corner';
import Avatar from 'components/Avatar/Avatar';

export type SelectFieldItem = OptionGroup | Option;

export interface Option {
  value: string;
  label?: string;
  disabled?: boolean;
  image?: string | JSX.Element;
}

export interface OptionGroup {
  title: string;
  options?: Option[];
  image?: string | JSX.Element;
}

interface Props {
  options: SelectFieldItem[];
  value: string;
  placeholder?: string;
  onChange: (value: string) => void;
  id?: string;
  densed?: boolean;
  isInvalid?: boolean;
  validationError?: string;
  submitError?: string;
  disabled?: boolean;
  name?: string;
  isUserSelect?: boolean;
  hasEmoji?: boolean;
}

const SelectField: React.FC<Props> = (props: Props) => {
  const {
    options, value, onChange, placeholder, densed = false,
    isInvalid, validationError, submitError, name, isUserSelect, hasEmoji,
  } = props;

  const [open, setOpen] = useState(false);
  const [currentValue, setCurrentValue] = useState(value);

  const plainOptions: Option[] = useMemo(() => {
    const optionsResult: Option[] = [];

    options.forEach((optionItem) => {
      if ('value' in optionItem && typeof optionItem.value !== 'undefined') {
        const option = optionItem as Option;
        optionsResult.push(option);
      } else {
        const optionGroup = optionItem as OptionGroup;
        if (optionGroup.options) {
          optionGroup.options.forEach((option) => {
            optionsResult.push(option);
          });
        }
      }
    });

    return optionsResult;
  }, [options]);

  const getCurrentLabel = useCallback((input: string) => {
    const preparedInput = String(input);
    return preparedInput && plainOptions.filter(item => item.value === preparedInput).length > 0
      ? plainOptions.filter(item => item.value === preparedInput)[0].label
      : placeholder || '';
  }, [plainOptions, placeholder]);

  const getCurrentImage = useCallback((input: string | JSX.Element) => {
    const currentOption = options.find((item) => (item) && (item as Option).value === input);
    return currentOption ? currentOption.image : undefined;
  }, [options]);

  const [currentLabel, setCurrentLabel] = useState(getCurrentLabel(value));
  const [currentImage, setCurrentImage] = useState(getCurrentImage(value));
  const [inputQuery, setInputQuery] = useState('');

  const handleChangeQuery = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setInputQuery(newValue);
  };

  useEffect(() => {
    setCurrentValue(value);
    setCurrentLabel(getCurrentLabel(value));
    setCurrentImage(getCurrentImage(value));
  }, [value, setCurrentLabel, getCurrentLabel, getCurrentImage]);

  const selectToggler = useRef<HTMLButtonElement>(null);

  const handleSelect = (newValue: string) => {
    setOpen(false);
    setCurrentValue(newValue);
    // if (newLabel) setCurrentLabel(newLabel);
    onChange(newValue);
    if (selectToggler.current) {
      selectToggler.current.focus();
    }
  };

  const toggleSelect = () => {
    setOpen(prevOpen => (!prevOpen));
  };

  const node = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (node && node.current && node.current.contains(event.target as Node)) {
      return;
    }
    setOpen(false);
  };
  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
      if (inputRef.current) {
        inputRef.current.focus();
      }
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
      setInputQuery('');
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);

  const optionsNode = useRef<HTMLUListElement>(null);

  const filteredOptions: any[] = useMemo(() => {
    if (!inputQuery) {
      return options;
    }

    const newOptions = cloneDeep(options);

    return newOptions.map((option: Option | OptionGroup) => {
      if ('value' in option && typeof option.value !== 'undefined') {
        return option;
      }

      const optionGroup = option as OptionGroup;
      optionGroup.options = optionGroup.options ? optionGroup.options.filter((subOption: Option) => subOption.label && subOption.label.toString().includes(inputQuery)) : [];
      return optionGroup;
    })
      .filter((option: any) => {
        if ('value' in option && typeof option.value !== 'undefined') {
          return option.label && option.label.includes(inputQuery);
        }

        return option.options && option.options.length > 0;
      });
  }, [options, inputQuery]);

  // TODO focus on first option on open

  const { disabled } = props;

  return (
    <>
      <div
        ref={node}
        className={[
          'geecko-selectfield',
          densed ? 'geecko-selectfield--densed' : '',
          disabled ? 'geecko-selectfield--disabled' : '',
          open ? 'geecko-selectfield--open' : '',
          isInvalid || validationError || submitError ? 'geecko-selectfield--invalid' : '',
        ].join(' ')}
      >
        {!open && (
          <button
            ref={selectToggler}
            className={['geecko-selectfield__value'].join(' ')}
            onClick={disabled ? undefined : toggleSelect}
            name={name}
            tabIndex={0}
            type="button"
            title={currentLabel}
          >
            <div className={`geecko-selectfield__current-value ${value ? '' : 'geecko-selectfield__current-value--placeholder'}`}>
              {isUserSelect ? <Avatar size={16} image={currentImage} fullName={currentLabel} /> : null }
              {hasEmoji ? <span className="geecko-selectfield__options-item__emoji">{currentImage}</span> : null}
              {currentLabel}
            </div>
          </button>
        )}
        {open && (
          <input
            ref={inputRef}
            className={['geecko-selectfield__value'].join(' ')}
            value={inputQuery}
            onChange={handleChangeQuery}
          />
        )}
        <div className="geecko-selectfield__arrow">
          <Corner />
        </div>
        <ul
          ref={optionsNode}
          className={[
            'geecko-selectfield__options',
            open ? 'geecko-selectfield__options--open' : '',
          ].join(' ')}
          tabIndex={-1}
          role="listbox"
        >
          {filteredOptions.map((option) => {
            if ('value' in option && typeof option.value !== 'undefined') {
              const optionItem = option as Option;

              return (
                <Option
                  key={optionItem.value}
                  label={optionItem.label}
                  value={optionItem.value}
                  selected={optionItem.value === currentValue}
                  onSelect={handleSelect}
                  disabled={optionItem.disabled}
                  image={optionItem.image}
                  isUserSelect={isUserSelect}
                  hasEmoji={hasEmoji}
                />
              );
            }

            const optionGroup = option as OptionGroup;

            return (
              <OptionGroupNode
                key={optionGroup.title}
                currentValue={currentValue}
                optionGroup={optionGroup}
                onSelect={handleSelect}
              />
            );
          })}
        </ul>
      </div>

      {(validationError || submitError) && (
        <div className="geecko-selectfield-error">{validationError || submitError}</div>
      )}
    </>
  );
};

interface OptionProps {
  label: string | undefined;
  value: string;
  selected: boolean;
  disabled?: boolean;
  onSelect: (value: string, label?: string) => void;
  image?: string | JSX.Element;
  isUserSelect?: boolean;
  hasEmoji?: boolean;
}

const Option: React.FC<OptionProps> = (props: OptionProps) => {
  const {
    label, value, selected, onSelect, disabled, image, isUserSelect, hasEmoji,
  } = props;
  const handleSelect = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    onSelect(value, label || value);
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    switch (event.key) {
      case 'Enter':
      case ' ':
        event.stopPropagation();
        event.preventDefault();
        onSelect(value, label || value);
        break;
      default:
        break;
    }
  };
  return (
    <li
      className={[
        'geecko-selectfield__options-item',
        disabled ? 'geecko-selectfield__options-item--disabled' : '',
        selected ? 'geecko-selectfield__options-item--selected' : '',
        value === 'add' ? 'geecko-selectfield__options-item--add' : '',
      ].join(' ')}
      value={value}
      onClick={disabled ? undefined : handleSelect}
      onKeyDown={disabled ? undefined : handleKeyDown}
      role="option"
      aria-selected={selected}
      aria-disabled={!!disabled}
      // eslint-disable-next-line
      tabIndex={0}
    >
      {isUserSelect ? <Avatar size={16} image={image} fullName={label} /> : null}
      {hasEmoji ? <span className="geecko-selectfield__options-item__emoji">{image}</span> : null}
      {ucFirst(label || value)}
    </li>
  );
};

interface OptionGroupProps {
  optionGroup: OptionGroup;
  currentValue?: string;
  onSelect: (newValue: string, newLabel?: string) => void;
}

const OptionGroupNode: React.FC<OptionGroupProps> = (props: OptionGroupProps) => {
  const { optionGroup, onSelect, currentValue } = props;
  return (
    <>
      {optionGroup.title && (
      <div className="geecko-selectfield__options-group">
        {optionGroup.title}
      </div>
      )}
      {optionGroup.options && optionGroup.options.map(option => (
        <Option
          key={option.value}
          label={option.label}
          value={option.value}
          selected={option.value === currentValue}
          onSelect={onSelect}
          disabled={option.disabled}
        />
      ))}
    </>
  );
};

export default SelectField;
