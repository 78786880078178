import React from 'react';
import ContentLoader from 'react-content-loader';

const FormBigSkeleton = () => (
  <div className="geecko-form__item">
    <ContentLoader
      speed={1}
      width="100%"
      viewBox="0 0 660 220"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="0" rx="4" ry="4" width="88" height="8" />
      <rect x="600" y="0" rx="4" ry="4" width="120" height="8" />
      <rect x="0" y="17" rx="8" ry="8" width="207" height="33" />
      <rect x="0" y="84" rx="4" ry="4" width="120" height="8" />
      <rect x="-1" y="102" rx="8" ry="8" width="321" height="33" />
      <rect x="339" y="84" rx="4" ry="4" width="120" height="8" />
      <rect x="339" y="102" rx="8" ry="8" width="315" height="33" />
      <rect x="0" y="164" rx="4" ry="4" width="88" height="8" />
      <rect x="0" y="182" rx="8" ry="8" width="207" height="33" />
      <rect x="224" y="164" rx="4" ry="4" width="88" height="8" />
      <rect x="224" y="182" rx="8" ry="8" width="207" height="33" />
      <rect x="447" y="164" rx="4" ry="4" width="88" height="8" />
      <rect x="447" y="182" rx="8" ry="8" width="207" height="33" />
    </ContentLoader>
  </div>
);

export default FormBigSkeleton;
