import React, { FocusEvent, forwardRef, useState } from 'react';
import classNames from 'classnames';
import { IMaskInput } from 'react-imask';
import { InputProps } from './Input';
import Spinner from '../../Spinner/Spinner';

export type InputMaskedProps = Props;

interface Props extends Omit<InputProps, 'onChange'> {
  mask: any;
  radix?: string;
  maskMin?: any;
  maskMax?: any;
  lazy?: boolean;
  onChange?: (value: string) => void;
}

const InputMasked = forwardRef<HTMLInputElement, Props>(({
  size = 'medium',
  mask,
  maskMin,
  maskMax,
  lazy,
  isLoading,
  isInvalid,
  className,
  onChange,
  onNativeChange,
  onFocus,
  onBlur,
  ...rest
}, ref) => {
  const [valueOnFocus, setValueOnFocus] = useState('');
  const completeClassName = classNames([
    'geecko-input',
    `geecko-input--size-${size}`,
    isInvalid ? 'geecko-input--error' : '',
    isLoading ? 'geecko-input--loading' : '',
  ]);
  const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setValueOnFocus(value);
    if (onFocus) {
      onFocus(event);
    }
  };
  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value !== valueOnFocus && onNativeChange) {
      onNativeChange(value, { ...rest });
    }
    if (onBlur) {
      onBlur(event);
    }
  };

  return (
    <div className={`geecko-input__wrapper geecko-input__wrapper--size-${size}`}>
      <IMaskInput
        ref={ref}
        className={`${completeClassName} ${className || ''}`}
        mask={mask}
        onAccept={(newValue: string) => {
          if (onChange) {
            onChange(newValue);
          }
        }}
        {...rest}
        lazy={lazy}
        min={maskMin}
        max={maskMax}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      {isLoading && (
        <div className="geecko-input__loading">
          <Spinner size={20} color="#898B94" />
        </div>
      )}
    </div>
  );
});

export default InputMasked;
