import { ReactNode } from 'react';
import { nanoid } from 'nanoid';
import { Dispatch } from 'redux';
import { addNotification } from '../store/ducks/notify';

export interface Notify {
  id?: string;
  content: ReactNode;
  type?: 'success' | 'error' | undefined;
  persist?: boolean; // Не скрывается автоматически
  cancelable?: boolean; // Имеет кнопку закрытия
}

export const NotifyConfig = {
  maxNotify: 3,
  autoHideDuration: 4000,
};

export const showNotifyNew = (dispatch: Dispatch, notify: Notify) => {
  dispatch(addNotification({
    ...notify,
    id: notify.id || nanoid(),
    persist: notify.persist || false,
    cancelable: notify.cancelable || true,
  }));
};
