import React, { FC } from 'react';

interface Props {
  size?: number;
}

const GitLabIcon: FC<Props> = ({ size = 18 }) => (
  <svg width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#gitlabClip)">
      <path d="M17.9663 10.1902L16.9598 7.089L14.9618 0.94725C14.8605 0.63 14.4143 0.63 14.3115 0.94725L12.3135 7.0875H5.68652L3.68927 0.94725C3.58727 0.63 3.13877 0.63 3.03752 0.945L1.03952 7.08675L0.0330193 10.1902C-0.0577307 10.4715 0.0435193 10.782 0.281269 10.9575L9.00002 17.2905L17.7188 10.9583C17.9573 10.782 18.0585 10.473 17.9663 10.1902Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="gitlabClip">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default GitLabIcon;
