import React, { FC, useRef, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import { FormApi } from 'final-form';
import Dialog from 'components/v2/Dialog/Dialog';
import { useDispatch } from 'react-redux';
import { modalClosed } from 'store/ducks/settings';
import { InputFormField } from 'components/v2/FinalForm/Fields/InputFormField';
import { TextareaFormField } from 'components/v2/FinalForm/Fields/TextareaFormField';
import {
  CvCandidateSpecialization,
  CvFormValuesExperience,
  CvFormValuesExperienceProject,
  CvSkills,
  getCandidateExperienceProjectItem,
} from 'models/CV/CvFormValues';
import './CVFormExperienceCompanyModal.css';
import Text from 'components/v2/Text/Text';
import Grid from 'components/Grid/Grid';
import Label from 'components/Form/Label/Label';
import { CheckRequired, SwitchAdapter } from 'components/FormAdapter/FormAdapter';
import TrashRounded from 'components/Icons/TrashRounded/TrashRounded';
import PlusCircle from 'components/Icons/PlusCircle/PlusCircle';
import FormCondition from 'components/Form/FormCondition/FormCondition';
import ArrowDownIcon from 'components/Icons/ArrowDownIcon';
import UIArrowUpIcon from 'components/Icons/UIArrowUpIcon';
import Button from 'components/v2/Button/Button';
import { getFilteredSkillsAreas } from 'components/Form/SkillSetStackField/SkillSetStackField';
import EditPaperRounded from 'components/Icons/EditPaperRounded';
import TagFieldFinalForm from 'components/final-form-adapters/TagFieldFinalForm';
import DateRangeFormField from 'components/Form/DateRangeFormField/DateRangeFormField';
import TagField from 'components/Form/TagField/TagField';
import CVExperienceStack from './CVExperienceStack';
import CVFormExperienceStackModal from './CVFormExperienceStackModal';

const MODAL_ID = 'edit-experience-company-modal';

interface Props {
  isOpen: boolean;
  commonSkills: StackFields;
  editMode: 'add' | 'edit';
  value: CvFormValuesExperience;
  onChange: (value: CvFormValuesExperience) => void;
  onClose: () => void;
}

export interface StackFields {
  specializations: CvCandidateSpecialization[];
  areas: string[];
  skills: CvSkills;
}

const CVFormExperienceCompanyModal: FC<Props> = ({
  isOpen,
  commonSkills,
  editMode,
  value,
  onChange,
  onClose,
}) => {
  const formApi = useRef<FormApi<CvFormValuesExperience>>();
  const dispatch = useDispatch();
  const [stackType, setStackType] = useState<'company' | 'project'>('company');
  const [editingProjectIndex, setEditingProjectIndex] = useState<number>(0);
  const [editingStack, setEditingStack] = useState<StackFields | undefined>();

  const validate = (values: CvFormValuesExperience) => {
    if (values.areas.length === 0) {
      return {
        areas: 'Выберите область деятельности',
      };
    }
    return undefined;
  };

  const handleFormSubmit = (values: CvFormValuesExperience) => {
    onChange(values);
  };

  const handleUpdateStack = (values: StackFields) => {
    if (formApi.current) {
      if (stackType === 'company') {
        formApi.current.change('areas', values.areas);
        formApi.current.change('specializations', values.specializations);
        formApi.current.change('skills', values.skills);
      } else {
        formApi.current.change(`projects[${editingProjectIndex}].areas`, values.areas);
        formApi.current.change(`projects[${editingProjectIndex}].specializations`, values.specializations);
        formApi.current.change(`projects[${editingProjectIndex}].skills`, values.skills);
      }
    }
    setEditingStack(undefined);
  };

  const handleCloseStack = () => {
    setEditingStack(undefined);
  };

  const handleClose = () => {
    dispatch(modalClosed(MODAL_ID));
    onClose();
  }

  return (
    <Dialog
      title={editMode === 'edit' ? 'Редактирование компании' : 'Новая компания'}
      isOpen={isOpen}
      onClose={handleClose}
      modalBaseId={MODAL_ID}
      modalStyle={{ maxWidth: 1000 }}
      closeOnBgClick={false}
    >
      {editingStack && (
        <CVFormExperienceStackModal
          isOpen={!!editingStack}
          value={editingStack!}
          commonSkills={commonSkills}
          onChange={handleUpdateStack}
          onClose={handleCloseStack}
          type={stackType}
        />
      )}
      <Form<CvFormValuesExperience>
        onSubmit={handleFormSubmit}
        initialValues={value}
        validate={validate}
        mutators={{
          ...arrayMutators,
        }}
        render={({ form, handleSubmit, submitting, values }) => {
          formApi.current = form;

          const handleSelectAreas = (newAreas: string[]) => {
            if (newAreas.length > 0 && newAreas.indexOf('other') < 0) {
              setStackType('company');
              setEditingStack({
                areas: newAreas,
                skills: values.skills,
                specializations: values.specializations,
              });
            }
          };

          const handleSelectProjectAreas = (projectIndex: number, newAreas: string[]) => {
            if (newAreas.length > 0 && newAreas.indexOf('other') < 0) {
              setStackType('project');
              setEditingProjectIndex(projectIndex);
              setEditingStack({
                areas: newAreas,
                skills: values.projects[projectIndex].skills,
                specializations: values.projects[projectIndex].specializations,
              });
            } else {
              form.change(`projects[${projectIndex}].areas`, newAreas);
            }
          };

          return (
            <form onSubmit={handleSubmit}>
              <div className="geecko-form__item">
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4}>
                    <Label required>Название компании</Label>
                    <InputFormField name="companyName" validate={CheckRequired} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Label>Сайт компании</Label>
                    <InputFormField name="companyLink" />
                  </Grid>
                </Grid>

                <div className="geecko-form__item">
                  <Label required>Период работы в компании</Label>
                </div>

                <DateRangeFormField />

                <div className="geecko-form__item">
                  <Label required>Должность / позиция</Label>
                  <InputFormField name="position" validate={CheckRequired} />
                </div>
                <div className="geecko-form__item">
                  <Label required>Чем занимались</Label>
                  <TextareaFormField
                    name="description"
                    placeholder="Над какими проектами работали и какие задачи выполняли"
                    validate={CheckRequired}
                  />
                </div>
              </div>
              <div className="geecko-form__item">
                <Label required>Область деятельности</Label>
                <TagFieldFinalForm
                  name="areas"
                  restrictGroups
                  options={[
                    ...getFilteredSkillsAreas(commonSkills.areas).map((item) => ({
                      value: item.id,
                      label: item.name,
                      groupKey: 'dev',
                    })),
                    { value: 'other', label: 'Другое', groupKey: 'other' },
                  ]}
                  onChange={handleSelectAreas}
                />
              </div>
              {values.areas.length > 0 && values.areas.indexOf('other') < 0 && (
                <>
                  {values.areas.length > 0 && values.specializations.length > 0 && (
                    <div className="geecko-form__item">
                      <CVExperienceStack specializations={values.specializations} skills={values.skills} />
                    </div>
                  )}
                  <div className="geecko-form__item">
                    <Button
                      variant="ghost"
                      color="secondary"
                      size="sm"
                      iconLeft={<EditPaperRounded size={16} />}
                      onClick={() => {
                        setStackType('company');
                        setEditingStack({
                          areas: values.areas,
                          skills: values.skills,
                          specializations: values.specializations,
                        });
                      }}
                    >
                      Редактировать стек
                    </Button>
                  </div>
                </>
              )}
              <div className="geecko-cv-experience-company__alert">
                <Text variant="md">
                  Самое лучше описание опыта работы – это описание проекта, команды, стека технологий и задач с которыми вам
                  приходилось работать. Вы можете использовать общее описание вашей работы в компании или отдельно рассказать про
                  каждый проект, если их было несколько
                </Text>
                <div className="geecko-util__mt-2">
                  <Field<boolean>
                    name="showProjects"
                    type="checkbox"
                    component={SwitchAdapter}
                    label="Рассказать отдельно про проекты"
                  />
                </div>
              </div>
              <FormCondition field="showProjects" is>
                <FieldArray<CvFormValuesExperienceProject> name="projects">
                  {(projectsArray) => (
                    <>
                      {projectsArray.fields.map((projectFieldName, projectFieldIndex) => {
                        const projectFieldValue = projectsArray.fields.value[projectFieldIndex];
                        return (
                          <div key={projectFieldValue.uid} className="geecko-cv-experience-company__project-wrapper">
                            <div className="geecko-cv-experience-company__project-title-wrapper">
                              <div className="geecko-cv-experience-company__project-title">
                                <Text variant="h3">{projectFieldValue.title || `Проект №${projectFieldIndex + 1}`}</Text>
                              </div>
                              <div className="geecko-cv-experience-company__project-actions">
                                {projectFieldIndex > 0 && (
                                  <div className="geecko-cv-experience-company__project-action">
                                    <Button
                                      variant="ghost"
                                      color="secondary"
                                      size="sm"
                                      iconLeft={<UIArrowUpIcon size={25} />}
                                      onClick={() => {
                                        projectsArray.fields.move(projectFieldIndex, projectFieldIndex - 1);
                                      }}
                                    >
                                      Поднять выше
                                    </Button>
                                  </div>
                                )}
                                {projectFieldIndex < projectsArray.fields.value.length - 1 && (
                                  <div className="geecko-cv-experience-company__project-action">
                                    <Button
                                      variant="ghost"
                                      color="secondary"
                                      size="sm"
                                      iconLeft={<ArrowDownIcon size={16} />}
                                      onClick={() => {
                                        projectsArray.fields.move(projectFieldIndex, projectFieldIndex + 1);
                                      }}
                                    >
                                      Опустить ниже
                                    </Button>
                                  </div>
                                )}
                                <div className="geecko-cv-experience-company__project-action">
                                  <Button
                                    variant="ghost"
                                    color="secondary"
                                    size="sm"
                                    iconLeft={<TrashRounded size={15} />}
                                    onClick={() => {
                                      projectsArray.fields.remove(projectFieldIndex);
                                    }}
                                  >
                                    Удалить
                                  </Button>
                                </div>
                              </div>
                            </div>
                            <div className="geecko-form__item">
                              <Grid container spacing={4}>
                                <Grid item xs={12} md={4}>
                                  <Label required>Название проекта</Label>
                                  <InputFormField
                                    name={`${projectFieldName}.title`}
                                    validate={CheckRequired}
                                  />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                  <Label>Должность / позиция</Label>
                                  <InputFormField name={`${projectFieldName}.position`} />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                  <Label>Сайт проекта</Label>
                                  <InputFormField name={`${projectFieldName}.link`} />
                                </Grid>
                              </Grid>
                            </div>
                            <div className="geecko-form__item">
                              <Label>Описание проекта</Label>
                              <TextareaFormField
                                name={`${projectFieldName}.description`}
                                placeholder="Над какими проектами работали и какие задачи выполняли"
                              />
                            </div>
                            <div className="geecko-form__item">
                              <Label required>Область деятельности</Label>
                              <TagField
                                value={projectFieldValue.areas}
                                restrictGroups
                                options={[
                                  ...getFilteredSkillsAreas(commonSkills.areas).map((item) => ({
                                    value: item.id,
                                    label: item.name,
                                    groupKey: 'dev',
                                  })),
                                  { value: 'other', label: 'Другое', groupKey: 'other' },
                                ]}
                                onChange={(newAreas) => {
                                  handleSelectProjectAreas(projectFieldIndex, newAreas);
                                }}
                              />
                            </div>
                            {projectFieldValue.areas.length > 0 && projectFieldValue.areas.indexOf('other') < 0 && (
                              <>
                                {projectFieldValue.areas.length > 0 && projectFieldValue.specializations.length > 0 && (
                                  <div className="geecko-form__item">
                                    <CVExperienceStack
                                      specializations={projectFieldValue.specializations}
                                      skills={projectFieldValue.skills}
                                    />
                                  </div>
                                )}
                                <div className="geecko-form__item">
                                  <Button
                                    variant="ghost"
                                    color="secondary"
                                    size="sm"
                                    iconLeft={<EditPaperRounded size={16} />}
                                    onClick={() => {
                                      handleSelectProjectAreas(projectFieldIndex, projectFieldValue.areas);
                                    }}
                                  >
                                    Редактировать стек проекта
                                  </Button>
                                </div>
                              </>
                            )}
                          </div>
                        );
                      })}
                      <div className="geecko-cv-experience-company__project-wrapper">
                        <Button
                          variant="ghost"
                          color="secondary"
                          size="sm"
                          iconLeft={<PlusCircle size={15} />}
                          onClick={() => {
                            projectsArray.fields.push(getCandidateExperienceProjectItem());
                          }}
                        >
                          Добавить проект
                        </Button>
                      </div>
                    </>
                  )}
                </FieldArray>
              </FormCondition>
              <div className="geecko-form__buttons-line geecko-form__buttons-line--margin-lg">
                <div className="geecko-form__buttons-line__button">
                  <Button size="lg" color="primary" type="submit" isLoading={submitting}>
                    {editMode === 'edit' ? 'Сохранить компанию' : 'Добавить компанию'}
                  </Button>
                </div>
                <div className="geecko-form__buttons-line__button">
                  <Button size="lg" color="secondary" type="button" onClick={handleClose}>
                    Закрыть
                  </Button>
                </div>
              </div>
            </form>
          );
        }}
      />
    </Dialog>
  );
};

export default CVFormExperienceCompanyModal;
