import { createAction, createAsyncAction } from 'typesafe-actions';
import { ResumeState } from 'models/Resume';
import { VacancyStateStatus } from 'models/VacancyState';
import { Immutable } from 'immer';

export type FetchResumeActionParams = { resumeId: string; vacancyId?: number; token?: string; viewAsCompany: boolean };

/**
 * Получение резюме
 */
export const fetchResumeAction = createAsyncAction(
  'resume/RESUME_REQUEST',
  'resume/RESUME_SUCCESS',
  'resume/RESUME_FAILURE',
)<FetchResumeActionParams, Immutable<ResumeState>, Error>();

/**
 * Экшен обновления резюме. Можно передать параметры, которые необходимо изменить в запросе
 */
export const reloadResumeAction = createAction('resume/RESUME_RELOAD')<Partial<FetchResumeActionParams> | undefined>();

/**
 * Получение статуса матчинга
 */
export const fetchResumeStatusAction = createAsyncAction(
  'resume/RESUME_STATUS_REQUEST',
  'resume/RESUME_STATUS_SUCCESS',
  'resume/RESUME_STATUS_FAILURE',
)<{ resumeId: string; vacancyId: number }, VacancyStateStatus, Error>();
