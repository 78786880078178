import React, { FC, ReactNode } from 'react';
import './FormDivider.css';
import classNames from 'classnames';
import PlusCircle from '../../Icons/PlusCircle/PlusCircle';

interface Props {
  text?: ReactNode;
  onClick?: () => void;
}

const FormDivider: FC<Props> = ({ onClick, text }) => {
  return (
    <div className={`geecko-form-divider ${onClick ? 'geecko-form-divider--with-action' : ''}`}>
      <div className="geecko-form-divider__divider" />
      {onClick && (
        <div className="geecko-form-divider__button-wrapper">
          <div className="geecko-paper__inner">
            <button
              className={classNames(
                'geecko-form-divider__button',
                { 'geecko-form-divider__button--with-text': !!text },
              )}
              type="button"
              onClick={onClick}
            >
              <PlusCircle size={16} />
              {text && (
                <div className="geecko-form-divider__button-text">
                  {text}
                </div>
              )}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FormDivider;
