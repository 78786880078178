import { ServerResponse } from 'http';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import { Router } from '../../server/routes';

export const redirect = (url: string, res?: ServerResponse, code = 302) => {
  if (res) {
    res.writeHead(code, {
      Location: url,
    });
    res.end();
  } else {
    Router.pushRoute(url).then(() => window.scrollTo(0, 0));
  }
};

export const replaceRoute = (url: string) => {
  Router.replaceRoute(url).then(() => window.scrollTo(0, 0));
};
