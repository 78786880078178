export const scrollToElementWithOffset = (elementSelector: Element | string, offset = 170) => {
  if (typeof document === 'undefined' || typeof window === 'undefined') {
    return;
  }
  const element = typeof elementSelector === 'string' ? document.querySelector(elementSelector) : elementSelector;
  if (element) {
    const elementPosition = element.getBoundingClientRect().top + pageYOffset;
    const offsetPosition = elementPosition - offset;
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  }
}