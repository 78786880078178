import React, { ChangeEvent, FC, FormEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import camelcaseKeys from 'camelcase-keys';
import Button from 'components/v2/Button/Button';
import { setCandidateCvImportStatusAction } from 'store/ducks/auth/actionCreators';
import Input from 'components/v2/Input/Input';
import { fetchCandidateCvForEditAction } from 'store/ducks/candidate/actionCreators';
import { candidateCvEditDataSelector } from 'store/ducks/candidate/selectors';
import { redirect } from 'utils/redirect';
import Typography from '../../components/Typography/Typography';
import InfoCircleIcon from '../../components/Icons/InfoCircleIcon';
import Alert from '../../components/Alert/Alert';
import { CandidateCvImport } from '../../store/ducks/auth/state';
import {
  authCandidateCvImportLinkSelector,
  authCandidateCvImportStatusSelector,
} from '../../store/ducks/auth/selectors';
import Label from '../../components/Form/Label/Label';
import Progress from '../../components/Progress/Progress';
import { useApiInstance } from '../../api';
import { showNotifyNew } from '../../models/Notify';
import { useInterval } from '../../hooks/use-interval';
import { CvFormStep } from '../../../pages/Candidate/Cv/CandidateEditCvPage/CandidateEditCvPage';

interface Props {
  onChangeFillRate?: (step: CvFormStep, fillRate: number) => void;
}

const CVImportForm: FC<Props> = ({ onChangeFillRate }) => {
  const api = useApiInstance();
  const dispatch = useDispatch();
  const [cvLink, setCvLink] = useState(useSelector(authCandidateCvImportLinkSelector) || '');
  const [cvStartLoading, setCvStartLoading] = useState(false);
  const [importProgress, setImportProgress] = useState(0);
  const progressInterval = useRef<any>(null);
  const cvImportStatus = useSelector(authCandidateCvImportStatusSelector);
  const cvData = useSelector(candidateCvEditDataSelector);
  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    if (!cvLink) {
      showNotifyNew(dispatch, {
        type: 'error',
        content: 'Укажите ссылку на резюме',
      });
      return;
    }
    setCvStartLoading(true);
    api
      .post('/candidate/parse', { link: cvLink })
      .then((response) => {
        dispatch(setCandidateCvImportStatusAction(camelcaseKeys(response.data) as CandidateCvImport));
        setCvStartLoading(false);
      })
      .catch((error) => {
        setCvStartLoading(false);

        if (error.response) {
          if (error.response.status === 422) {
            if (
              error.response.data.errors &&
              error.response.data.errors.link &&
              error.response.data.errors.link.length > 0
            ) {
              showNotifyNew(dispatch, {
                type: 'error',
                content: error.response.data.errors.link[0],
              });
              return;
            }
          } else if (error.response.status === 400) {
            showNotifyNew(dispatch, {
              type: 'error',
              content: error.response.data.message || 'Произошла ошибка, попробуйте еще раз',
            });
            return;
          }
        }

        showNotifyNew(dispatch, {
          type: 'error',
          content: 'Произошла ошибка, попробуйте еще раз',
        });
      });
  };
  const handleChangeCvLink = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setCvLink(value);
  };
  const handleCvFillManual = () => {
    redirect('/my/cv/edit/personal');
  };
  const progressColor = useMemo(() => {
    switch (cvImportStatus) {
      case 'finished':
        return 'success';
      case 'failed':
        return 'error';
      default:
        return 'primary';
    }
  }, [cvImportStatus]);

  const fetchCvWhenImportDone = async () => {
    dispatch(fetchCandidateCvForEditAction.request());
  };

  useEffect(() => {
    if (cvImportStatus === 'finished') {
      fetchCvWhenImportDone();
      setImportProgress(100);
      clearInterval(progressInterval.current);
    } else if (cvImportStatus === 'failed') {
      setImportProgress(100);
      clearInterval(progressInterval.current);
    }
  }, [cvImportStatus]);

  useInterval(
    () => {
      if (cvImportStatus !== 'in-progress' && cvImportStatus !== 'queued') {
        return;
      }
      const remainValue = 100 - importProgress;
      if (remainValue > 0) {
        const appendValue = remainValue / 60;
        setImportProgress(importProgress + appendValue);
      }
    },
    cvImportStatus !== 'in-progress' && cvImportStatus !== 'queued' ? null : 1000,
  );

  useInterval(
    () => {
      if (cvImportStatus !== 'in-progress' && cvImportStatus !== 'queued') {
        return;
      }

      api.get('/candidate/parse/status').then((response) => {
        dispatch(setCandidateCvImportStatusAction(camelcaseKeys(response.data) as CandidateCvImport));
      });
    },
    cvImportStatus !== 'in-progress' && cvImportStatus !== 'queued' ? null : 2000,
  );

  useEffect(() => {
    if (cvImportStatus === 'failed') {
      if (onChangeFillRate) {
        onChangeFillRate('import', 0);
      }
    } else if (cvImportStatus === 'finished') {
      if (onChangeFillRate) {
        onChangeFillRate('import', 100);
      }
    } else if (onChangeFillRate) {
      onChangeFillRate('import', importProgress);
    }
  }, [importProgress, cvImportStatus, onChangeFillRate]);

  return (
    <form className="geecko-company-edit-profile__form" onSubmit={handleSubmit}>
      <div className="geecko-paper__wrapper">
        <Typography variant="h6">Импорт резюме</Typography>

        <Alert icon={<InfoCircleIcon />}>
          Укажите ссылку на резюме <span className="geecko-text--bold">HeadHunter</span> и мы автоматически заполним
          основную информацию в вашем профиле
        </Alert>

        <div className="geecko-form__item">
          <Label required>Ссылка на резюме</Label>
          <Input
            value={cvLink}
            onChange={handleChangeCvLink}
            placeholder="https://"
            disabled={cvStartLoading || cvData.loading || (!!cvImportStatus && cvImportStatus !== 'failed')}
          />
        </div>

        <div
          className={classNames(
            'geecko-form__item',
            !cvImportStatus ? 'geecko-candidate-cv-form__import-progress--hidden' : undefined,
          )}
        >
          <Progress value={importProgress} color={progressColor} height={9} />

          <div className="geecko-candidate-cv-form__import-status">
            {(cvImportStatus === 'in-progress' || cvImportStatus === 'queued') &&
              'Заполняем информацию. В среднем это занимает одну минуту...'}
            {cvImportStatus === 'finished' &&
              'Готово! Вам осталось только проверить корректность информации и дозаполнить недостающую.'}
            {cvImportStatus === 'failed' &&
              'К сожалению мы не смогли импортировать резюме. Пожалуйста, попробуйте еще раз или заполните его самостоятельно.'}
            {!cvImportStatus && <>&nbsp;</>}
          </div>
        </div>

        <div className="geecko-form__buttons-line geecko-form__buttons-line--margin-lg">
          {(!cvImportStatus ||
            cvImportStatus === 'in-progress' ||
            cvImportStatus === 'queued' ||
            cvImportStatus === 'failed') && (
            <>
              <div className="geecko-form__buttons-line__button">
                <Button
                  color="primary"
                  type="submit"
                  size="lg"
                  isLoading={cvStartLoading || cvData.loading || cvImportStatus === 'in-progress' || cvImportStatus === 'queued'}
                >
                  Импортировать
                </Button>
              </div>
              <div className="geecko-form__buttons-line__button">
                <Button
                  color="secondary"
                  size="lg"
                  onClick={handleCvFillManual}
                  disabled={cvStartLoading || cvData.loading || cvImportStatus === 'in-progress' || cvImportStatus === 'queued'}
                >
                  {cvImportStatus === 'failed' ? 'Заполнить вручную' : 'Пропустить'}
                </Button>
              </div>
            </>
          )}
          {cvImportStatus === 'finished' && (
            <div className="geecko-form__buttons-line__button">
              <Button color="secondary" size="lg" onClick={handleCvFillManual}>
                Далее
              </Button>
            </div>
          )}
        </div>
      </div>
    </form>
  );
};

export default CVImportForm;
