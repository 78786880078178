import camelcaseKeys from 'camelcase-keys';
import { Location } from './Location';
import { SalaryExact } from './Salary';

export interface Candidate {
  id: string;
  fullName: string;
  photoUrl?: string;
  age: number;
  location: Location;
  salary: SalaryExact;
  badge?: {
    percent: number;
    name: string;
  };
}

export type CandidateSearchType = 'active' | 'passive' | 'off';
export type CandidateShareContactType = 'manual' | 'auto';

export interface AuthCandidate {
  id: number;
  fullName: string;
  profession: string;
  formProgress: string | null;
  searchStatus: CandidateSearchType;
  contactAccessType: CandidateShareContactType;
  isAnonymous: boolean;
  hash: string;
  surveyedAt?: string;
  sourceId?: 'sbercraft' | string;
  isSearchAvailable: boolean;
}

export interface CandidateWrapper {
  date: Date;
  candidate: Candidate;
  viewedAt: Date | null;
  daysInPipe?: number;
  commentsCount: number;
  rejectedReason: string | null;
  rejectedDate: Date | null;
  interviewDate?: Date | null;
  contactsShown?: boolean;
  response?: {
    isAccepted: boolean | null;
    createdAt: Date | null;
  };
}

export interface PipeCandidatesItem {
  status: string;
  candidatesCount: number;
  candidates: CandidateWrapper[];
}

export interface CandidatesCounters {
  active: number;
  leads: number;
  rejected: number;
}

export interface PipeCandidates {
  candidatesCounters: CandidatesCounters;
  pipe: PipeCandidatesItem[];
}

export const decodeCandidate = (json: any) => {
  const camelCasedJson: any = camelcaseKeys(json, { deep: true });
  const { salary } = json;
  salary.currency = salary.currency.toUpperCase();
  return {
    ...camelCasedJson,
    salary: {
      ...salary,
    },
  };
};

export const decodeAuthCandidate = (json: any): AuthCandidate => {
  const camelCasedKeys: any = camelcaseKeys(json, { deep: true });
  return camelCasedKeys;
};

export const decodeCandidateWrapper = (json: any) => {
  const camelCasedKeys: any = camelcaseKeys(json, { deep: true });
  return {
    ...camelCasedKeys,
    date: new Date(camelCasedKeys.date),
    viewedAt: (camelCasedKeys.viewedAt) ? new Date(camelCasedKeys.viewedAt) : null,
    rejectedDate: (camelCasedKeys.rejectedDate) ? new Date(camelCasedKeys.rejectedDate) : null,
    interviewDate: (camelCasedKeys.interviewDate) ? new Date(camelCasedKeys.interviewDate) : null,
    response: camelCasedKeys.response ? {
      ...camelCasedKeys.response,
      createdAt: camelCasedKeys.response.createdAt ? new Date(camelCasedKeys.response.createdAt) : null,
    } : undefined,
  };
};

export const decodePipeCandidatesItem = (json: any) => ({
  ...json,
  candidates: json.candidates.map(decodeCandidateWrapper),
});

export const decodePipeCandidates = (json: any): PipeCandidates => {
  const camelCasedJson: any = camelcaseKeys(json, { deep: true });
  return {
    ...camelCasedJson,
    pipe: camelCasedJson.pipe.map(decodePipeCandidatesItem),
  };
};
