import React from 'react';
import './Divider.css';

interface DividerProps {
  margin?: number;
}

const Divider: React.FC<DividerProps> = (props: DividerProps) => {
  const { margin = 0 } = props;
  return (
    <div className="geecko-divider" style={{ marginTop: margin, marginBottom: margin }} />
  );
};

export default Divider;
