import { createAsyncAction, createAction } from 'typesafe-actions';
import { User } from 'models/User';
import { Manager } from 'models/Manager';
import { AuthCandidate } from 'models/Candidate';
import { ResumeStatus } from 'models/Candidate/UserProfile';
import { AuthCompany } from 'models/Company/AuthCompany';
import { CandidateCvImport } from './state';

/**
 * Экшен выхода
 */
export const logoutAction = createAction('auth/LOGOUT')<string | undefined>();

/**
 * Получение данных о текущем пользователе
 */
export const fetchCurrentUserAction = createAsyncAction(
  'auth/FETCH_ME_REQUEST',
  'auth/FETCH_ME_SUCCESS',
  'auth/FETCH_ME_FAILURE',
)<undefined, undefined, Error>();

/**
 * Сохраняет токен авторизации
 */
export const setAuthTokenAction = createAction('auth/SET_TOKEN')<string | undefined>();

/**
 * Сохраняет текущего пользователя
 */
export const setAuthUserAction = createAction('auth/SET_USER')<User | undefined>();

/**
 * Сохраняет персонального менеджера текущего пользователя
 */
export const setAuthManagerAction = createAction('auth/SET_MANAGER')<Manager | undefined>();

/**
 * Сохраняет компанию текущего пользователя
 */
export const setAuthCompanyAction = createAction('auth/SET_COMPANY')<AuthCompany | undefined>();

/**
 * Сохраняет объект кандидата текущего пользователя
 */
export const setAuthCandidateAction = createAction('auth/SET_CANDIDATE')<AuthCandidate | undefined>();

/**
 * Сохраняет статус импорта CV текущего кандидата
 */
export const setCandidateCvImportStatusAction = createAction('auth/SET_CANDIDATE_CV_IMPORT_STATUS')<
  CandidateCvImport | undefined
>();

/**
 * Сохраняет статус проверки CV текущего кандидата
 */
export const setCandidateResumeStatusAction = createAction('auth/SET_CANDIDATE_RESUME_STATUS')<
  ResumeStatus | undefined
>();
