import { Notify } from 'models/Notify';
import { reject } from 'ramda';
import { AppState } from '../index';

export interface NotifyState {
  notifications: Notify[];
}

const defaultNotifyState = {
  notifications: [],
};

// Actions
const ADD_NOTIFICATION = 'marketplace/notify/add';
const DELETE_NOTIFICATION = 'marketplace/notify/delete';
const CLEAR_NOTIFICATIONS = 'marketplace/notify/clear';

// Reducer
export default function reducer(state: NotifyState = defaultNotifyState, action: any) {
  switch (action.type) {
    case ADD_NOTIFICATION:
      return {
        ...state,
        notifications: [...state.notifications, action.notification],
      };
    case DELETE_NOTIFICATION:
      return {
        ...state,
        notifications: reject((notification: Notify) => notification.id === action.notificationId, state.notifications),
      };
    case CLEAR_NOTIFICATIONS:
      return {
        ...state,
        notifications: [],
      };
    default:
      return state;
  }
}

// Action creators
export const addNotification = (notification: Notify) => ({ type: ADD_NOTIFICATION, notification });
export const deleteNotification = (notificationId: string) => ({ type: DELETE_NOTIFICATION, notificationId });
export const clearNotifications = () => ({ type: CLEAR_NOTIFICATIONS });

// Selectors
export const notificationsItemsSelector = (state: AppState) => state.notifications.notifications;
