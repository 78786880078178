import React, { ReactNode, useCallback } from 'react';
import './SidebarNav.css';
import Link from 'components/Link/Link';
import Divider from 'components/Divider/Divider';
import Progress from '../Progress/Progress';

interface Props {
  link: string;
  title: string;
  subtitle?: string;
  icon?: ReactNode;
  rightIcon?: ReactNode;
  active?: boolean;
  divided?: boolean;
  showProgress?: boolean;
  progress?: number;
  disabled?: boolean;
  fullWidth?: boolean;
}

const SidebarNavItem: React.FC<Props> = ({
  icon,
  rightIcon,
  divided,
  active,
  link,
  title,
  subtitle,
  showProgress,
  progress,
  disabled,
  fullWidth,
}) => {
  const renderMenuItem = useCallback(
    () => (
      <div
        className={[
          'geecko-sidebar-nav-item',
          active ? 'geecko-sidebar-nav-item--active' : '',
          disabled ? 'geecko-sidebar-nav-item--disabled' : '',
        ].join(' ')}
      >
        <div
          className={['geecko-sidebar-nav-item__content', fullWidth ? 'geecko-sidebar-nav-item__content--fullwidth' : ''].join(
            ' ',
          )}
        >
          {icon && <div className="geecko-sidebar-nav-item__icon">{icon}</div>}
          <div className="geecko-sidebar-nav-item__text">
            <div>{title}</div>
            {subtitle && <span>{subtitle}</span>}
          </div>
          {rightIcon && <div className="geecko-sidebar-nav-item__right-icon">{rightIcon}</div>}
        </div>
        {showProgress && (
          <div className="geecko-sidebar-nav-item__progress-wrapper">
            <Progress value={progress || 0} height={2} color="success" />
          </div>
        )}
      </div>
    ),
    [title, subtitle, active, disabled, icon, showProgress, progress],
  );

  return (
    <>
      {!disabled && (
        <>
          <Link to={link} className="geecko-anchor">
            {renderMenuItem()}
          </Link>
        </>
      )}
      {disabled && renderMenuItem()}
      {divided && <Divider />}
    </>
  );
};

export default SidebarNavItem;
