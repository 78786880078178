import React, { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Skill, SkillSetInterface } from '../../../models/Tech/skillset';
import './SkillSet.css';
import StackTag from '../../../components/StackTag/StackTag';
import SkillSetTag from './SkillSetTag';

interface Props {
  skills: SkillSetInterface;
  specializations: { id: string; name: string }[];
  showTooltips?: boolean;
}

const skillSortRule = (left: Skill, right: Skill): number => {
  if (left.isPrimary !== right.isPrimary) {
    return left.isPrimary ? -1 : 1;
  }

  const leftExperience = left.experienceMonths || 0;
  const rightExperience = right.experienceMonths || 0;

  if (leftExperience !== rightExperience) {
    return leftExperience > rightExperience ? -1 : 1;
  }

  if (left.popularity !== right.popularity) {
    return left.popularity > right.popularity ? -1 : 1;
  }

  return 0;
};

const SkillSet: FC<Props> = ({ specializations, skills, showTooltips }) => {
  const intl = useIntl();
  const languages = useMemo(() => skills.lang.filter((item) => [1, 2].indexOf(item.level) >= 0).sort(skillSortRule), [
    skills.lang,
  ]);
  const databases = useMemo(() => skills.db.filter((item) => [1, 2].indexOf(item.level) >= 0).sort(skillSortRule), [
    skills.db,
  ]);
  const technologies = useMemo(
    () => skills.tech.filter((item) => [1, 2].indexOf(item.level) >= 0).sort(skillSortRule),
    [skills.tech],
  );
  const otherTools = useMemo(() => {
    return [
      ...skills.lang.filter((item) => !item.level || item.level > 2),
      ...skills.db.filter((item) => !item.level || item.level > 2),
      ...skills.tech.filter((item) => !item.level || item.level > 2),
    ];
  }, [skills]);

  return (
    <div className="geecko-skillset__wrapper">
      <div className="geecko-skillset">
        <div className="geecko-skillset__group">
          {specializations && specializations.length > 0 && (
            <div className="geecko-skillset__group-language">
              <div className="geecko-skillset__group-title">{intl.formatMessage({ id: 'app.specialization' })}</div>
              {specializations.map((specialization) => (
                <StackTag key={specialization.id} label={specialization.name} group />
              ))}
            </div>
          )}
          {languages.length > 0 && (
            <div className="geecko-skillset__group-language">
              <div className="geecko-skillset__group-title">{intl.formatMessage({ id: 'app.languages' })}</div>
              <div className="geecko-skillset__group-item">
                {languages.map((language) => (
                  <SkillSetTag
                    key={language.id}
                    title={language.name}
                    experienceMonths={language.experienceMonths}
                    experienceFrom={language.experienceFrom}
                    experienceTo={language.experienceTo}
                    isPrimary={language.isPrimary}
                    showTooltips={showTooltips}
                  />
                ))}
              </div>
            </div>
          )}
          {databases.length > 0 && (
            <div className="geecko-skillset__group-language">
              <div className="geecko-skillset__group-title">{intl.formatMessage({ id: 'app.databases' })}</div>
              <div className="geecko-skillset__group-item">
                {databases.map((database) => (
                  <SkillSetTag
                    key={database.id}
                    title={database.name}
                    experienceMonths={database.experienceMonths}
                    experienceFrom={database.experienceFrom}
                    experienceTo={database.experienceTo}
                    isPrimary={database.isPrimary}
                    showTooltips={showTooltips}
                  />
                ))}
              </div>
            </div>
          )}
          {technologies.length > 0 && (
            <div className="geecko-skillset__group-language">
              <div className="geecko-skillset__group-title">{intl.formatMessage({ id: 'app.technology' })}</div>
              <div className="geecko-skillset__group-item">
                {technologies.map((technology) => (
                  <SkillSetTag
                    key={technology.id}
                    title={technology.name}
                    experienceMonths={technology.experienceMonths}
                    experienceFrom={technology.experienceFrom}
                    experienceTo={technology.experienceTo}
                    isPrimary={technology.isPrimary}
                    showTooltips={showTooltips}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      {otherTools.length > 0 && (
        <div className="geecko-skillset__other-wrapper">
          <div className="geecko-skillset__other-title">
            <div className="geecko-skillset__group-title">{intl.formatMessage({ id: 'app.other' })}</div>
          </div>
          {otherTools.map((tool) => (
            <div key={tool.id} className="geecko-skillset__other-item">
              {tool.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SkillSet;
