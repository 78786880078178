import React, { CSSProperties } from 'react';
import './Paper.css';
import classNames from 'classnames';

interface PaperProps {
  bordered?: boolean;
  className?: string;
  withPadding?: boolean;
  style?: CSSProperties;
  transparent?: boolean;
  spacing?: number;
  isNew?: boolean;
  straightBoreder?: boolean;
  straightBorederFirst?: boolean;
}

const Paper: React.FC<PaperProps> = ({
  children,
  className,
  bordered,
  transparent,
  style = {},
  spacing,
  withPadding,
  isNew,
  straightBoreder,
  straightBorederFirst,
  ...other
}) => {
  const addSpacing = typeof spacing !== 'undefined' ? { marginBottom: spacing * 5 } : {};
  return (
    <>
      <div
        className={classNames(
          {
            'geecko-paper': true,
            'geecko-paper--bordered': bordered,
            'geecko-paper--with-padding': withPadding,
            'geecko-paper--transparent': transparent,
            'geecko-paper--new': isNew,
            'geecko-paper--straight-border': straightBoreder,
            'geecko-paper--straight-border-first': straightBorederFirst,
          },
          className,
        )}
        style={{ ...style, ...addSpacing }}
        {...other}
      >
        {children}
      </div>
    </>
  );
};

export default Paper;
