/* eslint-disable no-restricted-globals,no-alert,@typescript-eslint/ban-ts-ignore */
import React, { FC } from 'react';
import debounce from 'lodash/debounce';
import { Field, Form, FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { useSelector, useDispatch } from 'react-redux';
import { useApiInstance } from 'api';
import { candidateCvEditDataSelector } from 'store/ducks/candidate/selectors';
import { updateCandidateCvFillRateForStep } from 'store/ducks/candidate/actionCreators';
import {
  calculateCvFormFillPercent,
  CvFormValues,
  CvFormValuesUniversityItem,
  getCandidateEducationItem,
  loadUniversitiesOptions,
  submitCvFormValues,
} from 'models/CV/CvFormValues';
import Grid from 'components/Grid/Grid';
import Label from 'components/Form/Label/Label';
import { CheckRequired } from 'components/FormAdapter/FormAdapter';
import TrashRounded from 'components/Icons/TrashRounded/TrashRounded';
import Text from 'components/v2/Text/Text';
import FormDivider from 'components/Form/FormDivider/FormDivider';
import DateRangeFormField from 'components/Form/DateRangeFormField/DateRangeFormField';
import { AsyncSelectLoadOptionsHandler } from 'components/AsyncSelect/AsyncSelect';
import { CommonEntity } from 'models/BaseTypes';
import { AsyncSelectFinalForm } from 'components/final-form-adapters/AsyncSelectFinalForm';
import { University } from 'models/University';
import FormBigSkeleton from 'components/v2/skeletons/FormSkeletons/FormBigSkeleton';
import { Immutable, castDraft } from 'immer';
import Button from 'components/v2/Button/Button';
import CVFormFooter from './CVFormFooter';
import { authCandidateHasCvSelector, authCandidateCvShowImportSelector } from '../../store/ducks/auth/selectors';
import { CvFormStep } from '../../../pages/Candidate/Cv/CandidateEditCvPage/CandidateEditCvPage';

interface Props {
  nextStep?: CvFormStep;
  previousStep?: CvFormStep;
}

const CVEducationForm: FC<Props> = ({ previousStep, nextStep }) => {
  const api = useApiInstance();
  const candidateHasCv = useSelector(authCandidateHasCvSelector);
  const cvData = useSelector(candidateCvEditDataSelector);
  const dispatch = useDispatch();
  const showCvImport = useSelector(authCandidateCvShowImportSelector);
  const handleFormSubmit = async (cvFormData: Immutable<CvFormValues>) =>
    submitCvFormValues(showCvImport, dispatch, api, castDraft(cvFormData), 'education', !candidateHasCv);
  const handleLoadUniversitiesOptions: AsyncSelectLoadOptionsHandler<University> = (inputValue: string) =>
    loadUniversitiesOptions(api, inputValue);

  const handleUpdateFillRate = debounce((formValues: CvFormValues) => {
    const fillRate = calculateCvFormFillPercent(formValues, 'education');
    dispatch(updateCandidateCvFillRateForStep({ step: 'education' as CvFormStep, fillRate }));
  }, 500);

  return (
    <>
      <div className="geecko-paper__wrapper">
        <div className="geecko-candidate-cv-form__form-header-wrapper">
          <div className="geecko-candidate-cv-form__dot-bg geecko-candidate-cv-form__form-header-dot--1" />
          <div className="geecko-candidate-cv-form__dot-bg geecko-candidate-cv-form__form-header-dot--2" />
          <Text variant="h1" tag="h1">
            Образование
          </Text>
        </div>

        {cvData.loading && (
          <>
            <FormBigSkeleton />
            <FormBigSkeleton />
          </>
        )}
      </div>

      {cvData.loaded && cvData.item && (
        <Form<Immutable<CvFormValues>>
          onSubmit={handleFormSubmit}
          initialValues={cvData.item}
          mutators={{
            ...arrayMutators,
          }}
          render={({ handleSubmit, form, submitting }) => (
            <form onSubmit={handleSubmit} encType="multipart/form-data" className="geecko-company-edit-profile__form">
              <>
                <FormSpy subscription={{ values: true }}>
                  {(props) => {
                    handleUpdateFillRate(props.values as CvFormValues);
                    return null;
                  }}
                </FormSpy>
                <FieldArray<CvFormValuesUniversityItem> name="education">
                  {({ fields }) => (
                    <div>
                      {fields.map((fieldName, fieldIndex) => {
                        const fieldValue = fields.value[fieldIndex];
                        return (
                          <div key={fieldValue.uid} className="qa-institution-container">
                            <FormDivider />
                            <div className="geecko-paper__wrapper">
                              <div className="geecko-form__item">
                                <Grid container spacing={4}>
                                  <Grid item xs={12} md={6}>
                                    <Label required>Учебное заведение</Label>
                                    <Field<CommonEntity>
                                      name={`${fieldName}.university`}
                                      className="qa-institution-input"
                                      // @ts-ignore
                                      component={AsyncSelectFinalForm}
                                      async
                                      loadOnInit
                                      valueKey="id"
                                      labelKey="name"
                                      loadOptions={handleLoadUniversitiesOptions}
                                      validate={CheckRequired}
                                      creatable
                                      placeholder="Выберите..."
                                    />
                                  </Grid>
                                  <Grid item xs={12} md={6}>
                                    <Label>Факультет</Label>
                                    <Field<CommonEntity>
                                      name={`${fieldName}.faculty`}
                                      className="qa-faculty-input"
                                      // @ts-ignore
                                      component={AsyncSelectFinalForm}
                                      plaholder="Выберите факультет..."
                                      valueKey="value"
                                      labelKey="label"
                                      validate={CheckRequired}
                                      options={
                                        fieldValue.university && fieldValue.university.faculties
                                          ? fieldValue.university.faculties.map((university) => ({
                                              value: university.id.toString(),
                                              label: university.name,
                                            }))
                                          : []
                                      }
                                      creatable
                                      isDisabled={!fieldValue.university}
                                    />
                                  </Grid>
                                </Grid>
                              </div>
                              <div className="geecko-form__item">
                                <Label>Период учебы</Label>
                              </div>
                              <DateRangeFormField namePrefix={fieldName} />
                              <div className="geecko-form__buttons-line qa-delete-institution-button">
                                <Button
                                  variant="ghost"
                                  color="danger"
                                  size="sm"
                                  iconLeft={<TrashRounded size={15} />}
                                  onClick={() => {
                                    if (
                                      (fieldValue.university || fieldValue.faculty) &&
                                      !confirm(
                                        'Вы действительно хотите удалить эту компанию включая все проекты? Это действие нельзя отменить',
                                      )
                                    ) {
                                      return;
                                    }
                                    fields.remove(fieldIndex);
                                  }}
                                >
                                  Удалить заведение
                                </Button>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      <FormDivider
                        text={fields.value.length === 0 ? 'Добавьте учебное заведение' : undefined}
                        onClick={() => {
                          fields.push(getCandidateEducationItem());
                        }}
                      />
                    </div>
                  )}
                </FieldArray>

                <div className="geecko-paper__wrapper">
                  <CVFormFooter
                    candidateHasCv={candidateHasCv}
                    nextStep={nextStep}
                    previousStep={previousStep}
                    submitting={submitting}
                  />
                </div>
              </>
            </form>
          )}
        />
      )}
    </>
  );
};

export default CVEducationForm;
