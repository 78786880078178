import React, { FC } from 'react';

interface Props {
  size?: number;
}

const ShieldIcon: FC<Props> = ({ size = 20 }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.99987 18.1818C9.99987 18.1818 16.3635 15.0909 16.3635 10.4546V5.04548L9.99987 2.72729L3.63623 5.04548V10.4546C3.63623 15.0909 9.99987 18.1818 9.99987 18.1818Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default ShieldIcon;
