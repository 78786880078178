import camelcaseKeys from 'camelcase-keys';
import { User } from 'models/User';
import ProTariffEmoji from 'components/Icons/Emoji/ProTariffEmoji';
import CoronaIcon from 'components/Icons/CoronaIcon';
import { Immutable } from 'immer';
import { Location } from './Location';
import { VacancySalary } from './Salary';
import { Language } from './BaseTypes';
import { CompanyProfile } from './CompanyProfile';
import { CompanyOffice } from './CompanyOffice';

export type Status = {
  value: string;
  name: string;
};

type SeniorityValue = 'junior' | 'middle' | 'senior' | 'lead';
export type PositionValue = 'office' | 'remote' | 'prefer-office' | 'prefer-remote' | 'on-demand';
// TODO удалить и проверить, что ничего не сломается
export type VacancyWorkType = 'office' | 'remote';

export type VacanciesCount = {
  active: number;
  archived: number;
  draft: number;
};
export type VacanciesSort = 'new' | 'created_at';

export type PositionType = {
  value: PositionValue;
  name: string;
};

export type VacancyTariffType = 'pro' | 'pro.corona';

export interface VacancyTariff {
  title: string;
  vacancyPrice: number;
  contactPrice?: number;
  employmentPrice?: string;
  icon: React.FunctionComponent;
}

export const vacancyTariffs: { [tariff: string]: VacancyTariff } = {
  pro: {
    title: 'Про-подбор',
    vacancyPrice: 0,
    employmentPrice: 'Месячный оклад',
    icon: ProTariffEmoji,
  },
  'pro.corona': {
    title: 'Корона-подбор',
    vacancyPrice: 15000,
    employmentPrice: 'Бесплатно для Junior, для остальных 15000 после выхода на работу',
    icon: CoronaIcon,
  },
  mp: {
    title: 'Маркетплейс',
    vacancyPrice: 0,
    employmentPrice: 'Бесплатно для Junior, для остальных 15000 после выхода на работу',
    icon: CoronaIcon,
  },
};

export type Seniority = {
  value: SeniorityValue;
  name: string;
};

export type Candidates = {
  active: number;
  rejected: number;
  need: number;
  employed: number;
  unviewed: number;
  unreviewed: number;
};

export interface Tariff {
  id: string;
  name: string;
}

export type Vacancy = Immutable<{
  id: number;
  name: string;
  status: Status;
  location: Location;
  seniority: Seniority[];
  position: PositionType;
  workType: VacancyWorkType[];
  salary: VacancySalary;
  skillset: string[];
  tariff: Tariff;
  candidates: Candidates;
  createdAt: Date;
  resolvedAt: Date;
  updatedAt: Date;
  hasRelocationSupport: boolean;
  companyResponsible?: User;
  isArchived: boolean;
  balance?: {
    amount: number;
    currency: string;
  };
  viewedAt?: string;
}>

export const decodeVacancy = (json: any): Vacancy => {
  const jsonCamelCase: any = camelcaseKeys(json, { deep: true });

  return {
    ...jsonCamelCase,
    createdAt: jsonCamelCase.createdAt ? new Date(jsonCamelCase.createdAt) : null,
    resolvedAt: jsonCamelCase.resolvedAt ? new Date(jsonCamelCase.resolvedAt) : null,
    updatedAt: jsonCamelCase.updatedAt ? new Date(jsonCamelCase.updatedAt) : null,
    seniority: !jsonCamelCase.seniority.length ? [jsonCamelCase.seniority] : jsonCamelCase.seniority,
  };
};

export interface PartialVacancy {
  id: number;
  tariff: Tariff;
  name: string;
  company: CompanyProfile;
  salary: VacancySalary;
  workType: VacancyWorkType[];
  tech: {
    seniority: string[];
    specializations: { name: string }[];
    isSkillsRequired: boolean;
    skillset: any;
    tools?: {
      id: number;
      name: string;
      image: string;
      link: string;
    }[];
  };
  office?: {
    id: number;
    name?: string;
    address: string;
    dadataAddress: any | null;
  };
}

export interface PublicVacancy extends PartialVacancy {
  quantity: number;
  location?: Location;
  companyResponsible?: User;
  companyResponsibleId?: number;
  isResponsibleVisible?: boolean;
  hasRelocationSupport: boolean;
  team?: {
    id: number;
    name: string;
    lineup: string;
  };
  languages?: Language[];
  info: {
    about: string;
    tasks: string;
    requirements?: string;
    benefits?: string;
  };
  workFeatures?: {
    id: number;
    name: string;
  }[];
  contactRequest?: {
    isConfirmed: boolean | null;
    createdAt: Date | null;
  };
  response?: {
    isAccepted: boolean | null;
    createdAt: Date | null;
  };
  permissions?: string[];
  isArchived: boolean;
  archivedAt: Date | null;
  isFeatured: boolean;
  status: {
    name: string;
    value: string;
  };
  viewedAt?: string;
}

export const decodePublicVacancy = (json: any): PublicVacancy => {
  const jsonCamelCase: any = camelcaseKeys(json, { deep: true });

  return {
    ...jsonCamelCase,
    tech: {
      ...jsonCamelCase.tech,
      seniority: !jsonCamelCase?.tech?.seniority?.length
        ? [jsonCamelCase.tech.seniority]
        : jsonCamelCase.tech.seniority,
    },
    contactRequest: jsonCamelCase.contactRequest
      ? {
          ...jsonCamelCase.contactRequest,
          createdAt: jsonCamelCase.contactRequest.createdAt ? new Date(jsonCamelCase.contactRequest.createdAt) : null,
        }
      : undefined,
    response: jsonCamelCase.response
      ? {
          ...jsonCamelCase.response,
          createdAt: jsonCamelCase.response.createdAt ? new Date(jsonCamelCase.response.createdAt) : null,
        }
      : undefined,
  };
};

export interface VacanciesCounters {
  active: number;
  rejected: number;
}

export interface PipeVacancy {
  id: number;
  name: string;
  workType: VacancyWorkType[];
  salary: VacancySalary;
  hasRelocationSupport: boolean;
}

export interface VacancyWrapper {
  contactRequest?: {
    isConfirmed: boolean;
    createdAt: Date | null;
  };
  contactsShown: boolean;
  createdAt: Date | null;
  viewedAt: Date | null;
  vacancy: PipeVacancy;
  company: CompanyProfile;
  office: CompanyOffice;
}

export interface PipeVacanciesItem {
  status: string;
  vacanciesCount: number;
  vacancies: VacancyWrapper[];
}

export type VacancyMatchingStatus = 'in-progress' | 'finished';

export interface PipeVacancies {
  vacanciesCounters?: VacanciesCounters;
  pipe: PipeVacanciesItem[];
  matchingRun?: {
    status: VacancyMatchingStatus;
  };
}

export const decodeVacancyWrapper = (json: any) => {
  const camelCasedKeys: any = camelcaseKeys(json, { deep: true });
  return {
    ...camelCasedKeys,
    date: new Date(camelCasedKeys.date),
    createdAt: camelCasedKeys.createdAt ? new Date(camelCasedKeys.createdAt) : null,
    viewedAt: camelCasedKeys.viewedAt ? new Date(camelCasedKeys.viewedAt) : null,
    rejectedDate: camelCasedKeys.rejectedDate ? new Date(camelCasedKeys.rejectedDate) : null,
    interviewDate: camelCasedKeys.interviewDate ? new Date(camelCasedKeys.interviewDate) : null,
  };
};

export const decodePipeVacanciesItem = (json: any) => ({
  ...json,
  vacancies: json.vacancies.map(decodeVacancyWrapper),
});

export const decodePipeVacancies = (json: any): PipeVacancies => {
  const camelCasedJson: any = camelcaseKeys(json, { deep: true });
  return {
    ...camelCasedJson,
    pipe: camelCasedJson.pipe.map(decodePipeVacanciesItem),
  };
};
