import React, { ReactNode, FC, CSSProperties, useMemo } from 'react';
import Modal from 'components/Modal/Modal';
import Text from 'components/v2/Text/Text';
import classNames from 'classnames';
import CancelCircle from 'components/Icons/CancelCircle/CancelCircle';
import './Dialog.css';
import { nanoid } from 'nanoid';
import { useDispatch } from 'react-redux';
import { modalClosed } from 'store/ducks/settings';
import { IFocusableElement } from 'models/BaseTypes';

interface Props {
  title: ReactNode;
  isOpen: boolean;
  modalBaseId?: string;
  modalStyle?: CSSProperties;
  modalClassName?: string;
  closeOnBgClick?: boolean;
  handlerRef?: IFocusableElement & HTMLElement | null;
  onClose: () => void;
}

const Dialog: FC<Props> = ({
  title,
  isOpen,
  closeOnBgClick,
  modalBaseId,
  modalStyle,
  modalClassName,
  handlerRef,
  onClose,
  children,
}) => {
  const modalId = useMemo(() => modalBaseId || nanoid(), []);
  const dispatch = useDispatch();

  const handleCloseTasks = () => {
    onClose();
    if (handlerRef) {
      handlerRef.focus();
    }
  };

  const handleClose = () => {
    dispatch(modalClosed(modalId));
    handleCloseTasks();
  };
  return (
    <Modal open={isOpen} modalBaseId={modalId} handlerRef={handlerRef} closeOnBg={closeOnBgClick} onClose={handleCloseTasks}>
      <div className={classNames('geecko-v2-dialog__modal', modalClassName)} style={modalStyle}>
        <div className="geecko-v2-dialog__modal-close-wrapper">
          <button className="geecko-v2-dialog__modal-close-button" type="button" onClick={handleClose}>
            <CancelCircle size={22} />
          </button>
        </div>
        <div>
          <div className="geecko-v2-dialog__title">
            <Text tag="h1" variant="h1">
              {title}
            </Text>
          </div>
          {children}
        </div>
      </div>
    </Modal>
  );
};

export default Dialog;
