import { ActionType, getType } from 'typesafe-actions';
import { initialAuthState, AuthState } from "./state";
import * as AuthAction from './actionCreators';

type AuthActionType = ActionType<typeof AuthAction>;

export const authReducer = (state: AuthState = initialAuthState, action: AuthActionType): AuthState => {
  switch (action.type) {
    case getType(AuthAction.fetchCurrentUserAction.request): {
      return {
        ...state,
        loading: true,
      }
    }

    case getType(AuthAction.fetchCurrentUserAction.success): {
      return {
        ...state,
        loading: false,
      }
    }

    case getType(AuthAction.fetchCurrentUserAction.failure): {
      return {
        ...state,
        loading: false,
      }
    }

    case getType(AuthAction.setAuthTokenAction): {
      return {
        ...state,
        token: action.payload,
      }
    }

    case getType(AuthAction.setAuthUserAction): {
      return {
        ...state,
        user: action.payload,
      }
    }

    case getType(AuthAction.setAuthManagerAction): {
      return {
        ...state,
        manager: action.payload,
      }
    }

    case getType(AuthAction.setAuthCompanyAction): {
      return {
        ...state,
        company: action.payload,
      }
    }

    case getType(AuthAction.setAuthCandidateAction): {
      return {
        ...state,
        candidate: action.payload,
      }
    }

    case getType(AuthAction.setCandidateCvImportStatusAction): {
      return {
        ...state,
        candidateCvImport: action.payload,
      }
    }

    case getType(AuthAction.setCandidateResumeStatusAction): {
      return {
        ...state,
        resumeStatus: action.payload,
      }
    }
  
    default:
      return state;
  }
};
