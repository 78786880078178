import React, {
  HTMLProps, FocusEvent, ReactNode, useState,
} from 'react';
import Check from '../Check/Check';
import './Checkbox.css';

interface Props extends HTMLProps<HTMLInputElement> {
  name?: string;
  label?: string;
  labelChecked?: string;
  checked?: boolean;
  disabled?: boolean;
  required?: boolean;
  valid?: boolean;
  validationError?: string;
  submitError?: string;
  value?: any;
  icon?: ReactNode;
  iconChecked?: ReactNode;
  overflow?: boolean;
}

const Checkbox: React.FC<Props> = (props: Props) => {
  const {
    name,
    checked,
    label,
    labelChecked,
    disabled,
    required,
    onChange,
    valid,
    validationError,
    submitError,
    value,
    onFocus,
    onBlur,
    icon,
    iconChecked,
    overflow = false,
  } = props;
  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    if (onChange) onChange(event);
  };
  const [focused, setFocused] = useState(false);

  const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
    setFocused(true);
    if (onFocus) {
      onFocus(event);
    }
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    setFocused(false);
    if (onBlur) {
      onBlur(event);
    }
  };

  const htmlFor = `checkbox_${name}`;
  return (
    <>
      <label aria-label={labelChecked || label} className={`geecko-checkbox ${checked ? ' geecko-checkbox--checked' : ''}${focused ? ' geecko-checkbox--focused' : ''}`}>
        <input
          id={htmlFor}
          className="geecko-checkbox__input"
          type="checkbox"
          disabled={disabled}
          required={required}
          value={value}
          onChange={handleChange}
          checked={checked}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
        {icon && iconChecked ? (
          <span
            className={`geecko-checkbox__icon
              ${valid === false ? ' geecko-checkbox__icon--invalid' : ''}`}
          >
            {checked ? iconChecked : icon}
          </span>
        ) : (
          <Check isChecked={checked} isInvalid={valid === false} />
        )}
        <span className={`geecko-checkbox__label${overflow ? ' geecko-checkbox__label--overflow' : ''}`}>
          {checked && labelChecked ? labelChecked : label}
        </span>
      </label>
      {(validationError || submitError) && (
        <div className="geecko-textfield-error">{validationError || submitError}</div>
      )}
    </>
  );
};

export default Checkbox;
