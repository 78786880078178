/* eslint-disable no-restricted-globals,no-alert,@typescript-eslint/ban-ts-ignore */
import React, { FC, memo } from 'react';
import { Form, Field, FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { useSelector, useDispatch } from 'react-redux';
import { useApiInstance } from 'api';
import debounce from 'lodash/debounce';
import {
  authCandidateHasCvSelector,
  authCandidateSelector,
  authCandidateCvShowImportSelector,
} from 'store/ducks/auth/selectors';
import { setAuthCandidateAction } from 'store/ducks/auth/actionCreators';
import { InputFormField } from 'components/v2/FinalForm/Fields/InputFormField';
import { candidateCvEditDataSelector } from 'store/ducks/candidate/selectors';
import FormBigSkeleton from 'components/v2/skeletons/FormSkeletons/FormBigSkeleton';
import { Immutable, castDraft } from 'immer';
import { updateCandidateCvFillRateForStep } from 'store/ducks/candidate/actionCreators';
import Button from 'components/v2/Button/Button';
import {
  calculateCvFormFillPercent,
  CONTACTS_CHANNELS,
  CvFormValues,
  CvFormValuesContact,
  submitCvFormValues,
} from '../../models/CV/CvFormValues';
import Alert from '../../components/Alert/Alert';
import ShieldIcon from '../../components/Icons/ShieldIcon';
import Grid from '../../components/Grid/Grid';
import Label from '../../components/Form/Label/Label';
import { CheckEmail, CheckRequired, validateBirthDate } from '../../components/FormAdapter/FormAdapter';
import InfoCircleIcon from '../../components/Icons/InfoCircleIcon';
import { CvFormStep } from '../../../pages/Candidate/Cv/CandidateEditCvPage/CandidateEditCvPage';
import TrashRounded from '../../components/Icons/TrashRounded/TrashRounded';
import FormPreventAwayUnsaved from '../../components/FormPreventAwayUnsaved/FormPreventAwayUnsaved';
import PhoneField from '../../components/Form/PhoneInput/PhoneField';
import PlusCircle from '../../components/Icons/PlusCircle/PlusCircle';
import PhotoUploader from '../../components/v2/PhotoUploader/PhotoUploader';
import CVFormFooter from './CVFormFooter';
import { InputMaskedField } from '../../components/final-form-adapters/InputMaskedField';
import Text from '../../components/v2/Text/Text';
import { AsyncSelectFinalForm } from '../../components/final-form-adapters/AsyncSelectFinalForm';

interface Props {
  nextStep?: CvFormStep;
  previousStep?: CvFormStep;
}

const CVPersonalForm: FC<Props> = memo(({ previousStep, nextStep }) => {
  const dispatch = useDispatch();
  const cvData = useSelector(candidateCvEditDataSelector);
  const candidate = useSelector(authCandidateSelector);
  const api = useApiInstance();
  const candidateHasCv = useSelector(authCandidateHasCvSelector);
  const showCvImport = useSelector(authCandidateCvShowImportSelector);
  const handleFormSubmit = async (cvFormData: Immutable<CvFormValues>) =>
    submitCvFormValues(showCvImport, dispatch, api, castDraft(cvFormData), 'personal', !candidateHasCv).then(
      (result) => {
        if (candidate) dispatch(setAuthCandidateAction({ ...candidate, fullName: cvFormData.fullName }));
        return result;
      },
    );

  const handleUpdateFillRate = debounce((formValues: CvFormValues) => {
    const fillRate = calculateCvFormFillPercent(formValues, 'personal');
    dispatch(updateCandidateCvFillRateForStep({ step: 'personal' as CvFormStep, fillRate }));
  }, 500);

  return (
    <div className="geecko-paper__wrapper">
      <div className="geecko-candidate-cv-form__form-header-wrapper">
        <div className="geecko-candidate-cv-form__dot-bg geecko-candidate-cv-form__form-header-dot--1" />
        <div className="geecko-candidate-cv-form__dot-bg geecko-candidate-cv-form__form-header-dot--2" />
        <Grid container spacing={4}>
          <Grid item xs={12} md={7}>
            <Text variant="h1" tag="h1">
              Персональные
              <br />
              данные
            </Text>
          </Grid>
          <Grid item xs={12} md={5}>
            <Text variant="h3">Никакого спама</Text>
            <div className="geecko-util__mt-2">
              <Text variant="md" color="#646D7B">
                Ни одна компания не получит ваши персональные данные без вашего на то согласия! По умолчанию вся
                персональная информация в вашем профиле скрыта
              </Text>
            </div>
          </Grid>
        </Grid>
      </div>

      {cvData.loading && (
        <>
          <FormBigSkeleton />
          <FormBigSkeleton />
        </>
      )}

      {cvData.loaded && cvData.item && (
        <Form<Immutable<CvFormValues>>
          onSubmit={handleFormSubmit}
          initialValues={cvData.item}
          mutators={{
            ...arrayMutators,
          }}
          subscription={{ submitting: true }}
          render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit} encType="multipart/form-data" className="geecko-company-edit-profile__form">
              <FormPreventAwayUnsaved />

              <FormSpy subscription={{ values: true }}>
                {(props) => {
                  handleUpdateFillRate(props.values as CvFormValues);
                  return null;
                }}
              </FormSpy>

              <div className="geecko-form__item">
                <Label>Фото профиля</Label>
                <Field name="photoUrl">
                  {(props) => <PhotoUploader value={props.input.value} onChange={props.input.onChange} />}
                </Field>
              </div>

              <div className="geecko-form__item">
                <Alert
                  color="default"
                  icon={
                    <span style={{ color: '#143CCC' }}>
                      <ShieldIcon />
                    </span>
                  }
                >
                  <span className="geecko-text--bold">Мы защищаем</span> персональные данные пользователей и не передаем
                  информацию о вас третьим лицам без вашего согласия
                </Alert>
              </div>
              <div className="geecko-form__item">
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4}>
                    <Label required>Имя и фамилия</Label>
                    <InputFormField name="fullName" validate={CheckRequired} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Label required>Эл. почта</Label>
                    <InputFormField name="email" type="email" validate={CheckEmail} disabled />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Label required>Телефон</Label>
                    <PhoneField name="phone" />
                  </Grid>
                </Grid>
              </div>

              <div className="geecko-form__item">
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4}>
                    <Label required>Дата рождения</Label>
                    <InputMaskedField
                      name="birthDay"
                      mask={Date}
                      maskMax={new Date()}
                      lazy
                      placeholder="ДД.ММ.ГГГГ"
                      validate={validateBirthDate}
                    />
                  </Grid>
                </Grid>
              </div>

              <div className="geecko-form__item">
                <Label>Прочие контакты</Label>
                <Alert icon={<InfoCircleIcon />}>Будут использоваться как возможные способы связи с вами</Alert>
              </div>

              <FieldArray<CvFormValuesContact> name="contacts">
                {({ fields }) => {
                  const usedChannels = fields
                    .map((fieldsName, fieldIndex) => fields.value[fieldIndex].channelId)
                    .filter((channelId) => !!channelId);
                  return (
                    <div className="geecko-form__item">
                      {fields.map((fieldName, fieldIndex) => {
                        const fieldValue = fields.value[fieldIndex];
                        const channelOptions = CONTACTS_CHANNELS.map((channelOption) => ({
                          ...channelOption,
                          disabled:
                            usedChannels.indexOf(channelOption.value) >= 0 &&
                            channelOption.value !== fieldValue.channelId,
                        }));

                        return (
                          <Grid key={fieldName} container spacing={3} className="qa-contact-container">
                            <Grid item xs={12} md={4}>
                              <Field
                                name={`${fieldName}.channelId`}
                                className="qa-location-container"
                                // @ts-ignore
                                component={AsyncSelectFinalForm}
                                options={channelOptions}
                                valueKey="value"
                                labelKey="label"
                                plainValue
                                placeholder="Выберите способ..."
                                validate={CheckRequired}
                              />
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <InputFormField
                                name={`${fieldName}.value`}
                                type="text"
                                placeholder="Ваш ID"
                                validate={CheckRequired}
                              />
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <div className="geecko-form__densed-checkbox qa-delete-contact-button">
                                <Button
                                  variant="ghost"
                                  color="danger"
                                  size="sm"
                                  iconLeft={<TrashRounded />}
                                  onClick={() => {
                                    if (fieldValue.channelId && fieldValue.value) {
                                      if (
                                        !confirm(
                                          'Вы действительно хотите удалить этот способсвязи? Это действие нельзя отменить',
                                        )
                                      ) {
                                        return;
                                      }
                                    }
                                    fields.remove(fieldIndex);
                                  }}
                                >
                                  Удалить
                                </Button>
                              </div>
                            </Grid>
                          </Grid>
                        );
                      })}

                      <div className="geecko-form__item-buttons qa-add-contact-button">
                        <Button
                          variant="ghost"
                          color="secondary"
                          size="sm"
                          iconLeft={<PlusCircle size={15} />}
                          disabled={fields.value.length >= CONTACTS_CHANNELS.length}
                          onClick={() => {
                            fields.push({
                              channelId: '',
                              value: '',
                            });
                          }}
                        >
                          Добавить способ связи
                        </Button>
                      </div>
                    </div>
                  );
                }}
              </FieldArray>

              <CVFormFooter
                candidateHasCv={candidateHasCv}
                nextStep={nextStep}
                previousStep={previousStep}
                submitting={submitting}
              />
            </form>
          )}
        />
      )}
    </div>
  );
});

export default CVPersonalForm;
