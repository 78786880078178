import { Decorator, FormApi, ValidationErrors } from 'final-form';
import { getIn } from 'final-form';
import { scrollToElementWithOffset } from 'utils/scroll-to-element';

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

export function createScrollToErrorDecorator<FormValues = object>(): Decorator<FormValues> {
  return (form: FormApi<FormValues>) => {
    const focusOnFirstError = (errors: ValidationErrors) => {
      if (typeof document === 'undefined') {
        return;
      }
      const errorFieldName = form.getRegisteredFields().find(name => getIn(errors, name));
      if (errorFieldName) {
        const element = document.querySelector(`[name='${errorFieldName}']`);
        if (element) {
          scrollToElementWithOffset(element);
          if (typeof (element as HTMLInputElement).focus !== 'undefined') {
            // (element as HTMLInputElement).focus();
          }
        }
      }
    }
    // Save original submit function
    const originalSubmit = form.submit
  
    // Subscribe to errors, and keep a local copy of them
    let state: { errors?: ValidationErrors; submitErrors?: ValidationErrors } = {}
    const unsubscribe = form.subscribe(
      nextState => {
        state = nextState
      },
      { errors: true, submitErrors: true }
    )
  
    // What to do after submit
    const afterSubmit = () => {
      const { errors, submitErrors } = state
      if (errors && Object.keys(errors).length) {
        focusOnFirstError(errors)
      } else if (submitErrors && Object.keys(submitErrors).length) {
        focusOnFirstError(submitErrors)
      }
    }
  
    // Rewrite submit function
    // eslint-disable-next-line no-param-reassign
    form.submit = () => {
      const result = originalSubmit.call(form)
      if (result && typeof result.then === 'function') {
        // async
        result.then(afterSubmit, noop)
      } else {
        // sync
        afterSubmit()
      }
      return result
    }
  
    return () => {
      unsubscribe()
      // eslint-disable-next-line no-param-reassign
      form.submit = originalSubmit;
  
      return noop;
    }
  }
}
