import { takeLatest, getContext, put, call, select } from 'redux-saga/effects';
import { ServerApi } from 'modules/api/server-api';
import { decodeRejectReason, RejectReason } from 'models/Dictionary/RejectReason';
import { fetchSkillsTemplates } from 'api/dictionary/fetch-skills-templates';
import { SkillsTemplate } from 'models/Dictionary/SkillsTemplate';
import { fetchCandidateRejectReasons } from 'api/dictionary/fetch-candidate-reject-reasons';
import { VacancyState, VacancyStatusValue } from 'models/VacancyState';
import { Specialization } from 'models/Specialization';
import { fetchSpecializations } from 'api/dictionary/fetch-specializations';
import { fetchLanguages } from 'api/dictionary/fetch-languages';
import { Language, CommonEntity } from 'models/BaseTypes';
import { fetchCompanyScopes } from 'api/dictionary/fetch-company-scopes';
import { fetchWorkFeatures } from 'api/dictionary/fetch-work-features';
import {
  fetchVacancyRejectReasonsAction,
  fetchSkillsTemplatesAction,
  fetchCandidateRejectReasonsAction,
  fetchSpecializationsAction,
  fetchLanguagesAction,
  fetchCompanyScopesAction,
  fetchWorkFeaturesAction, fetchCompanyUsersAction, fetchCompanyOfficesAction,
} from './actionCreators';
import { selectCompanyRejectResumeId, selectCompanyRejectVacancyId } from '../company/selectors';
import { selectCandidateRejectingVacancy } from '../candidate/selectors';
import { CompanyUser } from '../../../models/CompanyUser';
import { fetchCompanyUsers } from '../../../api/dictionary/fetch-company-users';
import { CompanyOffice } from '../../../models/CompanyOffice';
import { fetchCompanyOffices } from '../../../api/dictionary/fetch-company-offices';

// TODO: Вынести в отдельный файл
const getVacancyRejectReasons = (api: ServerApi) => async (status?: VacancyStatusValue) => {
  const response = await api.get(`/v3/dictionary/reject-reasons?type=candidate${status ? `&status=${status}` : ''}`);
  return response && response.data ? response.data.map(decodeRejectReason) : [];
};

/**
 * Специализации
 */
function* dictionarySpecializations() {
  try {
    const api: ServerApi = yield getContext('api');
    const specializations: Specialization[] = yield call(fetchSpecializations(api));
    yield put(fetchSpecializationsAction.success(specializations));
  } catch (e) {
    yield put(fetchSpecializationsAction.failure(e));
  }
}

/**
 * Предметные области
 */
function* dictionaryCompanyScopes() {
  try {
    const api: ServerApi = yield getContext('api');
    const companyScopes: CommonEntity[] = yield call(fetchCompanyScopes(api));
    yield put(fetchCompanyScopesAction.success(companyScopes));
  } catch (e) {
    yield put(fetchCompanyScopesAction.failure(e));
  }
}

/**
 * Важно в работе
 */
function* dictionaryWorkFeatures() {
  try {
    const api: ServerApi = yield getContext('api');
    const workFeatures: CommonEntity[] = yield call(fetchWorkFeatures(api));
    yield put(fetchWorkFeaturesAction.success(workFeatures));
  } catch (e) {
    yield put(fetchWorkFeaturesAction.failure(e));
  }
}

/**
 * Иностранные языки
 */
function* dictionaryLanguages() {
  try {
    const api: ServerApi = yield getContext('api');
    const languages: Language[] = yield call(fetchLanguages(api));
    yield put(fetchLanguagesAction.success(languages));
  } catch (e) {
    yield put(fetchLanguagesAction.failure(e));
  }
}

/**
 * Причины отказов компаниям
 */
function* dictionaryVacancyRejectReasons() {
  try {
    const rejectingVacancyState: VacancyState | undefined = yield select(selectCandidateRejectingVacancy);
    const api: ServerApi = yield getContext('api');
    const status = rejectingVacancyState && rejectingVacancyState.status ? rejectingVacancyState.status.id : undefined;
    const rejectReasons: RejectReason[] = yield call(getVacancyRejectReasons(api), status);
    yield put(fetchVacancyRejectReasonsAction.success(rejectReasons));
  } catch (e) {
    yield put(fetchVacancyRejectReasonsAction.failure(e));
  }
}

/**
 * Причины отказов кандидатам
 */
function* dictionaryCandidateRejectReasons() {
  try {
    const api: ServerApi = yield getContext('api');
    const resumeId = yield select(selectCompanyRejectResumeId);
    const vacancyId = yield select(selectCompanyRejectVacancyId);
    const rejectReasons: RejectReason[] = yield call(fetchCandidateRejectReasons(api), resumeId, vacancyId);
    yield put(fetchCandidateRejectReasonsAction.success(rejectReasons));
  } catch (e) {
    yield put(fetchCandidateRejectReasonsAction.failure(e));
  }
}

function* dictionarySkillsTemplates() {
  try {
    const api: ServerApi = yield getContext('api');
    const skillsTemplates: SkillsTemplate[] = yield call(fetchSkillsTemplates(api));
    yield put(fetchSkillsTemplatesAction.success(skillsTemplates));
  } catch (e) {
    yield put(fetchSkillsTemplatesAction.failure(e));
  }
}

function* dictionaryCompanyUsers() {
  try {
    const api: ServerApi = yield getContext('api');
    const users: CompanyUser[] = yield call(fetchCompanyUsers(api));
    yield put(fetchCompanyUsersAction.success(users));
  } catch (e) {
    yield put(fetchCompanyUsersAction.failure(e));
  }
}

function* dictionaryCompanyOffices() {
  try {
    const api: ServerApi = yield getContext('api');
    const offices: CompanyOffice[] = yield call(fetchCompanyOffices(api));
    yield put(fetchCompanyOfficesAction.success(offices));
  } catch (e) {
    yield put(fetchCompanyOfficesAction.failure(e));
  }
}

export function* dictionarySaga() {
  yield takeLatest(fetchVacancyRejectReasonsAction.request, dictionaryVacancyRejectReasons);
  yield takeLatest(fetchCandidateRejectReasonsAction.request, dictionaryCandidateRejectReasons);
  yield takeLatest(fetchSkillsTemplatesAction.request, dictionarySkillsTemplates);
  yield takeLatest(fetchSpecializationsAction.request, dictionarySpecializations);
  yield takeLatest(fetchLanguagesAction.request, dictionaryLanguages);
  yield takeLatest(fetchWorkFeaturesAction.request, dictionaryWorkFeatures);
  yield takeLatest(fetchCompanyScopesAction.request, dictionaryCompanyScopes);
  yield takeLatest(fetchCompanyUsersAction.request, dictionaryCompanyUsers);
  yield takeLatest(fetchCompanyOfficesAction.request, dictionaryCompanyOffices);
}
