/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, {
  useEffect, MouseEvent, useMemo, useRef,
} from 'react';
import ReactDOM from 'react-dom';
import { IFocusableElement } from 'models/BaseTypes';
import { useHotkeys } from 'react-hotkeys-hook';
import './Modal.css';
import FocusLock from 'react-focus-lock';
import { nanoid } from 'nanoid';
import last from 'lodash/last';
import { useDispatch, useSelector } from 'react-redux';
import { modalClosed, modalOpened, modalOpenedSelector } from '../../store/ducks/settings';
import { isServer } from '../../models/helpers';

interface ModalProps {
  modalBaseId?: string;
  open: boolean;
  onClose: () => void;
  handlerRef?: IFocusableElement & HTMLElement | null;
  align?: 'top' | 'center';
  closeOnBg?: boolean;
  scrollHandler?: (handler: () => void) => void;
}

const Modal: React.FC<ModalProps> = ({
  children, modalBaseId, open, closeOnBg = true, onClose, align, scrollHandler,
}) => {
  const modalRoot = isServer ? undefined : document.body;
  const modalId = useMemo(() => modalBaseId || nanoid(), []);
  const modalContainerRef = useRef<HTMLDivElement>(null);
  const openedModals = useSelector(modalOpenedSelector);
  const dispatch = useDispatch();
  const isModalOnTop = useMemo(() => openedModals.length > 0 && last(openedModals) === modalId, [openedModals, modalId]);
  const handleScrollToTop = () => {
    if (modalContainerRef.current) {
      modalContainerRef.current.scrollTop = 0;
    }
  };
  useEffect(() => {
    if (scrollHandler) {
      scrollHandler(handleScrollToTop);
    }
  }, [scrollHandler]);

  const handleClose = () => {
    onClose();
    dispatch(modalClosed(modalId));
  }

  useHotkeys('Escape', () => {
    if (isModalOnTop) {
      handleClose();
    }
  }, [isModalOnTop]);

  useEffect(() => {
    if (open) {
      dispatch(modalOpened(modalId));
    } else {
      handleClose();
    }
  }, [open]);

  useEffect(() => {
    return () => {
      dispatch(modalClosed(modalId));
    };
  }, []);

  const handleCloseDialog = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    if (!closeOnBg) {
      return;
    }
    const { target } = event;

    if (target) {
      const divElementTarget = target as HTMLDivElement;
      if (!divElementTarget || divElementTarget.getAttribute('id') === `geecko-modal-backdrop--id-${modalId}`) {
        handleClose();
      }
    }
  };

  return open && modalRoot ? ReactDOM.createPortal(
    <div
      id={`geecko-modal-backdrop--id-${modalId}`}
      className={`geecko-modal-root ${align ? `geecko-modal-root--align-${align}` : ''}`}
      role="dialog"
      aria-modal="true"
      onClick={handleCloseDialog}
      ref={modalContainerRef}
    >
      <FocusLock>
        {children}
      </FocusLock>
    </div>,
    modalRoot,
  ) : null;
};

export default Modal;
