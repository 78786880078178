import { Immutable } from 'immer';
import {
  CvFormValuesExperience,
  decodeCandidateExperienceItem,
  CvFormValuesCodeSample,
  decodeCvCodeSample,
  CvSalary,
  decodeCvSalary,
  CvCandidateSpecialization,
  CvSkills,
} from 'models/CV/CvFormValues';
import { decodeSkillNew } from 'models/Skill';

export enum ResumeImprovementType {
  Salary = 'salary',
  About = 'about',
  Experience = 'experience',
  CodeSamples = 'code_samples',
}

export enum ResumeImprovementImportance {
  Warning = 'warning',
  Critical = 'critical',
}

export enum ResumeImprovementStatus {
  InProgress = 'in-progress',
  Done = 'done',
  Ignored = 'ignored',
  Moderation = 'moderation',
}

export type ResumeImprovementFields = Immutable<{
  salary?: CvSalary;
  about?: string;
  experience?: CvFormValuesExperience;
  codeSamples?: CvFormValuesCodeSample[];
  specializations?: CvCandidateSpecialization[];
  areas?: string[];
  skills?: CvSkills;
}>;

export type ResumeImprovement = Immutable<{
  id: string;
  name: string;
  type: ResumeImprovementType;
  importance?: ResumeImprovementImportance;
  reason: string;
  improvement: string;
  status: ResumeImprovementStatus;
  fields?: ResumeImprovementFields;
  // recommended_fields не используются
  recommendedFields?: any;
}>;

export const decodeResumeImprovementFields = (json: any): ResumeImprovementFields => ({
  salary: json.salary ? decodeCvSalary(json.salary) : undefined,
  about: json.about ? json.about : undefined,
  experience: json.experience ? decodeCandidateExperienceItem(json.experience) : undefined,
  codeSamples: json.code_samples && Array.isArray(json.code_samples) ? json.code_samples.map(decodeCvCodeSample) : [],
  specializations:
    json.specializations && Array.isArray(json.specializations)
      ? json.specializations.map((specializationJson: any) => ({
          id: specializationJson.id,
          name: specializationJson.name,
          isPrimary: !!specializationJson.is_primary,
        }))
      : [],
  areas: json.areas && Array.isArray(json.areas) ? json.areas.filter((item: any) => !!item) : [],
  skills: {
    lang: json?.skills?.lang && Array.isArray(json.skills.lang) ? json.skills.lang.map(decodeSkillNew) : [],
    db: json?.skills?.db && Array.isArray(json.skills.db) ? json.skills.db.map(decodeSkillNew) : [],
    tech: json?.skills?.tech && Array.isArray(json.skills.tech) ? json.skills.tech.map(decodeSkillNew) : [],
  },
});

export const decodeResumeImprovement = (json: any): ResumeImprovement => ({
  id: json.id,
  name: json.name,
  type: json.type,
  importance: json.importance,
  reason: json.reason,
  improvement: json.improvement,
  status: json.status,
  fields: json.fields ? decodeResumeImprovementFields(json.fields) : undefined,
  recommendedFields: json.recommendedFields,
});
