/* eslint-disable no-restricted-globals,no-alert */
import React, { FC, useRef } from 'react';
import { Form, FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { useSelector, useDispatch } from 'react-redux';
import debounce from 'lodash/debounce';
import { useApiInstance } from 'api';
import { FORM_ERROR, FormApi } from 'final-form';
import FormBigSkeleton from 'components/v2/skeletons/FormSkeletons/FormBigSkeleton';
import { candidateCvEditDataSelector } from 'store/ducks/candidate/selectors';
import { updateCandidateCvFillRateForStep } from 'store/ducks/candidate/actionCreators';
import { Immutable, castDraft } from 'immer';
import { calculateCvFormFillPercent, CvFormValues, submitCvFormValues } from 'models/CV/CvFormValues';
import Grid from 'components/Grid/Grid';
import './CVExperienceForm.css';
import Text from 'components/v2/Text/Text';
import InfoCircleIcon from 'components/Icons/InfoCircleIcon';
import CVExperienceFormItem from 'modules/candidate/cv/components/CvExperienceFormItem/CvExperienceFormItem';
import FormDivider from 'components/Form/FormDivider/FormDivider';
import SkillSet from '../compositions/Tech/SkillSet';
import CVFormFooter from './CVFormFooter';
import { authCandidateHasCvSelector, authCandidateCvShowImportSelector } from '../../store/ducks/auth/selectors';
import { CvFormStep } from '../../../pages/Candidate/Cv/CandidateEditCvPage/CandidateEditCvPage';

interface Props {
  nextStep?: CvFormStep;
  previousStep?: CvFormStep;
}

const CVExperienceForm: FC<Props> = ({ previousStep, nextStep }) => {
  const api = useApiInstance();
  const cvData = useSelector(candidateCvEditDataSelector);
  const formApi = useRef<FormApi<Immutable<CvFormValues>>>();
  const dispatch = useDispatch();
  const candidateHasCv = useSelector(authCandidateHasCvSelector);
  const showCvImport = useSelector(authCandidateCvShowImportSelector);
  const handleFormSubmit = async (cvFormData: Immutable<CvFormValues>) =>
    submitCvFormValues(showCvImport, dispatch, api, castDraft(cvFormData), 'experience', !candidateHasCv);

  const validate = (values: Immutable<CvFormValues>) => {
    if (values.experience.length === 0) {
      return {
        [FORM_ERROR]: 'Добавьте как минимум одну компанию, в которой вы работали',
      };
    }
    return undefined;
  };

  const handleUpdateFillRate = debounce((formValues: CvFormValues) => {
    const fillRate = calculateCvFormFillPercent(formValues, 'experience');
    dispatch(updateCandidateCvFillRateForStep({ step: 'experience' as CvFormStep, fillRate }));
  }, 500);

  return (
    <>
      <div className="geecko-paper__wrapper">
        <div className="geecko-candidate-cv-form__form-header-wrapper">
          <div className="geecko-candidate-cv-form__dot-bg geecko-candidate-cv-form__form-header-dot--1" />
          <div className="geecko-candidate-cv-form__dot-bg geecko-candidate-cv-form__form-header-dot--2" />
          <Grid container spacing={4}>
            <Grid item xs={12} md={7}>
              <Text variant="h1" tag="h1">
                Профильный
                <br />
                опыт
              </Text>
            </Grid>
            {!candidateHasCv && (
              <Grid item xs={12} md={5}>
                <Text variant="h3">Мы сформировали стек, но пока не засчитали в нем опыт</Text>
                <div className="geecko-util__mt-2">
                  <Text variant="md" color="#646D7B">
                    Теперь укажите технологический стек в каждом из мест работы. Мы сформируем итоговый опыт на основе
                    этих данных и покажем вам
                  </Text>
                </div>
                <div className="geecko-util__mt-4">
                  <InfoCircleIcon size={18} />
                  <Text variant="md" color="#646D7B">
                    Благодаря подробному выбору, мы можем рассчитывать общий опыт как по технологиям, так и по
                    специализациям
                  </Text>
                </div>
              </Grid>
            )}
          </Grid>
        </div>

        {cvData.loading && (
          <>
            <FormBigSkeleton />
            <FormBigSkeleton />
          </>
        )}
      </div>

      {cvData.loaded && cvData.item && (
        <Form<Immutable<CvFormValues>>
          onSubmit={handleFormSubmit}
          initialValues={cvData.item}
          mutators={{
            ...arrayMutators,
          }}
          validate={validate}
          render={({ form, handleSubmit, submitting, values, error, submitFailed }) => {
            formApi.current = form;
            return (
              <form onSubmit={handleSubmit} encType="multipart/form-data" className="geecko-company-edit-profile__form">
                <>
                  <div className="geecko-paper__wrapper">
                    <div className="geecko-form__item">
                      <SkillSet
                        skills={castDraft(values.skillSet)}
                        specializations={castDraft(values.specializations)}
                      />
                    </div>
                  </div>

                  <FormSpy subscription={{ values: true }}>
                    {(props) => {
                      handleUpdateFillRate(props.values as CvFormValues);
                      return null;
                    }}
                  </FormSpy>
                  <FormDivider />
                  <CVExperienceFormItem values={values} form={form} formApi={formApi} />

                  <div className="geecko-paper__wrapper">
                    {error && submitFailed && <div className="geecko-cv-form__form-error">{error}</div>}
                    <CVFormFooter
                      candidateHasCv={candidateHasCv}
                      nextStep={nextStep}
                      previousStep={previousStep}
                      submitting={submitting}
                    />
                  </div>
                </>
              </form>
            );
          }}
        />
      )}
    </>
  );
};

export default CVExperienceForm;
