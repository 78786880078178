export interface PoolCounters {
  readonly byNow: number;
  readonly byDay: number;
  readonly byMonth: number;
  readonly vacancies?: number;
}

export const decodePoolCounters = (json: any): PoolCounters => ({
  byNow: json.by_now,
  byDay: json.by_day,
  byMonth: json.by_month,
  vacancies: json.vacancies,
})
