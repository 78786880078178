import React, { FC } from 'react';
import { Form, Field, FormSpy } from 'react-final-form';
import { useSelector, useDispatch } from 'react-redux';
import { useApiInstance } from 'api';
import debounce from 'lodash/debounce';
import { TextareaFormField } from 'components/v2/FinalForm/Fields/TextareaFormField';
import { updateCandidateCvFillRateForStep } from 'store/ducks/candidate/actionCreators';
import { candidateCvEditDataSelector } from 'store/ducks/candidate/selectors';
import FormBigSkeleton from 'components/v2/skeletons/FormSkeletons/FormBigSkeleton';
import { Immutable, castDraft } from 'immer';
import { workFeaturesSelector, companyScopesSelector } from 'store/ducks/dictionary/selectors';
import { calculateCvFormFillPercent, CvFormValues, submitCvFormValues } from '../../models/CV/CvFormValues';
import Label from '../../components/Form/Label/Label';
import { TagFieldAdapter } from '../../components/FormAdapter/FormAdapter';
import { CvFormStep } from '../../../pages/Candidate/Cv/CandidateEditCvPage/CandidateEditCvPage';
import { authCandidateHasCvSelector, authCandidateCvShowImportSelector } from '../../store/ducks/auth/selectors';
import InfoCircleIcon from '../../components/Icons/InfoCircleIcon';
import Alert from '../../components/Alert/Alert';
import CVFormFooter from './CVFormFooter';
import Text from '../../components/v2/Text/Text';

interface Props {
  nextStep?: CvFormStep;
  previousStep?: CvFormStep;
}

const CVPreferencesForm: FC<Props> = ({
  previousStep,
  nextStep,
}) => {
  const api = useApiInstance();
  const cvData = useSelector(candidateCvEditDataSelector);
  const workFeatures = useSelector(workFeaturesSelector);
  const companyScopes = useSelector(companyScopesSelector);
  const candidateHasCv = useSelector(authCandidateHasCvSelector);
  const dispatch = useDispatch();
  const showCvImport = useSelector(authCandidateCvShowImportSelector);
  const handleFormSubmit = async (cvFormData: Immutable<CvFormValues>) =>
    submitCvFormValues(showCvImport, dispatch, api, castDraft(cvFormData), 'preferences', !candidateHasCv);

  const handleUpdateFillRate = debounce((formValues: CvFormValues) => {
    const fillRate = calculateCvFormFillPercent(formValues, 'preferences');
    dispatch(updateCandidateCvFillRateForStep({ step: 'preferences' as CvFormStep, fillRate }));
  }, 500);

  return (
    <div className="geecko-paper__wrapper">
      <div className="geecko-candidate-cv-form__form-header-wrapper">
        <div className="geecko-candidate-cv-form__dot-bg geecko-candidate-cv-form__form-header-dot--1" />
        <div className="geecko-candidate-cv-form__dot-bg geecko-candidate-cv-form__form-header-dot--2" />
        <Text variant="h1" tag="h1">
          Предпочтения
        </Text>
      </div>

      {cvData.loading && (
        <>
          <FormBigSkeleton />
          <FormBigSkeleton />
        </>
      )}

      {cvData.loaded && cvData.item && (
        <Form<Immutable<CvFormValues>>
          onSubmit={handleFormSubmit}
          initialValues={cvData.item}
          render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit} encType="multipart/form-data" className="geecko-company-edit-profile__form">
              <FormSpy subscription={{ values: true }}>
                {(props) => {
                  handleUpdateFillRate(props.values as CvFormValues);
                  return null;
                }}
              </FormSpy>
  
              {companyScopes.loading && (
                <FormBigSkeleton />
              )}
              {companyScopes.loaded && companyScopes.items && (
                <div className="geecko-form__item">
                  <Label>Предметные области</Label>
                  <Field<string[]>
                    name="workAreas"
                    multiple
                    component={TagFieldAdapter}
                    options={companyScopes.items.map(item => ({ value: item.id, label: item.name }))}
                    maxValues={5}
                  />
                </div>
              )}

              {workFeatures.loading && (
                <FormBigSkeleton />
              )}
              {workFeatures.loaded && workFeatures.items && (
                <div className="geecko-form__item">
                  <Label>Важно в работе</Label>
                  <Alert icon={<InfoCircleIcon />}>Выберите 5 самых важных для вас пунктов</Alert>
                  <div className="geecko-form__item">
                    <Field<string[]>
                      name="workFeatures"
                      multiple
                      component={TagFieldAdapter}
                      options={workFeatures.items.map(item => ({ value: item.id, label: item.name }))}
                      maxValues={5}
                    />
                  </div>
                </div>
              )}
  
              <div className="geecko-form__item qa-expectations">
                <Label>Расскажите подробнее</Label>
                <TextareaFormField name="expectations" />
              </div>
  
              <CVFormFooter
                candidateHasCv={candidateHasCv}
                nextStep={nextStep}
                previousStep={previousStep}
                submitting={submitting}
              />
            </form>
          )}
        />
      )}
    </div>
  );
};

export default CVPreferencesForm;
