import React, { FC, ReactNode } from 'react';
import Grid from '../Grid/Grid';
import './BigTagSelect.css';
import Text from '../v2/Text/Text';

export interface BigTagSelectOption {
  value: string;
  icon: string | ReactNode;
  title: string;
  description?: string;
}

interface Props {
  options: BigTagSelectOption[];
  value?: string;
  onChange: (newValue: string) => void;
}

const BigTagSelect: FC<Props> = ({ options, value, onChange }) => {
  const handleSelectItem = (option: BigTagSelectOption) => {
    onChange(option.value);
  };
  return (
    <Grid container spacing={4} alignItems="stretch">
      {options.map((option) => (
        <Grid key={option.value} item xs={12} md={4}>
          <BigTagSelectItem
            option={option}
            key={option.value}
            isSelected={value === option.value}
            onSelect={handleSelectItem}
          />
        </Grid>
      ))}
    </Grid>
  );
};

interface OptionProps {
  option: BigTagSelectOption;
  isSelected: boolean;
  onSelect: (option: BigTagSelectOption) => void;
}

const BigTagSelectItem: FC<OptionProps> = ({ option, isSelected, onSelect }) => {
  const handleClick = () => {
    onSelect(option);
  };
  return (
    <div
      className={`geecko-big-tag-select__item ${isSelected ? ' geecko-big-tag-select__item--selected' : ''}`}
      key={option.value}
    >
      <button
        onClick={handleClick}
        className="geecko-big-tag-select__item-button"
        type="button"
      >
        <div className="geecko-big-tag-select__item-title">
          <div className="geecko-big-tag-select__item-icon">{option.icon}</div>
          <div className="geecko-big-tag-select__item-title-text">{option.title}</div>
        </div>
        <div className="geecko-util__mt-2">
          <Text variant="md">
            {option.description}
          </Text>
        </div>
      </button>
    </div>
  );
};

export default BigTagSelect;
