import React, { FC } from 'react';

interface Props {
  size?: number;
}

const BitBucketIcon: FC<Props> = ({ size = 18 }) => (
  <svg width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.43891 1.72892C1.15386 1.72568 0.917363 1.95244 0.914093 2.24076C0.914093 2.2699 0.917363 2.30228 0.920632 2.33146L3.12022 15.6815C3.17527 16.0184 3.46686 16.2679 3.81023 16.2711H14.3613C14.6172 16.2744 14.8375 16.0897 14.8796 15.837L17.0793 2.33469C17.1246 2.05288 16.9335 1.78724 16.6516 1.74189C16.6225 1.73869 16.5934 1.73542 16.561 1.73542L1.43891 1.72892ZM10.7007 11.3762H7.33162L6.42125 6.61409H11.517L10.7007 11.3762Z" fill="currentColor" />
  </svg>
);

export default BitBucketIcon;
