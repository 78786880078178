import React, { HTMLProps } from 'react';
import classNames from 'classnames';

import './Label.css';

interface Props extends HTMLProps<HTMLLabelElement> {
  primary?: boolean;
  spacing?: number;
}

const Label: React.FC<Props> = (props: Props) => {
  const {
    id,
    htmlFor,
    children,
    required,
    primary = false,
    style = {},
    spacing = 2,
    ...rest
  } = props;

  const labelClassName = classNames({
    'geecko-label': true,
    'geecko-label--primary': primary,
  });

  const Component = 'label';

  return (
    <Component
      className={labelClassName}
      id={id}
      htmlFor={htmlFor}
      style={{ ...style, marginBottom: spacing * 5 }}
      {...rest}
    >
      <span className="geecko-label__text">
        {children}
      </span>
      {required ? <span className="geecko-label__required"> *</span> : null}
    </Component>
  );
};

export default Label;
