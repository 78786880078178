import { put, takeLatest, takeEvery, getContext, call, select } from 'redux-saga/effects';
import { ServerApi } from 'modules/api/server-api';
import { fetchCompanyVacancies } from 'api/company/vacancy/get-company-vacancies';
import { Vacancy } from 'models/Vacancy';
import { fetchCompanyRequisites } from 'api/company/requisites/get-company-requisites';
import { CompanyRequisites } from 'models/Company/CompanyRequisites';
import {
  fetchPoolAction,
  fetchVacancyAction,
  fetchVacancyCandidatesAction, openScheduleInterviewDialogAction,
  reloadCandidatesListResumeAction,
} from 'store/ducks/company/actionCreators';
import { Pool, PoolOnline } from 'models/Company/Pool';
import { PoolCounters } from 'models/Company/Counters';
import { Candidate } from 'models/Company/Candidate';
import { fetchPool } from 'api/pools/fetch-pool';
import { ShortVacancy } from 'models/Company/CompanyPool';
import { openRejectCandidateDialog, fetchCompanyVacanciesAction, fetchCompanyRequisitesAction } from './actionCreators';
import {
  fetchCandidateRejectReasonsAction,
  fetchCompanyOfficesAction,
  fetchCompanyUsersAction,
} from '../dictionary/actionCreators';
import { VacanciesCounters } from './state';
import { NetworkEntitiesCollecion } from '../../index';
import { candidatesListSelector, candidatesTotalSelector } from './selectors';
import { fetchCompanyVacancy } from '../../../api/company/vacancy/get-company-vacancy';
import { fetchVacancyCandidates } from '../../../api/company/vacancy/get-vacancy-candidates';
import { Resume } from '../../../models/Resume';
import { fetchResume } from '../../../api/company/resume/get-resume';

function* getCandidateRejectReasonsWhenOpenRejectDialog() {
  yield put(fetchCandidateRejectReasonsAction.request());
}

/**
 * Получение вакансий компании
 */
function* getCompanyVacanciesSaga(action: ReturnType<typeof fetchCompanyVacanciesAction.request>) {
  try {
    const api: ServerApi = yield getContext('api');
    const { status, vacanciesSort } = action.payload;
    const { vacancies, counters }: { vacancies: Vacancy[]; counters: VacanciesCounters } = yield call(
      fetchCompanyVacancies(api),
      status,
      vacanciesSort,
    );
    yield put(fetchCompanyVacanciesAction.success({ vacancies, counters }));
  } catch (e) {
    yield put(fetchCompanyVacanciesAction.failure(e));
  }
}

/**
 * Получение реквизитов компании
 */
function* getCompanyRequisitesSaga() {
  try {
    const api: ServerApi = yield getContext('api');
    const requisites: CompanyRequisites = yield call(fetchCompanyRequisites(api));
    yield put(fetchCompanyRequisitesAction.success(requisites));
  } catch (e) {
    yield put(fetchCompanyRequisitesAction.failure(e));
  }
}

/**
 * Получение информации о конкретном пуле
 */
function* getPoolSaga(action: ReturnType<typeof fetchPoolAction.request>) {
  try {
    const api: ServerApi = yield getContext('api');
    let page = 1;
    if (action.payload.loadMore) {
      const candidates: NetworkEntitiesCollecion<Candidate> = yield select(candidatesListSelector);
      const candidatesTotal = yield select(candidatesTotalSelector);
      const candidatesLoaded = candidates.items || [];
      if (candidatesTotal > candidatesLoaded.length) {
        page = Math.floor(candidatesLoaded.length / 20) + 1;
      }
    }
    const result: {
      pool: Pool;
      counters: PoolCounters;
      online: PoolOnline;
      candidates: Candidate[];
      total: number;
      vacancies: ShortVacancy[];
    } = yield call(fetchPool(api), action.payload.id, page);
    yield put(fetchPoolAction.success({ ...result, append: action.payload.loadMore }));
  } catch (e) {
    yield put(fetchPoolAction.failure(e));
  }
}

/**
 * Получение вакансии
 */
function* getVacancySaga(action: ReturnType<typeof fetchVacancyAction.request>) {
  try {
    const api: ServerApi = yield getContext('api');
    const vacancy: Vacancy = yield call(fetchCompanyVacancy(api), action.payload);
    yield put(fetchVacancyAction.success(vacancy));
  } catch (e) {
    yield put(fetchVacancyAction.failure(e));
  }
}

/**
 * Обновление резюме в списке кандидатов
 */
function* reloadCandidatesListResume(action: ReturnType<typeof reloadCandidatesListResumeAction.request>) {
  try {
    const api: ServerApi = yield getContext('api');
    const resume: Resume = yield call(fetchResume(api), action.payload);
    yield put(reloadCandidatesListResumeAction.success(resume));
  } catch (e) {
    yield put(reloadCandidatesListResumeAction.failure(e));
  }
}

/**
 * Получение кандидатов вакансии
 */
function* getVacancyCandidates(action: ReturnType<typeof fetchVacancyCandidatesAction.request>) {
  try {
    const api: ServerApi = yield getContext('api');
    let page = 1;
    if (action.payload.loadMore) {
      const candidates: NetworkEntitiesCollecion<Candidate> = yield select(candidatesListSelector);
      const candidatesTotal = yield select(candidatesTotalSelector);
      const candidatesLoaded = candidates.items || [];
      if (candidatesTotal > candidatesLoaded.length) {
        page = Math.floor(candidatesLoaded.length / 20) + 1;
      }
    }

    const { vacancyId, status, loadMore } = action.payload;

    const result: {
      candidates: Candidate[];
      total: number;
      counters: { [status: string]: number };
    } = yield call(fetchVacancyCandidates(api), vacancyId, status, page);
    yield put(fetchVacancyCandidatesAction.success({ ...result, append: loadMore }));
  } catch (e) {
    yield put(fetchVacancyCandidatesAction.failure(e));
  }
}

function* loadDictionaryWhenOpenScheduleInterviewModal() {
  yield put(fetchCompanyUsersAction.request());
  yield put(fetchCompanyOfficesAction.request());
}


export function* companySaga() {
  yield takeLatest(openRejectCandidateDialog, getCandidateRejectReasonsWhenOpenRejectDialog);
  yield takeLatest(fetchCompanyVacanciesAction.request, getCompanyVacanciesSaga);
  yield takeLatest(fetchCompanyRequisitesAction.request, getCompanyRequisitesSaga);
  yield takeLatest(fetchPoolAction.request, getPoolSaga);
  yield takeLatest(fetchVacancyAction.request, getVacancySaga);
  yield takeLatest(fetchVacancyCandidatesAction.request, getVacancyCandidates);
  yield takeEvery(reloadCandidatesListResumeAction.request, reloadCandidatesListResume);
  yield takeLatest(openScheduleInterviewDialogAction, loadDictionaryWhenOpenScheduleInterviewModal);
}
