import { createSelector } from 'redux-views';
import { castDraft } from 'immer';
import { VacancyActiveStatus } from 'models/VacancyState';
import { Pool } from 'models/Company/Pool';
import { Candidate } from 'models/Company/Candidate';
import {
  AppState,
  getNetworkEntitiesCollectionSelectorState,
  getNetworkEntitySelectorState,
  NetworkEntitiesCollecionSelector,
  NetworkEntitySelector,
} from '../../index';
import { COMPANY_MODULE_NAME } from './index';
import { Vacancy } from '../../../models/Vacancy';

export const companyStateSelector = (state: AppState) => state[COMPANY_MODULE_NAME];

export const selectCompanyOfferResumeId = createSelector(
  [companyStateSelector],
  (companyState) => companyState && companyState.resumeOfferId,
);

export const selectCompanyOfferVacancyId = createSelector(
  [companyStateSelector],
  (companyState) => companyState && companyState.vacancyOfferId,
);

export const selectCompanyRejectResumeId = createSelector(
  [companyStateSelector],
  (companyState) => companyState && companyState.resumeRejectId,
);

export const selectCompanyRejectVacancyId = createSelector(
  [companyStateSelector],
  (companyState) => companyState && companyState.vacancyRejectId,
);

export const selectCompanyCandidateResponseResumeId = createSelector(
  [companyStateSelector],
  (companyState) => companyState && companyState.resumeCandidateResponseId,
);

export const selectCompanyCandidateResponseVacancyId = createSelector(
  [companyStateSelector],
  (companyState) => companyState && companyState.vacancyCandidateResponseId,
);

export const selectCompanyCandidateResponseComment = createSelector(
  [companyStateSelector],
  (companyState) => companyState && companyState.vacancyResponseComment,
);

export const selectCompanyVacancyStatusReloadId = createSelector(
  [companyStateSelector],
  (companyState) => companyState && companyState.statusReloadId,
);

export const selectCompanyInterviewFeedback = createSelector(
  [companyStateSelector],
  (companyState) => companyState && companyState.interviewFeedback,
);

/**
 * Селетор резюме и вакансии для модалки назначения собеседования
 */
export const companyInterviewScheduleSelector = createSelector(
  [companyStateSelector],
  (companyState) => companyState && companyState.interviewSchedule,
);

/**
 * Селекторы вакансий
 */
const selectCompanyVacanciesState = createSelector([companyStateSelector], (companyState) => companyState.vacancies);

export const selectCompanyVacanciesLoading = createSelector(
  [selectCompanyVacanciesState],
  (vacanciesState) => !!vacanciesState.vacancies.loading,
);

export const selectCompanyVacanciesLoadFailure = createSelector(
  [selectCompanyVacanciesState],
  (vacanciesState) => !!vacanciesState.vacancies.error,
);

export const companyVacanciesSelector = createSelector([selectCompanyVacanciesState], (vacanciesState) =>
  castDraft((vacanciesState.vacancies && vacanciesState.vacancies.items) || []),
);

export const selectCandidateVacanciesCounters = createSelector(
  [selectCompanyVacanciesState],
  (vacanciesState) => vacanciesState.counters,
);

export const selectVacanciesActiveStatus = createSelector(
  [selectCompanyVacanciesState],
  (vacanciesState) => vacanciesState.status || VacancyActiveStatus.Active,
);

/**
 * Селектор счетчика вакансий
 */
export const selectCompanyVacanciesCounters = createSelector(
  [selectCompanyVacanciesState],
  (vacanciesState) => vacanciesState.counters,
);

/**
 * Вакансия
 */
export const vacancyStateSelector = createSelector([companyStateSelector], (companyState) => companyState.vacancy);

export const vacancySelector: NetworkEntitySelector<Vacancy> = createSelector(
  [vacancyStateSelector],
  (vacancyState) => {
    return getNetworkEntitySelectorState(vacancyState.vacancy);
  },
);

/**
 * Селектор реквизитов компании
 */
export const selectCompanyRequisites = createSelector(
  [companyStateSelector],
  (companyState) => companyState && companyState.requisites.item,
);

/**
 * Пулы
 */
export const poolSelector: NetworkEntitySelector<Pool> = createSelector([companyStateSelector], (companyState) => {
  return getNetworkEntitySelectorState(companyState.pool.pool);
});

export const poolOnlineSelector = createSelector([companyStateSelector], (companyState) => {
  return companyState.pool.online;
});

export const poolCountersSelector = createSelector([companyStateSelector], (companyState) => {
  return companyState.pool.counters;
});

export const poolVacanciesSelector = createSelector([companyStateSelector], (companyState) => {
  return companyState.pool.vacancies;
});

/**
 * Список кандидатов
 */

export const candidatesListStateSelector = createSelector(
  [companyStateSelector],
  (companyState) => companyState.candidates,
);

export const candidatesListSelector: NetworkEntitiesCollecionSelector<Candidate> = createSelector(
  [candidatesListStateSelector],
  (candidatesState) => getNetworkEntitiesCollectionSelectorState(candidatesState.candidates),
);

export const candidatesStatusSelector = createSelector(
  [candidatesListStateSelector],
  (candidatesState) => candidatesState.status,
);


export const candidatesCountersSelector = createSelector(
  [candidatesListStateSelector],
  (candidatesState) => candidatesState.counters,
);

export const candidatesTotalSelector = createSelector(
  [candidatesListStateSelector],
  (candidatesState) => candidatesState.total || 0,
);

export const candidateHasLoadMoreSelector = createSelector(
  [candidatesListStateSelector, candidatesTotalSelector],
  (candidatesState, total) => total > (candidatesState.candidates.items || []).length,
);

export const candidatePreviewOpenedSelector = createSelector(
  [candidatesListStateSelector],
  (candidatesState) => !!candidatesState.previewId,
);

export const candidatePreviewSelector = createSelector(
  [candidatesListStateSelector, candidatesListSelector],
  (candidatesState, candidatesData) => {
    if (!candidatesState.previewId) {
      return undefined;
    }

    return candidatesData.items.find((candidate) => candidate.resume.hash === candidatesState.previewId);
  },
);

export const previewCandidateIndexSelector = createSelector(
  [candidatesListStateSelector, candidatesListSelector],
  (candidatesState, candidatesData) => {
    return candidatesState.previewId
      ? candidatesData.items.findIndex((item) => item.resume.hash === candidatesState.previewId)
      : -1;
  },
);
