import React, { ReactNode } from 'react';
import './SidebarNav.css';

export interface SidebarNavLink {
  id: string;
  link: string;
  title: string;
  subtitle?: string;
  icon?: ReactNode;
}

const SidebarNav: React.FC = ({ children }) => (
  <div className="geecko-sidebar-nav">
    {children}
  </div>
);

export default SidebarNav;
