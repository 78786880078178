import { ServerApi } from 'modules/api/server-api';
import { decodePool } from 'models/Company/Pool';
import { decodePoolCounters } from 'models/Company/Counters';

export const fetchPools = (api: ServerApi) => async () => {
  const response = await api.get('/v3/pools');
  const pools = response.data.pools.map(decodePool);
  const counters = decodePoolCounters(response.data.counters);
  return { pools, counters };
}
