import React, { HTMLProps } from 'react';
import './IconButton.css';

interface Props extends HTMLProps<HTMLButtonElement> {
  type?: 'button' | 'submit' | 'reset' | undefined;
  intent?: 'plain';
  inline?: boolean;
  rounded?: boolean;
}

const IconButton: React.FC<Props> = (props: Props) => {
  const {
    children, inline, intent, rounded, ...rest
  } = props;

  return (
    <button
      type="button"
      className={[
        'geecko-iconbutton',
        inline ? 'geecko-iconbutton--inline' : '',
        intent ? 'geecko-iconbutton--plain' : '',
        rounded ? 'geecko-iconbutton--rounded' : '',
      ].join(' ')}
      {...rest}
    >
      {children}
    </button>
  );
};

export default IconButton;
