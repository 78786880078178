import React, { FC } from 'react';
import { FieldValidator } from 'final-form';
import { Field } from 'react-final-form';
import TagField, { TagInputProps } from '../Form/TagField/TagField';

interface Props extends Omit<TagInputProps, 'name'> {
  name: string;
  validate?: FieldValidator<string>;
}

const TagFieldFinalForm: FC<Props> = ({ name, onChange, ...rest }) => {
  return (
    <Field name={name}>
      {props => {
        const {
          input, meta,
        } = props;
        const handleChange = (newValue: string[]) => {
          input.onChange(newValue);
          if (onChange) {
            onChange(newValue);
          }
        };
        return (
          <>
            <TagField
              validationError={meta.touched ? meta.error : ''}
              submitError={meta.touched && !meta.dirtySinceLastSubmit && !meta.submitting ? meta.submitError : ''}
              {...input}
              {...rest}
              value={input.value || []}
              onChange={handleChange}
            />
          </>
        );
      }}
    </Field>
  );
};

export default TagFieldFinalForm;
