import React from 'react';
import Grid from 'components/Grid/Grid';
import { Field } from 'react-final-form';
import { getSalaryNumber, formatOnlyNumbers } from 'models/helpers';
import {
  CheckRequired,
  SegmentedControlAdapter,
  formFormatUpperCase,
  composeValidators,
  SwitchAdapter,
} from 'components/FormAdapter/FormAdapter';
import { InputFormField } from 'components/v2/FinalForm/Fields/InputFormField';

const CvSalaryFormItem: React.FC = () => {
  return (
    <>
      <Grid container spacing={3} alignItems="flex-start">
        <Grid item xs={6} sm={3} lg={2}>
          <div className="geecko-form__thin-label">Валюта</div>
          <Field<string>
            name="salary.currency"
            component={SegmentedControlAdapter}
            options={[
              { label: '₽', value: 'RUB' },
              { label: '$', value: 'USD' },
            ]}
            format={formFormatUpperCase}
            validate={CheckRequired}
            color="default"
            size="big"
            densed
          />
        </Grid>
        <Grid item xs={6} sm={3} lg={2}>
          <div className="geecko-form__thin-label">Период</div>
          <Field<string>
            name="salary.period"
            component={SegmentedControlAdapter}
            options={[
              { label: 'Мес', value: 'monthly' },
              { label: 'Год', value: 'annually' },
            ]}
            color="default"
            validate={CheckRequired}
            densed
          />
        </Grid>
      </Grid>
      <div className="geecko-form__item">
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={6} sm={4} lg={3}>
            <div className="geecko-form__thin-label">Минимальная</div>
            <InputFormField<number>
              name="salary.min"
              parse={getSalaryNumber}
              format={formatOnlyNumbers}
              validate={composeValidators(CheckRequired, (value: number) =>
                value < 10000000 ? undefined : 'Введите значение меньше 10.000.000',
              )}
            />
          </Grid>
          <Grid item xs={6} sm={4} lg={3}>
            <div className="geecko-form__thin-label">Публичная</div>
            <InputFormField
              name="salary.public"
              parse={getSalaryNumber}
              format={formatOnlyNumbers}
              validate={composeValidators(CheckRequired, (value: number) =>
                value < 10000000 ? undefined : 'Введите значение меньше 10.000.000',
              )}
            />
          </Grid>
          <Grid item xs={6} sm>
            <div className="geecko-form__thin-label">&nbsp;</div>
            <div className="geecko-form__densed-checkbox">
              <Field<boolean>
                name="salary.isVisible"
                type="checkbox"
                component={SwitchAdapter}
                label="Показывать в резюме"
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default CvSalaryFormItem;
