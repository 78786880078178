import React, {
  HTMLProps, forwardRef, Ref, ReactNode,
} from 'react';
import classNames from 'classnames';
import './Button.css';

export type ButtonColor = 'primary' | 'secondary' | 'danger';
export type ButtonVariant = 'solid' | 'outline' | 'ghost';
export type ButtonSize = 'sm' | 'md' | 'lg';

export interface ButtonProps extends Omit<HTMLProps<HTMLButtonElement>, 'size'> {
  color?: ButtonColor;
  size?: ButtonSize;
  type?: 'button' | 'submit' | 'reset';
  isLoading?: boolean;
  isWide?: boolean;
  isThin?: boolean;
  iconLeft?: ReactNode;
  variant?: ButtonVariant;
}

const Button = forwardRef((props: ButtonProps, ref?: Ref<HTMLButtonElement>) => {
  const {
    children, color = 'primary', variant = 'solid', size = 'md',
    isLoading, type, disabled, isWide, isThin, iconLeft, ...rest
  } = props;

  const buttonType: 'submit' | 'reset' | 'button' = type || 'button';

  return (
    <button
      ref={ref}
      className={classNames(
        'geecko-v2-button',
        `geecko-v2-button--color-${color}`,
        `geecko-v2-button--variant-${variant}`,
        `geecko-v2-button--size-${size}`,
        isWide ? 'geecko-v2-button--wide' : undefined,
        isThin ? 'geecko-v2-button--thin' : undefined,
        isLoading ? 'geecko-v2-button--isloading' : undefined,
      )}
      type={buttonType}
      disabled={disabled}
      {...rest}
    >
      {iconLeft && (
        <span className="geecko-v2-button__icon geecko-v2-button__icon">
          {iconLeft}
        </span>
      )}
      <div className="geecko-v2-button__text">
        {children}
      </div>
    </button>
  );
});

export default Button;
