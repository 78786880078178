import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { getIn } from 'final-form';
import { Field, useField } from 'react-final-form';
import { AsyncSelectFinalForm } from 'components/final-form-adapters/AsyncSelectFinalForm';
import { InputFormField } from 'components/v2/FinalForm/Fields/InputFormField';
import Grid from '../../Grid/Grid';
import {
  CheckRequired, PlainCheckboxAdapter, validateYear,
} from '../../FormAdapter/FormAdapter';
import { MONTHS } from '../../../models/CV/CvFormValues';

interface Props {
  namePrefix?: string;
}

const getFieldNameWithPrefix = (name: string, namePrefix?: string): string => {
  return `${namePrefix ? `${namePrefix}.` : ''}${name}`;
};

const DateRangeFormField: FC<Props> = ({ namePrefix }) => {
  const intl = useIntl();
  const isContinuousField = useField<boolean>(getFieldNameWithPrefix('isContinuous', namePrefix));

  const validateMonthTo = (newValue: string, otherValues: any) => {
    const isContinuous = getIn(otherValues, getFieldNameWithPrefix('isContinuous', namePrefix));
    if (isContinuous) {
      return undefined;
    }

    return newValue ? undefined : intl.formatMessage({ id: 'app.form.required' });
  };

  const validateYearTo = (newValue: string, otherValues: any) => {
    const isContinuous = getIn(otherValues, getFieldNameWithPrefix('isContinuous', namePrefix));
    if (isContinuous) {
      return undefined;
    }

    if (!newValue) {
      return intl.formatMessage({ id: 'app.form.required' });
    }

    const integerValue = Number(newValue);
    if (Number.isNaN(integerValue)) {
      return intl.formatMessage({ id: 'app.form.error.year' });
    }

    if (integerValue < 1950 || integerValue > 2040) {
      return intl.formatMessage({ id: 'app.form.error.year' });
    }

    const startYear = getIn(otherValues, getFieldNameWithPrefix('startYear', namePrefix));
    const startYearInt = Number(startYear);
    if (!Number.isNaN(startYearInt)) {
      if (startYearInt > integerValue) {
        return intl.formatMessage({ id: 'app.form.error.year.end' });
      }
    }

    return undefined;
  };

  return (
    <div className="geecko-form__item">
      <Grid container spacing={3}>
        <Grid item xs={8} md={4}>
          <div className="geecko-form__thin-label">{intl.formatMessage({ id: 'app.month' })}</div>
          <Field
            name={getFieldNameWithPrefix('startMonth', namePrefix)}
            component={AsyncSelectFinalForm}
            options={MONTHS}
            placeholder={intl.formatMessage({ id: 'app.choose' })}
            valueKey="value"
            labelKey="label"
            plainValue
            validate={CheckRequired}
          />
        </Grid>
        <Grid item xs={4} md={2}>
          <div className="geecko-form__thin-label">{intl.formatMessage({ id: 'app.vacancy.salary.tab.year' })}</div>
          <InputFormField
            name={getFieldNameWithPrefix('startYear', namePrefix)}
            placeholder="2016"
            validate={validateYear}
          />
        </Grid>
        <Grid item xs={8} md={4}>
          <div className="geecko-form__thin-label">{intl.formatMessage({ id: 'app.month' })}</div>
          <Field
            name={getFieldNameWithPrefix('finishMonth', namePrefix)}
            component={AsyncSelectFinalForm}
            options={MONTHS}
            placeholder={intl.formatMessage({ id: 'app.choose' })}
            valueKey="value"
            labelKey="label"
            plainValue
            isDisabled={isContinuousField.input.value}
            validate={validateMonthTo}
          />
        </Grid>
        <Grid item xs={4} md={2}>
          <div className="geecko-form__thin-label">{intl.formatMessage({ id: 'app.vacancy.salary.tab.year' })}</div>
          <InputFormField
            name={getFieldNameWithPrefix('finishYear', namePrefix)}
            disabled={isContinuousField.input.value}
            placeholder="2018"
            validate={validateYearTo}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item hide={["xs", "sm"]} md={6}>
          &nbsp;
        </Grid>
        <Grid item xs={12} md={6}>
          <Field<boolean>
            name={getFieldNameWithPrefix('isContinuous', namePrefix)}
            type="checkbox"
            component={PlainCheckboxAdapter}
            content={intl.formatMessage({ id: 'app.resume.activity.utilnow' })}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default DateRangeFormField;
