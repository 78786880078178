import React, { HTMLProps } from 'react';
import './Typography.css';

const variantMapping = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  body1: 'span',
  body2: 'span',
  body3: 'span',
  body4: 'span',
  h2table: 'h2',
  tinyheading: 'div',
};

interface Props extends HTMLProps<HTMLElement> {
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'body1' | 'body2' | 'body3' | 'body4' | 'h2table' | 'tinyheading'; // the style
  component?: React.ElementType; // the component to render
  muted?: boolean; // set text to grey
  uppercase?: boolean; // set letters to upper case
  marked?: boolean; // mark text with yellow background
  noMargin?: boolean; // disables element margin
  spacing?: number; // margin bottom, will be multiplied by 5
  mutedHeader?: boolean;
  thin?: boolean;
  bold?: boolean;
}

const Typography: React.FC<Props> = ({
  variant, component, children, muted, uppercase, marked, noMargin, mutedHeader, className,
  style = {}, spacing, thin, bold, ...other
}) => {
  const variantStyle = variant || 'body1';
  let trueForm;
  if (!component && variant) trueForm = variantMapping[variant];
  const Component = component || trueForm || 'span';
  const addSpacing = typeof spacing !== 'undefined' ? { marginBottom: spacing * 5 } : {};
  return (
    <Component
      className={[
        `geecko-typography geecko-typography--${variantStyle}`,
        muted ? 'geecko-typography--muted' : '',
        uppercase ? 'geecko-typography--uppercase' : '',
        marked ? 'geecko-typography--marked' : '',
        noMargin ? 'geecko-typography--nomargin' : '',
        mutedHeader ? 'geecko-typography--muted-header' : '',
        thin ? 'geecko-typography--thin' : '',
        bold ? 'geecko-typography--bold' : '',
        className || '',
      ].join(' ')}
      style={{ ...style, ...addSpacing }}
      {...other}
    >
      {marked ? (
        <span>{children}</span>
      ) : (
        <>{children}</>
      )}
    </Component>
  );
};

export default Typography;
