/* eslint-disable no-param-reassign,no-restricted-globals,no-alert */
import React, { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { FormSpy } from 'react-final-form';
import Router from 'next/router';
import { isServer } from '../../models/helpers';

const FormPreventAwayUnsaved: FC = () => {
  const intl = useIntl();
  const [blockNavigation, setBlockNavigation] = useState(false);

  const checkRouteStart = () => {
    if (blockNavigation) {
      if (!confirm(intl.formatMessage({ id: 'app.confirm.exit' }))) {
        if (Router.router) {
          Router.router.abortComponentLoad('', { shallow: false });
          Router.router.events.emit('routeChangeError');
          throw new Error('Abort page navigation, please ignore this error');
        }
      }
    }

    return true;
  };

  useEffect(() => {
    Router.events.on('routeChangeStart', checkRouteStart);

    return () => {
      Router.events.off('routeChangeStart', checkRouteStart);
      window.onbeforeunload = null;
    };
  });

  useEffect(() => {
    if (isServer) {
      return;
    }
    if (blockNavigation) {
      window.onbeforeunload = (event: any) => {
        const message = intl.formatMessage({ id: 'app.datamaynotbesaved' });
        if (event) {
          event.returnValue = message;
        }
        return message;
      };
    } else {
      window.onbeforeunload = null;
    }
  }, [blockNavigation]);

  return (
    <FormSpy
      subscription={{ dirty: true, submitting: true, submitSucceeded: true }}
      onChange={(props) => {
        if (props.dirty && !props.submitSucceeded) {
          setBlockNavigation(true);
        } else {
          setBlockNavigation(false);
        }
      }}
    />
  );
};

export default FormPreventAwayUnsaved;
