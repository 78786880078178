import React, {
  FC, HTMLProps, FocusEvent, useState,
} from 'react';
import './PlainCheckbox.css';
import classNames from 'classnames';

const PlainCheckbox: FC<HTMLProps<HTMLInputElement>> = ({
  checked, disabled, onChange, onFocus, onBlur, children, ...rest
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
    setIsFocused(true);
    if (onFocus) {
      onFocus(event);
    }
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    setIsFocused(false);
    if (onBlur) {
      onBlur(event);
    }
  };

  return (
    <label className={classNames('geecko-plain-checkbox__label', disabled ? 'geecko-plain-checkbox--disabled' : undefined)}>
      <input
        className="geecko-plain-checkbox__input"
        type="checkbox"
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        {...rest}
      />
      <span className={`geecko-plain-checkbox__checkmark ${isFocused ? 'geecko-plain-checkbox__checkmark--focused' : ''}`} />
      {checked && (
        <span className="geecko-plain-checkbox__checkmark--checked" />
      )}
      <span className="geecko-plain-checkbox__label-content">{children}</span>
    </label>
  );
};

export default PlainCheckbox;
