import { createAsyncAction, createAction } from 'typesafe-actions';
import { SkillsSession, SkillsStatus } from 'models/Candidate/SkillsSession';
import { Immutable } from 'immer';
import { SearchSession } from 'models/Candidate/SearchSession';
import { CvFormValues } from 'models/CV/CvFormValues';
import { Report } from 'models/Candidate/Report';
import { VacancyState, VacancyCandidateActiveStatus, Interview } from 'models/VacancyState';
import { ResumeImprovement } from 'models/Candidate/ResumeImprovement';
import { VacanciesCounters } from './state';
import { CvFormStep } from '../../../../pages/Candidate/Cv/CandidateEditCvPage/CandidateEditCvPage';

/**
 * Получение вакансий кандидата
 */
export const fetchCandidateVacanciesAction = createAsyncAction(
  'candidate/VACANCIES_REQUEST',
  'candidate/VACANCIES_SUCCESS',
  'candidate/VACANCIES_FAILURE',
)<
  { status: VacancyCandidateActiveStatus; tariff: string },
  { vacancies: VacancyState[]; counters: VacanciesCounters },
  Error
>();

/**
 * Обновление конкретной вакансии кандидата
 */
export const fetchCandidateVacancyAction = createAsyncAction(
  'candidate/VACANCY_REQUEST',
  'candidate/VACANCY_SUCCESS',
  'candidate/VACANCY_FAILURE',
)<number, VacancyState, Error>();

/**
 * Получение сессий Skills кандидата
 */
export const fetchCandidateSkillsSessionsAction = createAsyncAction(
  'candidate/SKILLS_SESSIONS_REQUEST',
  'candidate/SKILLS_SESSIONS_SUCCESS',
  'candidate/SKILLS_SESSIONS_FAILURE',
)<undefined, SkillsSession[], Error>();

/**
 * Получение статуса статуса Skills кандидата
 */
export const fetchCandidateSkillsStatusAction = createAsyncAction(
  'candidate/SKILLS_STATUS_REQUEST',
  'candidate/SKILLS_STATUS_SUCCESS',
  'candidate/SKILLS_STATUS_FAILURE',
)<undefined, SkillsStatus, Error>();

/**
 * Открывает вакансию с переданным Id на предпросмотр
 */
export const openVacancyPreview = createAction('candidate/OPEN_VACANCY_PREVIEW')<number>();

/**
 * Закрывает предпросмотр вакансий
 */
export const closeVacancyPreview = createAction('candidate/CLOSE_VACANCY_PREVIEW')();

/**
 * Открывает предыдущую вакансию в предпросмотре
 */
export const previewVacancyGoPrev = createAction('candidate/PREVIEW_VACANCY_PREV')();

/**
 * Открывает следующую вакансию в предпросмотре
 */
export const previewVacancyGoNext = createAction('candidate/PREVIEW_VACANCY_NEXT')();

/**
 * Открывает модальное окно отклонения вакансии с указанным Id
 */
export const openRejectVacancyDialog = createAction('candidate/OPEN_REJECT_VACANCY')<number>();

/**
 * Закрывает модальное окно отклонения вакансии
 */
export const closeRejectVacancyDialog = createAction('candidate/CLOSE_REJECT_VACANCY')();

/**
 * Открывает модальное окно оценки интервью для вакансии с указанным Id
 */
export const openVacancyInterviewFeedbackDialog = createAction('candidate/OPEN_VACANCY_INTERVIEW_FEEDBACK')<number>();

/**
 * Закрывает модальное окно оценки интервью для вакансии
 */
export const closeVacancyInterviewFeedbackDialog = createAction('candidate/CLOSE_VACANCY_INTERVIEW_FEEDBACK')();

/**
 * Открывает модальное окно для ответа на запрос интервью с объектом интервью
 */
export const openVacancyInterviewAcceptDialog = createAction('candidate/OPEN_VACANCY_INTERVIEW_ACCEPT')<{
  interview: Interview;
  vacancyId: number;
}>();

/**
 * Закрывает модальное окно оценки интервью для вакансии
 */
export const closeVacancyInterviewAcceptDialog = createAction('candidate/CLOSE_VACANCY_INTERVIEW_ACCEPT')();

/**
 * Открывает модальное окно отклика на вакансию с указанным Id
 */
export const openVacancyResponseDialog = createAction('candidate/OPEN_VACANCY_RESPONSE')<number>();

/**
 * Закрывает модальное окно отклика на вакансию
 */
export const closeVacancyResponseDialog = createAction('candidate/CLOSE_VACANCY_RESPONSE')();

/**
 * Получение статуса суперпоиска
 */
export const fetchCandidateSuperSearchSessionAction = createAsyncAction(
  'candidate/SUPER_SEARCH_SESSION_REQUEST',
  'candidate/SUPER_SEARCH_SESSION_SUCCESS',
  'candidate/SUPER_SEARCH_SESSION_FAILURE',
)<undefined, SearchSession, Error>();

/**
 * Получение CV для редактирования
 */
export const fetchCandidateCvForEditAction = createAsyncAction(
  'candidate/CV_REQUEST',
  'candidate/CV_SUCCESS',
  'candidate/CV_FAILURE',
)<undefined, CvFormValues, Error>();

/**
 * Сохраняет CV кандидата
 */
export const saveCandidateCv = createAction('candidate/CV_SAVE')<{ cv: Immutable<CvFormValues>; step: CvFormStep }>();

/**
 * Закрывает модальное окно оценки интервью для вакансии
 */
export const updateCandidateCvFillRateForStep = createAction('candidate/CV_UPDATE_STEP_FILL_RATE')<{
  step: CvFormStep;
  fillRate: number;
}>();

/**
 * Стартует суперпоиск
 */
export const candidateStartSuperSearchAction = createAsyncAction(
  'candidate/START_SUPER_SEARCH_REQUEST',
  'candidate/START_SUPER_SEARCH_SUCCESS',
  'candidate/START_SUPER_SEARCH_FAILURE',
)<undefined, undefined, Error>();

/**
 * Стартует суперпоиск
 */
export const candidateStopSuperSearchAction = createAsyncAction(
  'candidate/STOP_SUPER_SEARCH_REQUEST',
  'candidate/STOP_SUPER_SEARCH_SUCCESS',
  'candidate/STOP_SUPER_SEARCH_FAILURE',
)<undefined, undefined, Error>();

/**
 * Получение отчета разработчика
 */
export const fetchCandidateReportAction = createAsyncAction(
  'candidate/REPORT_REQUEST',
  'candidate/REPORT_SUCCESS',
  'candidate/REPORT_FAILURE',
)<undefined, Report, Error>();

/**
* Получение CV для улучшения
 */
export const fetchCandidateCvImprovementsAction = createAsyncAction(
  'candidate/CV_IMPROVEMENTS_REQUEST',
  'candidate/CV_IMPROVEMENTS_SUCCESS',
  'candidate/CV_IMPROVEMENTS_FAILURE',
)<undefined, ResumeImprovement[], Error>();
