import qs from 'qs';
import { GeeckoApi } from 'modules/api/geecko-api';

export interface StackTool {
  id: number;
  value: number;
  name: string;
  label: string;
  image: string;
  type?: 'tech' | 'lang' | string;
}

export const decodeStackTool = (json: any): StackTool => ({
  id: json.id,
  value: json.id,
  name: json.name,
  label: json.name,
  image: json.image,
  type: json.type ? json.type : undefined,
});

export const loadStackOptionsNew = async (api: GeeckoApi, inputValue: string) => {
  if (inputValue) {
    const result = await api.get(`/dictionary/tools?${qs.stringify({ query: inputValue })}`)
    return result.data && result.data.tools && result.data.tools.length > 0
        ? result.data.tools.map(decodeStackTool)
        : [];
  }

  return [];
};


export const loadStackOptions = (api: GeeckoApi, inputValue: string, callback: any) => {
  if (inputValue) {
    api.get(`/dictionary/tools?${qs.stringify({ query: inputValue })}`)
      .then((result) => {
        callback(
          result.data && result.data.tools && result.data.tools.length > 0
            ? result.data.tools.map((item: any) => ({ label: item.name, value: item.id, image: item.image }))
            : [],
        );
      })
      .catch(() => {
        //
      });
  } else {
    callback([]);
  }
};
