import { createSelector } from 'redux-views';
import { Specialization } from 'models/Specialization';
import { Language, CommonEntity } from 'models/BaseTypes';
import { AppState, NetworkEntitiesCollecionSelector, getNetworkEntitiesCollectionSelectorState } from '../../index';
import { DICTIONARY_MODULE_NAME } from '.';
import { CompanyUser } from '../../../models/CompanyUser';
import { CompanyOffice } from '../../../models/CompanyOffice';

export const dictionaryStateSelector = (state: AppState) => state[DICTIONARY_MODULE_NAME];

/**
 * Селектор специализаций
 */
const specializationsStateSelector = createSelector(
  [dictionaryStateSelector],
  (dictionaryState) => dictionaryState.specializations,
);

export const specializationsSelector: NetworkEntitiesCollecionSelector<Specialization> = createSelector(
  [specializationsStateSelector],
  (specializationsState) => {
    return getNetworkEntitiesCollectionSelectorState(specializationsState);
  },
);

/**
 * Селектор иностранных языков
 */
const languagesStateSelector = createSelector(
  [dictionaryStateSelector],
  (dictionaryState) => dictionaryState.languages,
);

export const languagesSelector: NetworkEntitiesCollecionSelector<Language> = createSelector(
  [languagesStateSelector],
  (languagesState) => {
    return getNetworkEntitiesCollectionSelectorState(languagesState);
  },
);

/**
 * Причины отказа для вакансии
 */
export const selectDictionaryVacancyRejectReasonsState = createSelector(
  [dictionaryStateSelector],
  (dictionaryState) => dictionaryState && dictionaryState.vacancyRejectReasons,
);

export const selectDictionaryVacancyRejectReasons = createSelector(
  [selectDictionaryVacancyRejectReasonsState],
  (state) => state.items || [],
);

export const selectDictionaryVacancyRejectReasonsLoading = createSelector(
  [selectDictionaryVacancyRejectReasonsState],
  (state) => !!state.loading,
);

export const selectDictionaryVacancyRejectReasonsLoaded = createSelector(
  [selectDictionaryVacancyRejectReasonsState],
  (state) => !!state.loaded,
);

export const selectDictionaryVacancyRejectReasonsError = createSelector(
  [selectDictionaryVacancyRejectReasonsState],
  (state) => state.error,
);

/**
 * Причины отказа для кандидатам
 */
export const selectDictionaryCandidateRejectReasonsState = createSelector(
  [dictionaryStateSelector],
  (dictionaryState) => dictionaryState && dictionaryState.candidateRejectReasons,
);

export const selectDictionaryCandidateRejectReasons = createSelector(
  [selectDictionaryCandidateRejectReasonsState],
  (state) => state.items || [],
);

export const selectDictionaryCandidateRejectReasonsLoading = createSelector(
  [selectDictionaryCandidateRejectReasonsState],
  (state) => !!state.loading,
);

export const selectDictionaryCandidateRejectReasonsLoaded = createSelector(
  [selectDictionaryCandidateRejectReasonsState],
  (state) => !!state.loaded,
);

export const selectDictionaryCandidateRejectReasonsError = createSelector(
  [selectDictionaryCandidateRejectReasonsState],
  (state) => state.error,
);

/**
 * Тестовые шаблоны Skills
 */
export const selectSkillsTemplatesState = createSelector(
  [dictionaryStateSelector],
  (dictionaryState) => dictionaryState && dictionaryState.skillsTemplates,
);

export const selectSkillsTemplates = createSelector([selectSkillsTemplatesState], (state) => state.items || []);

export const selectSkillsTemplatesLoading = createSelector([selectSkillsTemplatesState], (state) => !!state.loading);

export const selectSkillsTemplatesLoaded = createSelector([selectSkillsTemplatesState], (state) => !!state.loaded);

export const selectSkillsTemplatesError = createSelector([selectSkillsTemplatesState], (state) => state.error);

/**
 * Селектор "Важно в работе"
 */
const workFeaturesStateSelector = createSelector(
  [dictionaryStateSelector],
  (dictionaryState) => dictionaryState.workFeatures,
);

export const workFeaturesSelector: NetworkEntitiesCollecionSelector<CommonEntity> = createSelector(
  [workFeaturesStateSelector],
  (workFeaturesState) => {
    return getNetworkEntitiesCollectionSelectorState(workFeaturesState);
  },
);

/**
 * Селектор "Предметные области"
 */
const companyScopesStateSelector = createSelector(
  [dictionaryStateSelector],
  (dictionaryState) => dictionaryState.companyScopes,
);

export const companyScopesSelector: NetworkEntitiesCollecionSelector<CommonEntity> = createSelector(
  [companyScopesStateSelector],
  (companyScopesState) => {
    return getNetworkEntitiesCollectionSelectorState(companyScopesState);
  },
);

/**
 * Селектор "Пользователи компании"
 */
const companyUsersStateSelector = createSelector(
  [dictionaryStateSelector],
  (dictionaryState) => dictionaryState.companyUsers,
);

export const companyUsersSelector: NetworkEntitiesCollecionSelector<CompanyUser> = createSelector(
  [companyUsersStateSelector],
  (companyUsersState) => {
    return getNetworkEntitiesCollectionSelectorState(companyUsersState);
  },
);

/**
 * Селектор "Офисы компании"
 */
const companyOfficesStateSelector = createSelector(
  [dictionaryStateSelector],
  (dictionaryState) => dictionaryState.companyOffices,
);

export const companyOfficesSelector: NetworkEntitiesCollecionSelector<CompanyOffice> = createSelector(
  [companyOfficesStateSelector],
  (companyOfficesState) => {
    return getNetworkEntitiesCollectionSelectorState(companyOfficesState);
  },
);
