import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import './SkillSetTag.css';
import classNames from 'classnames';
import Tooltip from 'components/Tooltip/Tooltip';
import CancelCircle from 'components/Icons/CancelCircle/CancelCircle';
import CheckCircleIcon from 'components/Icons/CheckCircleIcon';

interface Props {
  title: string;
  experienceMonths?: number;
  experienceFrom?: number;
  experienceTo?: number;
  isPrimary: boolean;
  showTooltips?: boolean;
}

const SkillSetTag: FC<Props> = ({
  title, experienceMonths, experienceFrom, experienceTo, isPrimary, showTooltips,
}) => {
  const intl = useIntl();
  const skillTagClassName = classNames({
    'geecko-candidate-stacktag': true,
    'geecko-candidate-stacktag--inactive': !isPrimary,
  });
  let experience = '';
  let verbose = false;
  if (experienceMonths) {
    if (experienceMonths >= 12) {
      experience = (Math.round(experienceMonths / 12 * 2) / 2).toString();
    } else {
      experience = '< 1';
      verbose = true;
    }
  } else if (!experienceFrom && experienceTo) {
    experience = '< 1';
    verbose = true;
  } else if (experienceFrom && experienceTo) {
    experience = `${experienceFrom}-${experienceTo}`;
  } else if (experienceFrom && !experienceTo) {
    experience = `${experienceFrom}+`;
  }
  return (
    <Tooltip
      overlay={showTooltips ? (
        <div className="geecko-candidate-stacktag__popup">
          <div className="geecko-candidate-stacktag__popup-title">
            <div className="geecko-candidate-stacktag__popup-label">
              {title}
            </div>
          </div>
          <div className={`geecko-candidate-stacktag__popup-text ${!isPrimary ? 'geecko-color-danger' : 'geecko-color-primary'}`}>
            <span>{!isPrimary ? <CancelCircle /> : <CheckCircleIcon />}</span>
            <div>
              {intl.formatMessage({ id: `app.candidate.skilltag.${!isPrimary ? 'not' : ''}lookingfor` })}
            </div>
          </div>
        </div>
      ) : undefined}
    >
      <div className={skillTagClassName}>
        <div className="geecko-candidate-stacktag__label" title={title}>
          {title}
        </div>
        {experience && (
          <>
            <div className={`geecko-candidate-stacktag__experience${verbose ? '--verbose' : ''}`}>
              {experience}
            </div>
          </>
        )}
      </div>
    </Tooltip>
  );
};

export default SkillSetTag;
