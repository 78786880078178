export interface University {
  id?: number;
  name: string;
  description?: string;
  faculties: Faculty[];
}

export interface Faculty {
  id: number;
  name: string;
}

export const decodeUniversity = (json: any): University => json;
