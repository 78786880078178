import React, { FC } from 'react';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import Dialog from 'components/v2/Dialog/Dialog';
import { useDispatch, useSelector } from 'react-redux';
import { modalClosed } from 'store/ducks/settings';
import { specializationsSelector } from 'store/ducks/dictionary/selectors';
import FormBigSkeleton from 'components/v2/skeletons/FormSkeletons/FormBigSkeleton';
import { StackFields } from './CVFormExperienceCompanyModal';
import SkillSetStackField from '../../components/Form/SkillSetStackField/SkillSetStackField';
import Button from '../../components/v2/Button/Button';

interface Props {
  isOpen: boolean;
  commonSkills: StackFields;
  value: StackFields;
  onChange: (value: StackFields) => void;
  onClose: () => void;
  type: 'company' | 'project';
}

const MODAL_ID = 'company-stack-modal';

const CVFormExperienceStackModal: FC<Props> = ({ isOpen, type, commonSkills, value, onChange, onClose }) => {
  const dispatch = useDispatch();
  const specializations = useSelector(specializationsSelector);
  const handleFormSubmit = (newValue: StackFields) => {
    onChange(newValue);
  };

  const handleClose = () => {
    dispatch(modalClosed(MODAL_ID));
    onClose();
  };

  return (
    <Dialog
      title={type === 'company' ? 'Стэк компании' : 'Стэк проекта'}
      isOpen={isOpen}
      modalBaseId={MODAL_ID}
      onClose={onClose}
      modalStyle={{ maxWidth: 800 }}
      closeOnBgClick={false}
    >
      <Form<StackFields>
        onSubmit={handleFormSubmit}
        initialValues={value}
        mutators={{
          ...arrayMutators,
        }}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              {specializations.loading && (
                <FormBigSkeleton />
              )}
              {specializations.loaded && specializations.items && (
                <div className="geecko-form__item">
                  <SkillSetStackField variant="specific" specializations={specializations.items} commonSkills={commonSkills} />
                </div>
              )}
              <div className="geecko-form__buttons-line geecko-form__buttons-line--margin-lg">
                <div className="geecko-form__buttons-line__button">
                  <Button size="lg" color="primary" type="submit" isLoading={submitting}>
                    Сохранить
                  </Button>
                </div>
                <div className="geecko-form__buttons-line__button">
                  <Button size="lg" color="secondary" type="button" onClick={handleClose}>
                    Закрыть
                  </Button>
                </div>
              </div>
            </form>
          );
        }}
      />
    </Dialog>
  );
};

export default CVFormExperienceStackModal;
