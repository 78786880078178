export enum SearchStatus {
  InProgress = 'in-progress',
  Waiting = 'waiting',
  Succeeded = 'succeeded',
  Failed = 'failed',
  Stopped = 'stopped',
}

export enum SearchStageStatus {
  ResumeCreating = 'resume-creating',
  ResumeReview = 'resume-review',
  Interviewing = 'interviewing',
  OfferReview = 'offer-review',
}

export interface ImpovementsCounter {
  done: number;
  moderation: number;
  warning: number;
  critical: number;
}

export interface SearchStage {
  readonly id: SearchStageStatus;
  readonly daysTotal: number;
  readonly daysLeft: number;
  readonly finishAt: string;
  readonly status: SearchStatus;
  readonly name: string;
  readonly text: string;
}

export interface SearchSession {
  readonly isAvailable: boolean;
  readonly status: SearchStatus;
  readonly stages: SearchStage[];
  readonly failed?: SearchSessionFailedInfo;
  readonly improvements?: ImpovementsCounter;
  readonly isFullReportRready?: boolean;
}

interface SearchSessionFailedInfo {
  readonly title: string;
  readonly text: string;
  readonly canRetryAt: string;
}

export const decodeSearchSessionFailedInfo = (json: any): SearchSessionFailedInfo => ({
  title: json.title,
  text: json.text,
  canRetryAt: json.can_retry_at,
});

export const decodeSearchStage = (json: any): SearchStage => ({
  id: json.id,
  daysTotal: json.days_total,
  daysLeft: json.days_left,
  finishAt: json.finish_at,
  status: json.status,
  name: json.name,
  text: json.text,
});

export const decodeSearchSession = (json: any): SearchSession => ({
  isAvailable: json.is_available,
  status: json.status,
  stages: json.stages && Array.isArray(json.stages) ? json.stages.map(decodeSearchStage) : [],
  failed: json.failed ? decodeSearchSessionFailedInfo(json.failed) : undefined,
  improvements: json.improvements ? json.improvements : undefined,
  isFullReportRready: !!json.is_full_report_ready,
});
