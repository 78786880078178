/* eslint-disable no-param-reassign */
import { ActionType, getType } from 'typesafe-actions';
import produce, { Draft, castDraft } from 'immer';
import { CompanyState, initialCompanyState } from './state';
import * as CompanyAction from './actionCreators';

type CompanyActionType = ActionType<typeof CompanyAction>;

export const companyReducer = produce((state: Draft<CompanyState>, action: CompanyActionType) => {
  switch (action.type) {
    case getType(CompanyAction.openOfferDialog): {
      state.resumeOfferId = action.payload.resumeId;
      state.vacancyOfferId = action.payload.vacancyId;
      break;
    }

    case getType(CompanyAction.closeOfferDialog): {
      state.resumeOfferId = undefined;
      state.vacancyOfferId = undefined;
      break;
    }

    case getType(CompanyAction.openRejectCandidateDialog): {
      state.resumeRejectId = action.payload.resumeId;
      state.vacancyRejectId = action.payload.vacancyId;
      break;
    }

    case getType(CompanyAction.closeRejectCandidateDialog): {
      state.resumeRejectId = undefined;
      state.vacancyRejectId = undefined;
      break;
    }

    case getType(CompanyAction.openResponseCandidateDialog): {
      state.resumeCandidateResponseId = action.payload.resumeId;
      state.vacancyCandidateResponseId = action.payload.vacancyId;
      state.vacancyResponseComment = action.payload.comment;
      break;
    }

    case getType(CompanyAction.closeResponseCandidateDialog): {
      state.resumeCandidateResponseId = undefined;
      state.vacancyCandidateResponseId = undefined;
      state.vacancyResponseComment = undefined;
      break;
    }

    case getType(CompanyAction.reloadVacanciesState): {
      state.statusReloadId = action.payload;
      break;
    }

    case getType(CompanyAction.openCompanyInterviewFeedbackDialog): {
      state.interviewFeedback = action.payload;
      break;
    }

    case getType(CompanyAction.closeCompanyInterviewFeedbackDialog): {
      state.interviewFeedback = undefined;
      break;
    }

    /**
     * Получение вакансий
     */
    case getType(CompanyAction.fetchCompanyVacanciesAction.request): {
      state.vacancies.vacancies.items = [];
      state.vacancies.vacancies.loading = true;
      state.vacancies.vacancies.loaded = false;
      state.vacancies.vacancies.error = undefined;
      state.vacancies.status = action.payload.status;
      break;
    }
    case getType(CompanyAction.fetchCompanyVacanciesAction.success): {
      state.vacancies.vacancies.items = castDraft(action.payload.vacancies);
      state.vacancies.vacancies.loading = false;
      state.vacancies.vacancies.loaded = true;
      state.vacancies.counters = action.payload.counters;
      break;
    }
    case getType(CompanyAction.fetchCompanyVacanciesAction.failure): {
      state.vacancies.vacancies.loading = false;
      state.vacancies.vacancies.error = action.payload;
      break;
    }

    /**
     * Получение реквизитов
     */
    case getType(CompanyAction.fetchCompanyRequisitesAction.request): {
      state.requisites.loading = true;
      state.requisites.loaded = false;
      state.requisites.error = undefined;
      break;
    }
    case getType(CompanyAction.fetchCompanyRequisitesAction.success): {
      state.requisites.item = action.payload;
      state.requisites.loading = false;
      state.requisites.loaded = true;
      break;
    }
    case getType(CompanyAction.fetchCompanyRequisitesAction.failure): {
      state.vacancies.vacancies.loading = false;
      state.vacancies.vacancies.error = action.payload;
      break;
    }

    /**
     * Получение конкретного пула
     */
    case getType(CompanyAction.fetchPoolAction.request): {
      if (!action.payload.loadMore) {
        state.pool.pool.loading = true;
        state.pool.pool.loaded = false;
        state.pool.pool.error = undefined;
        state.candidates.candidates.items = [];
      }
      state.candidates.candidates.loading = true;
      state.candidates.candidates.loaded = false;
      break;
    }

    case getType(CompanyAction.fetchPoolAction.success): {
      state.pool.pool.loading = false;
      state.pool.pool.loaded = true;
      state.pool.pool.item = castDraft(action.payload.pool);
      state.pool.counters = action.payload.counters;
      state.pool.online = action.payload.online;
      state.pool.vacancies = action.payload.vacancies;
      if (action.payload.append) {
        state.candidates.candidates.items = state.candidates.candidates.items || [];
        state.candidates.candidates.items.push(...castDraft(action.payload.candidates));
      } else {
        state.candidates.candidates.items = castDraft(action.payload.candidates);
      }
      state.candidates.candidates.loading = false;
      state.candidates.candidates.loaded = true;
      state.candidates.total = action.payload.total;
      break;
    }

    case getType(CompanyAction.fetchPoolAction.failure): {
      state.pool.pool.loading = false;
      state.pool.pool.error = action.payload;
      state.candidates.candidates.loading = false;
      state.candidates.candidates.error = action.payload;
      break;
    }

    case getType(CompanyAction.openCandidatePreview): {
      state.candidates.previewId = action.payload;
      break;
    }
    case getType(CompanyAction.closeCandidatePreview): {
      state.candidates.previewId = undefined;
      break;
    }
    case getType(CompanyAction.previewCandidateGoNext): {
      if (
        state.candidates.candidates.items &&
        state.candidates.candidates.items.length > 0 &&
        state.candidates.previewId
      ) {
        const candidateIndex = state.candidates.candidates.items.findIndex(
          (item) => item.resume.hash === state.candidates.previewId,
        );
        if (candidateIndex >= 0 && candidateIndex < state.candidates.candidates.items.length - 1) {
          state.candidates.previewId = state.candidates.candidates.items[candidateIndex + 1].resume.hash;
          break;
        }
        if (state.candidates.total === state.candidates.candidates.items.length) {
          state.candidates.previewId = state.candidates.candidates.items[0].resume.hash;
        }
        break;
      }

      break;
    }
    case getType(CompanyAction.previewCandidateGoPrev): {
      if (
        state.candidates.candidates.items &&
        state.candidates.candidates.items.length > 0 &&
        state.candidates.previewId
      ) {
        const candidateIndex = state.candidates.candidates.items.findIndex(
          (item) => item.resume.hash === state.candidates.previewId,
        );
        if (candidateIndex > 0) {
          state.candidates.previewId = state.candidates.candidates.items[candidateIndex - 1].resume.hash;
          break;
        }

        if (state.candidates.total === state.candidates.candidates.items.length) {
          state.candidates.previewId =
            state.candidates.candidates.items[state.candidates.candidates.items.length - 1].resume.hash;
        }
        break;
      }

      break;
    }

    /**
     * Получение вакансии
     */
    case getType(CompanyAction.fetchVacancyAction.request): {
      state.vacancy.vacancy.loading = true;
      state.vacancy.vacancy.loaded = false;
      state.vacancy.vacancy.error = undefined;
      break;
    }
    case getType(CompanyAction.fetchVacancyAction.success): {
      state.vacancy.vacancy.item = castDraft(action.payload);
      state.vacancy.vacancy.loading = false;
      state.vacancy.vacancy.loaded = true;
      break;
    }
    case getType(CompanyAction.fetchVacancyAction.failure): {
      state.vacancy.vacancy.loading = false;
      state.vacancy.vacancy.error = action.payload;
      break;
    }

    /**
     * Получение кандидатов вакансии
     */
    case getType(CompanyAction.fetchVacancyCandidatesAction.request): {
      if (!action.payload.loadMore) {
        state.candidates.candidates.items = [];
      }
      // Если экшен вызван с текущей вакансией в стейте - не сбрасываем счетчики, иначе сбрасываем
      if (!state.vacancy.vacancy.item || state.vacancy.vacancy.item.id !== +action.payload.vacancyId) {
        state.candidates.counters = {};
      }
      state.candidates.status = action.payload.status;
      state.candidates.candidates.loading = true;
      state.candidates.candidates.loaded = false;
      break;
    }

    case getType(CompanyAction.fetchVacancyCandidatesAction.success): {
      if (action.payload.append) {
        state.candidates.candidates.items = state.candidates.candidates.items || [];
        state.candidates.candidates.items.push(...castDraft(action.payload.candidates));
      } else {
        state.candidates.candidates.items = castDraft(action.payload.candidates);
      }
      state.candidates.counters = action.payload.counters;
      state.candidates.candidates.loading = false;
      state.candidates.candidates.loaded = true;
      state.candidates.total = action.payload.total;
      break;
    }

    case getType(CompanyAction.fetchVacancyCandidatesAction.failure): {
      state.candidates.candidates.loading = false;
      state.candidates.candidates.error = action.payload;
      break;
    }

    case getType(CompanyAction.reloadCandidatesListResumeAction.success): {
      if (state.candidates.candidates.items) {
        const resume = action.payload;
        const resumeIndex = state.candidates.candidates.items.findIndex((item) => item.resume.hash === resume.hash);
        if (typeof resumeIndex !== 'undefined') {
          state.candidates.candidates.items[resumeIndex].resume = castDraft(resume);
          state.candidates.candidates.items[resumeIndex].isFull = true;
        }
      }
      break;
    }

    case getType(CompanyAction.openScheduleInterviewDialogAction): {
      state.interviewSchedule = action.payload;
      break;
    }

    case getType(CompanyAction.closeScheduleInterviewDialogAction): {
      state.interviewSchedule = undefined;
      break;
    }

    default: {
      //
    }
  }
}, initialCompanyState);
