import { put, takeLatest, getContext, call, delay } from 'redux-saga/effects';
import { ServerApi } from 'modules/api/server-api';
import { VacancyState } from 'models/VacancyState';
import { fetchCandidateVacancies } from 'api/candidate/vacancy/get-candidate-vacancies';
import { fetchCandidateSkillsSessions } from 'api/candidate/skills/get-skills-sessions';
import { SkillsSession, SkillsStatus, SkillsSessionStatus } from 'models/Candidate/SkillsSession';
import { fetchCandidateVacancy } from 'api/candidate/vacancy/candidate-vacancy';
import { fetchCandidateSkillsStatus } from 'api/candidate/skills/get-skills-status';
import { isServer } from 'models/helpers';
import { SearchSession } from 'models/Candidate/SearchSession';
import { fetchCandidateSuperSearchSession } from 'api/candidate/super-search/get-candidate-super-search-session';
import { CvFormValues } from 'models/CV/CvFormValues';
import { fetchCandidateCv } from 'api/candidate/cv/fetch-candidate-cv';
import { candidateStartSuperSearch } from 'api/candidate/super-search/start-super-search';
import { candidateStopSuperSearch } from 'api/candidate/super-search/stop-super-search';
import { fetchCandidateReport } from 'api/candidate/report/get-candidate-report';
import { Report } from 'models/Candidate/Report';
import { ResumeImprovement } from 'models/Candidate/ResumeImprovement';
import { getCandidateCvImprovements } from 'api/candidate/cv/get-candidate-cv-improvements';
import { VacanciesCounters } from './state';
import {
  openRejectVacancyDialog,
  fetchCandidateVacanciesAction,
  fetchCandidateSkillsSessionsAction,
  fetchCandidateVacancyAction,
  fetchCandidateSkillsStatusAction,
  fetchCandidateSuperSearchSessionAction,
  fetchCandidateCvForEditAction,
  candidateStartSuperSearchAction,
  candidateStopSuperSearchAction,
  fetchCandidateReportAction,
  fetchCandidateCvImprovementsAction,
} from './actionCreators';
import { fetchVacancyRejectReasonsAction } from '../dictionary/actionCreators';

function* getVacancyRejectReasonsWhenOpenRejectDialog() {
  yield put(fetchVacancyRejectReasonsAction.request());
}

/**
 * Получение вакансий кандидата
 */
function* getCandidateVacanciesSaga(action: ReturnType<typeof fetchCandidateVacanciesAction.request>) {
  try {
    const api: ServerApi = yield getContext('api');
    const { status, tariff } = action.payload;
    const { vacancies, counters }: { vacancies: VacancyState[]; counters: VacanciesCounters } = yield call(
      fetchCandidateVacancies(api),
      status,
      tariff,
    );
    yield put(fetchCandidateVacanciesAction.success({ vacancies, counters }));
  } catch (e) {
    yield put(fetchCandidateVacanciesAction.failure(e));
  }
}

/**
 * Обновление вакансии кандидата
 */
function* updateCandidateVacancySaga(action: ReturnType<typeof fetchCandidateVacancyAction.request>) {
  try {
    const api: ServerApi = yield getContext('api');
    const vacancy: VacancyState = yield call(fetchCandidateVacancy(api), action.payload);
    yield put(fetchCandidateVacancyAction.success(vacancy));
  } catch (e) {
    yield put(fetchCandidateVacancyAction.failure(e));
  }
}

/**
 * Получение сессий Skills кандидата
 */
function* getCandidateSkillsSessionsSaga() {
  try {
    const api: ServerApi = yield getContext('api');
    const sessions: SkillsSession[] = yield call(fetchCandidateSkillsSessions(api));
    yield put(fetchCandidateSkillsSessionsAction.success(sessions));
  } catch (e) {
    yield put(fetchCandidateSkillsSessionsAction.failure(e));
  }
}

function* getCandidateSkillsStatusSaga() {
  try {
    const api: ServerApi = yield getContext('api');
    const status: SkillsStatus = yield call(fetchCandidateSkillsStatus(api));
    yield put(fetchCandidateSkillsStatusAction.success(status));

    if (
      !isServer &&
      status.lastSession &&
      status.lastSession.statusId === SkillsSessionStatus.FINISHED &&
      typeof status.lastSession.scorePercent !== 'number'
    ) {
      yield delay(5000);
      yield put(fetchCandidateSkillsStatusAction.request());
    }
  } catch (e) {
    yield put(fetchCandidateSkillsStatusAction.failure(e));
  }
}

/**
 * Получение статуса суперпоиска
 */
function* getCandidateSuperSearchSessionSaga() {
  try {
    const api: ServerApi = yield getContext('api');
    const session: SearchSession = yield call(fetchCandidateSuperSearchSession(api));
    yield put(fetchCandidateSuperSearchSessionAction.success(session));
  } catch (e) {
    yield put(fetchCandidateSuperSearchSessionAction.failure(e));
  }
}

/**
 * Получение CV кандидата для редактирования
 */
function* getCandidateCvForEditSaga() {
  try {
    const api: ServerApi = yield getContext('api');
    const cv: CvFormValues = yield call(fetchCandidateCv(api));
    yield put(fetchCandidateCvForEditAction.success(cv));
  } catch (e) {
    yield put(fetchCandidateCvForEditAction.failure(e));
  }
}

/**
 * Старт супер поиска
 */
function* startSuperSearch() {
  try {
    const api: ServerApi = yield getContext('api');
    yield call(candidateStartSuperSearch(api));
    yield put(candidateStartSuperSearchAction.success());
    yield put(fetchCandidateSuperSearchSessionAction.request());
  } catch (e) {
    yield put(candidateStartSuperSearchAction.failure(e));
  }
}

/**
 * Стоп супер поиска
 */
function* stopSuperSearch() {
  try {
    const api: ServerApi = yield getContext('api');
    yield call(candidateStopSuperSearch(api));
    yield put(candidateStopSuperSearchAction.success());
    yield put(fetchCandidateSuperSearchSessionAction.request());
  } catch (e) {
    yield put(candidateStopSuperSearchAction.failure(e));
  }
}

/**
 * Получение отчета разработчика
 */
function* getCandidateReportSaga() {
  try {
    const api: ServerApi = yield getContext('api');
    const report: Report = yield call(fetchCandidateReport(api));
    yield put(fetchCandidateReportAction.success(report));
  } catch (e) {
    yield put(fetchCandidateReportAction.failure(e));
  }
}

/**
 * Получение рекомндаций по улучшению резюме
 */
function* getCandidateCvImprovementsSaga() {
  try {
    const api: ServerApi = yield getContext('api');
    const cvImprovements: ResumeImprovement[] = yield call(getCandidateCvImprovements(api));
    yield put(fetchCandidateCvImprovementsAction.success(cvImprovements));
  } catch (e) {
    yield put(fetchCandidateCvImprovementsAction.failure(e));
  }
}

export function* candidateSaga() {
  yield takeLatest(openRejectVacancyDialog, getVacancyRejectReasonsWhenOpenRejectDialog);
  yield takeLatest(fetchCandidateVacanciesAction.request, getCandidateVacanciesSaga);
  yield takeLatest(fetchCandidateSkillsSessionsAction.request, getCandidateSkillsSessionsSaga);
  yield takeLatest(fetchCandidateSkillsStatusAction.request, getCandidateSkillsStatusSaga);
  yield takeLatest(fetchCandidateVacancyAction.request, updateCandidateVacancySaga);
  yield takeLatest(fetchCandidateSuperSearchSessionAction.request, getCandidateSuperSearchSessionSaga);
  yield takeLatest(fetchCandidateCvForEditAction.request, getCandidateCvForEditSaga);
  yield takeLatest(candidateStartSuperSearchAction.request, startSuperSearch);
  yield takeLatest(candidateStopSuperSearchAction.request, stopSuperSearch);
  yield takeLatest(fetchCandidateReportAction.request, getCandidateReportSaga);
  yield takeLatest(fetchCandidateCvImprovementsAction.request, getCandidateCvImprovementsSaga);
}
