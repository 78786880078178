import React from 'react';

interface Props {
  size? : number;
}

const PlusCircle: React.FC<Props> = (props: Props) => {
  const { size = 16 } = props;
  return (
    <>
      <svg
        viewBox="0 0 20 20"
        width={size}
        height={size}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11 8.99976H14V10.9998H11V13.9998H9V10.9998H6V8.99976H9V5.99976H11V8.99976ZM10 17.9998C5.589 17.9998 2 14.4108 2 9.99976C2 5.58876 5.589 1.99976 10 1.99976C14.411 1.99976 18 5.58876 18 9.99976C18 14.4108 14.411 17.9998 10 17.9998ZM10 -0.000244141C4.477 -0.000244141 0 4.47676 0 9.99976C0 15.5228 4.477 19.9998 10 19.9998C15.523 19.9998 20 15.5228 20 9.99976C20 4.47676 15.523 -0.000244141 10 -0.000244141Z"
          fill="currentColor"
        />
      </svg>
    </>
  );
};

export default PlusCircle;
