import { ReactNode } from 'react';
import camelcaseKeys from 'camelcase-keys';
import { Immutable } from 'immer';
import { Location } from './Location';
import { Salary } from './Salary';
import { CandidateShareContactType } from './Candidate';
import { Comment } from './Comment';
import { decodeSkillSet, SkillSetInterface } from './Tech/skillset';
import { Specialization } from './Specialization';
import { CvCandidateSpecialization, CvSkills } from './CV/CvFormValues';
import { VideoInterview, decodeVideoInterview } from './Candidate/VideoInterview';
import { VacancyStateStatus, Interview, Offer, decodeVacancyStateStatus, decodeInterview, decodeOffer } from './VacancyState';

export interface ExperienceSummary {
  management: {
    years: number;
    months: number;
  };
  total: {
    years: number;
    months: number;
  };
}

export type Company = Immutable<{
  title: string;
  image?: string;
  link?: string;
  description: string | ReactNode;
}>

export interface Project {
  title: string;
  position: string;
  description: string;
  link: string;
  tools: {
    id: number;
    name: string;
    link: string;
    image: string;
  }[];
}

export type Experience = Immutable<{
  id: number;
  start: string;
  finish: string;
  periodMonths: number;
  company: Company;
  isAdditional: boolean;
  projects?: Project[];
  position?: string;
  tools?: {
    name: string;
    image: string;
    link: string;
  }[];
  areas: string[];
  specializations: CvCandidateSpecialization[];
  skillSet: CvSkills;
}>

export interface Contact {
  channelId: string;
  channelName: string;
  value?: string;
  isPreferred?: boolean;
}

export interface Community {
  title: string;
  image: string;
  months: number;
  link: string;
  username: string;
  summary: string;
  skills?:
    {
      name: string;
      type: string;
    }[];
  hidden: boolean;
}

export interface Education {
  image: string;
  name: string;
  description: string;
  faculty: string;
  start: string;
  finish: string;
  periodMonths: number;
}

export interface LanguageSkill {
  id: number;
  name: string;
  level: string;
}

export type Resume = Immutable<{
  comment: Comment;
  hash: string;
  links?: {
    full: string;
    noSalary: string;
  };
  profession?: string;
  fullName: string;
  photoUrl: string;
  salary: Salary;
  experienceSummary: ExperienceSummary;
  experience: Experience[];
  location: Location;
  age: number;
  workType: string;
  relocationCity?: Location[];
  relocationType: string;
  relocationCountry?: Location[];
  countryRelocationType: 'ready' | 'not_ready';
  contacts: Contact[];
  contactAccessType: CandidateShareContactType;
  specializations: Specialization[];
  tools: {
    name: string;
    image: string;
    link: string;
  }[];
  languages: LanguageSkill[];
  badge?: {
    link: string;
    percent: number;
    name: string;
  };
  badges: {
    id: number;
    scoreTitle: string;
    title: string;
    text: string;
    url: string;
    urlText: string;
    tooltip: string;
    isTop?: boolean;
    hidden: boolean;
    image?: string;
    isVisible?: boolean;
  }[];
  about: string;
  expectations: string;
  workFeatures?: {
    id: string;
    name: string;
  }[];
  workAreas?: {
    id: string;
    name: string;
    experienceMonths: number;
    isPrimary?: boolean;
  }[];
  codeSamples?: {
    title: string;
    link: string;
    description: string;
    tools?: {
      name: string;
      image: string;
      link: string;
    }[];
    hidden: boolean;
  }[];
  communities?: Community[];
  education?: Education[];
  skillSummary?: string;
  skillSet: SkillSetInterface;
  videoInterview?: VideoInterview;
  viewedAt?: string;
}>

export type ResumeState = Immutable<{
  resume?: Resume;
  status?: VacancyStateStatus;
  interview?: Interview;
  offer?: Offer;
  burnAt?: string;
  isExpired?: boolean;
}>

export const decodeResumeState = (json: any): ResumeState => {
  return {
    resume: json.resume ? decodeResume(json.resume) : undefined,
    status: json.status ? decodeVacancyStateStatus(json.status) : undefined,
    interview: json.interview ? decodeInterview(json.interview) : undefined,
    offer: json.offer ? decodeOffer(json.offer) : undefined,
    burnAt: json.burn_at ? json.burn_at : undefined,
    isExpired: !!json.is_expired,
  }
}

export const decodeExperience = (json: any): Experience => {
  const result = {
    ...json,
    periodMonths: json.period_months,
    isAdditional: json.is_additional,
    start: json.start ? new Date(json.start) : null,
    finish: json.finish ? new Date(json.finish) : null,
    skillSet: decodeSkillSet(json.skills),
  };
  delete result.period_months;
  delete result.is_additional;
  return result;
};

export const decodeEducation = (json: any) => {
  const result = {
    ...json,
    periodMonths: json.period_months,
    start: new Date(json.start),
    finish: json.finish ? new Date(json.finish) : null,
  };
  delete result.period_months;
  return result;
};

export const decodeContact = (json: any) => {
  const result = {
    ...json,
    channelId: json.channel_id,
    channelName: json.channel_name,
    isPreferred: json.is_preferred,
  };
  delete result.channel_id;
  delete result.channel_name;
  delete result.is_preferred;
  return result;
};

export const decodeBadge = (json: any) => {
  const result = {
    ...json,
    scoreTitle: json.score_title,
    isTop: json.is_top,
    urlText: json.url_text,
    isVisible: json.is_visible,
  };
  delete result.score_title;
  delete result.is_top;
  delete result.url_text;
  delete result.is_visible;
  return result;
};

export const decodeWorkArea = (json: any) => {
  const result = {
    ...json,
    experienceMonths: json.experience_months,
    isPrimary: json.is_primary,
  };
  delete result.experience_months;
  delete result.is_primary;
  return result;
};

export const decodeResume = (json: any): Resume => {
  const result = {
    ...json,
    skillSummary: json.skill_summary,
    fullName: json.full_name,
    photoUrl: json.photo_url,
    contactAccessType: json.contact_access_type,
    experienceSummary: {
      management: json.experience_summary.management_months ? {
        years: Math.floor(json.experience_summary.management_months / 12),
        months: json.experience_summary.management_months % 12,
      } : null,
      total: {
        years: Math.floor(json.experience_summary.total_months / 12),
        months: json.experience_summary.total_months % 12,
      },
    },
    experience: json.experience ? json.experience.map(decodeExperience) : [],
    education: json.education ? json.education.map(decodeEducation) : [],
    workType: json.work_type,
    relocationCity: json.relocation_city,
    relocationType: json.relocation_type,
    relocationCountry: json.countries,
    countryRelocationType: json.country_relocation_type,
    contacts: json.contacts ? json.contacts.map(decodeContact) : [],
    stackSkills: [],
    badges: json.badges ? json.badges.map(decodeBadge) : [],
    workFeatures: json.work_features,
    workAreas: json.work_areas && json.work_areas.length ? json.work_areas.map(decodeWorkArea) : [],
    codeSamples: json.code_samples,
    salary: camelcaseKeys((json.salary || {}), { deep: true }),
    skillSet: decodeSkillSet(json.skills),
    videoInterview: json.video_interview && json.video_interview.video ? decodeVideoInterview(json.video_interview) : undefined,
    viewedAt: json.viewed_at ? json.viewed_at : undefined,
  };
  if (result.links) {
    result.links = {
      full: result.links.full,
      noSalary: result.links.no_salary,
    };
  }
  delete result.contact_access_type;
  delete result.skill_summary;
  delete result.full_name;
  delete result.photo_url;
  delete result.experience_summary;
  delete result.work_type;
  delete result.relocation_city;
  delete result.relocation_type;
  delete result.country_relocation_type;
  delete result.stack_skills;
  delete result.code_samples;
  delete result.extra_skills;
  delete result.work_features;
  delete result.work_areas;
  delete result.viewed_at;
  return result;
};


