import { StackTool, decodeStackTool } from "models/StackTool";
import { Immutable } from 'immer';
import { PoolCounters, decodePoolCounters } from "./Counters";

export type Pool = Immutable<{
  id: string;
  name: string;
  tool?: StackTool;
  counters?: PoolCounters;
  isComingSoon: boolean;
  telegramChannel?: string;
  specializations: string[];
}>

export const decodePool = (json: any): Pool => ({
  id: json.id,
  name: json.name,
  tool: json.tool ? decodeStackTool(json.tool) : undefined,
  counters: json.counters ? decodePoolCounters(json.counters) : undefined,
  isComingSoon: !!json.is_coming_soon,
  telegramChannel: json.telegram_channel,
  specializations: json.specializations && Array.isArray(json.specializations) ? json.specializations : [],
});

export interface PoolOnline {
  readonly candidates?: number;
  readonly companies?: number;
}
