import { createAsyncAction } from 'typesafe-actions';
import { Pool } from 'models/Company/Pool';
import { PoolCounters } from 'models/Company/Counters';

/**
 * Получение пулов
 */
export const fetchPoolsAction = createAsyncAction(
  'pools/POOLS_REQUEST',
  'pools/POOLS_SUCCESS',
  'pools/POOLS_FAILURE',
)<undefined, { pools: Pool[]; counters: PoolCounters }, Error>();
