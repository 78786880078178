/* eslint-disable camelcase */
import groupBy from 'lodash/groupBy';
import { LOCALE } from 'utils/base';

export type SkillType = 'type' | 'skill.language' | 'skill.tech' | 'skill.framework' | 'skill.common';

export interface Skill {
  key: string;
  label: string;
  type: SkillType;
  children?: Skill[];
  groupType: string | null;
  position: number;
  index: number;
}

export interface ApiSkill {
  id?: string;
  uid?: string;
  skill_area?: string;
  skill_technology?: string;
  group?: number;
  key?: string;
  type?: string;
  experience_from_month?: number;
  experience_to_month?: number;
  is_selected?: boolean;
  is_primary?: boolean;
}

interface SkillGroup {
  title: string;
  skills: Skill[];
}

export const getGroupedSkills = (skills: Skill[]): SkillGroup[] => {
  const result: SkillGroup[] = [];

  const skillGroupsRules: { [type: string]: { sort: number; name: string } } = {
    'skill.language': {
      sort: 1,
      name: LOCALE === 'ru' ? 'Язык' : 'Language',
    },
    'skill.framework': {
      sort: 2,
      name: LOCALE === 'ru' ? 'Фреймворк' : 'Framework',
    },
    'skill.tech': {
      sort: 3,
      name:  LOCALE === 'ru' ? 'Технология' : 'Technology',
    },
    'skill.common': {
      sort: 4,
      name:  LOCALE === 'ru' ? 'Прочие' : 'Other',
    },
  };
  const skillGroups = Object.keys(skillGroupsRules);
  const skillGroupsSorts = skillGroups.map(skillGroup => ({
    sort: skillGroupsRules[skillGroup].sort,
    type: skillGroup,
    name: skillGroupsRules[skillGroup].name,
  })).sort();

  const preprocessedSkills = skills.filter(skill => skillGroups.indexOf(skill.type) >= 0).sort((skillLeft, skillRight) => {
    const skillLeftData = skillGroupsRules[skillLeft.type];
    const skillRightData = skillGroupsRules[skillRight.type];

    if (skillLeftData && skillRightData) {
      return skillLeftData.sort < skillRightData.sort ? -1 : 1;
    }

    return 0;
  });

  const skillsGrouped = groupBy(preprocessedSkills, 'type');
  skillGroupsSorts.forEach((value) => {
    if (!skillsGrouped[value.type] || skillsGrouped[value.type].length === 0) {
      return;
    }

    result.push({
      title: value.name,
      skills: skillsGrouped[value.type],
    });
  });

  return result;
};

export const decodeSkill = (json: any): Skill => {
  const result = {
    ...json,
    groupType: json.group_type,
    children: json.children && Array.isArray(json.children)
      ? json.children.map((child: any) => decodeSkill(child)) : undefined,
  };
  delete result.group_type;
  return result;
};

export interface SkillNew {
  id: number;
  name: string;
  image: string;
  link: string;
  type: 'lang' | 'tech' | 'tech.db';
  isPrimary: boolean;
}

export const decodeSkillNew = (json: any): SkillNew => ({
  id: json.id,
  name: json.name,
  image: json.image,
  link: json.link,
  type: json.type,
  isPrimary: json.is_primary,
});
