import { NetworkEntitiesCollecion } from 'store';
import { Pool } from 'models/Company/Pool';
import { PoolCounters } from 'models/Company/Counters';
import { Immutable } from 'immer';

interface PoolsStateInterface {
  counters?: PoolCounters;
  pools: NetworkEntitiesCollecion<Immutable<Pool>>;
}

export type PoolsState = Immutable<PoolsStateInterface>;

export const initialPoolsState: PoolsState = {
  pools: {},
};
