import { Field } from 'react-final-form';
import React, { FC } from 'react';
import { FieldValidator } from 'final-form';
import InputMasked, { InputMaskedProps } from '../v2/Input/InputMasked';

interface Props extends Omit<InputMaskedProps, 'name'> {
  name: string;
  validate?: FieldValidator<string>;
}

export const InputMaskedField: FC<Props> = ({ name, ...rest }) => {
  return (
    <Field name={name}>
      {props => {
        const {
          input, meta, ref,
        } = props;
        const error = meta.touched ? meta.error : '';
        const submitError = meta.touched && !meta.dirtySinceLastSubmit && !meta.submitting ? meta.submitError : '';
        return (
          <>
            <InputMasked
              {...input}
              {...rest}
              ref={ref}
              isInvalid={error || submitError}
            />
            {(error || submitError) && (
              <div className="geecko-field-error">{error || submitError}</div>
            )}
          </>
        );
      }}
    </Field>
  );
};
