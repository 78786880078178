import camelcaseKeys from 'camelcase-keys';

// Группа пользователя
// public - не авторизован
// company - авторизован как сотрудник компании
// candidate - авторизован как кандидат
export type UserGroup = 'public' | 'company' | 'candidate';

export interface User {
  id: number;
  avatar?: string;
  email: string;
  name: string;
  fullName: string;
  position?: string;
  about?: string;
  role?: string;
  emailVerifiedAt?: string;
  chatraId?: string;
}

export const decodeUser = (json: any): User => {
  const jsonCamelCase: any = camelcaseKeys(json, { deep: true });
  if (jsonCamelCase.name) {
    jsonCamelCase.fullName = jsonCamelCase.name;
    delete jsonCamelCase.name;
  }
  if (jsonCamelCase.emailVerifiedAt) {
    jsonCamelCase.emailVerifiedAt = new Date(jsonCamelCase.emailVerifiedAt);
  }
  return {
    ...jsonCamelCase,
  };
};
