import React, { FC } from 'react';
import { useField } from 'react-final-form';

interface Props {
  name: string;
}

const FormError: FC<Props> = ({ name }) => {
  const field = useField(name);
  const { meta } = field;
  const error = meta.touched ? meta.error : '';
  const submitError = meta.touched && !meta.dirtySinceLastSubmit && !meta.submitting ? meta.submitError : '';
  if (error || submitError) {
    return (
      <div className="geecko-field-error">{error || submitError}</div>
    );
  }
  return null;
};

export default FormError;
