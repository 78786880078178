import React, { FC } from 'react';

interface Props {
  size?: number;
}

const UIArrowUpIcon: FC<Props> = ({ size = 25 }) => (
  <svg width={size} height={size} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25"><path d="M16.667 12.5l-4.166-4.167L8.334 12.5M12.5 16.667V8.333" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /></svg>
);

export default UIArrowUpIcon;
