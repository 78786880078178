import React, { HTMLProps } from 'react';
import './Switch.css';

interface Props extends HTMLProps<HTMLInputElement> {
  name?: string;
  label?: string;
  checked?: boolean;
  disabled?: boolean;
  required?: boolean;
  valid?: boolean;
  validationError?: string;
  submitError?: string;
  value?: any;
  variant?: 'small';
}

const Switch: React.FC<Props> = (props: Props) => {
  const {
    value,
    name,
    label,
    disabled,
    required,
    onChange,
    valid,
    validationError,
    submitError,
    variant,
    checked,
  } = props;
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    if (onChange) onChange(event);
  };
  const htmlFor = name ? `switch_${name}` : undefined;

  return (
    <>
      <label className={`geecko-switch${variant ? ` geecko-switch--${variant}` : ''}`} htmlFor={htmlFor}>
        <input
          id={htmlFor}
          className="geecko-switch__input"
          type="checkbox"
          disabled={disabled}
          required={required}
          value={value}
          onChange={handleChange}
          checked={checked}
        />
        <span className="geecko-switch__slider" />
        <span
          className={[
            'geecko-switch__label',
            !value ? 'geecko-switch__label--muted' : '',
          ].join(' ')}
        >
          {label}
        </span>
      </label>
      {!valid ? validationError || submitError : ''}
    </>
  );
};

export default Switch;
