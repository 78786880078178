import React, { KeyboardEvent } from 'react';
import './TagField.css';
import { ucFirst } from 'models/helpers';
import TargetIconOff from 'components/Icons/TargetIconOff';

export enum TagState {
  On,
  Off,
  OnPartial,
}

interface OptionProps {
  label: string|undefined;
  value: string;
  selected: TagState;
  onSelect?: (value: string) => void;
  disabled?: boolean;
}

const TagFieldItem = React.forwardRef((
  props: OptionProps,
  ref: React.Ref<HTMLLIElement>,
) => {
  const {
    label, value, selected, onSelect, disabled,
  } = props;

  const handleSelect = (event: React.MouseEvent<HTMLLIElement>) => {
    event.stopPropagation();
    const target = event.target as HTMLLIElement;
    if (target) {
      target.blur();
    }
    if (onSelect) {
      onSelect(value);
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    switch (event.key) {
      case 'Enter':
      case ' ':
        event.preventDefault();
        event.stopPropagation();
        if (onSelect) {
          onSelect(value);
        }
        break;
      default:
        break;
    }
  };
  return (
    <li
      ref={ref}
      className={[
        'geecko-tagfield__options-item',
        selected === TagState.On ? 'geecko-tagfield__options-item--selected' : '',
        selected === TagState.OnPartial ? 'geecko-tagfield__options-item--selected-partitial' : '',
        disabled ? 'geecko-tagfield__options-item--disabled' : '',
      ].join(' ')}
      value={value}
      onClick={handleSelect}
      onKeyDown={handleKeyDown}
      role="option"
      aria-selected={selected === TagState.On}
      tabIndex={0}
    >
      <span>{ucFirst(label || value)}</span>
      {selected === TagState.OnPartial && <TargetIconOff size={14.5} />}
    </li>
  );
});

export default TagFieldItem;
