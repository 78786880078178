export enum OfferAcceptStatus {
  Approved = 'approved',
  Waiting = 'waiting',
  Pending = 'pending',
}

export interface Balance {
  amount: number;
  currency: string;
}

export interface AuthCompany {
  id?: number;
  name?: string;
  balance?: Balance;
  offerAcceptStatus?: OfferAcceptStatus;
}

export const decodeBalance  = (json: any) => ({
  amount: json.amount,
  currency: json.currency,
})

export const decodeAuthCompany = (json: any) => ({
  id: json.id ? json.id : undefined,
  name: json.name ? json.name : undefined,
  balance: json.balance ? decodeBalance(json.balance) : undefined,
  offerAcceptStatus: json.offer_accept_status ? json.offer_accept_status : undefined,
})
