import { createStore, combineReducers, Store, applyMiddleware } from 'redux';
import createSagaMiddleware, { Task } from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import { ServerApi } from 'modules/api/server-api';
import { MakeStoreOptions } from 'next-redux-wrapper';
import { Immutable } from 'immer';
import { PoolsState, initialPoolsState } from 'store/ducks/pools/state';
import { POOLS_MODULE_NAME, poolsReducer } from 'store/ducks/pools';
import notifyReducer, { NotifyState } from './ducks/notify';
import settingsReducer, { SettingsState } from './ducks/settings';
import { AUTH_MODULE_NAME, authReducer, initialAuthState } from './ducks/auth';
import { CANDIDATE_MODULE_NAME, candidateReducer, initialCandidateState } from './ducks/candidate';
import { CandidateState } from './ducks/candidate/state';
import { DICTIONARY_MODULE_NAME, initialDictionaryState, dictionaryReducer } from './ducks/dictionary';
import { DictionaryState } from './ducks/dictionary/state';
import { rootSaga } from './sagas';
import { COMPANY_MODULE_NAME, initialCompanyState, companyReducer } from './ducks/company';
import { CompanyState } from './ducks/company/state';
import { AuthState } from './ducks/auth/state';
import { RESUME_MODULE_NAME, resumeReducer } from './ducks/resume';
import { ResumeState, initialResumeState } from './ducks/resume/state';

export interface AppState {
  [AUTH_MODULE_NAME]: AuthState;
  notifications: NotifyState;
  settings: SettingsState;
  [CANDIDATE_MODULE_NAME]: CandidateState;
  [COMPANY_MODULE_NAME]: CompanyState;
  [DICTIONARY_MODULE_NAME]: DictionaryState;
  [POOLS_MODULE_NAME]: PoolsState;
  [RESUME_MODULE_NAME]: ResumeState;
}

const isSpecialTariffsViewed =
  typeof localStorage !== 'undefined' ? !!localStorage.getItem('settings_tariffs_viewed') : false;

export interface NetworkEntitiesCommon {
  loading?: boolean;
  loaded?: boolean;
  error?: Error;
}

export interface NetworkEntitiesCollecion<T> extends NetworkEntitiesCommon {
  items?: T[];
}

export type NetworkEntitiesCollecionSelector<T> = (state: AppState) => Immutable<{
  loading: boolean;
  loaded: boolean;
  items: T[];
  error?: Error;
}>;

export type NetworkEntitySelector<T> = (state: AppState) => Immutable<{
  loading: boolean;
  loaded: boolean;
  item?: T;
  error?: Error;
}>;

export interface NetworkEntity<T> extends NetworkEntitiesCommon {
  item?: T;
}

export function getNetworkEntitiesCollectionSelectorState<T>(state: Immutable<NetworkEntitiesCollecion<T>>) {
  return {
    loading: !!state.loading,
    loaded: !!state.loaded,
    error: state.error,
    items: state.items || [],
  }
}

export function getNetworkEntitySelectorState<T>(state: Immutable<NetworkEntity<T>>) {
  return {
    loading: !!state.loading,
    loaded: !!state.loaded,
    error: state.error,
    item: state.item,
  }
}

export const defaultState: AppState = {
  [AUTH_MODULE_NAME]: initialAuthState,
  notifications: {
    notifications: [],
  },
  settings: {
    settings: {
      vacanciesSort: 'new',
      tariffsViewed: isSpecialTariffsViewed,
    },
    data: {
      openedModals: [],
      hiddenElements: [],
    },
  },
  [CANDIDATE_MODULE_NAME]: initialCandidateState,
  [COMPANY_MODULE_NAME]: initialCompanyState,
  [DICTIONARY_MODULE_NAME]: initialDictionaryState,
  [POOLS_MODULE_NAME]: initialPoolsState,
  [RESUME_MODULE_NAME]: initialResumeState,
};

export interface SagaContext {
  api: ServerApi;
}

interface StoreWithSagatask {
  sagaTask?: Task;
}

export const initializeStore = (state: AppState = defaultState, ctx: MakeStoreOptions) => {
  const { isServer, req } = ctx;

  // Создаем главный редьюсер комбинацией редьюсеров модулей
  const rootReducer = combineReducers({
    [AUTH_MODULE_NAME]: authReducer,
    notifications: notifyReducer,
    settings: settingsReducer,
    [CANDIDATE_MODULE_NAME]: candidateReducer,
    [COMPANY_MODULE_NAME]: companyReducer,
    [DICTIONARY_MODULE_NAME]: dictionaryReducer,
    [POOLS_MODULE_NAME]: poolsReducer,
    [RESUME_MODULE_NAME]: resumeReducer,
  });

  // Создаем middleare для redux saga
  const sagaMiddleware = createSagaMiddleware<SagaContext>();

  // Создаем объект стора. В продакшене отключаем redux dev tools
  const store: Store<AppState> & StoreWithSagatask =
    process.env.NODE_ENV !== 'production'
      ? createStore(rootReducer, state, composeWithDevTools(applyMiddleware(sagaMiddleware)))
      : createStore(rootReducer, state, applyMiddleware(sagaMiddleware));

  // Создаем объект API для использования в сагах и сохраняем его в контекте саги
  const api = new ServerApi(store);
  sagaMiddleware.setContext({ api });

  /**
   * next-redux-saga depends on `sagaTask` being attached to the store during `getInitialProps`.
   * It is used to await the rootSaga task before sending results to the client.
   * However, next-redux-wrapper creates two server-side stores per request:
   * One before `getInitialProps` and one before SSR (see issue #62 for details).
   * On the server side, we run rootSaga during `getInitialProps` only:
   */

  // Запускаем рутовую сагу
  if (req || !isServer) {
    store.sagaTask = sagaMiddleware.run(rootSaga);
  }

  return store;
};
